import React, { FunctionComponent } from "react";

const RoundedRect: FunctionComponent<
  React.SVGProps<SVGPathElement> & {
    width: number;
    height: number;
    corners: number[];
  }
> = ({ width, height, corners, ...props }) => {
  const [rTL, rTR, rBR, rBL] = corners;

  return (
    <path
      d={`
        M0,${rTL}
        a${rTL},${rTL} 0,0,1, ${rTL},${-rTL}
        l${width - rTL - rTR},0
        a${rTR},${rTR} 0,0,1 ${rTR},${rTR}
        l0,${height - rTR - rBR}
        a${rBR},${rBR} 0,0,1 ${-rBR},${rBR}
        l${-width + rBR + rBL},0
        a${rBL},${rBL} 0,0,1 ${-rBL},${-rBL}
        l0,${-height + rBL + rTL}z`}
      {...props}
    />
  );
};

export default RoundedRect;
