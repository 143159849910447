import { w3cwebsocket as W3CWebSocket } from "websocket";
const SECTION_LOCK_CHANNEL_NAME = "SectionLockChannel";
const SUBSCRIBE = "subscribe";
//const MESSAGE = "message";

export function subscribeToChanel(
  accountQuestionnaireId: string | number,
  channelName = SECTION_LOCK_CHANNEL_NAME
) {
  return new Promise((resolve, reject) => {
    let wsUrl: string = process.env.REACT_APP_WEB_SOCKET_URL || "";
    const client = new W3CWebSocket(wsUrl);

    client.onopen = () => {
      let payload = {
        command: SUBSCRIBE,
        identifier: JSON.stringify({
          channel: channelName,
          account_questionnaire_id: accountQuestionnaireId
        })
      };
      client.send(JSON.stringify(payload));
      console.info("Subscribed to socket");
      resolve(client);
    };

    client.onerror = (error: any) => {
      reject(error);
    };
  });
}
export function sendMessageToSocket({
  client,
  data,
  channelName = SECTION_LOCK_CHANNEL_NAME,
  accountQuestionnaireId
}: {
  client: any;
  data: any;
  channelName?: string;
  accountQuestionnaireId: string | number;
}) {
  client &&
    client.send(
      JSON.stringify({
        command: "message",
        identifier: JSON.stringify({
          channel: channelName,
          account_questionnaire_id: accountQuestionnaireId
        }),
        data: JSON.stringify(data)
      })
    );
}

export function closeWebSocket(client: any) {
  if (client) {
    console.info("Socket closed");
    client.close();
  }
}
