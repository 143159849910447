import { ChartData } from "chart.js";
import React from "react";
import { Doughnut } from "react-chartjs-2";

interface Props {
  data: ChartData;
  options: any;
}

const DoughnutChart: React.FC<Props> = ({ data, options }) => {
  return <Doughnut type="doughnut" data={data} options={options} />;
};

export default DoughnutChart;
