import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useTranslation } from "react-i18next";
import { SortOptions } from "./types";

type Props = {
  sortOptions: SortOptions[];
  onSort: (selectValue: string | number | null) => void;
};

const Sort: React.FC<Props> = ({ sortOptions, onSort }) => {
  const { t } = useTranslation();
  return (
    <Dropdown>
      <Dropdown.Toggle variant="outline-primary">
        {t("Sort by")}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {sortOptions?.map((option, index: number) => {
          return (
            <Dropdown.Item
              key={`SortIndex-${index}`}
              onSelect={() => {
                onSort(option.value);
              }}
            >
              {t(`${option.label}`)}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default Sort;
