import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";

interface Props {
  id: number | string;
  name: string;
  label: string;
  value: string;
  isDisabled?: boolean;
  checked: boolean;
  onChange?: ({ target }: { target: any }) => void;
}
const Checkbox: React.FC<Props> = ({
  id,
  label,
  value,
  checked,
  isDisabled = false,
  name,
  onChange
}) => {
  const { t } = useTranslation();
  const [fieldVal, setFieldVal] = useState<boolean>();
  useEffect(() => {
    setFieldVal(checked);
  }, [checked]);
  return (
    <div
      className={`custom-control custom-checkbox ${
        fieldVal ? "checkbox-checked" : ""
      }`}
    >
      <input
        type="checkbox"
        name={name}
        id={`checkbox-${id}`}
        className="custom-control-input"
        value={value}
        disabled={isDisabled}
        checked={fieldVal || false}
        onChange={(e) => {
          setFieldVal(e.target.checked);
          onChange && onChange(e);
        }}
      />
      <label className="custom-control-label" htmlFor={`checkbox-${id}`}>
        {t(label)}
      </label>
    </div>
  );
};
export default Checkbox;
