import React, { useState, useEffect } from "react";
import {
  RiskCategorizationOverview,
  Section,
  Question,
  RiskLevel,
  Params
} from "./types";
import Dropdown from "react-bootstrap/Dropdown";
import { getQuestionnaireRiskLevels } from "../../../apis/RiskQuestionOptions";
import { changeQuestionnaireRiskLevel } from "../../../apis/CounterpartySubmittedQuestionnaire";
import { toast } from "react-toastify";
import * as utils from "../../../lib/utils";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { getAppLoader as Load } from "../../presentation/Loader/services";

type Props = {
  overallQuestionnaireRiskLevel: string;
  riskCategorizationOverview: RiskCategorizationOverview;
  allowAnswerSubmit: boolean;
  onPageRefresh?: () => void;
};

const RiskCatOverview: React.FC<Props> = ({
  overallQuestionnaireRiskLevel,
  riskCategorizationOverview,
  allowAnswerSubmit,
  onPageRefresh
}) => {
  const { t } = useTranslation();
  const { accountQuestionnaireId, sectionId }: Params = useParams<any>();
  const NOT_AVAILABLE = t("Not Available");
  const [riskLevelsList, setRiskLevelsList] = useState<RiskLevel[]>([]);
  const [questionnaireRiskLevel, setQuestionnaireRiskLevel] = useState<string>(
    overallQuestionnaireRiskLevel
  );

  useEffect(() => {
    allowAnswerSubmit &&
      getQuestionnaireRiskLevels()
        .then((res: any) => {
          setRiskLevelsList(res.data?.questionnaireRiskLevels);
        })
        .catch((error) => {
          toast.error(utils.formatGraphQLError(error.message));
        });
  }, []);

  function onQuestionnaireRiskChange(eventKey: any) {
    let selectedItemIndex = parseInt(eventKey);
    if (selectedItemIndex > -1) {
      setQuestionnaireRiskLevel(riskLevelsList[selectedItemIndex]?.label);
      changeQuestionnaireRiskLevel({
        accountQuestionnaireId,
        riskLevelId: riskLevelsList[selectedItemIndex]?.id,
        sectionId
      })
        .then((res: any) => {
          toast.success(res?.data?.changeQuestionnaireRiskLevel.message);
          onPageRefresh && onPageRefresh();
        })
        .catch((e: any) => {
          toast.error(utils.formatGraphQLError(e.message));
        });
    }
  }

  return riskCategorizationOverview.sections?.length ? (
    <>
      <div className="pr-4">
        <div className="page-actions heading-leftRight-colum">
          <div>
            <div className="sub-heading-title">
              {`${t("Overall risk category")}:`}
              <div className="m-l-10">
                {allowAnswerSubmit &&
                riskLevelsList.length > 0 &&
                questionnaireRiskLevel ? (
                  <Dropdown
                    className="filter m-0 label-dropdown-custom"
                    data-dropdown="fill"
                    data-dropdowntype="select"
                    onSelect={onQuestionnaireRiskChange}
                  >
                    <Dropdown.Toggle
                      className={`btn rating--${utils.getScoreColor(
                        questionnaireRiskLevel
                      )}`}
                    >
                      <span className="btn__content">
                        {utils.convertRiskLabels(questionnaireRiskLevel)}
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {riskLevelsList?.map(
                        (riskLevel: RiskLevel, index: number) => {
                          return (
                            <Dropdown.Item
                              key={index}
                              eventKey={index.toString()}
                            >
                              <span
                                className={`status status__dot status__dot--${utils.getDotColor(
                                  riskLevel.label
                                )}`}
                              >
                                {utils.convertRiskLabels(riskLevel.label)}
                              </span>
                            </Dropdown.Item>
                          );
                        }
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <div
                    className={`label-18 m-l-10 rating--${
                      questionnaireRiskLevel
                        ? utils.getScoreColor(questionnaireRiskLevel)
                        : "expired"
                    }`}
                  >
                    {questionnaireRiskLevel
                      ? utils.convertRiskLabels(questionnaireRiskLevel)
                      : NOT_AVAILABLE}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pr-4">
        {riskCategorizationOverview.sections.map(
          (section: Section, index: number) => {
            return (
              section.questions.length > 0 && (
                <div className="card mt-4" key={index}>
                  <div className="heading-leftRight-colum table-heading-padding">
                    <div>
                      <div className="form-title">{section.title}</div>
                    </div>
                    <div className="heading-leftRight-colum">
                      <strong className="mb-1">{t("Overall risk")}:</strong>

                      <div
                        className={`label-16 m-l-10 rating--${
                          section.riskLevel
                            ? utils.getScoreColor(section.riskLevel)
                            : "expired"
                        }`}
                      >
                        {section.riskLevel
                          ? utils.convertRiskLabels(section.riskLevel)
                          : NOT_AVAILABLE}
                      </div>
                    </div>
                  </div>
                  <div className="big-table-wrp">
                    <div className="resp-table full-screen-table">
                      <div className="resp-table-header table-row-padding">
                        <div className="table-header-cell col-30-percent">
                          {t("Risk factor")}
                        </div>
                        <div className="table-header-cell col-30-percent">
                          {t("Input")}
                        </div>
                        <div className="table-header-cell">{t("Score")}</div>
                        <div className="table-header-cell table-txt-right">
                          {t("Risk level")}
                        </div>
                      </div>
                      <div className="resp-table-body">
                        {section.questions?.map(
                          (question: Question, index: number) => {
                            return (
                              <div
                                className="resp-table-row table-row-padding"
                                key={index}
                              >
                                <div className="table-body-cell col-30-percent">
                                  <div className="colum-label">
                                    {t("Risk factor")}
                                  </div>
                                  <div className="colum-deas">
                                    {question.title}
                                  </div>
                                </div>
                                <div className="table-body-cell col-30-percent">
                                  <div className="colum-label">Input</div>
                                  <div className="colum-deas">
                                    {question.answer}
                                  </div>
                                </div>
                                <div className="table-body-cell">
                                  <div className="colum-label">Score</div>
                                  <div className="colum-deas">
                                    {question.score}
                                  </div>
                                </div>
                                <div className="table-body-cell table-txt-right">
                                  <div className="colum-label">
                                    {t("Risk level")}
                                  </div>
                                  <div className="colum-deas">
                                    <div
                                      className={`label-16 rating--${utils.getScoreColor(
                                        question.riskLevel
                                      )}`}
                                    >
                                      {question.riskLevel
                                        ? utils.convertRiskLabels(
                                            question.riskLevel
                                          )
                                        : NOT_AVAILABLE}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )
            );
          }
        )}
      </div>
    </>
  ) : null;
};

export default RiskCatOverview;
