import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as utils from "../../../lib/utils";
import GridComponent from "../../presentation/Grid";
import { getColumnConfig, getGridFiltersConfig } from "./services";
import * as apis from "../../../apis/UsersList";
import Pagination from "../../presentation/Pagination";
import PageSize from "../../presentation/PageSize";
import {
  Params,
  PageDetailsState,
  Columns,
  TUser,
  UsersFilterList,
  Role,
  AccountGroup
} from "./types";
import { UserContext } from "../../../Contexts/UserContext";
import { OrgKeyMap } from "../../../constants/app-constants";
import CustomModal from "../../presentation/Modals/CustomModal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import MultiSelect from "../../presentation/MultiSelect";
import "./styles.scss";
import GridFilters from "../../presentation/GridFilters";
import ConfirmationModal from "../../presentation/Modals/ConfirmationModal";
import NoDataFound from "../../presentation/NoDataFound";
import { useTranslation } from "react-i18next";
import { FilterItem } from "../../presentation/GridFilters/types";

type Props = {
  getPageHeaderDetails: (pageDetails: any) => void;
};

const UsersList: React.FC<Props> = ({ getPageHeaderDetails }) => {
  const { t } = useTranslation();
  const { accountId }: Params = useParams<any>();
  const DEFAULT_PAGE_SIZE = 20;
  const DEFAULT_START_PAGE = 1;
  const [pageDetails, setPageDetails] = useState<PageDetailsState>({
    accountId,
    pageSize: DEFAULT_PAGE_SIZE,
    pageNumber: DEFAULT_START_PAGE,
    filters: utils.getLocalStorage("USERS_LIST_FILTERS") || []
  });
  const [responseList, setResponseList] = useState<Columns[]>();
  const [totalUsersCount, setTotalUsersCount] = useState<number>(0);
  const [userListSearchResponseCount, setUserListSearchResponseCount] =
    useState<number>(0);
  const gridData = React.useMemo(() => responseList, [responseList]);
  const [filtersList, setFiltersList] = useState<UsersFilterList | null>();
  const filtersData = React.useMemo(() => filtersList, [filtersList]);
  const columnConfig = React.useMemo(() => getColumnConfig(), []);
  const { userDetails }: any = useContext(UserContext);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [confirmationBoxData, setConfirmationBoxData] = useState({
    title: "",
    message: "",
    callBack: () => {}
  });

  const [initialValues, setInitialValues] = useState<TUser>({
    name: "",
    email: "",
    roles: [],
    accountId,
    accountGroups: []
  });
  const [roles, setRoles] = useState<Role[]>([]);
  const [accountGroups, setAccountGroups] = useState<AccountGroup[]>([]);
  const [accountTypes, setAccountTypes] = useState<string[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [reloadData, setReloadData] = useState<number>(1);
  const [editMode, setEditMode] = useState<boolean>(false);

  const validationSchema = yup.object().shape({
    email: yup.string().email().required(t("Please enter your email")),
    // roles: yup.array().min(1, t("Please add a role")) //make role as optional
  });

  const handlePageSize = (pageSize: number) => {
    //console.log("selected page size is >>> ", pageSize);
    setPageDetails({
      ...pageDetails,
      pageNumber: DEFAULT_START_PAGE,
      pageSize
    });
  };

  const handlePagination = (page: number) => {
    //console.log("Current Page Number >>> ", page);
    setPageDetails({
      ...pageDetails,
      pageNumber: page
    });
  };

  const handleSorting = (value: any) => {
    //console.log("sort is >>> ", value);
    if (value) {
      setPageDetails({
        ...pageDetails,
        pageNumber: DEFAULT_START_PAGE,
        sort: value.sortDirection
          ? [
              {
                column: value.id,
                direction: value.sortDirection,
                priority: 1
              }
            ]
          : []
      });
    }
  };

  const handleSubmit = (values: TUser, { setSubmitting }: any) => {
    if (editMode) {
      apis
        .editUser({
          roles: values.roles
            ? values.roles.map((item: any) => {
                return { id: item.id };
              })
            : [],
          accountId,
          userId: values.userId,
          accountGroups: values.accountGroups
            ? values.accountGroups.map((item: any) => {
                return { id: item.id };
              })
            : []
        })
        .then((res) => {
          //console.log("Result is >>> ", res);
          setShowModal(false);
          const message = res.data?.editUser?.message;
          toast.success(message);
          setReloadData(reloadData + 1);
        })
        .catch((error) => {
          //console.log("Error is >> ", error);
          toast.error(utils.formatGraphQLError(error.message));
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      apis
        .createUser({
          email: values.email,
          roles: values.roles
            ? values.roles.map((item: any) => {
                return { id: item.id };
              })
            : [],
          accountId,
          accountGroups: values.accountGroups
            ? values.accountGroups.map((item: any) => {
                return { id: item.id };
              })
            : []
        })
        .then((res) => {
          //console.log("Result is >>> ", res);
          setShowModal(false);
          const message = res.data?.createUser?.message;
          toast.success(message);
          setReloadData(reloadData + 1);
        })
        .catch((error) => {
          //console.log("Error is >> ", error);
          toast.error(utils.formatGraphQLError(error.message));
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  const handleFilter = (key: string, filter: FilterItem[]) => {
    let filtersList = utils.setActiveFilter(pageDetails.filters, {
      filterName: key,
      filterValue: filter?.length
        ? filter
        : []
    });
    utils.setLocalStorage("USERS_LIST_FILTERS", filtersList);
    setPageDetails({
      ...pageDetails,
      filters: filtersList
    });
  };

  const resetAllFilters = () => {
    utils.removeLocalStorage("USERS_LIST_FILTERS");  
    setPageDetails({
      ...pageDetails,
      filters: []
    });   
  };

  const inviteUser = (userId: string | number) => {
    setConfirmationBoxData(
      Object.assign(
        {},
        {
          title: "Confirm Invitation",
          message: "Are you sure want to invite this user?",
          callBack: () => {
            setShowConfirmationModal(false);
            apis
              .inviteUser({
                userId,
                accountId
              })
              .then((res) => {
                const message = res.data?.inviteUser?.message;
                toast.success(message);
                setReloadData(reloadData + 1);
              })
              .catch((error) => {
                toast.error(utils.formatGraphQLError(error.message));
              });
          }
        }
      )
    );
    setShowConfirmationModal(true);
  };

  const deleteUser = (userId: string | number) => {
    setConfirmationBoxData(
      Object.assign(
        {},
        {
          title: "Confirm Delete",
          message: "Are you sure want to delete this user?",
          callBack: () => {
            setShowConfirmationModal(false);
            apis
              .deleteUser({
                userId,
                accountId
              })
              .then((res) => {
                const message = res.data?.deleteUser?.message;
                toast.success(message);
                setReloadData(reloadData + 1);
              })
              .catch((error) => {
                toast.error(utils.formatGraphQLError(error.message));
              });
          }
        }
      )
    );
    setShowConfirmationModal(true);
  };

  const openEditDialog = (userInfo: TUser) => {
    setShowModal(true);
    setEditMode(true);
    setInitialValues({
      ...userInfo
    });
  };

  useEffect(() => {
    apis
      .getUsersList(pageDetails)
      .then((res: any) => {
        setResponseList(res.data?.usersList);
        setFiltersList(res.data?.userFilterList);
        setUserListSearchResponseCount(res.data?.userListSearchCount);
        setTotalUsersCount(res.data?.totalUsersCount);
        setAccountGroups(res.data?.getAccountGroups);
        setAccountTypes(res.data?.accountTypes);
      })
      .catch((error) => {
        setResponseList([]);
        setFiltersList(null);
        setUserListSearchResponseCount(0);
        setAccountGroups([]);
        setAccountTypes([]);
        toast.error(utils.formatGraphQLError(error.message));
      });
  }, [pageDetails, reloadData]);

  useEffect(() => {
    getPageHeaderDetails({
      title: `${OrgKeyMap.module_name} ${
        utils.isAdminUser(userDetails?.user_type) ? t("Admin") : ""
      }`,
      details: [`${t("Total Users")}: ${totalUsersCount || 0}`]
    });
  }, [totalUsersCount, getPageHeaderDetails, userDetails, reloadData]);

  useEffect(() => {
    if (accountTypes && accountTypes.length) {
      apis
        .getUserRoles(accountTypes)
        .then((res: any) => {
          setRoles(res.data?.getUserRoles);
        })
        .catch((error) => {
          setRoles([]);
          toast.error(utils.formatGraphQLError(error.message));
        });
    }
  }, [accountTypes]);

  useEffect(() => {
    if (!showModal) {
      setInitialValues({
        name: "",
        email: "",
        roles: [],
        accountId,
        accountGroups: []
      });
    }
  }, [showModal, accountId]);

  return (
    <>
      {filtersData && Object.keys(filtersData as UsersFilterList).length && (
        <GridFilters
          onSelect={handleFilter}
          filters={getGridFiltersConfig(filtersData as UsersFilterList)}
          selectedFilters={pageDetails?.filters}
          resetAllFilters={resetAllFilters}
        >
          <div className="row">
            <div className="page-action col-auto">
              <button
                onClick={() => setShowModal(true)}
                type="button"
                className="btn btn-primary"
              >
                {t("Add User")}
              </button>
            </div>
          </div>
        </GridFilters>
      )}

      {gridData && gridData.length > 0 ? (
        <div>
          <GridComponent
            pageIndex={pageDetails.pageNumber - 1}
            pageSize={pageDetails.pageSize}
            gridData={gridData}
            columnConfig={columnConfig}
            defaultPageSize={DEFAULT_PAGE_SIZE}
            totalRecordsCount={userListSearchResponseCount}
            action={{ inviteUser, deleteUser, openEditDialog }}
            onSort={handleSorting}
            hiddenColumns={["userId", "validDomain"]}
          />
          <div className="row">
            <div className="col-sm-12 mt-4">
              <div className="pagination-container">
                <div className="row">
                  <div
                    className="col-sm-4 col-md-4"
                    style={{
                      display: "block"
                    }}
                  >
                    <PageSize
                      handleChange={handlePageSize}
                      defaultPageSize={DEFAULT_PAGE_SIZE}
                    />
                  </div>

                  <div className="col-sm-8 col-md-8">
                    <div className="float-right">
                      <Pagination
                        totalRecordsCount={userListSearchResponseCount}
                        pageSize={pageDetails.pageSize}
                        defaultStartPage={DEFAULT_START_PAGE}
                        handleChange={handlePagination}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        responseList && <NoDataFound />
      )}

      <CustomModal
        modalTitle={editMode ? "Edit User" : "Create User"}
        showModal={showModal}
        onClose={() => {
          setShowModal(false);
          setEditMode(false);
        }}
      >
        <React.Fragment>
          <div id="add-user-container">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                isSubmitting,
                isValid,
                dirty,
                setFieldValue,
                setFieldTouched
              }) => (
                <Form>
                  <div
                    className="form-group"
                    style={{ display: editMode ? "block" : "none" }}
                  >
                    <label htmlFor="name">{t("Name")}:</label>
                    <Field
                      name="name"
                      type="text"
                      className="form-control"
                      placeholder=""
                      disabled={editMode ? true : false}
                    />
                    <div className="mt-1" style={{ color: "red" }}>
                      <ErrorMessage name="name" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">{t("Email")}:</label>
                    <Field
                      name="email"
                      type="text"
                      className="form-control"
                      placeholder={t("Please enter email")}
                      disabled={editMode ? true : false}
                    />
                    <div className="mt-1" style={{ color: "red" }}>
                      <ErrorMessage name="email" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="roles">{t("Roles")}:</label>
                    <MultiSelect
                      name="roles"
                      options={utils.modifyForSelectBox(roles, "id", "label")}
                      defaultValue={utils.modifyForSelectBox(
                        initialValues.roles,
                        "id",
                        "label"
                      )}
                      placeholder={`${t("Select")}...`}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                    <div className="mt-1" style={{ color: "red" }}>
                      <ErrorMessage name="roles" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="accountGroups">
                      {t("Account Groups")}:
                    </label>
                    <MultiSelect
                      name="accountGroups"
                      options={utils.modifyForSelectBox(
                        accountGroups,
                        "id",
                        "name"
                      )}
                      defaultValue={utils.modifyForSelectBox(
                        initialValues.accountGroups && initialValues.accountGroups.length ? initialValues.accountGroups : accountGroups.filter(ele=>ele.isDefaultGroup),
                        "id",
                        "name"
                      )}
                      placeholder={`${t("Select")}...`}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                    <div className="mt-1" style={{ color: "red" }}>
                      <ErrorMessage name="accountGroups" />
                    </div>
                  </div>
                  <div className="form-group mt-5">
                    <div className="row align-items-baseline">
                      <button
                        type="submit"
                        className={`col-sm-auto btn btn-primary ${
                          !(dirty && isValid) || isSubmitting ? "disabled" : ""
                        }`}
                        disabled={!(dirty && isValid) || isSubmitting}
                      >
                        {editMode ? t("Update") : t("Create")}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </React.Fragment>
      </CustomModal>

      {/* Modal for confirmation box start */}
      <ConfirmationModal
        modalTitle={confirmationBoxData.title}
        message={confirmationBoxData.message}
        callBack={confirmationBoxData.callBack}
        showConfirmationModal={showConfirmationModal}
        onModalClose={() => {
          setShowConfirmationModal(false);
        }}
      />
    </>
  );
};

export default UsersList;
