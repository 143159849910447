import { ApprovalDetails } from "../../SubmittedQuestionnaire/types";
import { ApprovalStepsDetails } from "./types";
import {
  APPROVED,
  READY_FOR_APPROVAL,
  NOT_READY_FOR_APPROVAL
} from "../../../../constants/app-constants";
export function parseApprovalSteps(approvalDetails: ApprovalDetails[]) {
  if (!approvalDetails?.length) {
    return approvalDetails;
  }
  let parsedData: ApprovalStepsDetails[] = [];
  let sequence: number = -1;
  let counter = 0;
  let approvalData = [...approvalDetails];

  approvalData
    .sort((a, b) => a.approvalStep?.sequence - b.approvalStep?.sequence)
    .forEach((step) => {
      if (Number(sequence) !== Number(step.approvalStep?.sequence)) {
        counter++;
      }
      let foundStepIndex = parsedData.findIndex(
        (val: ApprovalStepsDetails) => val.sequence === counter
      );
      let derivedStatus =
        step.approval?.approvalStatus === APPROVED
          ? APPROVED
          : isPreviousStepApproved(parsedData, counter - 1)
          ? READY_FOR_APPROVAL
          : NOT_READY_FOR_APPROVAL;
      if (foundStepIndex !== -1) {
        let steps = parsedData[foundStepIndex].steps;
        steps.push({
          title: step.approvalStep?.approvalName,
          group: step.accountGroup?.name,
          approvalStatus: derivedStatus,
          isCommentsPresent: step.approvalComments?.length ? true : false,
          approvedBy: step.approval?.approvedBy,
          approvedAt: step.approval?.approvedAt
        });
        parsedData[foundStepIndex] = { ...parsedData[foundStepIndex], steps };
      } else {
        parsedData.push({
          sequence: counter,
          steps: [
            {
              title: step.approvalStep?.approvalName,
              group: step.accountGroup?.name,
              approvalStatus: derivedStatus,
              isCommentsPresent: step.approvalComments?.length ? true : false,
              approvedBy: step.approval?.approvedBy,
              approvedAt: step.approval?.approvedAt
            }
          ]
        });
      }

      sequence = step.approvalStep?.sequence;
    });
  return parsedData;
}
function isPreviousStepApproved(
  data: ApprovalStepsDetails[],
  sequence: number
) {
  if (!data.length) {
    return true;
  }
  let foundStep = data.find((val) => Number(val.sequence) === Number(sequence));
  if (foundStep) {
    let notApproved = foundStep.steps.find(
      (val) =>
        val.approvalStatus === NOT_READY_FOR_APPROVAL ||
        val.approvalStatus === READY_FOR_APPROVAL
    );
    if (notApproved) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
}
