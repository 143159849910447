import React, { FunctionComponent } from "react";
import * as d3 from "d3";
import { OrgVisualizationNode } from "../types";

const Link: FunctionComponent<{
  link: d3.HierarchyPointLink<OrgVisualizationNode>;
}> = ({ link }) => {
  const yMiddle = (link.source.y + link.target.y) / 2;
  const points = [
    [link.source.x, link.source.y],
    [link.source.x, yMiddle],
    [link.target.x, yMiddle],
    [link.target.x, link.target.y]
  ];

  const d = `M${points.map(([x, y]) => `${x},${y}`).join("L")}`;

  return <path d={d} />;
};

export default Link;
