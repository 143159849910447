import axios, { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";
import { PERMISSION_ERROR_MESSAGE } from "../lib/questionnairePermissionsAccess";

export const uploadFile = async (
  file: any,
  params: {
    accountLevelOneQuestionnaireId?: string | number;
    tempFileId?: string | number;
    fileType: string;
  }
): Promise<AxiosResponse> => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const uploadedFiles: AxiosResponse = await axios({
      method: "post",
      url: `${process.env.REACT_APP_REST_ENDPOINT}/api/v1/uploadFile`,
      data: formData,
      params: {
        ...params
      },
      withCredentials: true
    });

    return uploadedFiles;
  } catch (error) {
    throw new Error((error as any).message);
  }
};

export const downloadFile = ({
  accountLevelOneQuestionnaireId,
  timeStampedFileName,
  originalFileName,
  fileType,
  sectionId
}: {
  accountLevelOneQuestionnaireId: string | number;
  timeStampedFileName: string;
  originalFileName: string;
  fileType: string;
  sectionId?: string | number;
}) => {
  axios({
    method: "GET",
    url: `${process.env.REACT_APP_REST_ENDPOINT}/api/v1/downloadFile`,
    params: { accountLevelOneQuestionnaireId, timeStampedFileName, fileType, ...(sectionId && {sectionId}) },
    withCredentials: true,
    responseType: "blob"
  })
    .then((res: AxiosResponse) => {
      let responseBlob = res.data;
      let format = originalFileName.split(".").pop();
      if (format === "xlsx") {
        responseBlob = new Blob([responseBlob], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
      } else {
        responseBlob = new Blob([responseBlob]);
      }

      saveAs(responseBlob, originalFileName);
    })
    .catch((err: any) => {
      if (err?.response?.status === 403) {
        toast.error(PERMISSION_ERROR_MESSAGE);
      } else {
        toast.error(err?.message);
      }
    });
};

export const deleteFile = async ({
  accountLevelOneQuestionnaireId,
  timeStampedFileName,
  fileType,
  questionnaireFileUploadId
}: {
  accountLevelOneQuestionnaireId: string | number;
  timeStampedFileName: string;
  fileType: string;
  questionnaireFileUploadId: string | number | null;
}): Promise<AxiosResponse> => {
  try {
    const deleteFile: AxiosResponse = await axios({
      method: "DELETE",
      url: `${process.env.REACT_APP_REST_ENDPOINT}/api/v1/deleteFile`,
      withCredentials: true,
      params: {
        accountLevelOneQuestionnaireId,
        timeStampedFileName,
        fileType,
        questionnaireFileUploadId
      }
    });

    return deleteFile;
  } catch (error) {
    throw new Error((error as any).message);
  }
};

export const downloadReports = ({
  accountQuestionnaireId,
  fileName,
  fileType,
  fileExtension
}: {
  accountQuestionnaireId: string | number;
  fileName: string;
  fileType: string;
  fileExtension?: string;
}) => {
  axios({
    method: "GET",
    url: `${process.env.REACT_APP_REST_ENDPOINT}/api/v1/downloadFile`,
    params: {
      accountQuestionnaireId,
      fileType
    },
    withCredentials: true,
    responseType: "blob"
  })
    .then((res) => {
      let responseBlob = new Blob([res.data]);
      saveAs(
        responseBlob,
        `${fileExtension ? `${fileName}.${fileExtension}` : fileName}`
      );
    })
    .catch((err: any) => {
      if ([403, 400].includes(err?.response?.status)) {
        toast.error(PERMISSION_ERROR_MESSAGE);
      } else {
        toast.error(err?.message);
      }
    });
};
