import gql from "graphql-tag";

export const GET_QUESTIONNAIRE_RESPONSE_LIST_QUERY = gql`
  query QuestionnaireResponseList(
    $assetManagerId: ID!
    $pageNumber: Int!
    $pageSize: Int!
    $search: String
    $sort: [SortingAttributes!]
    $filters: [QuestionnaireFilterAttributes!]
  ) {
    questionnaireResponseList(
      assetManagerId: $assetManagerId
      pageNumber: $pageNumber
      pageSize: $pageSize
      search: $search
      sort: $sort
      filters: $filters
    ) {
      id
      distributor
      distributorId
      questionnaireId
      questionnaireTitle
      score
      submissionDate
      expiryDate
      lastUpdatedDate
      status {
        id
        label
        name
      }
      isClientManaged
      amlReport
      questionnairePdfError
      riskLevel
      completePercentage
      sectionsCount
      submissionDate
      submittedQuestionnaireId
      sectionSubmission
      reopenRequest {
        requestedAt
        requestedBy
      }
    }
    questionnaireResponseCount(assetManagerId: $assetManagerId)
    questionnaireFilterList(
      filters: $filters
      assetManagerId: $assetManagerId
    ) {
      counterpartyList
      questionnaireList
      accountTypeList
      validUntilList
      statusList {
        id
        label
        name
      }
      riskLevels {
        label
        value
      }
    }
    questionnaireSearchResponseCount(
      filters: $filters
      assetManagerId: $assetManagerId
      search: $search
    )
    latestSubmissionDate(assetManagerId: $assetManagerId)
  }
`;

export const GET_QUESTIONNAIRE_SECTIONS_LIST_QUERY = gql`
  query AssetManagerAccountQuestionnaireSections(
    $assetManagerId: ID!
    $accountQuestionnaireId: ID!
  ) {
    assetManagerAccountQuestionnaireSections(
      accountQuestionnaireId: $accountQuestionnaireId
      assetManagerId: $assetManagerId
    ) {
      id
      title
      status {
        id
        label
        name
      }
      isClientManaged
      submittedQuestionnaireStatus {
        id
        label
        name
      }
      approvalDetails {
        approvalStatus
        approvedAt
        approvedBy
        approvalGroupName
        isApproved
        sequence
        approvalStepName
        stepGrpRoleId
      }
      lastUpdatedDate
      submissionDate
      score
      riskLevel
      completePercentage
      accountQuestionnaireId
      submittedQuestionnaireId
      sectionSubmission
      reopenRequest {
        requestedAt
        requestedBy
      }
      permissions {
        name
      }
      groups {
        id
        name
      }
    }
  }
`;
