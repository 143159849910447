import React, { useState, useEffect, useContext, useRef } from "react";
import CustomModal from "../../../presentation/Modals/CustomModal";
import {
  ApprovalDetails,
  AddComments,
  UpdateComments,
  DeleteComments
} from "../../SubmittedQuestionnaire/types";
import { toast } from "react-toastify";
import { getDate } from "../../../../lib/utils";
import Icons from "../../../presentation/Icons";
import { ApprovalCommentsData, CommentableApprovalSteps } from "./types";
import { prepareCommentData, getCommentableApprovalSteps } from "./service";
import { UserContext } from "../../../../Contexts/UserContext";
import Select from "react-select";
import { modifyForSelectBox } from "../../../../lib/utils";
import { FOUR_EYE_CHECK } from "../../../../constants/app-constants";
import { useTranslation } from "react-i18next";
import { QueryPrameters } from "../../../../types/applicationData";
import { useQueryParams } from "../../../../Hooks/useQueryParams";
type Props = {
  approvalDetails: ApprovalDetails[];
  showModal: boolean;
  onClose: () => void;
  onAddComment: (comments: AddComments) => void;
  onUpdateComment: (comments: UpdateComments) => void;
  onDeleteComment: (comments: DeleteComments) => void;
  allowApprovals: boolean;
};
const ApprovalComments: React.FC<Props> = ({
  approvalDetails,
  showModal,
  onClose,
  onAddComment,
  onUpdateComment,
  onDeleteComment,
  allowApprovals
}) => {
  const queryParams: QueryPrameters | undefined = useQueryParams({
    paramNames: ["approvalCommentId"]
  });
  const commentRef = useRef<any>();
  const isScrollingDone = useRef<boolean>(false);
  const { t } = useTranslation();
  const { userDetails }: any = useContext(UserContext);
  const [commentList, setCommentList] = useState<ApprovalCommentsData[]>([]);
  const [availableStepsForComment, setAvailableStepsForComment] =
    useState<CommentableApprovalSteps[]>();
  useEffect(() => {
    if (queryParams?.approvalCommentId && !isScrollingDone.current) {
      commentRef?.current?.scrollIntoView(true);
      isScrollingDone.current = true;
    }
  });
  useEffect(() => {
    setCommentList([...prepareCommentData(approvalDetails)]);
    setAvailableStepsForComment([
      ...modifyForSelectBox(
        getCommentableApprovalSteps({
          approvalDetails,
          loggedInUser: userDetails
        }),
        "approvalStepGroupRoleId",
        "title",
        "approvalType"
      )
    ]);
  }, [approvalDetails]);
  const [approvalStepComment, setApprovalStepComment] = useState<any>(null);

  const [comments, setComments] = useState<string>();
  const [editedComments, setEditedComments] = useState<string>();
  //   function handleCommentTypeChange(commentType: string) {
  //     setCommentType(commentType);
  //   }
  async function deleteComment(comment: ApprovalCommentsData) {
    if (
      comment.id &&
      comment.commentedById.toString() === userDetails.user_id.toString()
    ) {
      //call delete comment here
      onDeleteComment({
        approvalStepGroupRoleId: comment.approvalStepGroupRoleId,
        id: comment.id
      });
    }
  }
  function enableDisableEdit(comment: ApprovalCommentsData, type: string) {
    let commentIndex = commentList.findIndex((val) => val.id === comment.id);
    let updatedCommentList = [...commentList];
    updatedCommentList[commentIndex] = {
      ...comment,
      enableEdit: type === "enable" ? true : false
    };
    setCommentList([...updatedCommentList]);
    if (type === "enable") {
      setEditedComments(comment.comment);
    }
  }

  async function updatedComment(comment: ApprovalCommentsData, type: string) {
    if (type === "save") {
      if (editedComments) {
        if (
          comment.id &&
          comment.commentedById.toString() === userDetails.user_id.toString()
        ) {
          //call update comment here
          onUpdateComment({
            approvalStepGroupRoleId: comment.approvalStepGroupRoleId,
            comment: editedComments,
            id: comment.id
          });
        }
      } else {
        toast.error(t("Please enter comments"));
      }
    } else {
      enableDisableEdit(comment, "disable");
      setEditedComments("");
    }
  }
  async function addComments() {
    if (comments?.trim()) {
      onAddComment({
        comment: comments,
        approvalStepGroupRoleId: approvalStepComment.id
      });
      setComments("");
    } else {
      toast.error(t("Please enter comments"));
    }
  }
  return (
    <>
      <CustomModal
        modalTitle={t("Comments")}
        classNames="cdd-groups-management cdd-auto-feature editable"
        showModal={showModal}
        onClose={() => {
          onClose();
        }}
        showFooter={true}
        footer={
          <div className="comments">
            {commentList && commentList.length > 0
              ? commentList.map(
                  (comment: ApprovalCommentsData, index: number) => {
                    return (
                      <div key={index} className={`comment`}>
                        <div className="comment__meta">
                          <span className="mr-1">{comment.commentedBy}</span>
                          <span className="text-muted">
                            {getDate(
                              comment.createdAt,
                              "MMMM Do YYYY, h:mm:ss a"
                            )}{" "}
                            for {comment.title} approval
                            {comment.approvalType === FOUR_EYE_CHECK
                              ? `(${comment.approvalType})`
                              : ""}
                          </span>
                          {allowApprovals &&
                            availableStepsForComment &&
                            availableStepsForComment.length > 0 && (
                              <span className="comment__private-note">
                                {!comment.enableEdit &&
                                comment.commentedById.toString() ===
                                  userDetails.user_id.toString() ? (
                                  <>
                                    <span
                                      className="text-primary"
                                      onClick={() =>
                                        enableDisableEdit(comment, "enable")
                                      }
                                    >
                                      <Icons className="icon" icon={"edit"} />
                                    </span>
                                    <span
                                      className="text-primary"
                                      onClick={() => deleteComment(comment)}
                                    >
                                      <Icons className="icon" icon={"delete"} />
                                    </span>
                                  </>
                                ) : (
                                  ""
                                )}
                              </span>
                            )}
                        </div>
                        {comment.enableEdit ? (
                          <div className="comment">
                            <div className="comment__content">
                              <div className="form-group">
                                <textarea
                                  //type="text"
                                  className="form-control form-control--bordered"
                                  rows={6}
                                  placeholder={t("Enter your comment here")}
                                  onChange={(data) =>
                                    setEditedComments(data.target.value)
                                  }
                                  value={editedComments}
                                ></textarea>
                              </div>
                              <button
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={() => updatedComment(comment, "save")}
                              >
                                {t("Save")}
                              </button>
                              <button
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={() =>
                                  updatedComment(comment, "cancel")
                                }
                              >
                                {t("Cancel")}
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="comment__content"
                            ref={commentRef}
                            id={`approval-comments-${comment.id}`}
                          >
                            {comment.comment}
                          </div>
                        )}
                      </div>
                    );
                  }
                )
              : t("No comments available")}
          </div>
        }
      >
        {allowApprovals &&
          availableStepsForComment &&
          availableStepsForComment.length > 0 && (
            <div className="modal__body">
              <div className="form-group">
                <Select
                  placeholder={t("Select approval step to add comment")}
                  name="approvalStep"
                  onChange={(data) => setApprovalStepComment(data)}
                  value={approvalStepComment}
                  options={availableStepsForComment}
                  noOptionsMessage={() => t("No options")}
                />
              </div>
              {approvalStepComment?.value && (
                <>
                  <div className="form-group">
                    <textarea
                      //type="text"
                      className="form-control form-control--bordered"
                      rows={6}
                      placeholder={t("Enter your comment here")}
                      value={comments || ""}
                      onChange={(data) => setComments(data.target.value)}
                    ></textarea>
                  </div>
                  <button
                    className="btn btn-primary"
                    onClick={() => addComments()}
                    disabled={comments ? false : true}
                  >
                    {t("Add comment")}
                  </button>
                </>
              )}
            </div>
          )}
      </CustomModal>
    </>
  );
};
export default ApprovalComments;
