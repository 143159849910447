import {
  ApprovalDetails,
  ApprovalComments
} from "../../SubmittedQuestionnaire/types";
import { TUserDetails } from "../../../../types/user-details";
import { ApprovalCommentsData, CommentableApprovalSteps } from "./types";
import { APPROVED } from "../../../../constants/app-constants";
import { FOUR_EYE_CHECK } from "../../../../constants/app-constants";
export function prepareCommentData(approvalDetails: ApprovalDetails[]) {
  let comments: ApprovalCommentsData[] = [];
  approvalDetails?.length &&
    approvalDetails.forEach((approvalData) => {
      approvalData.approvalComments?.length &&
        approvalData.approvalComments.forEach((comment: ApprovalComments) => {
          comments.push({
            approvalStepGroupRoleId: approvalData.id,
            comment: comment.comment,
            id: comment.id,
            createdAt: comment.createdAt,
            updatedAt: comment.updatedAt,
            commentedBy: comment.user.name,
            commentedById: comment.user.userId,
            title: approvalData.approvalStep?.approvalName,
            group: approvalData.accountGroup?.name,
            approvalType: approvalData.approvalStep?.approvalType?.name
          });
        });
    });
  return comments.sort((a: ApprovalCommentsData, b: ApprovalCommentsData) => {
    return new Date(a.createdAt).valueOf() - new Date(b.createdAt).valueOf();
  });
}
export function getCommentableApprovalSteps({
  approvalDetails,
  loggedInUser
}: {
  approvalDetails: ApprovalDetails[];
  loggedInUser: TUserDetails;
}) {
  let approvalSteps: CommentableApprovalSteps[] = [];
  approvalDetails?.length &&
    approvalDetails.forEach((step) => {
      if (
        step.approval?.approvalStatus !== APPROVED &&
        loggedInUser.groups.find(
          (val) => String(val.id) === String(step.accountGroup.id)
        )
      ) {
        approvalSteps.push({
          approvalStepGroupRoleId: step.id,
          title: step.approvalStep?.approvalName,
          approvalType:
            step.approvalStep?.approvalType?.name === FOUR_EYE_CHECK
              ? step.approvalStep?.approvalType?.name
              : ""
        });
      }
    });
  return approvalSteps;
}
