import React from "react";
import Icons from "../../presentation/Icons";
import * as utils from "../../../lib/utils";
import { Column } from "react-table";
import { Columns, QuestionnaireFilterList, ReopenRequestType } from "./types";
import { Filter } from "../../presentation/GridFilters/types";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {
  allowStatusChange,
  DRAFT,
  acceptRequestToReopen
} from "../../../lib/questionnaireStatusAccess";
import {
  OrgKeyMap,
  PDF_FILE_EXTENSION,
  ZIP_FILE_EXTENSION,
  FILE_TYPE_QUESTIONNAIRE_PDF,
  FILE_TYPE_QUESTIONNAIRE_PDF_IN_ZIP,
  FILE_TYPE_AML_REPORT
} from "../../../constants/app-constants";
import { downloadReports } from "../../../services/FileUploadDownloadService";
import { toast } from "react-toastify";
import {
  USER_ADMIN,
  sectionAccessPermission,
  ASSET_MANAGER_USER,
  ADMIN_SECTION_ROLE_ASSIGNMENT_CP_PERMISSION,
  findUserPermissionsFromRoles,
  permissionError,
  ADMIN_SECTION_REOPEN_PERMISSION,
  ADMIN_STATUS_CHANGE_PERMISSION
} from "../../../lib/questionnairePermissionsAccess";
import i18n from "../../../i18n";

export function getSectionColumnConfig(
  assetManagerId: number | string,
  userDetails: any,
  changeStatus: (data: {
    changeSectionId?: string | number;
    changeAccountQuestionnaireId?: string | number;
    currentStatus: string;
    isClientManaged: boolean;
  }) => void,
  reopenRequest: (data: {
    changeSectionId?: string | number;
    changeAccountQuestionnaireId?: string | number;
    reopenRequest: ReopenRequestType | null;
    sectionName?: string;
    questionnaireName?: string;
    currentStatus: string;
  }) => void
) {
  const columnConfig: Column<Columns>[] = [
    {
      Header: "Section Name",
      accessor: "title"
    },
    {
      Header: "Groups/Departments",
      accessor: "groups",
      Cell: (cellProps: any) => {
        let groups = "";
        if (cellProps.value && cellProps.value.length) {
          groups = cellProps.value.map((v: any, i: any) => v.name).join(", ");
        }
        return <span>{groups}</span>;
      }
    },
    {
      Header: "Risk Level",
      accessor: "riskLevel",
      Cell: (cellProps: any) => {
        return (
          <div
            className={`rating rating--medium rating--${utils.getScoreColor(
              cellProps.row.original.riskLevel
            )}`}
          >
            <div className="rating__number h5">
              {cellProps.row.original.riskLevel
                ? utils.convertRiskLabels(cellProps.row.original.riskLevel)
                : ""}
            </div>
          </div>
        );
      }
    },
    {
      Header: "Date Submitted",
      accessor: "submissionDate",
      Cell: (cellProps: any) => (
        <span>
          {cellProps.value ? utils.getDate(cellProps.value, "DD/MM/YYYY") : ""}
        </span>
      )
    },
    {
      Header: "Last update",
      accessor: "lastUpdatedDate",
      Cell: (cellProps: any) => (
        <span>
          {cellProps.value ? utils.getDate(cellProps.value, "DD/MM/YYYY") : ""}
        </span>
      )
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (cellProps: any) => {
        return (
          <span
            className={`approval-label-status label-${utils.getStatusCapsule(
              cellProps.value.name
            )}`}
          >
            {`${i18n.t(cellProps.value.label)}${
              cellProps.value.name === DRAFT
                ? " - " + cellProps.row.original.completePercentage + "%"
                : ""
            }`}
          </span>
        );
      }
    },
    {
      Header: "Approval Details",
      accessor: "approvalDetails",
      Cell: (cellProps: any) => {
        return (
          <span>
            {cellProps.row.original.sortedApprovedList?.length ? (
              <div className="status-timeline-wrap dropdown">
                <ul className="dropbtn">
                  {cellProps.row.original.sortedApprovedList.map(
                    (approvalDetail: any, index: any) => {
                      return (
                        <li key={index}>
                          <span
                            className={`${
                              approvalDetail.isApproved ? "green" : "grey"
                            }`}
                          ></span>
                        </li>
                      );
                    }
                  )}
                </ul>
                <div className="approval-dropdown">
                  <div className="approval-dropdown__title">
                    <h4>{i18n.t("Approval List")}</h4>
                  </div>
                  <div className="approval-dropdown__body">
                    {cellProps.row.original.sortedApprovedList[0]
                      ?.isApproved && (
                      <div className="success-list">
                        <ul>
                          {cellProps.row.original.sortedApprovedList.map(
                            (approvalDetail: any) => {
                              return approvalDetail.isApproved ? (
                                <li>
                                  <span>
                                    <Icons icon="greenDot"></Icons>
                                  </span>
                                  <span>{approvalDetail.approvalStepName}</span>
                                </li>
                              ) : null;
                            }
                          )}
                        </ul>
                      </div>
                    )}
                    <div className="pending-list">
                      <ul>
                        {cellProps.row.original.sortedApprovedList.map(
                          (approvalDetail: any) => {
                            return !approvalDetail.isApproved ? (
                              <li>
                                <span>
                                  <Icons icon="orangeDot" />
                                </span>
                                <span>{approvalDetail.approvalStepName}</span>
                              </li>
                            ) : null;
                          }
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="caret-shadow"></div>
                </div>
                <div className="status-txt">
                  {cellProps.row.original?.pendingList?.length
                    ? cellProps.row.original?.pendingList
                        .map((list: any) => list.approvalStepName)
                        .join(", ") + ` ${i18n.t("pending")}`
                    : `${i18n.t("completed")}`}
                </div>
              </div>
            ) : cellProps.row.original.isSectionLevelApproval ? (
              <span>Section Level Approval</span>
            ) : (
              ""
            )}
          </span>
        );
      }
    },
    {
      Header: "Actions",
      accessor: "actions",
      disableSortBy: true,
      Cell: (cellProps: any) => {
        let sectionPermission = sectionAccessPermission({
          permissions: cellProps.row.original.permissions,
          permissionType: ASSET_MANAGER_USER
        });
        return (
          <div className="action-group">
            {cellProps.row.original.sectionSubmission &&
              acceptRequestToReopen({
                status: cellProps.row.original.status?.name
              }) && (
                <>
                  {cellProps.row.original.reopenRequest?.requestedAt && (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`reopen-info-questionnaire`}>
                          {i18n.t(
                            `{{name}} has requested to reopen the section at {{date}}`,
                            {
                              name: cellProps.row.original.reopenRequest
                                ?.requestedBy,
                              date: utils.getDate(
                                cellProps.row.original.reopenRequest
                                  ?.requestedAt,
                                "MMMM Do YYYY, h:mm:ss a"
                              )
                            }
                          )}
                        </Tooltip>
                      }
                    >
                      <span
                        className="action"
                        onClick={() => {
                          if (
                            findUserPermissionsFromRoles([
                              {
                                name: USER_ADMIN,
                                permissionType: ASSET_MANAGER_USER
                              },
                              { name: ADMIN_SECTION_REOPEN_PERMISSION }
                            ])
                          ) {
                            reopenRequest({
                              changeSectionId: cellProps.row.original.id,
                              reopenRequest:
                                cellProps.row.original.reopenRequest,
                              sectionName: cellProps.row.original.title,
                              currentStatus: cellProps.row.original.status?.name
                            });
                          } else {
                            permissionError();
                          }
                        }}
                      >
                        <Icons className="icon" icon="activeDocument" />
                      </span>
                    </OverlayTrigger>
                  )}
                </>
              )}
            {cellProps.row.original.sectionSubmission &&
              allowStatusChange({
                status: cellProps.row.original.status?.name,
                userType: userDetails.user_type
              }) && (
                <OverlayTrigger
                  placement="top"
                  rootClose
                  overlay={
                    <Tooltip id={`change-section-status`}>
                      {i18n.t("Change status")}
                    </Tooltip>
                  }
                >
                  <span
                    className="action"
                    onClick={() => {
                      if (
                        findUserPermissionsFromRoles([
                          {
                            name: USER_ADMIN,
                            permissionType: ASSET_MANAGER_USER
                          },
                          { name: ADMIN_STATUS_CHANGE_PERMISSION }
                        ])
                      ) {
                        changeStatus({
                          changeSectionId: cellProps.row.original.id,
                          currentStatus: cellProps.row.original.status?.name,
                          isClientManaged:
                            cellProps.row.original.isClientManaged
                        });
                      } else {
                        permissionError();
                      }
                    }}
                  >
                    <Icons className="icon" icon="circleStatusChange" />
                  </span>
                </OverlayTrigger>
              )}
            <div
              className="action action--view link_loader"
              onClick={() =>
                cellProps.action &&
                cellProps.action.navigateToLink(
                  `/submittedQuestionnaire/am/${
                    cellProps.row.values.accountQuestionnaireId
                  }/${assetManagerId}/${
                    cellProps.row.values.submittedQuestionnaireId || "new"
                  }/${cellProps.row.values.id}`,
                  sectionPermission.viewSection
                )
              }
            >
              <Icons className="icon" icon="edit" />
            </div>
          </div>
        );
      }
    },
    {
      accessor: "accountQuestionnaireId",
      Cell: () => null,
      Header: () => null
    },
    {
      Cell: () => null,
      Header: () => null,
      accessor: "completePercentage"
    },
    {
      accessor: "id",
      Cell: () => null,
      Header: () => null
    },
    {
      accessor: "submittedQuestionnaireId",
      Cell: () => null,
      Header: () => null
    },
    {
      accessor: "permissions",
      Cell: () => null,
      Header: () => null
    },
    {
      accessor: "submittedQuestionnaireStatus",
      Cell: () => null,
      Header: () => null
    }
  ];
  return columnConfig;
}

export function getQuestionnaireColumnConfig(
  assetManagerId: number | string,
  userDetails: any,

  changeStatus: (data: {
    changeSectionId?: string | number;
    changeAccountQuestionnaireId?: string | number;
    currentStatus: string;
    isClientManaged: boolean;
  }) => void,
  reopenRequest: (data: {
    changeSectionId?: string | number;
    changeAccountQuestionnaireId?: string | number;
    reopenRequest: ReopenRequestType | null;
    questionnaireName?: string;
    currentStatus: string;
  }) => void,
  assignGroupsToSection: any
) {
  const columnConfig: Column<Columns>[] = [
    {
      Header: `${OrgKeyMap.end_user_name}`,
      accessor: "distributor"
    },
    {
      Header: "Questionnaire",
      accessor: "questionnaireTitle"
    },
    {
      Header: "Risk Level",
      accessor: "riskLevel",
      Cell: (cellProps: any) => {
        return (
          <div
            className={`rating rating--medium rating--${utils.getScoreColor(
              cellProps.row.original.riskLevel
            )}`}
          >
            <div className="rating__number h5">
              {cellProps.row.original.riskLevel
                ? utils.convertRiskLabels(cellProps.row.original.riskLevel)
                : ""}
            </div>
          </div>
        );
      }
    },
    {
      Header: "Date Submitted",
      accessor: "submissionDate",
      Cell: (cellProps: any) => (
        <span>
          {cellProps.value ? utils.getDate(cellProps.value, "DD/MM/YYYY") : ""}
        </span>
      )
    },
    {
      Header: "Last update",
      accessor: "lastUpdatedDate",
      Cell: (cellProps: any) => (
        <span>
          {cellProps.value ? utils.getDate(cellProps.value, "DD/MM/YYYY") : ""}
        </span>
      )
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (cellProps: any) => {
        return (
          <span
            className={`approval-label-status label-${utils.getStatusCapsule(
              cellProps.value.name
            )}`}
          >
            {`${i18n.t(cellProps.value.label)}${
              cellProps.value.name === DRAFT
                ? " - " + cellProps.row.original.completePercentage + "%"
                : ""
            }`}
          </span>
        );
      }
    },

    {
      Header: "Actions",
      accessor: "actions",
      disableSortBy: true,
      Cell: (cellProps: any) => {
        return (
          <div className="action-group" onClick={(e) => e.stopPropagation()}>
            {findUserPermissionsFromRoles([
              { name: ADMIN_SECTION_ROLE_ASSIGNMENT_CP_PERMISSION }
            ]) && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`section-permisions`}>
                    {i18n.t(
                      "Configure questionnaire section permissions for the counterparty"
                    )}
                  </Tooltip>
                }
              >
                <div
                  className="mr-2 action"
                  onClick={(e: any) => {
                    assignGroupsToSection &&
                      assignGroupsToSection(
                        cellProps.row.original.id,
                        cellProps.row.original.distributorId
                      );
                  }}
                >
                  <Icons className="icon" icon="section-assignment" />
                </div>
              </OverlayTrigger>
            )}
            {!cellProps.row.original.sectionSubmission &&
              acceptRequestToReopen({
                status: cellProps.row.original.status?.name
              }) && (
                <>
                  {cellProps.row.original.reopenRequest?.requestedAt && (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`reopen-info-questionnaire`}>
                          {i18n.t(
                            `{{name}} has requested to reopen the questionnaire at {{date}}`,
                            {
                              name: cellProps.row.original.reopenRequest
                                ?.requestedBy,
                              date: utils.getDate(
                                cellProps.row.original.reopenRequest
                                  ?.requestedAt,
                                "MMMM Do YYYY, h:mm:ss a"
                              )
                            }
                          )}
                        </Tooltip>
                      }
                    >
                      <span
                        className="action"
                        onClick={() => {
                          if (
                            findUserPermissionsFromRoles([
                              {
                                name: USER_ADMIN,
                                permissionType: ASSET_MANAGER_USER
                              },
                              { name: ADMIN_SECTION_REOPEN_PERMISSION }
                            ])
                          ) {
                            reopenRequest({
                              changeAccountQuestionnaireId:
                                cellProps.row.original.id,
                              reopenRequest:
                                cellProps.row.original.reopenRequest,
                              questionnaireName:
                                cellProps.row.original.questionnaireTitle,
                              currentStatus: cellProps.row.original.status?.name
                            });
                          } else {
                            permissionError();
                          }
                        }}
                      >
                        <Icons className="icon" icon="activeDocument" />
                      </span>
                    </OverlayTrigger>
                  )}
                </>
              )}
            {!cellProps.row.original.sectionSubmission &&
              allowStatusChange({
                status: cellProps.row.original.status?.name,
                userType: userDetails.user_type
              }) && (
                <OverlayTrigger
                  placement="top"
                  rootClose
                  overlay={
                    <Tooltip id={`change-questionnaire-status`}>
                      {i18n.t("Change status")}
                    </Tooltip>
                  }
                >
                  <span
                    className="action"
                    onClick={() => {
                      if (
                        findUserPermissionsFromRoles([
                          {
                            name: USER_ADMIN,
                            permissionType: ASSET_MANAGER_USER
                          },
                          { name: ADMIN_STATUS_CHANGE_PERMISSION }
                        ])
                      ) {
                        changeStatus({
                          changeAccountQuestionnaireId:
                            cellProps.row.original.id,
                          currentStatus: cellProps.row.original.status?.name,
                          isClientManaged:
                            cellProps.row.original.isClientManaged
                        });
                      } else {
                        permissionError();
                      }
                    }}
                  >
                    <Icons className="icon" icon="circleStatusChange" />
                  </span>
                </OverlayTrigger>
              )}
            <OverlayTrigger
              placement="left"
              rootClose
              trigger="click"
              overlay={
                <Tooltip id={`download-report-${cellProps.row.original.id}`}>
                  <div>
                    {cellProps.row.original.questionnairePdfError ? (
                      <div>
                        {i18n.t(cellProps.row.original.questionnairePdfError)}
                      </div>
                    ) : (
                      <>
                        <button
                          onClick={async () => {
                            downloadReports({
                              accountQuestionnaireId: cellProps.row.original.id,
                              fileName:
                                cellProps.row.original.questionnaireTitle,
                              fileType: FILE_TYPE_QUESTIONNAIRE_PDF,
                              fileExtension: PDF_FILE_EXTENSION
                            });
                          }}
                          className="dropdown-item"
                        >
                          {i18n.t("Questionnaire PDF")}
                        </button>
                        <button
                          className="dropdown-item"
                          onClick={async () => {
                            downloadReports({
                              accountQuestionnaireId: cellProps.row.original.id,
                              fileName:
                                cellProps.row.original.questionnaireTitle,
                              fileType: FILE_TYPE_QUESTIONNAIRE_PDF_IN_ZIP,
                              fileExtension: ZIP_FILE_EXTENSION
                            });
                          }}
                        >
                          {i18n.t("All Documents Zip")}
                        </button>
                      </>
                    )}
                    {cellProps.row.original.amlReport && (
                      <button
                        className="dropdown-item"
                        onClick={async () => {
                          downloadReports({
                            accountQuestionnaireId: cellProps.row.original.id,
                            fileName: cellProps.row.original.amlReport,
                            fileType: FILE_TYPE_AML_REPORT
                          });
                        }}
                      >
                        AML report
                      </button>
                    )}
                  </div>
                </Tooltip>
              }
            >
              <span className="action">
                <Icons className="icon" icon="download" />
              </span>
            </OverlayTrigger>
          </div>
        );
      }
    },
    {
      accessor: "accountQuestionnaireId",
      Cell: () => null,
      Header: () => null
    },
    {
      Cell: () => null,
      Header: () => null,
      accessor: "completePercentage"
    },
    {
      accessor: "id",
      Cell: () => null,
      Header: () => null
    },
    {
      accessor: "submittedQuestionnaireId",
      Cell: () => null,
      Header: () => null
    },
    {
      accessor: "permissions",
      Cell: () => null,
      Header: () => null
    },
    {
      accessor: "submittedQuestionnaireStatus",
      Cell: () => null,
      Header: () => null
    }
  ];
  return columnConfig;
}

export function getGridFiltersConfig(filtersData: QuestionnaireFilterList) {
  const gridFilters: Filter[] = [
    {
      label: `${OrgKeyMap.end_user_name}`,
      items: [
        ...new Set(
          filtersData["counterpartyList"].map((x: any) => {
            return { label: x, value: x };
          })
        )
      ],
      key: "distributor",
      searchable: true,
      multiple: true
    },
    {
      label: "Questionnaire",
      items: [
        ...new Set(
          filtersData["questionnaireList"].map((x: any) => {
            return { label: x, value: x };
          })
        )
      ],
      key: "questionnaireTitle",
      searchable: true,
      multiple: true
    },
    {
      label: "Account Type",
      items: [
        ...new Set(
          filtersData["accountTypeList"].map((x: any) => {
            return { label: x, value: x };
          })
        )
      ],
      key: "accountType"
    },
    {
      label: "Risk Level",
      items: [
        ...new Set(
          filtersData["riskLevels"].map((x: any) => {
            return { label: x.label, value: x.value };
          })
        )
      ],
      key: "riskLevel",
      dots: true
    },
    {
      label: "Valid until",
      items: [
        ...new Set(
          filtersData["validUntilList"].map((x: any) => {
            return { label: x, value: x };
          })
        )
      ],
      key: "expiryDate"
    },
    {
      label: "Status",
      items: [
        ...new Set(
          filtersData["statusList"].map((x: any) => {
            return { label: x.label, value: x.name };
          })
        )
      ],
      key: "status",
      dots: true
    }
  ];
  return gridFilters;
}
