import React, { useState } from "react";
import { Comment } from "../../container/ReviewQuestionnaire/types";
import InputField from "../InputField";
import * as utils from "../../../lib/utils";
import Modal from "react-bootstrap/esm/Modal";
import { useTranslation } from "react-i18next";

type Props = {
  modalTitle: string;
  comments?: Comment[];
  onSaveComment: (comment: string) => void;
  onModalClose: () => void;
  allowAddComment: boolean;
  showCommentsModal: boolean;
};

const CommentsModal: React.FC<Props> = ({
  modalTitle,
  comments,
  onSaveComment,
  onModalClose,
  allowAddComment,
  showCommentsModal
}) => {
  const { t } = useTranslation();
  const [userComments, setUserComments] = useState("");

  function onCommentChange({ target }: { target: any }): void {
    setUserComments(target.value);
  }

  function onClose() {
    setUserComments("");
    onModalClose();
  }

  function getFooter() {
    return comments && comments.length ? (
      <React.Fragment>
        {allowAddComment && (
          <form onSubmit={(e) => e.preventDefault()}>
            <InputField
              inputType="text_area"
              id="textarea-add-coments"
              name="text-area"
              isDisabled={false}
              placeholder="Enter your comment here"
              value={userComments}
              onChange={onCommentChange}
            />
          </form>
        )}
        {allowAddComment && (
          <button
            className="btn btn-primary"
            data-dismiss="modal"
            onClick={(e) => {
              onSaveComment(userComments);
              setUserComments("");
            }}
            disabled={!userComments}
          >
            {t("Save comment")}
          </button>
        )}
      </React.Fragment>
    ) : (
      <React.Fragment>
        {allowAddComment && (
          <button
            className="btn btn-primary"
            data-dismiss="modal"
            onClick={(e) => {
              onSaveComment(userComments);
              setUserComments("");
            }}
            disabled={!userComments}
          >
            {t("Save comment")}
          </button>
        )}
      </React.Fragment>
    );
  }
  function getBody() {
    return comments && comments.length ? (
      <React.Fragment>
        <div className="comments">
          {comments.length > 0 &&
            []
              .concat(comments as any)
              .sort((a: Comment, b: Comment) => {
                return (
                  new Date(a.commentedAt).valueOf() -
                  new Date(b.commentedAt).valueOf()
                );
              })
              .map((comment: Comment, index: number) => {
                return (
                  <div key={index} className="comment">
                    <div className="comment__meta">
                      <span>{comment.commentedByName}</span>
                      <span
                        className="text-muted"
                        style={{ whiteSpace: "pre" }}
                      >
                        {` on ${utils.getDate(comment.commentedAt)}`}
                      </span>
                    </div>
                    <div className="comment__content">{comment.comment}</div>
                  </div>
                );
              })}
        </div>
      </React.Fragment>
    ) : allowAddComment ? (
      <React.Fragment>
        <form onSubmit={(e) => e.preventDefault()}>
          <InputField
            inputType="text_area"
            id="textarea-add-coments"
            name="text-area"
            isDisabled={false}
            placeholder="Enter your comment here"
            value={userComments}
            onChange={onCommentChange}
          />
        </form>
      </React.Fragment>
    ) : (
      t("Cannot add comments to the questionnaire")
    );
  }
  return (
    <Modal
      show={showCommentsModal}
      onHide={onClose}
      className="modal--large"
      id="cdd-add-comment-modal"
      tabIndex={-1}
    >
      <Modal.Header className="modal__header" closeButton>
        <Modal.Title className="modal__title">{t(modalTitle)}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal__body modal__body--comments">
        {getBody()}
      </Modal.Body>
      <Modal.Footer
        className="modal__footer border-top"
        style={{ display: "block" }}
      >
        {getFooter()}
      </Modal.Footer>
    </Modal>
  );
};

export default CommentsModal;
