import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback
} from "react";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import * as utils from "../../../lib/utils";
import {
  Params,
  Columns,
  QuestionnaireFilterList,
  PageDetailsState,
  ReopenRequestType,
  AccountQuestionnaireSectionsT
} from "./types";
import * as apis from "../../../apis/ResponseList";
import { getSectionAssignment } from "../../../apis/ReceivedAssignmentsList";
import Pagination from "../../presentation/Pagination";
import PageSize from "../../presentation/PageSize";
import GridFilters from "../../presentation/GridFilters";
import {
  getSectionColumnConfig,
  getQuestionnaireColumnConfig,
  getGridFiltersConfig
} from "./services";
import { UserContext } from "../../../Contexts/UserContext";
import { OrgKeyMap } from "../../../constants/app-constants";
import NoDataFound from "../../presentation/NoDataFound";
import "./styles.scss";
import GridComponent from "../../presentation/Grid";
import Select from "react-select";
import CustomModal from "../../presentation/Modals/CustomModal";
import { appData } from "../../../services/appConfig";
import { AllSubmissionStatus } from "../../../types/applicationData";
import {
  getChangableStatus,
  DRAFT,
  INTERNAL_DRAFT
} from "../../../lib/questionnaireStatusAccess";
import { ChangeStatusType } from "../SubmittedQuestionnaire/types";
import {
  changeQuestionnaireStatus,
  rejectReopenRequest
} from "../../../apis/SubmittedQuestionnaire";
import {
  ASSET_MANAGER_USER,
  findUserPermissionsFromRoles,
  permissionError,
  USER_ADMIN
} from "../../../lib/questionnairePermissionsAccess";
import {
  groupsSectionAssignmentQuestionnaire,
  groupsSectionAssignmentGroups,
  groupsSectionAssignmentRoles
} from "../SectionGroupsAssignments/types";
import SectionGroupAssignment from "../SectionGroupsAssignments";
import { useTranslation } from "react-i18next";
import { FilterItem } from "../../presentation/GridFilters/types";

type Props = {
  getPageHeaderDetails: (pageDetails: any) => void;
};

const ResponseQuestionnaireList: React.FC<Props> = ({
  getPageHeaderDetails
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { accountId }: Params = useParams<any>();
  const assetManagerId = accountId;
  const { userDetails }: any = useContext(UserContext);
  const DEFAULT_PAGE_SIZE = 20;
  const DEFAULT_START_PAGE = 1;
  const [pageDetails, setPageDetails] = useState<PageDetailsState>({
    assetManagerId,
    pageSize: DEFAULT_PAGE_SIZE,
    pageNumber: DEFAULT_START_PAGE,
    filters: utils.getLocalStorage("RESPONSE_LIST_FILTERS") || []
  });
  const [questionnaireResponseCount, setQuestionnaireResponseCount] =
    useState<number>(0);
  const [
    questionnaireSearchResponseCount,
    setQuestionnaireSearchResponseCount
  ] = useState<number>(0);
  const [latestSubmissionDate, setLatestSubmissionDate] = useState<
    number | string
  >("");
  const [responseList, setResponseList] = useState<Columns[]>([]);
  const gridData = React.useMemo(() => responseList, [responseList]);
  const [accountQuestionnaireId, setAccountQuestionnaireId] = useState<any>();
  const [filtersList, setFiltersList] =
    useState<QuestionnaireFilterList | null>();
  const filtersData = React.useMemo(() => filtersList, [filtersList]);
  const selectedQuestionnaireTitle = useRef("");
  const [sectionGroupQuestionnaire, setSectionGroupQuestionnaire] =
    useState<groupsSectionAssignmentQuestionnaire>();
  const [showGroupSectionAssignmentModal, setShowGroupSectionAssignmentModal] =
    useState<boolean>(false);
  const [sectionGroup, setSectionGroup] = useState<
    groupsSectionAssignmentGroups[]
  >([]);

  const [sectionGroupRoles, setSectionGroupRoles] = useState<
    groupsSectionAssignmentRoles[]
  >([]);
  const assignGroupsToSection = useCallback(
    (
      accountQuestionnaireId: string | number,
      distributorId: string | number
    ) => {
      getSectionAssignment({
        accountQuestionnaireId,
        accountId: distributorId
      })
        .then((res: any) => {
          setSectionGroupQuestionnaire(res?.data.getSectionAssignment);
          setSectionGroup(res?.data.getAccountGroups);
          setSectionGroupRoles(res?.data.getUserRoles);
          setShowGroupSectionAssignmentModal(true);
        })
        .catch((error: any) => {
          toast.error(utils.formatGraphQLError(error.message));
          setShowGroupSectionAssignmentModal(false);
        });
    },
    []
  );
  const questionnaireColumnConfig = React.useMemo(
    () =>
      getQuestionnaireColumnConfig(
        assetManagerId,
        userDetails,
        changeStatus,
        reopenRequest,
        assignGroupsToSection
      ),
    [
      assetManagerId,
      userDetails,
      changeStatus,
      reopenRequest,
      assignGroupsToSection
    ]
  );
  const sectionColumnConfig = React.useMemo(
    () =>
      getSectionColumnConfig(
        assetManagerId,
        userDetails,
        changeStatus,
        reopenRequest
      ),
    [assetManagerId, userDetails, changeStatus, reopenRequest]
  );
  const [accountQuestionnaireSections, setAccountQuestionnaireSections] =
    useState<AccountQuestionnaireSectionsT[]>([]);
  const [allSubmissionStatus, setAllSubmissionStatus] =
    useState<AllSubmissionStatus[]>();
  const [changeStatusData, setChangeStatusData] = useState<ChangeStatusType>();
  const [refreshQuestionnaires, setRefreshQuestionnaires] = useState(0);
  const [refreshSections, setRefreshSections] = useState(0);
  const [reopenRequestData, setReopenRequestData] =
    useState<ChangeStatusType>();
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    (async () => {
      let response: any = await appData.fetchAllSubmissionStatus();
      setAllSubmissionStatus(response);
    })();
  }, []);

  useEffect(() => {
    if (accountQuestionnaireId) {
      setAccountQuestionnaireSections([]);
      getQuestionnaireSectionsData(accountQuestionnaireId)
        .then((sections: any) => {
          let response = [
            ...sections.data.assetManagerAccountQuestionnaireSections
          ];
          //Add Approved List and Pending List to existing sections
          /*******************************************************/
          let result: AccountQuestionnaireSectionsT[] = [];
          response.forEach((section: any) => {
            let sectionDetails = { ...section };
            if (sectionDetails?.approvalDetails?.length) {
              //sort by isApproved and then sequence
              let sortedApprovedList = utils.sortByMutipleKeys(
                sectionDetails.approvalDetails
              );
              sectionDetails = {
                ...sectionDetails,
                sortedApprovedList
              };
              //filter out approvd list and get pending list
              let allPendingList = sortedApprovedList.filter(
                (approvalDetail: any) => !approvalDetail.isApproved
              );
              //get all approval steps for first pending sequence
              if (allPendingList.length) {
                let sequence = allPendingList[0].sequence;
                let pendingList = allPendingList.filter(
                  (approvalDetail: any) => approvalDetail.sequence === sequence
                );
                sectionDetails = {
                  ...sectionDetails,
                  pendingList
                };
              }
            }
            result.push(
              sectionDetails
            ); /*******************************************************/
          });
          setAccountQuestionnaireSections(result);
        })
        .catch((err: any) => {
          toast.error(utils.formatGraphQLError(err.message));
        });
    }
  }, [accountQuestionnaireId, refreshSections]);
  const navigateToLink = (link: string, hasPermission: boolean) => {
    if (hasPermission) {
      history.push(link);
    } else {
      permissionError();
    }
  };

  const handlePageSize = (pageSize: number) => {
    setPageDetails({
      ...pageDetails,
      pageNumber: DEFAULT_START_PAGE,
      pageSize
    });
  };

  const handleSorting = (value: any) => {
    if (value) {
      setPageDetails({
        ...pageDetails,
        pageNumber: DEFAULT_START_PAGE,
        sort: value.sortDirection
          ? [
              {
                column: value.id,
                direction: value.sortDirection,
                priority: 1
              }
            ]
          : []
      });
    }
  };

  const handlePagination = (page: number) => {
    setPageDetails({
      ...pageDetails,
      pageNumber: page
    });
  };

  const handleFilter = (key: string, filter: FilterItem[]) => {
    let filtersList = utils.setActiveFilter(pageDetails.filters, {
      filterName: key,
      filterValue: filter.length ? filter : []
    });
    utils.setLocalStorage("RESPONSE_LIST_FILTERS", filtersList);
    setPageDetails({
      ...pageDetails,
      pageNumber: DEFAULT_START_PAGE,
      filters: filtersList
    });
  };

  const resetAllFilters = () => {
    utils.removeLocalStorage("RESPONSE_LIST_FILTERS");
    setPageDetails({
      ...pageDetails,
      filters: []
    });
  };

  useEffect(() => {
    apis
      .getQuestionnaireResponseList(pageDetails)
      .then((res) => {
        let data = [];
        data = res.data?.questionnaireResponseList.map((item: any) => ({
          ...item,
          actions: "placeholder"
        }));
        setResponseList(data);
        setQuestionnaireResponseCount(res.data?.questionnaireResponseCount);
        setFiltersList(res.data?.questionnaireFilterList);
        setQuestionnaireSearchResponseCount(
          res.data?.questionnaireSearchResponseCount
        );
        setLatestSubmissionDate(res.data?.latestSubmissionDate);
      })
      .catch((error) => {
        setResponseList([]);
        setQuestionnaireResponseCount(0);
        setFiltersList(null);
        setQuestionnaireSearchResponseCount(0);
        setLatestSubmissionDate("");
        setMessage("");
        toast.error(utils.formatGraphQLError(error.message));
      });
  }, [pageDetails, refreshQuestionnaires]);

  useEffect(() => {
    getPageHeaderDetails({
      title: `${OrgKeyMap.module_name} ${
        utils.isAdminUser(userDetails?.user_type) ? t("Admin") : ""
      }`,
      details: [
        `${questionnaireResponseCount || 0} ${t("Questionnaires")} | ${t(
          "Latest submission"
        )}: ${
          utils.getDate(latestSubmissionDate, "MMMM Do YYYY, h:mm:ss a") || ""
        }`
      ]
    });
  }, [
    questionnaireResponseCount,
    latestSubmissionDate,
    getPageHeaderDetails,
    userDetails,
    t
  ]);

  const getQuestionnaireSectionsData = (accountQuestionnaireId: number) => {
    return apis
      .getQuestionnaireSections({ assetManagerId, accountQuestionnaireId })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  };

  function changeStatus({
    changeSectionId,
    changeAccountQuestionnaireId,
    currentStatus,
    isClientManaged
  }: {
    changeSectionId?: string | number;
    changeAccountQuestionnaireId?: string | number;
    currentStatus: string;
    isClientManaged: boolean;
  }) {
    if (changeSectionId) {
      setChangeStatusData({
        showModal: true,
        accountQuestionnaireId: accountQuestionnaireId,
        sectionId: changeSectionId,
        currentStatus,
        isClientManaged
      });
    }
    if (changeAccountQuestionnaireId) {
      setChangeStatusData({
        showModal: true,
        accountQuestionnaireId: changeAccountQuestionnaireId,
        sectionId: undefined,
        currentStatus,
        isClientManaged
      });
    }
  }

  function reopenRequest({
    changeSectionId,
    changeAccountQuestionnaireId,
    reopenRequest,
    sectionName,
    questionnaireName,
    currentStatus
  }: {
    changeSectionId?: string | number;
    changeAccountQuestionnaireId?: string | number;
    reopenRequest: ReopenRequestType | null;
    sectionName?: string;
    questionnaireName?: string;
    currentStatus: string;
  }) {
    let draftStatus = getChangableStatus({
      statusList: allSubmissionStatus,
      currentStatus,
      userType: userDetails.user_type
    })?.find((val: any) => val.name === DRAFT);
    if (changeSectionId) {
      setReopenRequestData({
        showModal: true,
        accountQuestionnaireId: accountQuestionnaireId,
        sectionId: changeSectionId,
        reopenRequest,
        sectionName,
        currentStatus
      });
      setChangeStatusData({
        accountQuestionnaireId: accountQuestionnaireId,
        sectionId: changeSectionId,
        statusId: draftStatus?.id,
        currentStatus
      });
    }
    if (changeAccountQuestionnaireId) {
      setReopenRequestData({
        showModal: true,
        accountQuestionnaireId: changeAccountQuestionnaireId,
        sectionId: undefined,
        reopenRequest,
        questionnaireName,
        currentStatus
      });
      setChangeStatusData({
        accountQuestionnaireId: changeAccountQuestionnaireId,
        sectionId: undefined,
        statusId: draftStatus?.id,
        currentStatus
      });
    }
  }

  function updateStatus() {
    if (changeStatusData?.statusId) {
      changeQuestionnaireStatus({
        accountQuestionnaireId: changeStatusData.accountQuestionnaireId,
        statusId: changeStatusData?.statusId,
        sectionId: changeStatusData?.sectionId || null
      })
        .then(() => {
          toast.success(t("Status changed successfully"));
          setChangeStatusData(undefined);
          setReopenRequestData(undefined);
          setRefreshSections(refreshSections + 1);
          setRefreshQuestionnaires(refreshQuestionnaires + 1);
        })
        .catch((e) => {
          toast.error(utils.formatGraphQLError(e.message));
        });
    } else {
      toast.error(t("Please select status"));
    }
  }
  function denyEditRequest() {
    if (reopenRequestData) {
      rejectReopenRequest({
        accountQuestionnaireId: reopenRequestData.accountQuestionnaireId,
        sectionId: reopenRequestData?.sectionId || null
      })
        .then(() => {
          toast.success(t("Operation successfully"));
          setChangeStatusData(undefined);
          setReopenRequestData(undefined);
          setRefreshSections(refreshSections + 1);
          setRefreshQuestionnaires(refreshQuestionnaires + 1);
        })
        .catch((e) => {
          toast.error(utils.formatGraphQLError(e.message));
        });
    }
  }
  return (
    <>
      {filtersData &&
        Object.keys(filtersData as QuestionnaireFilterList).length && (
          <div className="responses">
            <GridFilters
              onSelect={handleFilter}
              filters={getGridFiltersConfig(
                filtersData as QuestionnaireFilterList
              )}
              selectedFilters={pageDetails?.filters}
              resetAllFilters={resetAllFilters}
            ></GridFilters>
          </div>
        )}
      {gridData?.length ? (
        <div>
          <GridComponent
            gridData={gridData}
            columnConfig={questionnaireColumnConfig}
            pageIndex={0}
            pageSize={1000}
            onSort={handleSorting}
            hiddenColumns={[
              "questionnaireId",
              "score",
              "accountQuestionnaireId",
              "id",
              "submittedQuestionnaireId",
              "permissions",
              "submittedQuestionnaireStatus",
              "completePercentage"
            ]}
            totalRecordsCount={questionnaireSearchResponseCount}
            defaultPageSize={1000}
            rowAccordion={true}
            selectedRow={(row: any) => {
              selectedQuestionnaireTitle.current =
                row.original.questionnaireTitle;
              let id = row.original.id;
              setAccountQuestionnaireId(id);
            }}
            nestedComponentTemplate={
              <GridComponent
                gridData={accountQuestionnaireSections}
                columnConfig={sectionColumnConfig}
                pageIndex={0}
                pageSize={1000}
                hiddenColumns={[
                  "questionnaireId",
                  "score",
                  "accountQuestionnaireId",
                  "id",
                  "submittedQuestionnaireId",
                  "permissions",
                  "submittedQuestionnaireStatus",
                  "completePercentage"
                ]}
                totalRecordsCount={accountQuestionnaireSections?.length}
                defaultPageSize={1000}
                disableSort={true}
                tableClassName={"questionnaire-inner-table"}
                action={{ navigateToLink }}
              />
            }
          />

          <div className="row">
            <div className="col-sm-12 mt-4">
              <div className="pagination-container">
                <div className="row">
                  <div
                    className="col-sm-4 col-md-4"
                    style={{
                      display: "block"
                    }}
                  >
                    <PageSize
                      handleChange={handlePageSize}
                      defaultPageSize={DEFAULT_PAGE_SIZE}
                    />
                  </div>

                  <div className="col-sm-8 col-md-8">
                    <div className="float-right">
                      <Pagination
                        totalRecordsCount={questionnaireSearchResponseCount}
                        pageSize={pageDetails.pageSize}
                        defaultStartPage={DEFAULT_START_PAGE}
                        handleChange={handlePagination}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <NoDataFound message={message} />
      )}
      <CustomModal
        modalTitle="Change status"
        classNames="modal__body p-0"
        showModal={changeStatusData?.showModal || false}
        onClose={() => {
          setChangeStatusData(undefined);
        }}
        showFooter={true}
        footer={
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <button
              className="btn btn-outline-primary"
              onClick={(e) => {
                setChangeStatusData(undefined);
              }}
            >
              {t("Cancel")}
            </button>
            <button className="btn btn-primary" onClick={updateStatus}>
              {t("Confirm")}
            </button>
          </div>
        }
      >
        <form
          className="form"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <div className="form-group">
            <div className="mt-3">
              *{" "}
              {t(
                "On change of status, all the existing approvals will be reset (if present)."
              )}
            </div>
            <div className="mt-3">
              <label className="mb-1">{t("Change Status")}</label>
              <Select
                options={utils.modifyForSelectBox(
                  (changeStatusData &&
                    getChangableStatus({
                      statusList: allSubmissionStatus,
                      currentStatus: changeStatusData.currentStatus,
                      userType: userDetails.user_type,
                      isClientManaged: !!changeStatusData.isClientManaged
                    })) ||
                    [],
                  "id",
                  "label"
                )}
                value={
                  changeStatusData?.statusId
                    ? {
                        label: changeStatusData.statusLabel,
                        value: changeStatusData.statusId
                      }
                    : null
                }
                placeholder={`${t("Select")}...`}
                noOptionsMessage={() => t("No options")}
                isClearable={true}
                isMulti={false}
                onChange={({
                  id,
                  value,
                  label
                }: {
                  id: string | number;
                  value: string | number;
                  label: string;
                }) => {
                  changeStatusData &&
                    setChangeStatusData({
                      ...changeStatusData,
                      statusId: id,
                      statusLabel: label
                    });
                }}
              />
            </div>
          </div>
        </form>
      </CustomModal>
      <CustomModal
        modalTitle="Request to edit"
        classNames="modal"
        modalBodyClasses="modal__body p-0"
        showModal={reopenRequestData?.showModal || false}
        onClose={() => {
          setReopenRequestData(undefined);
        }}
      >
        <form
          className="form"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <div className="form-group">
            <div className="mt-3">
              <div className="loop-edit-questionnaire">
                <div className="text-muted">
                  {utils.getDate(
                    reopenRequestData?.reopenRequest?.requestedAt,
                    "MMMM Do YYYY, h:mm:ss a"
                  )}
                </div>
                <div className="loop-question-level">
                  {reopenRequestData?.reopenRequest?.requestedBy}{" "}
                  {t("has requested to edit")}{" "}
                  <strong>
                    {reopenRequestData?.sectionName
                      ? `${selectedQuestionnaireTitle.current}:${reopenRequestData?.sectionName}`
                      : reopenRequestData?.questionnaireName}
                  </strong>
                  <br />
                  {reopenRequestData?.currentStatus === INTERNAL_DRAFT && (
                    <div className="color-red">
                      {t(
                        `Request to reopen cannot be allowed if ${
                          reopenRequestData?.sectionName
                            ? "section"
                            : "questionnaire"
                        } status is in Internal draft. Please complete ${
                          reopenRequestData?.sectionName
                            ? "the section"
                            : "all the sections"
                        } first`
                      )}
                    </div>
                  )}
                </div>
                <div className="loop-edit-questionnaire-btn">
                  <button
                    className={`btn ${
                      reopenRequestData?.currentStatus === INTERNAL_DRAFT
                        ? "btn-disabled"
                        : "btn-primary"
                    }`}
                    onClick={() => {
                      if (reopenRequestData?.currentStatus === INTERNAL_DRAFT)
                        return false;
                      changeStatusData?.statusId
                        ? updateStatus()
                        : toast.error(t("Request to edit cannot be allowed"));
                    }}
                  >
                    {t("Allow")}
                  </button>
                  <button
                    onClick={denyEditRequest}
                    className="btn btn-outline-secondary"
                  >
                    {t("Deny")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </CustomModal>
      {sectionGroupQuestionnaire && setShowGroupSectionAssignmentModal && (
        <CustomModal
          classNames="cdd-groups-management"
          showModal={showGroupSectionAssignmentModal}
          onClose={() => {}}
        >
          <SectionGroupAssignment
            questionnaire={sectionGroupQuestionnaire}
            groups={sectionGroup}
            roles={sectionGroupRoles}
            onClose={() => {
              setShowGroupSectionAssignmentModal(false);
            }}
          />
        </CustomModal>
      )}
    </>
  );
};

export default ResponseQuestionnaireList;
