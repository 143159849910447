//Usage
//1. Wrapping Parent Components which need user context in app routing
//import UserContextProvider from "./Contexts/UserContext";
/* <UserContextProvider user={userDetails}>
    <Route exact path="/admin" component={Admin} />
    </UserContextProvider> */
//2. Using the user context in children components
/*import { UserContext } from "../Contexts/UserContext";
    const { userDetails } = useContext(UserContext);*/
import React, { useState, useEffect } from "react";
import { TUserDetails } from "../types/user-details";
// import { useIntercom } from "react-use-intercom";
import { isCounterParty } from "../lib/utils";
import { ZEIDLER } from "../constants/app-constants";

interface Props {
  user: TUserDetails;
}

export const UserContext = React.createContext({
  userDetails: {} as TUserDetails
});

const UserContextProvider: React.FC<Props> = (props) => {
  const [userDetails] = useState(props.user);
  // const { boot } = useIntercom();

  // useEffect(() => {
  //   if (userDetails?.user_name || userDetails?.user_email) {
  //     let userData = {
  //       name: `${userDetails?.user_name} (${process.env.REACT_APP_REDIRECT_URL})`,
  //       email: userDetails?.user_email
  //     };
  //     if (
  //       isCounterParty(userDetails.user_type) &&
  //       process.env.REACT_APP_ORGANIZATION === ZEIDLER
  //     ) {
  //       boot(userData);
  //     }
  //   }
  // }, [userDetails]);

  return (
    <UserContext.Provider
      value={{
        userDetails
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
export default UserContextProvider;
