import React, { useEffect, useState } from "react";
import {
  NotificationTypes,
  CounterpartyNotificationTypes
} from "../../../../types/applicationData";
import { useTranslation } from "react-i18next";
interface Props {
  notificationPreferences: { [key: string]: string };
  boxPosition?: { top: string; left: string };
  show: boolean;
  notificationDetails: NotificationTypes | CounterpartyNotificationTypes;
  onClose: () => void;
  applyChanges: (notifications?: any) => void;
}

const NotificationDetails: React.FC<Props> = ({
  notificationPreferences,
  boxPosition,
  show,
  notificationDetails,
  onClose,
  applyChanges
}) => {
  const { t } = useTranslation();
  const [notificationInput, setNotificationInput] = useState<
    NotificationTypes | CounterpartyNotificationTypes
  >();
  useEffect(() => {
    notificationDetails && setNotificationInput(notificationDetails);
  }, [notificationDetails]);
  function handleChange({ target }: { target: any }) {
    notificationInput &&
      setNotificationInput({
        ...notificationInput,
        [target.name]: target.checked
      });
  }
  return (
    notificationDetails && (
      <div
        style={boxPosition ? { ...boxPosition } : {}}
        className={`notification-toggle custom-big-dropdown dropdown-menu dropdown-menu-right ${
          show ? "show" : ""
        }`}
      >
        <div className="px-3 py-3 flex-left-right border-bottom">
          <div className="bold">{t("Subscribe/Unsubscribe")}</div>
          <div className="close-dropdown-toggle px-3"></div>
        </div>
        <div className="px-3 py-3">
          <div className="pb-2 small-text">{t("Notification Types")}</div>
          <div>
            {notificationInput &&
              Object.entries(notificationInput).map(([key, value]) => {
                return (
                  <div className="flex-left-right py-1" key={key}>
                    <div className="bold">{t(notificationPreferences[key])}</div>
                    <div className="border-switch-toggle">
                      <div className="bt-switch-toggle ">
                        <div className="bt-switch-toggle-flex">
                          <div className="toggle-btn">
                            <label className="switch">
                              <input
                                name={key}
                                type="checkbox"
                                onChange={handleChange}
                                checked={value}
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
        <div className="pl-3 pr-3 pb-3">
          <button
            className="btn btn-primary"
            onClick={() => notificationInput && applyChanges(notificationInput)}
          >
            {t("Apply")}
          </button>
          <button
            className="btn btn-outline-secondary ml-3"
            onClick={(e) => {
              onClose();
              e.stopPropagation();
            }}
          >
            {t("Cancel")}
          </button>
        </div>
      </div>
    )
  );
};

export default NotificationDetails;
