export function createSelectOptionQuestionnaireList(questionnaireList: any) {
  let updatedList: any = [{ id: 1, value: "All", label: "All" }];
  if (!questionnaireList.length) {
    return updatedList;
  }
  questionnaireList.forEach((questionnaire: any) => {
    updatedList.push({
      label: questionnaire.title,
      value: questionnaire.id,
      id: questionnaire.id
    });
  });
  return updatedList;
}
