import React, { FunctionComponent } from "react";
import { OrgVisualizationNode } from "../../types";
import { companyPath, naturalPath, uboPath } from "./service";
import styles from "../../css/styles";

const Avatar: FunctionComponent<{ node: OrgVisualizationNode }> = ({
  node
}) => {
  const classes = [styles.avatar, node.ubo ? styles.ubo : undefined].filter(
    Boolean
  );
  let pathData = companyPath;
  let translate = [6, 5];

  if (node.type === "natural") {
    pathData = node.ubo ? uboPath : naturalPath;
    translate = node.ubo ? [3, 8] : [6, 5];
  }

  return (
    <g className={classes.join(" ")} data-entity-type={node.type}>
      <rect className={styles.bg} width="24" height="24" rx="12" />
      <path
        className={styles.icon}
        transform={`translate(${translate.join(",")})`}
        d={pathData}
      />
    </g>
  );
};

export default Avatar;
