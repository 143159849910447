import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Icons from "../../../presentation/Icons";
import BarChart from "../../../presentation/BarChart";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { getChartDataset } from "./service";
import { ChartState, Params } from "../types";
import { toast } from "react-toastify";
import { formatGraphQLError } from "../../../../lib/utils";
import * as apis from "../../../../apis/Dashboard";
import { useTranslation } from "react-i18next";

const ResponseTimelines: React.FC = () => {
  const { t } = useTranslation();
  const { accountId }: Params = useParams<any>();
  const assetManagerId = accountId;
  const [responseTimelineChartData, setResponseTimelineChartData] =
    useState<ChartState>();
  useEffect(() => {
    apis
      .getResponseTimeline(assetManagerId)
      .then((res) => {
        setResponseTimelineChartData(
          getChartDataset(res?.data?.responseTimeline)
        );
      })
      .catch((error) => {
        toast.error(formatGraphQLError(error.message));
      });
  }, [assetManagerId]);
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: false,
    scales: {
      xAxes: [
        {
          stacked: true, // this should be set to make the bars stacked
          ticks: {
            fontColor: "#868e96",
            fontSize: 16
          },
          gridLines: {
            color: "#f6f6f6"
          }
        }
      ],
      yAxes: [
        {
          stacked: true, // this also..
          ticks: {
            beginAtZero: true,
            fontColor: "#868e96"
          },
          gridLines: {
            display: false
          }
        }
      ]
    }
  };
  return (
    <>
      <div className="card card--chartjs">
        <div className="card__header">
          <div className="card__title">
            <h4>
              {t("Response timeline")}
              <OverlayTrigger
                placement="bottom"
                rootClose
                overlay={
                  <Tooltip id={`response-timeline`}>
                    {t(
                      "This section is only visible to you and the Zeidler team. Your client can't see it. months."
                    )}
                  </Tooltip>
                }
              >
                <span className="m-l-10">
                  <Icons className="icon icon--small" icon="info"></Icons>
                </span>
              </OverlayTrigger>
            </h4>
          </div>
        </div>
        <div className="card__body pt-0">
          <div id="chartResponseTimeline">
            {/* <canvas id="canvasResponseTimeline"></canvas> */}
            {responseTimelineChartData ? (
              <BarChart data={responseTimelineChartData} options={options} />
            ) : null}
          </div>

          <p className="chart-legend chart-legend--stacked mt-4 mb-0">
            <span className="chart-legend__square bg-success"></span> 0-1 m{" "}
            <span className="chart-legend__square light-green"></span> 1-2 m{" "}
            <span className="chart-legend__square yellow"></span> 2-3 m{" "}
            <span className="chart-legend__square orange"></span> 3-4 m{" "}
            <span className="chart-legend__square red"></span> 4+ m
          </p>
        </div>
      </div>
    </>
  );
};
export default ResponseTimelines;
