import gql from "graphql-tag";
import { publicPrivateComments } from "../CounterpartySubmittedQuestionnaire/queries";
export const DELETE_COMMENTS_QUERY = gql`
  mutation DeletePublicPrivateComment($input:  DeletePublicPrivateCommentInput!) {
    deletePublicPrivateComment(input: $input) {
        commentsList{
            ${publicPrivateComments}
            }
    }
  }
`;

export const UPDATES_COMMENTS_QUERY = gql`
  mutation UpdatePublicPrivateComment($input: UpdatePublicPrivateCommentInput!) {
    updatePublicPrivateComment(input: $input) {
        commentsList{
            ${publicPrivateComments}
            }
    }
  }
`;
export const CREATE_COMMENTS_QUERY = gql`
  mutation CreatePublicPrivateComments($input: CreatePublicPrivateCommentInput!) {
    createPublicPrivateComments(input: $input) {
        commentsList{
        ${publicPrivateComments}
        }
    }
  }
`;
