import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { ENG, GER } from "./components/presentation/LanguageSelector";
import { getCookieConsent } from "./lib/utils"
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next);
  
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  if (getCookieConsent()) {
    i18n.use(LanguageDetector);
  }
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  i18n.init({
    fallbackLng: ENG,
    lng: localStorage.getItem("i18nextLng") || GER,
    debug: false,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    nsSeparator: false // char to split namespace from key
  });

export default i18n;
