import gql from "graphql-tag";

export const GET_QUESTIONNAIRE_DOCUMENTS_LIST_QUERY = gql`
  query QuestionnaireDocuments($accountQuestionnaireId: ID!) {
    questionnaireDocuments(accountQuestionnaireId: $accountQuestionnaireId) {
      sectionName
      sectionId
      status {
        label
        name
      }
      submittedQuestionnaireStatus {
        label
        name
      }
      documents {
        id
        originalFileName
        timeStampedFileName
        fileType
        filePath
        publicPrivateComments {
          id
          comment
          createdAt
          user {
            name
            userId
          }
          commentType {
            id
            name
          }
        }
      }
    }
  }
`;
