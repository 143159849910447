import React, { useState, useRef } from "react";
import Icons from "../Icons";
import "./styles.scss";
import ScoreDetails from "../../container/ScoreDetails";
import {
  getScoringNotes,
  saveScoringDetails
} from "../../../apis/SubmittedQuestionnaire";
import { toast } from "react-toastify";
import { formatGraphQLError } from "../../../lib/utils";
import { useTranslation } from "react-i18next";
import { useCommentScroll } from "../../../Hooks/useCommentScroll";
import {
  QueryParamActions,
  QueryPrameters
} from "../../../types/applicationData";
interface Props {
  queryParams?: QueryPrameters | undefined | null;
  fieldId: number | string;
  answerId: number | string;
  defaultDeductions?: number | string;
  currentDeductions?: number | string;
  editScoring: boolean;
  iconColor?: string;
  showDefaultScore?: boolean;
  showCurrentScore?: boolean;
  updateParent: (
    points: number | string,
    fieldValueId: number | string
  ) => void;
}

const Deductions: React.FC<Props> = ({
  queryParams,
  defaultDeductions = 0,
  currentDeductions,
  editScoring = false,
  iconColor,
  fieldId,
  answerId,
  showDefaultScore = false,
  showCurrentScore = false,
  updateParent
}) => {
  const commentRef = useRef<any>();
  const queryParamActions = useRef<QueryParamActions>({
    hasScrolled: false,
    hasPopupOpened: false
  });
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [scoreDeatils, setScoreDeatils] = useState();

  function getScoreNotes() {
    if (fieldId && answerId) {
      setShowModal(true);
      getScoringNotes({
        fieldValueId: fieldId,
        questionAnswerId: answerId
      })
        .then((res) => {
          setScoreDeatils(res.data?.deductions);
        })
        .catch((e) => {
          toast.error(formatGraphQLError(e.message));
        });
    } else {
      toast.error(t("Insufficient parameters provided to fetch score details"));
    }
  }
  useCommentScroll({
    callBack: getScoreNotes,
    commentRef,
    queryParamActions: queryParamActions.current,
    idFromQueryParam: queryParams?.fieldId || null,
    entityId: fieldId
  });
  function saveScoreDetails(note: string, points: number) {
    saveScoringDetails({
      note,
      points,
      fieldValueId: fieldId,
      questionAnswerId: answerId
    })
      .then((res) => {
        toast.success(t("Score saved successfully"));
        updateParent(
          res?.data.saveDeductionAndNotes?.latestDeduction || points,
          fieldId
        );
        getScoreNotes();
      })
      .catch((e) => {
        toast.error(formatGraphQLError(e.message));
      });
  }
  return showDefaultScore || showCurrentScore ? (
    <>
      <div className="ml-auto mb-auto form-score">
        <div
          ref={commentRef}
          className="form-row"
          id={`score-for-field-${fieldId}`}
        >
          <div className="deduction-input is-edited">
            {showCurrentScore && (
              <span className="deduction-input__current-score">
                {currentDeductions}
              </span>
            )}
            {showDefaultScore && (
              <span className="deduction-input__default-score">
                {defaultDeductions}
              </span>
            )}

            {showDefaultScore && showCurrentScore && (
              <button
                type="button"
                className="deduction-input__edit"
                onClick={() => {
                  getScoreNotes();
                }}
              >
                <Icons
                  icon="pen"
                  className={`icon icon--x-small v-baseline ${
                    iconColor && iconColor === "blue"
                      ? "blue-icon"
                      : "black-icon"
                  }`}
                />
              </button>
            )}
          </div>
        </div>
      </div>
      {showModal && scoreDeatils && (
        <ScoreDetails
          defaultPoints={defaultDeductions}
          currentPoints={currentDeductions || 0}
          notes={scoreDeatils || []}
          allowEdit={editScoring}
          showModal={showModal}
          onClose={() => {
            setShowModal(false);
            setScoreDeatils(undefined);
            queryParamActions.current.hasScrolled = true;
            queryParamActions.current.hasPopupOpened = true;
          }}
          onSave={saveScoreDetails}
        />
      )}
    </>
  ) : (
    <></>
  );
};
export default Deductions;
