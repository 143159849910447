import { TUserDetails, TRoles, TPermissions } from "../types/user-details";
import {
  RiskCountry,
  RiskIndustry,
  RiskLegalForm,
  AllSubmissionStatus
} from "../types/applicationData";
import {
  getRiskCountries,
  getRiskIndustries,
  getRiskLegalForms
} from "../apis/RiskQuestionOptions";
import { getAllSubmissionStatus } from "../apis/SubmittedQuestionnaire";

export const appData = (() => {
  let userInfo: TUserDetails;
  let applicationData: {
    riskCountries?: RiskCountry[];
    riskIndustries?: RiskIndustry[];
    riskLegalForms?: RiskLegalForm[];
    allSubmissionStatus?: AllSubmissionStatus[];
  } = {};
  return {
    getUserDetails: () => {
      return userInfo;
    },
    setUserDetails: (userDetails: TUserDetails) => {
      userInfo = userDetails;
    },
    getUserPermissions: () => {
      let permissions: TPermissions[] = [];
      let roles: TRoles[] = userInfo?.roles;
      roles?.forEach((role: TRoles) => {
        permissions = permissions.concat(role.permissions);
      });

      return permissions;
    },
    fetchRiskCountries: function () {
      return new Promise((resolve, reject) => {
        if (
          !applicationData.riskCountries ||
          !applicationData.riskCountries.length
        ) {
          getRiskCountries()
            .then((res) => {
              applicationData.riskCountries = res.data.riskCountries;
              resolve(applicationData.riskCountries);
            })
            .catch((e) => {
              console.error("Error in fetching risk countries", e);
              reject();
            });
        } else {
          resolve(applicationData.riskCountries);
        }
      });
    },
    fetchRiskIndustries: function () {
      return new Promise(function (resolve, reject) {
        if (
          !applicationData.riskIndustries ||
          !applicationData.riskIndustries.length
        ) {
          getRiskIndustries()
            .then((res) => {
              applicationData.riskIndustries = res.data.riskIndustries;
              resolve(applicationData.riskIndustries);
            })
            .catch((e) => {
              console.error("Error in fetching risk industries", e);
              reject();
            });
        } else {
          resolve(applicationData.riskIndustries);
        }
      });
    },
    fetchRiskLegalForm: function () {
      return new Promise(function (resolve, reject) {
        if (
          !applicationData.riskLegalForms ||
          !applicationData.riskLegalForms.length
        ) {
          getRiskLegalForms()
            .then((res) => {
              applicationData.riskLegalForms = res.data.riskLegalForms;
              resolve(applicationData.riskLegalForms);
            })
            .catch((e) => {
              console.error("Error in fetching risk legal forms", e);
              reject();
            });
        } else {
          resolve(applicationData.riskLegalForms);
        }
      });
    },
    fetchAllSubmissionStatus: function () {
      return new Promise(function (resolve, reject) {
        if (
          !applicationData.allSubmissionStatus ||
          !applicationData.allSubmissionStatus.length
        ) {
          getAllSubmissionStatus()
            .then((res) => {
              applicationData.allSubmissionStatus =
                res.data.getSubmissionStatuses;
              resolve(applicationData.allSubmissionStatus);
            })
            .catch((e) => {
              console.error("Error in fetching all submission status", e);
              reject();
            });
        } else {
          resolve(applicationData.allSubmissionStatus);
        }
      });
    }
  };
})();
