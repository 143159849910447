import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
  message?: string;
};

const NoDataFound: React.FC<Props> = ({ message }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="container mt-4">
        <div className="row">
          <div className="card col-sm-12 card--list">
            <div className="card__body">
              <h4 className="card__title">
                <p>{message ? t(message) : t("No Data Found")}</p>
              </h4>
              <div className="details__list"></div>
            </div>
            <div className="card__actions"></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default NoDataFound;
