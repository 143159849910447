import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Icons from "../../../presentation/Icons";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import * as apis from "../../../../apis/Dashboard";
import { CounterpartiesOnboardCount, Params } from "../types";
import { toast } from "react-toastify";
import { formatGraphQLError } from "../../../../lib/utils";
import { OrgKeyMap } from "../../../../constants/app-constants";
import { useTranslation } from "react-i18next";

const NewCounterpartiesOnboardCount: React.FC = () => {
  const { t } = useTranslation();
  const { accountId }: Params = useParams<any>();
  const assetManagerId = accountId;
  const [counterpartiesOnboardCount, setCounterpartiesOnboardCount] =
    useState<CounterpartiesOnboardCount>({
      lastMonth: 0,
      lastTwelveMonths: 0,
      currentMonth: 0
    });
  useEffect(() => {
    apis
      .getCounterpartiesOnboarded(assetManagerId)
      .then((res) => {
        setCounterpartiesOnboardCount(res.data.newCounterpartiesOnboarded);
      })
      .catch((error) => {
        toast.error(formatGraphQLError(error.message));
      });
  }, [assetManagerId]);
  return (
    <>
      <div className="card card--chartjs mb-4">
        <div className="card__header pb-3">
          <div className="card__title">
            <h4 className="mb-lg-0">
              {t(`New ${OrgKeyMap.end_user_names.toLowerCase()} onboarded`)}
              <OverlayTrigger
                placement="bottom"
                rootClose
                overlay={
                  <Tooltip id={`counterparties-onboarded`}>
                    {t(`The amount of ${OrgKeyMap.end_user_names.toLowerCase()} onboarded up the last 12 months.`)}
                  </Tooltip>
                }
              >
                <span className="m-l-10">
                  <Icons className="icon icon--small" icon="info"></Icons>
                </span>
              </OverlayTrigger>
            </h4>
          </div>
        </div>
        <div className="card__body pt-0">
          <div className="onboard-statistics">
            <div className="onboard-stat">
              <h1 className="onboard-stat__number">
                {counterpartiesOnboardCount?.currentMonth}
              </h1>
              <p className="text-muted mb-0">{t("This month")}</p>
            </div>
            <div className="onboard-stat">
              <h1 className="onboard-stat__number">
                {counterpartiesOnboardCount?.lastMonth}
              </h1>
              <p className="text-muted mb-0">{t("Last month")}</p>
            </div>
            <div className="onboard-stat">
              <h1 className="onboard-stat__number">
                {counterpartiesOnboardCount?.lastTwelveMonths}
              </h1>
              <p className="text-muted mb-0">{t("Last 12 months")}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NewCounterpartiesOnboardCount;
