import { UBODetailsType } from "../types";
import { trimAllVals } from "../service";
import i18n from "../../../i18n";
export const uboQuestionDropDown = [
  { value: "known", label: i18n.t("Yes, I just want to add them") },
  { value: "derived", label: "No, I want to find out" },
  { value: "fictitious", label: "Add fictitious ubos" }
];

export const uboQuestionDropDownGerman = [
  { value: "known", label: "Ja, ich möchte sie nur hinzufügen" },
  { value: "derived", label: "Nein, ich möchte es herausfinden" },
  { value: "fictitious", label: "Fiktiven wB hinzufügen" }
];

export const KNOWN = "known";
export const DERIVED = "derived";
export const FICTITIOUS = "fictitious";
export function parseDataToSave(
  uboDetails: UBODetailsType,
  shareHolding: number,
  voteRights: number
) {
  if (uboDetails?.uboInfoType?.value === KNOWN) {
    return {
      ...uboDetails,
      orgStructure: undefined,
      fictitiousUbos: [],
      isFinalized: uboDetails.knownUbosDetails?.knownUBOs?.length
        ? true
        : false,
      ubosFromOrgStructure: [],
      knownUbosDetails: {
        ...uboDetails.knownUbosDetails,
        knownUBOs: trimAllVals(uboDetails.knownUbosDetails?.knownUBOs || [])
      }
    };
  }
  if (uboDetails?.uboInfoType?.value === DERIVED) {
    let identifiedUbos = uboDetails?.orgStructure?.owners || [];
    return {
      ...uboDetails,
      knownUbosDetails: {},
      fictitiousUbos: [],
      isFinalized: identifiedUbos?.length ? true : false,
      ubosFromOrgStructure: identifiedUbos
    };
  }
  if (uboDetails?.uboInfoType?.value === FICTITIOUS) {
    return {
      ...uboDetails,
      knownUbosDetails: {},
      isFinalized: uboDetails.fictitiousUbos?.length ? true : false,
      fictitiousUbos: trimAllVals(uboDetails.fictitiousUbos || [])
    };
  }
}
export function isRedirectToFictitious(uboDetails: UBODetailsType) {
  if (
    uboDetails?.uboInfoType?.value === DERIVED &&
    !uboDetails?.ubosFromOrgStructure?.length
  ) {
    return true;
  }
  return false;
}
export function getSelectDefaultValue(answer: any, options: any) {
  let obj: any = {};
  if (answer) {
    obj = options.find((o: any) => o.id === answer);
    obj = obj
      ? {
          id: obj.id,
          value: obj.name,
          label: i18n.language === "de" ? obj.germanName : obj.name
        }
      : {};
    return obj;
  }
  return [];
}

export function getMultiSelectValue(answers: any, options: any) {
  let selectvalues: any = [];
  answers.forEach((e: any) => {
    let obj = getSelectDefaultValue(e.id || e.value, options);
    selectvalues.push(obj);
  });
  return selectvalues;
}

export function getTranslatedLabel(value: string) {
  let newlabel: string = "";
  if (i18n.language === "de") {
    uboQuestionDropDownGerman.forEach((ele) => {
      if (ele.value === value) newlabel = ele.label;
    });
  } else {
    uboQuestionDropDown.forEach((ele) => {
      if (ele.value === value) newlabel = ele.label;
    });
  }
  return newlabel;
}
