import client from "../apollo.config";
import * as queries from "./queries";
import { groupsSectionAssignmentSections } from "../../components/container/SectionGroupsAssignments/types";
export const getReviewQuestionnaire = ({
  assetManagerId,
  questionnaireId
}: {
  assetManagerId?: string | number;
  questionnaireId: string | number;
}) => {
  return client.query({
    query: assetManagerId
      ? queries.GET_REVIEW_ASSET_MANAGER_QUESTIONNAIRE_QUERY
      : queries.GET_REVIEW_QUESTIONNAIRE_QUERY,
    fetchPolicy: "network-only",
    variables: { assetManagerId, questionnaireId }
  });
};

export const addComments = (input: any) => {
  return client.mutate({
    mutation: queries.ADD_COMMENTS_QUERY,
    variables: { input }
  });
};

export const addApprovalForQuestionnaire = (input: any) => {
  return client.mutate({
    mutation: queries.UPDATE_APPROVAL_QUERY,
    variables: { input }
  });
};

export const updateStatus = (input: any) => {
  return client.mutate({
    mutation: queries.UPDATE_STATUS_QUERY,
    variables: { input }
  });
};
export const getAssetManagerSectionAssignment = ({
  assetManagerQuestionnaireId,
  accountId
}: {
  assetManagerQuestionnaireId: string | number;
  accountId: string | number;
}) => {
  return client.query({
    query: queries.GET_ASSET_MANAGER_SECTION_ASSIGNMENT_QUERY,
    fetchPolicy: "network-only",
    variables: { assetManagerQuestionnaireId, accountId }
  });
};

export const saveAssetmanagerSectionGroupAssignment = (input: {
  assetManagerQuestionnaireId: string | number;
  sections: groupsSectionAssignmentSections[];
}) => {
  return client.mutate({
    mutation: queries.SAVE_ASSET_MANAGER_SECTION_GROUP_ASSIGNMENT,
    variables: { input }
  });
};
