import client from "../apollo.config";
import * as queries from "./queries";

export const getDataSetDetails = (
  datasetQuestionAnswerId: string | number,
  questionId: string | number,
  accountQuestionnaireId: string | number,
  sectionId: string | number
) => {
  return client.query({
    query: queries.GET_DATASET_DETAILS,
    fetchPolicy: "network-only",
    variables: {
      datasetQuestionAnswerId,
      questionId,
      accountQuestionnaireId,
      sectionId
    }
  });
};

export const deleteDataSet = (input: any) => {
  return client.mutate({
    mutation: queries.DELETE_DATASET,
    variables: { input }
  });
};

export const saveDataSet = (input: any) => {
  return client.mutate({
    mutation: queries.SAVE_DATASET,
    variables: { input }
  });
};
