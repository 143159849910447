import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import PublishedCompletedQuestionnaireGraph from "./PublishedCompletedQuestionnaireGraph";
import NewCounterpartiesOnboardCount from "./NewCounterpartiesOnboardCount";
import SubmittedQuestionnaireCountByStatusesGraph from "./SubmittedQuestionnaireCountByStatusesGraph";
import HighRiskCounterpartyList from "./HighRiskCounterpartyList";
import ResponseTimelines from "./ResponseTimelines";
import * as apis from "../../../apis/Dashboard";
import { PageDetails, Params, QuestionnairesList } from "./types";
import { createSelectOptionQuestionnaireList } from "./service";
import { ADMIN, OrgKeyMap } from "../../../constants/app-constants";
import { formatGraphQLError, isAdminUser } from "../../../lib/utils";
import { UserContext } from "../../../Contexts/UserContext";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

type Props = {
  getPageHeaderDetails: (pageDetails: PageDetails) => void;
};

const Dashboard: React.FC<Props> = ({ getPageHeaderDetails }) => {
  const { t } = useTranslation();
  const { accountId }: Params = useParams<any>();
  const { userDetails }: any = useContext(UserContext);
  const [questionnairesList, setQuestionnairesList] =
    useState<QuestionnairesList[]>();
  useEffect(() => {
    apis
      .getDashboardQuestionnairesList(accountId)
      .then((res) => {
        setQuestionnairesList(
          createSelectOptionQuestionnaireList(res.data.publishedQuestionnaires)
        );
      })
      .catch((error) => {
        setQuestionnairesList([]);
        toast.error(formatGraphQLError(error.message));
      });
  }, [accountId]);
  useEffect(() => {
    getPageHeaderDetails({
      title: `${OrgKeyMap.module_name} ${
        isAdminUser(userDetails?.user_type) ? t(ADMIN) : ""
      }`,
      details: []
    });
  }, [getPageHeaderDetails, userDetails]);
  return (
    <>
      <div className="container has-loader mt-4">
        <PublishedCompletedQuestionnaireGraph
          questionnairesList={questionnairesList}
        />
        <div className="row">
          <div className="col-md-6">
            <NewCounterpartiesOnboardCount />
            <HighRiskCounterpartyList questionnairesList={questionnairesList} />
          </div>
          <div className="col-md-6">
            <SubmittedQuestionnaireCountByStatusesGraph
              questionnairesList={questionnairesList}
            />
            <ResponseTimelines />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
