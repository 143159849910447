export function getTabDetails({
  activeTab,
  assetManagerId,
  submittedQuestionnaireId,
  accountType,
  sectionId,
  accountQuestionnaireId,
  nestedPath
}: any) {
  let tabs = [
    {
      className: activeTab === "Questionnaire" ? "nav-link active" : "nav-link",
      role: "tab",
      ariaControls: "Questionnaire",
      ariaSelected: true,
      tabName: "Questionnaire",
      tabLink: `/submittedQuestionnaire/${accountType}/${accountQuestionnaireId}/${assetManagerId}/${submittedQuestionnaireId}/${sectionId}/questionnaire`,
      visible: true
    },
    {
      className: activeTab === "Documents" ? "nav-link active" : "nav-link",
      role: "tab",
      ariaControls: "Documents",
      ariaSelected: true,
      tabName: "Documents",
      tabLink: `/submittedQuestionnaire/${accountType}/${accountQuestionnaireId}/${assetManagerId}/${submittedQuestionnaireId}/${sectionId}/documents`,
      visible: true
    }
  ];

  return tabs;
}

export function getDefaultRoute(path: string) {
  //console.log("getDefaultRoute called with >> ", path);
  switch (path) {
    case "documents":
      return "documents";
    case "questionnaire":
    default:
      return "questionnaire";
  }
}

export function getDefaultTab(nestedRoute: string) {
  //console.log("getDefaultTab called with >> ", nestedRoute);
  switch (nestedRoute) {
    case "documents":
      return "Documents";
    case "questionnaire":
    default:
      return "Questionnaire";
  }
}
