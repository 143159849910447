import React, { useState, useEffect } from "react";
import { ApprovalDetails } from "../../SubmittedQuestionnaire/types";
import { parseApprovalSteps } from "./service";
import { ApprovalStepsDetails } from "./types";
import {
  APPROVED,
  NOT_READY_FOR_APPROVAL
} from "../../../../constants/app-constants";
import Icons from "../../../presentation/Icons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { getDate } from "../../../../lib/utils";
import { useTranslation } from "react-i18next";
type Props = {
  approvalDetails: ApprovalDetails[];
  // getPageHeaderDetails: (pageDetails: any) => void;
};

const ApprovalSteps: React.FC<Props> = ({ approvalDetails }) => {
  const { t } = useTranslation();
  const [approvalSteps, setApprovalSteps] = useState<ApprovalStepsDetails[]>();
  useEffect(() => {
    let parsedData: any[] = parseApprovalSteps(approvalDetails);
    parsedData?.length && setApprovalSteps([...parsedData]);
  }, [approvalDetails]);
  return (
    <div className="step-approval-workflow">
      <div className="step-approval-workflow-list">
        {approvalSteps?.length &&
          approvalSteps.map((approval, approvalIndex) => {
            return (
              <div className="step-approval-workflow-box" key={approvalIndex}>
                <div className="step-approval-number">{approval.sequence}</div>

                {approval.steps?.length &&
                  approval.steps.map((step, stepIndex) => {
                    return (
                      <div
                        className={`step-approval-workflow-box-list ${
                          step.approvalStatus === NOT_READY_FOR_APPROVAL
                            ? "disabled"
                            : ""
                        }`}
                        key={stepIndex}
                      >
                        <div className="step-approval-icon-status">
                          {step.approvalStatus === APPROVED ? (
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id={`approval-details-${stepIndex}`}>
                                  <div className="approval-workflow-tooltip-heading">
                                    <span>
                                      {" "}
                                      {t(
                                        "dynamicStringTranslation.approvedBy",
                                        {
                                          username: `${step.approvedBy}`
                                        }
                                      )}{" "}
                                    </span>
                                    <span>
                                      {step.isCommentsPresent && (
                                        <Icons icon={"smallCommentMessage"} />
                                      )}
                                    </span>
                                  </div>
                                  <div className="approval-workflow-tooltip-text">
                                    {getDate(
                                      step.approvedAt,
                                      "MMMM Do YYYY, h:mm:ss a"
                                    )}
                                  </div>
                                </Tooltip>
                              }
                            >
                              <span>
                                <Icons icon={"circleApprovedView"} />
                              </span>
                            </OverlayTrigger>
                          ) : (
                            <Icons icon={"circleUnapprovedView"} />
                          )}
                        </div>

                        <div className="step-approval-text">
                          <div className="step-approval-text-heading">
                            {t(step.title)}
                          </div>
                          <div className="step-approval-sub-heading">
                            {t(step.group)}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            );
          })}
      </div>
    </div>
  );
};
export default ApprovalSteps;
