import gql from "graphql-tag";

export const ADD_COMMENT_QUERY = gql`
  mutation createBuildApprovalComments(
    $input: CreateBuildApprovalCommentInput!
  ) {
    createBuildApprovalComments(input: $input) {
      message
    }
  }
`;
export const UPDATE_COMMENT_QUERY = gql`
  mutation updateBuildApprovalComment(
    $input: UpdateBuildApprovalCommentInput!
  ) {
    updateBuildApprovalComment(input: $input) {
      message
    }
  }
`;
export const DELETE_COMMENT_QUERY = gql`
  mutation deleteBuildApprovalComment(
    $input: DeleteBuildApprovalCommentInput!
  ) {
    deleteBuildApprovalComment(input: $input) {
      message
    }
  }
`;
export const APPROVE_BUILD = gql`
  mutation approveBuiltQuestionnaire($input: ApproveBuiltQuestionnaireInput!) {
    approveBuiltQuestionnaire(input: $input) {
      message
    }
  }
`;

export const GET_BUILD_APPROVAL_DETAILS_QUERY = gql`
  query GetBuildApprovalDetails($questionnaireId: ID!, $assetManagerId: ID!) {
    getBuildApprovalDetails(
      questionnaireId: $questionnaireId
      assetManagerId: $assetManagerId
    ) {
      approvalStepGroupRole {
        accountGroup {
          id
          name
        }
        approvalComments {
          comment
          createdAt
          id
          updatedAt
          user {
            email
            name
            userId
          }
        }
        approvalStep {
          approvalName
          approvalType {
            id
            name
          }
          id
          sequence
        }
        approval {
          approvalStatus
          approvedBy
          approvedById
          approvedAt
          id
        }
        id
      }
    }
  }
`;
