import React from "react";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import QuestionnaireDocumentsList from "../QuestionnaireDocumentsList";
import SubmittedQuestionnaire from "../SubmittedQuestionnaire";
import GuardedRoute from "../../../GuardedRoute";
import {
  ADMIN_USER,
  ASSET_MANAGER_USER
} from "../../../lib/questionnairePermissionsAccess";

type Props = {
  defaultPath: string;
};
const ReviewSubmittedQuestionnaireRoutes: React.FC<any> = ({
  defaultPath,
  refreshQuestionnaire
}) => {
  //   const { userDetails }: any = useContext(UserContext);
  const { path, url } = useRouteMatch();
  return (
    <Switch>
      <GuardedRoute
        exact
        path={`${path}/questionnaire`}
        component={SubmittedQuestionnaire}
        compProps={{ pageHeaderPropsChange: refreshQuestionnaire }}
        accessUserTypes={[ADMIN_USER, ASSET_MANAGER_USER]}
      />
      <GuardedRoute
        exact
        path={`${path}/documents`}
        component={QuestionnaireDocumentsList}
        accessUserTypes={[ADMIN_USER, ASSET_MANAGER_USER]}
      />
      <Route>
        <Redirect from="submittedQuestionnaire" to={`${url}/${defaultPath}`} />
      </Route>
    </Switch>
  );
};
export default ReviewSubmittedQuestionnaireRoutes;
