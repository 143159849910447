import client from "../apollo.config";
import * as queries from "./queries";
import { groupsSectionAssignmentSections } from "../../components/container/SectionGroupsAssignments/types";
import {
  PageDetailsState,
  SectionParams
} from "../../components/container/ReceivedAssigmentsQuestionnaireList/types";

export const getQuestionnaireAssignmentsList = ({
  pageNumber,
  pageSize,
  search,
  sort,
  counterpartyId,
  filters
}: PageDetailsState) => {
  return client.query({
    query: queries.GET_ASSIGNMENTS_LIST_QUERY,
    fetchPolicy: "network-only",
    variables: { pageNumber, pageSize, search, sort, counterpartyId, filters }
  });
};

export const getSectionAssignment = ({
  accountQuestionnaireId,
  accountId
}: {
  accountQuestionnaireId: string | number;
  accountId: string | number;
}) => {
  return client.query({
    query: queries.GET_SECTION_ASSIGNMENT_QUERY,
    fetchPolicy: "network-only",
    variables: { accountQuestionnaireId, accountId }
  });
};

export const saveSectionGroupAssignment = (input: {
  accountQuestionnaireId: string | number;
  sections: groupsSectionAssignmentSections[];
}) => {
  return client.mutate({
    mutation: queries.SAVE_SECTION_GROUP_ASSIGNMENT,
    variables: { input }
  });
};

export const getQuestionnaireSections = ({
  counterpartyId,
  accountQuestionnaireId
}: SectionParams) => {
  return client.query({
    query: queries.GET_QUESTIONNAIRE_SECTIONS_LIST_QUERY,
    fetchPolicy: "network-only",
    variables: { counterpartyId, accountQuestionnaireId }
  });
};
export const saveCPNotificationPreferences = (input: {
  userId: string | number;
  accountId: string | number;
  accountQuestionnaireId: string[] | number[];
  publicPrivateComments: boolean;
  submissionStatusChange: boolean;
}) => {
  return client.mutate({
    mutation: queries.SAVE_CP_NOTIFICATION_PREFERENCES,
    variables: { input }
  });
};
