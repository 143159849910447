import React, { useEffect, useState } from "react";
import * as utils from "../../../lib/utils";
import { useTable, usePagination, useFilters, useSortBy } from "react-table";
import Accordion from "react-bootstrap/Accordion";
import { useTranslation } from "react-i18next";
import { COUNTERPARTY_USER } from "../../../lib/questionnairePermissionsAccess";

interface Props {
  gridData: any;
  pageIndex: number;
  pageSize: number;
  columnConfig: any;
  totalRecordsCount: number;
  hiddenColumns?: string[];
  onSort?: (selectValue: any) => void;
  defaultPageSize?: number;
  disableSort?: boolean;
  action?: any;
  submissionDate?: string;
  rowAccordion?: boolean;
  nestedComponentTemplate?: any;
  selectedRow?: any;
  tableClassName?: any;
  userType?: string;
}

const GridComponent: React.FC<Props> = ({
  pageIndex,
  pageSize,
  gridData,
  columnConfig,
  totalRecordsCount,
  hiddenColumns = [],
  onSort,
  defaultPageSize = 1000,
  disableSort,
  action,
  rowAccordion,
  nestedComponentTemplate,
  selectedRow,
  tableClassName = "",
  userType
}) => {
  const { t } = useTranslation();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize
  } = useTable(
    {
      columns: columnConfig,
      data: gridData,
      initialState: {
        pageIndex: 0,
        pageSize: defaultPageSize,
        hiddenColumns: hiddenColumns
      },
      manualPagination: true,
      pageCount: Math.ceil(totalRecordsCount / pageSize),
      manualSortBy: true,
      autoResetPage: false,
      autoResetSortBy: false,
      disableSortBy: disableSort ? true : false,
      action
    },
    useFilters,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    setPageSize(pageSize);
  }, [pageSize, setPageSize, totalRecordsCount]);

  useEffect(() => {
    gotoPage(pageIndex);
  }, [pageIndex, gotoPage]);
  //const [accountQuestionnaireId, setAccountQuestionnaireId] = useState();
  const [collapseId, setCollapseId] = useState<string>();
  function getMarginClass(index: number) {
    if (userType === COUNTERPARTY_USER) {
      if (index === 0) {
        return "col-300-min";
      } else if ((index === 1 || index === 2) && !rowAccordion) {
        return "col-230-min";
      } else if ((index === 1 || index === 2) && rowAccordion) {
        return "col-250-min";
      } else {
        return "";
      }
    } else {
      switch (index) {
        case 0:
          return "col-170-min";
        default:
          return "";
      }
    }
  }
  return (
    <>
      <div className={`table ${tableClassName}`} {...getTableProps()}>
        <div className="table__header">
          <div className="table__row--outer">
            <div className="table__row--inner container">
              {headerGroups.map((headerGroup: any) => (
                <div
                  className="table__row row"
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column: any, index: number) => {
                    return (
                      <div
                        className={`table__cell col table__cell--${utils.camelCaseToDash(
                          column.id
                        )} ${getMarginClass(index)}`}
                        key={index}
                      >
                        <div
                          {...column.getHeaderProps(
                            column.getSortByToggleProps({ title: undefined })
                          )}
                        >
                          <div
                            className={`active cell__title ${
                              column.isSorted ? "is-sortable" : ""
                            }`}
                            data-sortdirection={
                              column.isSorted
                                ? column.isSortedDesc
                                  ? "down"
                                  : "up"
                                : ""
                            }
                            onClick={() => {
                              if (!column.canSort) return false;

                              // if column is not sorted, then sort by ascending.
                              // if column is sorted by ascending, then sort by descending.
                              // if column is sorted by descending, then sort by default.

                              let sortDirection = column.isSorted
                                ? column.isSortedDesc
                                  ? ""
                                  : "desc"
                                : "asc";

                              onSort &&
                                onSort({
                                  id: column.id,
                                  sortDirection: sortDirection
                                });
                            }}
                          >
                            <b>{t(column.render("Header"))}</b>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="table__body" {...getTableBodyProps()}>
          {rowAccordion ? (
            <Accordion>
              {page.map((row: any) => {
                prepareRow(row);
                return (
                  <div
                    className="table__row--outer row-caret "
                    aria-expanded={
                      collapseId === row.values.id ? "true" : "false"
                    }
                    {...row.getRowProps()}
                  >
                    <Accordion.Toggle
                      as="div"
                      eventKey={row.values.id}
                      className={`table__row--container container dropdown-toggle ${
                        collapseId === row.values.id ? "open" : "collapsed"
                      }`}
                      onClick={(event: any) => {
                        event.stopPropagation();
                        // setAccountQuestionnaireId(data.id);
                        selectedRow(row);
                        setCollapseId((collapseId) =>
                          collapseId !== row.values.id ? row.values.id : ""
                        );
                      }}
                    >
                      <div className="table__row row">
                        {row.cells.map((cell: any, index: number) => {
                          return (
                            <div
                              className={`table__cell col table__cell--${utils.camelCaseToDash(
                                cell.column.id
                              )} ${getMarginClass(index)}`}
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </div>
                          );
                        })}
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={row.values.id}>
                      {nestedComponentTemplate}
                    </Accordion.Collapse>
                  </div>
                );
              })}
            </Accordion>
          ) : (
            <>
              {page.map((row: any) => {
                prepareRow(row);
                return (
                  <div className="table__row--outer" {...row.getRowProps()}>
                    <div className="table__row--container container">
                      <div className="table__row row">
                        {row.cells.map((cell: any, index: number) => {
                          return (
                            <div
                              className={`table__cell col table__cell--${utils.camelCaseToDash(
                                cell.column.id
                              )} ${getMarginClass(index)}`}
                              {...cell.getCellProps()}
                            >
                              {cell.render("Cell")}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default GridComponent;
