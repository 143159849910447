import { Section } from "../SectionComponent/types";

export enum CommentableType {
  Questionnaire = "Questionnaire",
  Section = "Section",
  Question = "Question"
}

export type CommentDetails = {
  commentableId: number | string;
  commentableType: string;
  comments: Comment[];
};

export type Params = {
  assetManagerId: string | number;
  questionnaireId: string | number;
};

export type Questionnaire = {
  id: number | string;
  sections: Section[];
  title: string;
  comments: Comment[];
};

export type Comment = {
  comment: string;
  commentId: string | number;
  commentedAt: string;
  commentedById: string | number;
  commentedByName: string;
};

export type ApprovalStatus = {
  approvedAt: string;
  approvedBy: string;
  approvalStatus: string;
};
