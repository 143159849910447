import React, { useState, useEffect, useRef } from "react";
import "react-tabulator/lib/styles.css";
import "react-tabulator/lib/css/bootstrap/tabulator_bootstrap4.min.css";
import { reactFormatter, React15Tabulator } from "react-tabulator";
import { createTableConfig, getDefaultPlaceHolder } from "./service";
import { TableOptions, TableStaticElements } from "./types";
import { Answer } from "../../container/QuestionComponent/types";
import RemoveButton from "./TableButtons/removeButton";
import Buttons from "./TableButtons/Buttons";

type IProps = {
  tableData: string | object;
  onChange: any;
  isDisabled: Boolean;
  tableAnswer: Answer;
  itemIndex: any;
};

const Table: React.FC<IProps> = ({
  tableData,
  onChange,
  isDisabled,
  tableAnswer,
  itemIndex
}) => {
  const [tableConfig, setTableConfig] = useState<TableOptions | null>(null);
  const [tableKey, setTableKey] = React.useState(`${Math.random()}`);
  const tableRef: any = useRef();
  const tableStaticElements: TableStaticElements = {
    removeButtonColObj: {
      field: "remove-btn",
      title: "",
      hozAlign: "right",
      width: 70,
      height: 47,
      formatter: reactFormatter(<RemoveButton onBtnClick={removeRow} />)
    },
    childTableButtons: {
      field: "btn-group",
      title: "",
      hozAlign: "right",
      width: 80,
      height: 47,
      formatter: reactFormatter(
        <Buttons onRowRemovedClick={removeRow} onAddRowClick={addRow} />
      )
    }
  };
  const tableIndex = `${itemIndex.split(".").join("-")}`;
  useEffect(() => {
    if (tableData) {
      setTableKey(`${Math.random()}`);
      setTableConfig(
        createTableConfig(
          tableData,
          tableStaticElements,
          1,
          tableAnswer,
          onTableDataChange,
          tableIndex,
          !isDisabled
        )
      );
      // setColumns([...getColumnsDetails(tableData, 1, !isDisabled)])
    }
  }, [tableData, isDisabled]);

  function onTableDataChange(data: any) {
    onChange(JSON.stringify(data));
  }

  function onAddRowClick() {
    let table = tableRef.current.table;
    // table.addRow(tableConfig?.rowPlaceHolder)
    addRow(table);
  }

  function removeRow(row: any) {
    row
      .delete()
      .then(function (res: any) {
        // console.log('Success',res)
        //run code after row has been deleted
      })
      .catch(function (error: any) {
        console.log("Error", error);
        //handle error deleting row
      });
  }

  function addRow(table: any) {
    let defaultRowValue = getDefaultPlaceHolder(table);
    table.addRow(defaultRowValue);
  }

  return (
    tableConfig && (
      <>
        {tableConfig.addNewRowButton && !isDisabled && (
          <button className="btn btn-primary" onClick={onAddRowClick}>
            {tableConfig.addNewRowButton}
          </button>
        )}
        <React15Tabulator
          data={tableConfig?.data}
          columns={tableConfig.columns}
          options={tableConfig?.options}
          key={tableKey}
          ref={tableRef}
          className={`tabulator-parent-${tableIndex}`}
          dataChanged={onTableDataChange}
        />
      </>
    )
  );
};

export default Table;
