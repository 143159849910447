import React from "react";
import {
  createUltimatePagination,
  ITEM_TYPES
} from "react-ultimate-pagination";
import { PaginationElem } from "./types";

/**
 * Bootstrap 4+ theme React Ultimate Pagination wrapper
 * createUltimatePagination api takes the pagination item type components and overrides with custom bootstrap theme components
 * It also accespts the wrapper element which is list in our case
 * Pagination item types - Page, First, Previous, Next, Last and Ellipsis
 * @Refer - https://github.com/ultimate-pagination/react-ultimate-pagination
 * @Refer - https://github.com/ultimate-pagination/react-ultimate-pagination-bootstrap-4/blob/master/src/react-ultimate-pagination-bootstrap-4.js
 */

const WrapperComponent = ({ children }: any) => (
  <ul className="pagination">{children}</ul>
);

const withPreventDefault = (fn: any) => (event: any) => {
  event.preventDefault();
  fn();
};

const Page = ({ value, isActive, onClick }: PaginationElem) => (
  <li className={isActive ? "page-item active" : "page-item"}>
    {isActive ? (
      <span className="page-link">
        {value}
        <span className="sr-only">(current)</span>
      </span>
    ) : (
      <a
        className="page-link"
        href="#"
        role="button"
        onClick={withPreventDefault(onClick)}
      >
        {value}
      </a>
    )}
  </li>
);

const Ellipsis = ({ onClick }: PaginationElem) => (
  <li className="page-item">
    <a
      className="page-link"
      href="#"
      role="button"
      onClick={withPreventDefault(onClick)}
    >
      <span aria-hidden="true">...</span>
      <span className="sr-only">More</span>
    </a>
  </li>
);

const FirstPageLink = ({ onClick }: PaginationElem) => (
  <li className="page-item">
    <a
      className="page-link"
      href="#"
      role="button"
      onClick={withPreventDefault(onClick)}
    >
      <span aria-hidden="true">&laquo;</span>
      <span className="sr-only">First</span>
    </a>
  </li>
);

const PreviousPageLink = ({ onClick }: PaginationElem) => (
  <li className="page-item">
    <a
      className="page-link"
      href="#"
      role="button"
      onClick={withPreventDefault(onClick)}
    >
      <span aria-hidden="true">&lsaquo;</span>
      <span className="sr-only">Previous</span>
    </a>
  </li>
);

const NextPageLink = ({ onClick }: PaginationElem) => (
  <li className="page-item">
    <a
      className="page-link"
      href="#"
      role="button"
      onClick={withPreventDefault(onClick)}
    >
      <span aria-hidden="true">&rsaquo;</span>
      <span className="sr-only">Next</span>
    </a>
  </li>
);

const LastPageLink = ({ onClick }: PaginationElem) => (
  <li className="page-item">
    <a
      className="page-link"
      href="#"
      role="button"
      onClick={withPreventDefault(onClick)}
    >
      <span aria-hidden="true">&raquo;</span>
      <span className="sr-only">Last</span>
    </a>
  </li>
);

const itemTypeToComponent = {
  [ITEM_TYPES.PAGE]: Page,
  [ITEM_TYPES.ELLIPSIS]: Ellipsis,
  [ITEM_TYPES.FIRST_PAGE_LINK]: FirstPageLink,
  [ITEM_TYPES.PREVIOUS_PAGE_LINK]: PreviousPageLink,
  [ITEM_TYPES.NEXT_PAGE_LINK]: NextPageLink,
  [ITEM_TYPES.LAST_PAGE_LINK]: LastPageLink
};

const BootstrapPaginator = createUltimatePagination({
  itemTypeToComponent,
  WrapperComponent
});

export default BootstrapPaginator;
