import React, { useState, useEffect, useRef } from "react";
import CustomModal from "../../presentation/Modals/CustomModal";

import { toast } from "react-toastify";
import { getDate, isNumericValue } from "../../../lib/utils";

import { Notes } from "./types";
import { useTranslation } from "react-i18next";
import { QueryPrameters } from "../../../types/applicationData";
import { useQueryParams } from "../../../Hooks/useQueryParams";
type Props = {
  defaultPoints: number | string;
  currentPoints: number | string;
  notes: Notes[];
  showModal: boolean;
  onClose: () => void;
  allowEdit: boolean;
  onSave: (note: string, points: number) => void;
};
const ScoreDetails: React.FC<Props> = ({
  defaultPoints = 0,
  currentPoints = 0,
  notes,
  showModal,
  onClose,
  allowEdit,
  onSave
}) => {
  const queryParams: QueryPrameters | undefined = useQueryParams({
    paramNames: ["noteId"]
  });
  const commentRef = useRef<any>();
  const isScrollingDone = useRef<boolean>(false);
  useEffect(() => {
    if (queryParams?.noteId && !isScrollingDone.current) {
      commentRef?.current?.scrollIntoView();
      isScrollingDone.current = true;
    }
  });
  const { t } = useTranslation();
  const [note, setNote] = useState<string>();
  const [points, setPoints] = useState<number>(0);
  useEffect(() => {
    setPoints(Number(currentPoints));
  }, [currentPoints]);
  useEffect(() => {
    setNote("");
  }, [notes]);
  function saveDetails() {
    if (!note?.trim()) {
      toast.error(t("Please enter notes"));
      return;
    }
    if (
      !isNumericValue({
        data: Number(points || 0),
        allowNegative: false,
        allowOnlyNonZero: false,
        upperLimit: Number(defaultPoints || 0)
      })
    ) {
      toast.error(
        t("Please enter non negative points less than default points")
      );
      return;
    }
    onSave(note.trim(), points);
  }
  return (
    <>
      <CustomModal
        modalTitle="Edit deductions"
        classNames="cdd-groups-management cdd-auto-feature editable edit-deductions-modal"
        showModal={showModal}
        onClose={() => {
          onClose();
        }}
        showFooter={true}
        footer={
          allowEdit ? (
            <div className="form">
              <div className="form-group">
                <textarea
                  rows={6}
                  placeholder={t("Leave your notes here..")}
                  value={note || ""}
                  onChange={(data) => setNote(data.target.value)}
                ></textarea>
              </div>

              <div className="form-group">
                <div className="form-row">
                  <div className="col-12 d-flex">
                    <div className="deduction-input mr-auto">
                      <span className="deduction-input__new-score">
                        {t("New")}:
                        <input
                          type="number"
                          className="deduction-input__new-score-input"
                          placeholder={String(currentPoints || 0)}
                          onChange={({ target }) =>
                            setPoints(parseInt(target.value))
                          }
                        ></input>
                      </span>
                      <span className="deduction-input__default-score">
                        {t("Default")}: {defaultPoints}
                      </span>
                    </div>
                    <button
                      type="button"
                      className="col-sm-auto btn btn-primary"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={saveDetails}
                    >
                      {t("Save")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )
        }
      >
        <div className="comments">
          {notes && notes.length > 0
            ? notes.map((note: Notes, index: number) => {
                return (
                  <div
                    ref={commentRef}
                    key={index}
                    className={`comment accordion__body`}
                    id={`individual-note-${note.id}`}
                  >
                    <div className="comment__meta">
                      <span className="modal__detail text-secondary modal__detail--trailing-dot">
                        <b>
                          {t("Changed to")} {note.point}
                        </b>
                      </span>
                      <span className="modal__detail modal__detail">
                        {t("by")} {note.userName}
                      </span>
                      <span className="modal__detail-date">
                        | {getDate(note.createdAt, "MMMM Do YYYY, h:mm:ss a")}
                      </span>

                      {/* <span className="mr-1">{note.userName}</span>
                      <span className="text-muted">
                        {getDate(note.createdAt, "MMMM Do YYYY, h:mm:ss a")}
                      </span> */}
                    </div>
                    <div>
                      <span className="modal__detail modal__detail--note">
                        {t("Notes")}:
                      </span>
                      <p>{note.note}</p>
                    </div>
                  </div>
                );
              })
            : t("No notes available")}
        </div>
      </CustomModal>
    </>
  );
};
export default ScoreDetails;
