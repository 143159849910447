import React, { useState, useEffect } from "react";
import ShareholderDetails from "./ShareholderDetails";
import OrganizationTree from "./OrganizationTree";
import {
  uboQuestionDropDown,
  uboQuestionDropDownGerman,
  getTranslatedLabel
} from "../service";
import { getRemainingPercent } from "./service";
import { SelectDropDownType } from "../../types";
import Select from "react-select";
import { OrgTreeDetails, SelectedNodeShareHolder } from "../../types";
import i18n from "../../../../i18n";
import {
  findNodeElement,
  addNodeElement,
  updateNodeElement,
  calculateAbsoluteOwnerShip,
  NATURAL_PERSON,
  removeNodeElement,
  markUBOsInOrgStructure
} from "./service";
import { toast } from "react-toastify";
import OrganizationChart from "../../OrganizationChart";
import { convertTree } from "../../OrganizationChart/service";
import { useTranslation } from "react-i18next";
import { getByLabelText } from "@testing-library/react";
interface Props {
  uboInfoType: { value: string; label: string };
  onUboInfoTypeChange: (data: SelectDropDownType) => void;
  updateParent: (data?: OrgTreeDetails) => void;
  orgStructure?: OrgTreeDetails;
  voteThreshold: number;
  shareThreshold: number;
  fileUploadParams: any;
  allowEdit: boolean;
}

const DerivedUBO: React.FC<Props> = ({
  uboInfoType,
  onUboInfoTypeChange,
  updateParent,
  orgStructure,
  voteThreshold,
  shareThreshold,
  fileUploadParams,
  allowEdit
}) => {
  const { t } = useTranslation();
  const [orgData, setOrgData] = useState<OrgTreeDetails | undefined>(
    orgStructure
  );
  const [selectedNode, setSelectedNode] = useState<
    SelectedNodeShareHolder | undefined
  >(
    !orgStructure?.nodeId
      ? {
          nodeId: "1"
        }
      : undefined
  );
  const [isShowUBOsOnly, setIsShowUBOsOnly] = useState<boolean>(false);
  useEffect(() => {
    updateParent(orgData);
    //setIsShowUBOsOnly(false);
  }, [orgData]);
  function updateShareHolderData(data: SelectedNodeShareHolder) {
    let nodeData = {
      ...data,
      nodeId: data.nodeId,
      votingRights: data.votingRights,
      shareHolding: data.shareHolding,
      legalEntityName: data.legalEntityName,
      firstName: data.firstName,
      lastName: data.lastName,
      entityType: data.entityType,
      documents: data.documents
    };
    if (!orgData) {
      nodeData = {
        ...nodeData,
        ...{
          votingRights: 100,
          shareHolding: 100,
          absoluteVotePercent: 100,
          absoluteSharePercent: 100,
          subsidiaries: []
        }
      };

      setOrgData({
        ...markUBOsInOrgStructure({
          orgTree: nodeData,
          shareHolding: shareThreshold,
          voteRights: voteThreshold
        })
      });
    } else {
      let parentNode;
      if (data.parentNodeId) {
        parentNode = findNodeElement({
          nodeId: data.parentNodeId,
          orgData
        });
      }
      //if parent is not present it means it is the parent node
      nodeData = {
        ...nodeData,
        ...calculateAbsoluteOwnerShip({
          parentAbsoluteVoteShare: parentNode
            ? parentNode.absoluteVotePercent || 0
            : 100,
          parentAbsoluteShareHolding: parentNode
            ? parentNode.absoluteSharePercent || 0
            : 100,
          curruntVotePercent: parentNode ? data.votingRights || 0 : 100,
          currentSharePercent: parentNode ? data.shareHolding || 0 : 100
        })
      };

      if (
        data.entityType === NATURAL_PERSON ||
        data.isEntityWithNoUbo ||
        data.isFreeFloatShareEntity
      ) {
        nodeData = { ...nodeData, ...{ subsidiaries: [] } };
      }
      let updatedData: any = updateNodeElement({
        node: nodeData,
        orgData: orgData
      });

      updatedData &&
        setOrgData({
          ...markUBOsInOrgStructure({
            orgTree: updatedData,
            shareHolding: shareThreshold,
            voteRights: voteThreshold
          })
        });
    }
    //setSelectedNode(undefined);
  }
  function editShareHolderData({
    parentNodeId,
    nodeId
  }: {
    parentNodeId: string | null;
    nodeId: string;
  }) {
    if (orgData) {
      let foundData: any = findNodeElement({ nodeId, orgData });
      setSelectedNode({ ...foundData, parentNodeId });
    }
  }
  function addShareHolderData({
    parentNodeId,
    nodeId
  }: {
    parentNodeId: string | null;
    nodeId: string;
  }) {
    if (orgData) {
      let foundData: any = findNodeElement({ nodeId, orgData });
      if (parentNodeId) {
        let parentData: any = findNodeElement({
          nodeId: parentNodeId,
          orgData
        });
        let total = parentData.subsidiaries.reduce(
          (acc: number, e: OrgTreeDetails) => {
            acc += Number(e.votingRights || 0) + Number(e.shareHolding || 0);
            return acc;
          },
          0
        );
        if (Number(total) < 200) {
          toast.error(
            t(
              "Please assign all 100% for voting rights and share capital to continue with the current entity"
            )
          );
          return;
        }
      }

      if (foundData) {
        let subsidiariesCount = foundData.subsidiaries.length;
        let updatedData = addNodeElement({
          parentNodeId: nodeId,
          node: {
            nodeId: nodeId + "." + (subsidiariesCount + 1),
            legalEntityName: "",
            votingRights: undefined,
            shareHolding: undefined,
            entityType: "",
            absoluteVotePercent: 0,
            absoluteSharePercent: 0,
            subsidiaries: []
          },
          orgData: orgData
        });
        setSelectedNode({
          parentNodeId: nodeId,
          nodeId: nodeId + "." + (subsidiariesCount + 1),
          votingRights: undefined,
          shareHolding: undefined,
          legalEntityName: ""
        });
        setOrgData(updatedData);
      }
    }
  }
  function deleteShareHolderData(
    nodeId: string | null,
    parentNodeId: string | null
  ) {
    //This means it is parent node
    if (!parentNodeId && Number(nodeId) === 1) {
      setOrgData(undefined);
      setSelectedNode({
        nodeId: "1"
      });
      //delete complete structure
    } else if (nodeId && parentNodeId && orgData) {
      let updatedData = removeNodeElement({
        parentNodeId,
        nodeId,
        orgData
      });
      setOrgData({ ...updatedData });
      setSelectedNode(undefined);
    }
  }
  function showUBOsOnly() {
    let showUBOVal = !isShowUBOsOnly;

    // if (orgData) {
    //   showUBOVal &&
    //     setOrgData({
    //       ...markUBOsInOrgStructure({
    //         orgTree: orgData,
    //         shareHolding: shareThreshold,
    //         voteRights: voteThreshold
    //       })
    //     });
    // }
    setIsShowUBOsOnly(showUBOVal);
  }

  return (
    <>
      <div className="ubo-sec-content">
        <div className="ubo-left-filter">
          <div className="ubo-status l-r-15">
            <p className="ubo-status-text">
              {t("Do you already know who the UBOs are?")}
            </p>
            <Select
              placeholder={t("Select")}
              name="uboInfoType"
              onChange={(option: any) => onUboInfoTypeChange(option)}
              value={{
                ...uboInfoType,
                label: getTranslatedLabel(uboInfoType.value)
              }}
              options={
                i18n.language === "de"
                  ? uboQuestionDropDownGerman
                  : uboQuestionDropDown
              }
              isDisabled={!allowEdit}
              noOptionsMessage={() => t("No options")}
            />
          </div>
          {orgData && (
            <>
              <OrganizationTree
                onEdit={editShareHolderData}
                onAdd={addShareHolderData}
                nodeId={"1"}
                treeDetails={orgData}
                isShowUboOnly={isShowUBOsOnly}
                allowEdit={allowEdit}
              />
              <div className="bt-switch-toggle">
                <div className="bt-switch-toggle-flex">
                  <div className="toggle-btn">
                    <label className="switch">
                      <input
                        type="checkbox"
                        onChange={() => {
                          showUBOsOnly();
                        }}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                  <div className="toggle-label">{t("Show UBOs only")}</div>
                </div>
              </div>
            </>
          )}
        </div>

        {selectedNode && (
          <ShareholderDetails
            shareHolderDetails={selectedNode}
            getShareHolder={updateShareHolderData}
            onDelete={deleteShareHolderData}
            fileUploadParams={fileUploadParams}
            allowEdit={allowEdit}
            onClose={() => setSelectedNode(undefined)}
            {...getRemainingPercent(
              selectedNode,
              orgData,
              selectedNode?.parentNodeId
            )}
          />
        )}
        {orgData && (
          <div className="ubo-org-chart">
            <OrganizationChart
              hierarchy={convertTree(orgData, true)}
              selectedId={selectedNode?.nodeId}
              addCallback={(parent) =>
                addShareHolderData({
                  parentNodeId: null,
                  nodeId: parent.id
                })
              }
              editCallback={(node, parent) =>
                editShareHolderData({
                  nodeId: node.id,
                  parentNodeId: parent?.id || null
                })
              }
            />
          </div>
        )}
      </div>

      {/* <div className="row">
        <div className="col-sm-6">
          <OrganizationTree
            onEdit={editShareHolderData}
            onAdd={addShareHolderData}
            nodeId={"1"}
            treeDetails={orgData}
          />
        </div>
        <div className="col-sm-6">
          <ShareholderDetails
            shareHolderDetails={selectedNode}
            getShareHolder={updateShareHolderData}
          />
        </div>
      </div> */}
    </>
  );
};

export default DerivedUBO;
