import client from "../apollo.config";
import * as queries from "./queries";

export const getQuestionnaireDocumentsList = (
  accountQuestionnaireId: string | number
) => {
  return client.query({
    query: queries.GET_QUESTIONNAIRE_DOCUMENTS_LIST_QUERY,
    fetchPolicy: "network-only",
    variables: { accountQuestionnaireId }
  });
};
