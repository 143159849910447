import axios, { AxiosResponse } from "axios";
import { SectionLocks } from "../components/container/CounterpartySubmittedQuestionnaire/types";
import { CDD_MODULE_NAME } from "../constants/app-constants";
import UserService from "./userServices";
export const fetchSectionLocks = async (data: {
  account_questionnaire_id: string | number;
  section_locks: SectionLocks[];
  current_section_id: string | number;
}): Promise<AxiosResponse<any>> => {
  try {
    const sectionLocks: AxiosResponse<SectionLocks> = await axios({
      method: "post",
      url: `${process.env.REACT_APP_REST_ENDPOINT}/api/v1/createSectionLock`,
      data: data,
      withCredentials: true
    });

    return sectionLocks;
  } catch (error) {
    let response = error.response;
    // Axios error object provides api response within response.data field
    if (
      response &&
      response.status === 401 &&
      response.data.errors === "Session Expired"
    ) {
      // Redirect to SSO login page with redirect_url set to requested page if status code 401
      // window.location.href =
      //   process.env.REACT_APP_SSO_LOGIN_URL +
      //   `?module_name=${CDD_MODULE_NAME}&redirect_url=` +
      //   process.env.REACT_APP_REDIRECT_URL;
      UserService.doLogout();

      throw new Error("redirect");
    } else if (response && response.status === 403) {
      // Redirect user to swift landing page if current user account does not have access to CDD module
      window.location.href = process.env.REACT_APP_SWIFT_APP_URL as string;
      throw new Error("redirect");
    } else {
      throw new Error(error.message); // Throw generic error if status code is other than 401
    }
  }
};

export const handleWindowCloseForLocks = async (data: {
  account_questionnaire_id: string | number;
}) => {
  await axios({
    method: "get",
    url: `${process.env.REACT_APP_REST_ENDPOINT}/api/v1/deleteSectionLock`,
    params: data,
    withCredentials: true
  });
};
