import React, { useState } from "react";
import CustomModal from "../presentation/Modals/CustomModal";
import AddUBOs from "./AddUBOs";
import { UBODetailsType, PersonOfInterestType } from "./types";
import ShowKnownUboList from "./FinalUboDetails/ShowKnownUboList";
import ShowDerivedUboList from "./FinalUboDetails/ShowDerivedUboList";
import PersonOfInterest from "./PersonOfInterest";
//import * as apis from "../../apis/CounterpartySubmittedQuestionnaire";
import { KNOWN, DERIVED, FICTITIOUS } from "./AddUBOs/service";
//import { formatGraphQLError } from "../../lib/utils";
//import { toast } from "react-toastify";
import ShowFictitiousUboList from "./FinalUboDetails/ShowFictitiousUboList";
import { convertTree } from "./OrganizationChart/service";
import OrganizationChart from "./OrganizationChart";
import { useTranslation } from "react-i18next";
interface Props {
  saveQuestionDetails: any;
  voteThreshold: number;
  shareThreshold: number;
  //Above 2 parameters are used to idenntify the ubos
  answer?: UBODetailsType;
  fileUploadParams: any;
  allowEdit: boolean;
  questionId: string | number;
  onChange: (data: any) => void;
}

const UBOEngine: React.FC<Props> = ({
  voteThreshold,
  shareThreshold,
  saveQuestionDetails,
  answer,
  fileUploadParams,
  allowEdit,
  questionId,
  onChange
}) => {
  const { t } = useTranslation();
  const [showAddUBO, setShowAddUBO] = useState(false);
  const [uboDetails, setUboDetails] = useState<UBODetailsType | undefined>({
    ...answer
  });

  function saveUboDetails(details: UBODetailsType) {
    setUboDetails({ ...details });
    onChange(details);
  }
  function onUpdatePersonOfInterest(data: PersonOfInterestType[]) {
    let parsedData = {
      ...uboDetails,
      personOfInterest: [...data]
    };
    setUboDetails({
      ...parsedData
    });
    onChange(parsedData);
    // apis
    //   .saveAnswers({
    //     ...saveQuestionDetails,
    //     questionAnswers: [
    //       {
    //         ...saveQuestionDetails.questionAnswers,
    //         answer: JSON.stringify(parsedData)
    //       }
    //     ]
    //   })
    //   .then((res) => {
    //     toast.success("Data saved successsfully");
    //     setUboDetails({
    //       ...parsedData
    //     });
    //   })
    //   .catch((error) => {
    //     toast.error(formatGraphQLError(error.message));
    //   });
  }
  function showUboList() {
    switch (uboDetails?.uboInfoType?.value) {
      case KNOWN:
        return uboDetails.isFinalized ? (
          <>
            <div className="company-ubo-heading">Known UBOs</div>
            <ShowKnownUboList
              uboData={uboDetails?.knownUbosDetails || { knownUBOs: [] }}
              fileParams={{
                ...fileUploadParams,
                sectionId: saveQuestionDetails.sectionId
              }}
            />
          </>
        ) : (
          <div style={{ color: "red" }}>
            *
            {t(
              `Please finalise the ubo details by clicking on "Launch UBO engine"`
            )}
          </div>
        );
      case DERIVED:
        return uboDetails.isFinalized ? (
          <>
            <div className="company-ubo-heading">Derived UBOs</div>
            <ShowDerivedUboList
              uboData={uboDetails?.ubosFromOrgStructure || []}
              fileParams={{
                ...fileUploadParams,
                sectionId: saveQuestionDetails.sectionId
              }}
            />
            <div className="ubo-chart">
              <div className="ubo-chart-heading-row">
                <div className="ubo-chart-heading">
                  {t("Company structure diagram")}
                </div>
              </div>
              <div className="ubo-chart-in">
                {uboDetails?.orgStructure && (
                  <div style={{ height: "500px" }}>
                    <OrganizationChart
                      hierarchy={convertTree(uboDetails?.orgStructure, true)}
                      scrollToZoomDisabled
                    />
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <div style={{ color: "red" }}>
            *
            {t(
              `Please finalise the ubo details by clicking on "Launch UBO engine"`
            )}
          </div>
        );
      case FICTITIOUS:
        return uboDetails.isFinalized ? (
          <>
            <div className="company-ubo-heading">Fictitious UBOs</div>
            <ShowFictitiousUboList
              uboData={uboDetails?.fictitiousUbos || []}
              fileParams={{
                ...fileUploadParams,
                sectionId: saveQuestionDetails.sectionId
              }}
            />
            <div className="ubo-chart">
              <div className="ubo-chart-heading-row">
                <div className="ubo-chart-heading">
                  {t("Company structure diagram")}
                </div>
              </div>
              <div className="ubo-chart-in">
                {uboDetails?.orgStructure && (
                  <div style={{ height: "500px" }}>
                    <OrganizationChart
                      hierarchy={convertTree(uboDetails?.orgStructure, true)}
                      scrollToZoomDisabled
                    />
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <div style={{ color: "red" }}>
            *
            {t(
              `Please finalise the ubo details by clicking on "Launch UBO engine"`
            )}
          </div>
        );

      default:
        return t("No UBOs present");
    }
  }
  return (
    <>
      <div className="ubo-question">
        <div className="company-ubo-button">
          <button
            disabled={!allowEdit}
            className="btn btn-primary"
            onClick={() => setShowAddUBO(true)}
          >
            {t("Launch UBO engine")}
          </button>
        </div>
      </div>
      {showUboList()}

      <PersonOfInterest
        personsOfInterest={uboDetails?.personOfInterest || []}
        onUpdate={onUpdatePersonOfInterest}
        allowEdit={allowEdit}
        questionId={questionId}
      />

      {showAddUBO && (
        <CustomModal
          classNames="cdd-groups-management cdd-auto-feature editable ubo-tool-modal"
          showModal={showAddUBO}
          // onClose={() => {
          //   setShowAddUBO(false);
          // }}
          bodyStyle={{ padding: "0px", minHeight: "100%" }}
          contentClassName="fullScreenModal"
        >
          <AddUBOs
            voteThreshold={voteThreshold}
            shareThreshold={shareThreshold}
            saveQuestionDetails={saveQuestionDetails}
            answer={uboDetails}
            onSave={saveUboDetails}
            onClose={() => setShowAddUBO(false)}
            fileUploadParams={fileUploadParams}
            allowEdit={allowEdit}
          />
        </CustomModal>
      )}
    </>
  );
};

export default UBOEngine;
