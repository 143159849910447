import fetch from "isomorphic-fetch";
import { CDD_MODULE_NAME } from "../constants/app-constants";
import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
  from
} from "@apollo/client";
import _ from "lodash";
import { onError } from "@apollo/client/link/error";
import { getAppLoader as Load } from "../components/presentation/Loader/services";
import { setContext } from "@apollo/client/link/context";
import { getCookieValue } from "../lib/utils";
import UserService from "../services/userServices";
const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_ENDPOINT,
  credentials: "include",
  fetch: fetch
});

const addLoaderLink = new ApolloLink((operation, forward) => {
  Load.showLoader();
  return forward(operation).map((response: any) => {
    Load.hideLoader();
    return response;
  });
});

const networkErrorLink = onError(({ networkError, response }) => {
  Load.hideLoader();
  if (
    networkError &&
    "statusCode" in networkError &&
    networkError.statusCode === 401
  ) {
    UserService.doLogout()
  }
  if(response?.errors?.length) {
    response.errors = _.uniqBy(response.errors, "message")
  }
});
const authLink = setContext(async (_, { headers }) => {
  // Please implement Authorization header in updateToken in asyn way
  // if (UserService.isLoggedIn()) {
  //   UserService.updateToken(()=>{});
  // }
  try {
    await UserService.updateToken(180);
    return {
      headers: {
        ...headers,
        "Accept-Language": localStorage.getItem("i18nextLng") || "de",
        "X-CSRF-TOKEN": getCookieValue("csrf-token") || null,
        //This will not get updated token unless updateToken function is used
        Authorization: `Bearer ${UserService.getToken()}`
      }
    };
  } catch (e) {
    UserService.doLogout();
  }
});
const client = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false
  }),
  link: from([authLink, addLoaderLink, networkErrorLink, httpLink])
});

export default client;
