import React, { useState, useEffect, useRef } from "react";
import Icons from "../Icons";
import "./styles.scss";
import { UploadedFiles } from "../../container/QuestionComponent/types";
import {
  downloadFile,
  deleteFile,
  uploadFile
} from "../../../services/FileUploadDownloadService";
import { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import ConfirmationModal from "../Modals/ConfirmationModal";
import { getAppLoader as Load } from "../Loader/services";
import { useTranslation } from "react-i18next";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

type UploadedFilesResponse = {
  originalFileName: string;
  timeStampedFileName: string;
};
interface Props {
  uploadFiles?: boolean;
  downloadFiles?: boolean;
  files?: UploadedFiles[];
  onChange?: (files: [UploadedFilesResponse]) => void;
  fileParams?: any;
  uploaderId: string;
  uploadButtonName?: string;
}
const Files: React.FC<Props> = ({
  uploadFiles,
  downloadFiles,
  files,
  onChange,
  fileParams,
  uploaderId,
  uploadButtonName = "Upload multiple files"
}) => {
  const { t } = useTranslation();
  const [filesUploadingCount, setFilesUploadingCount] = useState<number>(0);
  const [filesList, setFilesList] = useState<UploadedFiles[]>(files || []);
  const filesRef = useRef(filesList);
  filesRef.current = filesList;
  const filesUploadingCountRef = useRef(filesUploadingCount);
  filesUploadingCountRef.current = filesUploadingCount;
  const [erroredFileList, setErroredFileList] = useState<any[]>([]);
  const [confirmationBoxData, setConfirmationBoxData] = useState({
    title: "",
    message: "",
    callBack: () => {}
  });
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const fileInputElem = "cddFileUploader-" + uploaderId;

  const openUploaderBox = (event: any) => {
    event.stopPropagation();
    const element = document.getElementById(fileInputElem) as HTMLElement;
    element && element.click();
  };

  const loadFiles = (event: any) => {
    event.stopPropagation();
    Load.disableLoader();
    let files = event.target.files;
    let tempArr = [];
    setFilesUploadingCount(files.length);
    for (let k = 0; k < files.length; k++) {
      let randomNum = Math.floor(
        Math.random() * 100 + new Date().getTime()
      ).toString();
      let tempId = randomNum;
      tempArr.push({
        tempFileId: tempId,
        loading: true,
        originalFileName: files[k].name
      });

      uploadFile(files[k], {
        ...fileParams,
        tempFileId: randomNum
      })
        .then((res: AxiosResponse) => {
          let data = res?.data.data;
          let updatedFilesList: any = addToFilesList(data);

          setFilesUploadingCount(filesUploadingCountRef.current - 1);
          if (filesUploadingCountRef.current === 0) {
            Load.enableLoader();
          }

          onChange &&
            onChange(
              updatedFilesList?.map((val: any) => {
                return {
                  originalFileName: val.originalFileName,
                  timeStampedFileName: val.timeStampedFileName,
                  tempFileId: val.tempFileId,
                  loading: val?.loading
                };
              })
            );
        })
        .catch((err: Error) => {
          Load.enableLoader();
          removeFromLocalFilesList(tempId);
          let updatedFilesList: any = removeFromLocalFilesList(tempId);
          onChange &&
            onChange(
              updatedFilesList?.map((val: any) => {
                return {
                  originalFileName: val.originalFileName,
                  timeStampedFileName: val.timeStampedFileName,
                  tempFileId: val.tempFileId,
                  loading: val?.loading
                };
              })
            );
          toast.error(err.message);
        });
    }
    if (tempArr.length) {
      setFilesList([...filesList, ...tempArr]);
    }
  };

  const addToFilesList = (data: any) => {
    const newFilesList = filesRef.current.map((file: any) => {
      if (file.tempFileId === data.tempFileId) {
        const updatedFile = {
          ...file,
          loading: false,
          //tempFileId: null,
          timeStampedFileName: data.timeStampedFileName
        };
        return updatedFile;
      }
      return file;
    });

    setFilesList(newFilesList);
    return newFilesList;
  };

  const removeFromFilesList = (timeStampedFileName: string) => {
    let tempArr = filesRef?.current?.length ? [...filesRef.current] : [];
    let removeIndex = filesRef.current
      .map((file: any) => {
        return file.timeStampedFileName;
      })
      .indexOf(timeStampedFileName);

    if (removeIndex > -1 && tempArr.length) {
      tempArr.splice(removeIndex, 1);
      setFilesList(tempArr);
    }
    return tempArr;
  };

  const removeFromLocalFilesList = (tempFileId: string) => {
    let tempArr: UploadedFiles[] = filesRef?.current?.length
      ? [...filesRef.current]
      : [];
    let removeIndex = filesRef.current
      .map((file: any) => {
        return file.tempFileId;
      })
      .indexOf(tempFileId);
    let erroredFile = tempArr[removeIndex];
    erroredFile &&
      setErroredFileList((prev: any) => {
        return [...prev, erroredFile];
      });
    if (removeIndex > -1 && tempArr.length) {
      tempArr.splice(removeIndex, 1);
      setFilesList(tempArr);
    }
    return tempArr;
  };

  const onDelete = (
    timeStampedFileName: string,
    id: string | number | null
  ) => {
    setConfirmationBoxData(
      Object.assign(
        {},
        {
          title: "Confirm Delete",
          message: "Are you sure want to delete this file?",
          callBack: () => {
            setShowConfirmationModal(false);
            deleteFile({
              accountLevelOneQuestionnaireId:
                fileParams.accountLevelOneQuestionnaireId,
              timeStampedFileName,
              fileType: fileParams.fileType,
              questionnaireFileUploadId: id
            })
              .then((res: AxiosResponse) => {
                let updatedFilesList: any =
                  removeFromFilesList(timeStampedFileName);
                onChange &&
                  onChange(
                    updatedFilesList?.map((val: any) => {
                      return {
                        originalFileName: val.originalFileName,
                        timeStampedFileName: val.timeStampedFileName
                      };
                    })
                  );
              })
              .catch((err: Error) => {
                toast.error(err.message);
              });
          }
        }
      )
    );
    setShowConfirmationModal(true);
  };

  const onDownload = (
    timeStampedFileName: string,
    originalFileName: string
  ) => {
    downloadFile({
      accountLevelOneQuestionnaireId: fileParams.accountLevelOneQuestionnaireId,
      timeStampedFileName,
      originalFileName,
      fileType: fileParams.fileType,
      ...(fileParams.fileType === "answerDoc" &&
        fileParams.sectionId && { sectionId: fileParams.sectionId })
    });
  };

  useEffect(() => {
    let tempFiles = [];
    if (files?.length) {
      tempFiles = files.map((file: any) => {
        return {
          tempFileId: null,
          ...file
        };
      });
    }
    setFilesList(tempFiles);
  }, [files]);

  function getFileUploaderType(): any {
    return (
      <>
        {uploadFiles && (
          <>
            <input
              id={fileInputElem}
              type="file"
              accept=".pdf, .xls, .xlsx, .doc, .docx, .png, .jpeg, .jpg, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              className="file-uploader__input"
              onChange={(event: any) => {
                loadFiles(event);
                event.target.value = null;
              }}
              multiple
            />
            <div className="button-container">
              <button
                className="btn btn-outline-primary mb-1"
                onClick={openUploaderBox}
              >
                {t(uploadButtonName)}
                <Icons className="icon--x-small ml-1" icon="upload" />
              </button>
              <div>
                (
                {t(
                  "Supports only .pdf, .docx, .png, .jpg, .jpeg and xlsx files"
                )}
                )
              </div>
            </div>
            {/* Modal for confirmation box start */}
            <ConfirmationModal
              modalTitle={confirmationBoxData.title}
              message={confirmationBoxData.message}
              callBack={confirmationBoxData.callBack}
              showConfirmationModal={showConfirmationModal}
              onModalClose={() => {
                setShowConfirmationModal(false);
              }}
            />
          </>
        )}
        <div className="file-upload-here">
          <div className="action-group">
            {downloadFiles && filesRef.current && !!filesRef.current.length
              ? filesRef.current.map((file, index) => {
                  return (
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip id={`tooltip-${index}`}>
                          <span>{file.originalFileName}</span>
                        </Tooltip>
                      }
                    >
                      <div className="action-group-in" key={index}>
                        {uploadFiles && file.loading && (
                          <div className="action action--view">
                            <Icons className="icon" icon="saving" />
                          </div>
                        )}
                        <div className="action action--view mr-1 align-items-center">
                          <span className="fixed-filename">
                            {file.originalFileName}
                          </span>
                        </div>

                        {file.timeStampedFileName && (
                          <>
                            {" "}
                            <div
                              className="action action--view"
                              onClick={() => {
                                if (file.loading) return false;
                                onDownload(
                                  file.timeStampedFileName as string,
                                  file.originalFileName as string
                                );
                              }}
                            >
                              <Icons className="icon" icon="download" />
                            </div>
                            {uploadFiles && (
                              <div
                                className="action action--delete"
                                onClick={() => {
                                  if (file.loading) return false;
                                  onDelete(
                                    file.timeStampedFileName as string,
                                    file.id || null
                                  );
                                }}
                              >
                                <Icons className="icon" icon="delete" />
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </OverlayTrigger>
                    // <div className="action-group" key={index}>
                    //   {uploadFiles && file.loading && (
                    //     <div className="action action--view">
                    //       <Icons className="icon" icon="saving" />
                    //     </div>
                    //   )}

                    //   <div
                    //     className="action action--view"
                    //     onClick={() => {
                    //       if (file.loading) return false;
                    //       onDownload(
                    //         file.timeStampedFileName as string,
                    //         file.originalFileName as string
                    //       );
                    //     }}
                    //   >
                    //     <Icons className="icon" icon="download" />
                    //   </div>

                    //   {uploadFiles && (
                    //     <div
                    //       className="action action--delete"
                    //       onClick={() => {
                    //         if (file.loading) return false;
                    //         onDelete(
                    //           file.timeStampedFileName as string,
                    //           file.id || null
                    //         );
                    //       }}
                    //     >
                    //       <Icons className="icon" icon="delete" />
                    //     </div>
                    //   )}
                    //   <div className="action action--view mr-1 align-items-center">
                    //     <span className="fixed-filename">
                    //       {file.originalFileName}
                    //     </span>
                    //   </div>
                    // </div>
                  );
                })
              : t("No files uploaded")}
            {!!erroredFileList.length &&
              erroredFileList.map((file, index) => {
                return (
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id={`tooltip-${index}`}>
                        <span>
                          Error while uploading {file?.originalFileName}, Please
                          try again.
                        </span>
                      </Tooltip>
                    }
                  >
                    <div className="action-group-in" key={index}>
                      <div className="action action--view mr-1 align-items-center">
                        <span className="fixed-filename">
                          {file?.originalFileName}
                        </span>
                      </div>
                      <div className="action action--view">
                        <Icons className="icon" icon="error" />
                      </div>
                    </div>
                  </OverlayTrigger>
                );
              })}
          </div>
        </div>
      </>
    );
  }
  return getFileUploaderType();
};

export default Files;
