import React, {
  FunctionComponent,
  useState,
  useRef,
  KeyboardEvent,
  useEffect,
  useMemo
} from "react";
import styles from "./css/styles";
import * as d3 from "d3";
import { OrgVisualizationNode } from "./types";
//import PolkaDots from "./PolkaDots";
import DropShadowFilter from "./DropShadowFilter";
import Link from "./Link";
import Node from "./Node";
import { generateId, useOrganizationLayout, useZoom } from "./service";
import ZoomControls from "./ZoomControls";
import { useComponentSize } from "./useComponentSize";

interface Props {
  hierarchy: OrgVisualizationNode;
  addCallback?: (parent: OrgVisualizationNode) => void;
  editCallback?: (
    node: OrgVisualizationNode,
    parent?: OrgVisualizationNode
  ) => void;
  selectedId?: string;
  scrollToZoomDisabled?: boolean;
}

const dummySvg = document.createElement("svg") as unknown as SVGSVGElement;

const OrganizationChart: FunctionComponent<Props> = ({
  hierarchy,
  addCallback,
  editCallback,
  selectedId,
  scrollToZoomDisabled
}) => {
  const readonly = !addCallback;
  const [dropdownNode, setDropdownNode] = useState<
    d3.HierarchyNode<OrgVisualizationNode> | undefined
  >();
  const svgRef = useRef<SVGSVGElement>(null);
  const widthTesterRef = useRef<HTMLDivElement>(null);
  const [scaledToFit, setScaledToFit] = useState<boolean>(true);

  const { ref: divRef, width, height } = useComponentSize();

  const { root, extentX, extentY, centerTransform } = useOrganizationLayout({
    hierarchy,
    width,
    height,
    readonly,
    widthTesterRef
  });

  const {
    transform,
    handleZoomIn,
    handleZoomOut,
    handleFitZoom,
    handleResetZoom,
    fitScale
  } = useZoom({
    svg: svgRef.current || dummySvg,
    width,
    height,
    extentX,
    extentY,
    scrollToZoomDisabled
  });

  const filterId = useMemo(() => generateId(), []);
  //const patternId = useMemo(() => generateId(), []);

  useEffect(() => {
    if (scaledToFit && fitScale !== 0 && fitScale !== 1) {
      setTimeout(handleFitZoom, 0);
      setScaledToFit(false);
    }
  }, [fitScale, scaledToFit, handleFitZoom]);

  const handleNodeAction = (
    node: d3.HierarchyPointNode<OrgVisualizationNode>,
    action: "show dropdown" | "hide dropdown" | "add" | "edit"
  ) => {
    if (action === "add" && addCallback && node.parent?.data) {
      addCallback(node.parent?.data);
    } else if (action === "edit" && editCallback) {
      editCallback(node.data, node.data?.parent);
    } else if (action === "show dropdown") {
      setDropdownNode(node);
    } else if (action === "hide dropdown") {
      setDropdownNode(undefined);
    }
  };

  const handleKeyDown = (event: KeyboardEvent<SVGSVGElement>) => {
    if (event.key === ")" && event.shiftKey) {
      handleResetZoom();
    } else if (event.key === "F" && event.shiftKey) {
      handleFitZoom();
    } else if (event.key === "=" || event.key === "+") {
      handleZoomIn();
    } else if (event.key === "-") {
      handleZoomOut();
    }
  };

  return (
    <div ref={divRef} className={styles.organizationChart}>
      <svg
        width={width}
        height={height}
        ref={svgRef}
        viewBox={[0, 0, width, height].join(",")}
        onKeyDown={handleKeyDown}
        tabIndex={0}
      >
        <defs>
          <DropShadowFilter id={filterId} />
          {/* <PolkaDots id={patternId} /> */}
        </defs>

        <rect x="0" y="0" width="100%" height="100%" fill={"#ffffff"} />

        <g transform={transform}>
          <g transform={centerTransform}>
            <g className={styles.links}>
              {root.links().map((link) => (
                <Link
                  key={[link.source.data.id, link.target.data.id].join("->")}
                  link={link}
                />
              ))}
            </g>
            <g className={styles.nodes}>
              {root
                .descendants()
                .filter((node) => dropdownNode?.data.id !== node.data.id)
                .map((node) => (
                  <Node
                    key={node.data.id}
                    node={node}
                    infoShown={dropdownNode?.data.id === node.data.id}
                    selected={selectedId === node.data.id}
                    onAction={handleNodeAction}
                    filterId={filterId}
                  />
                ))}
              {root
                .descendants()
                .filter((node) => dropdownNode?.data.id === node.data.id)
                .map((node) => (
                  <Node
                    key={node.data.id}
                    node={node}
                    infoShown={dropdownNode?.data.id === node.data.id}
                    selected={selectedId === node.data.id}
                    onAction={handleNodeAction}
                    filterId={filterId}
                  />
                ))}
            </g>
          </g>
        </g>
      </svg>
      <ZoomControls onZoomIn={handleZoomIn} onZoomOut={handleZoomOut} />
      <div id="org-chart-width-tester" ref={widthTesterRef} />
    </div>
  );
};

export default OrganizationChart;
