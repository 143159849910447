import React, { useState } from "react";
import { PersonOfInterestType } from "../../types";
import Icons from "../../../presentation/Icons";
import ConfirmationModal from "../../../presentation/Modals/ConfirmationModal";
import { useTranslation } from "react-i18next";
import { getStringValues } from "../../../../lib/utils";
interface Props {
  personOfInterestList: PersonOfInterestType[];
  onEdit: (data: PersonOfInterestType) => void;
  onDelete: (firstName: string, lastName: string) => void;
  allowEdit: boolean;
}

const ShowPersonOfInterest: React.FC<Props> = ({
  personOfInterestList,
  onEdit,
  onDelete,
  allowEdit
}) => {
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const { t } = useTranslation();
  const [confirmationBoxData, setConfirmationBoxData] = useState({
    title: "",
    message: "",
    callBack: () => {}
  });
  return (
    <>
      <div className="ubo-add-person-table">
        <div className="ubo-table-wrp">
          <div className="ubo-table-list">
            <div className="responses-table-wrap relative">
              <div className="x-scroll">
                <div className="resp-table">
                  <div className="resp-table-body">
                    {personOfInterestList.map((personOfInterest, index) => {
                      return (
                        <div className="resp-table-row" key={index}>
                          <div className="table-body-cell col-200-min">
                            <div className="colum-label">{t("Name")}</div>
                            <div className="colum-deas">
                              {personOfInterest.firstName}{" "}
                              {personOfInterest.lastName}
                            </div>
                          </div>

                          <div className="table-body-cell col-200-min">
                            <div className="colum-label">
                              {t("Justification")}
                            </div>
                            <div className="colum-deas">
                              {personOfInterest.justification}
                            </div>
                          </div>
                          <div className="table-body-cell col-200-min">
                            <div className="colum-label">
                              {t("Place of birth")}
                            </div>
                            <div className="colum-deas">
                              {personOfInterest.placeOfBirth}
                            </div>
                          </div>

                          <div className="table-body-cell col-200-min">
                            <div className="colum-label">
                              {t("Date of birth")}
                            </div>
                            <div className="colum-deas">
                              {personOfInterest.dob}
                            </div>
                          </div>
                          <div className="table-body-cell col-200-min">
                            <div className="colum-label">
                              {t("Nationalities")}
                            </div>
                            <div className="colum-deas">
                              {getStringValues(personOfInterest.nationality)}
                            </div>
                          </div>
                          <div className="table-body-cell col-200-min">
                            <div className="colum-label">{t("Country")}</div>
                            <div className="colum-deas">
                              {personOfInterest.country?.name || ""}
                            </div>
                          </div>
                          <div className="table-body-cell col-200-min">
                            <div className="colum-label">{t("Tax-ID")}</div>
                            <div className="colum-deas">
                              {personOfInterest.taxIdNumber}
                            </div>
                          </div>
                          <div className="table-body-cell col-200-min">
                            <div className="colum-label">{t("Street")}</div>
                            <div className="colum-deas">
                              {personOfInterest.street}
                            </div>
                          </div>
                          <div className="table-body-cell col-150-min">
                            <div className="colum-label">{t("House no.")}</div>
                            <div className="colum-deas">
                              {personOfInterest.houseNo}
                            </div>
                          </div>
                          <div className="table-body-cell col-150-min">
                            <div className="colum-label">{t("Zip")}</div>
                            <div className="colum-deas">
                              {personOfInterest.zip}
                            </div>
                          </div>
                          <div className="table-body-cell col-100-min">
                            <div className="colum-label">{t("City")}</div>
                            <div className="colum-deas">
                              {personOfInterest.city}
                            </div>
                          </div>
                          {allowEdit && (
                            <>
                              <div className="table-body-cell full-colum last-coulmn-sticky col-100-min coulmn_in_height">
                                <div className="table-txt-right">
                                  <div className="colum-deas">
                                    <div className="action-group">
                                      <div
                                        className="action action--download"
                                        onClick={() =>
                                          onEdit({
                                            ...personOfInterest,
                                            editIndex: index
                                          })
                                        }
                                      >
                                        <Icons className="icon" icon="edit" />
                                      </div>
                                      <div
                                        className="action action--download"
                                        onClick={() => {
                                          setShowConfirmationModal(true);
                                          setConfirmationBoxData(
                                            Object.assign(
                                              {},
                                              {
                                                title: "Confirm Delete",
                                                message:
                                                  "Are you sure want to delete the entity ?",
                                                callBack: () => {
                                                  setShowConfirmationModal(
                                                    false
                                                  );
                                                  onDelete(
                                                    personOfInterest.firstName ||
                                                      "",
                                                    personOfInterest.lastName ||
                                                      ""
                                                  );
                                                }
                                              }
                                            )
                                          );
                                        }}
                                      >
                                        <Icons className="icon" icon="delete" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="table-body-cell col-100-min mobile-hidden"></div>
                            </>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        modalTitle={confirmationBoxData.title}
        message={confirmationBoxData.message}
        callBack={confirmationBoxData.callBack}
        showConfirmationModal={showConfirmationModal}
        onModalClose={() => {
          setShowConfirmationModal(false);
        }}
      />
    </>
  );
};

export default ShowPersonOfInterest;
