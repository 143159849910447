import React from "react";
import { Bar } from "react-chartjs-2";
interface Props {
  data: any;
  options: any;
}

const BarChart: React.FC<Props> = ({ data, options }) => {
  return <Bar type="bar" data={data} options={options} height={400} />;
};

export default BarChart;
