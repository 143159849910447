import React, { useState, useEffect } from "react";
import ShowPersonOfInterest from "./ShowPersonOfInterest";
import AddPersonOfInterest from "./AddPersonOfInterest";
import { PersonOfInterestType } from "../types";
interface Props {
  personsOfInterest: PersonOfInterestType[];
  onUpdate: (data: PersonOfInterestType[]) => void;
  allowEdit: boolean;
  questionId: string | number;
}

const PersonOfInterest: React.FC<Props> = ({
  onUpdate,
  personsOfInterest,
  allowEdit,
  questionId
}) => {
  const [editPersonOfInterest, setEditPersonOfInterest] =
    useState<PersonOfInterestType>();
  const [personOfInterestList, setPersonOfInterestList] = useState<
    PersonOfInterestType[]
  >([]);
  useEffect(() => {
    setPersonOfInterestList(personsOfInterest);
  }, [personsOfInterest]);
  function savePersonOfInterest(personOfInterest: PersonOfInterestType) {
    let existingData = [...(personOfInterestList || [])];
    if (personOfInterest.editIndex === 0 || personOfInterest?.editIndex) {
      let index = personOfInterest.editIndex;
      delete personOfInterest.editIndex;
      existingData[index] = personOfInterest;
    } else {
      existingData.push(personOfInterest);
    }
    setPersonOfInterestList([...existingData]);
    onUpdate([...existingData]);
  }
  function deletePersonOfInterest(firstName: string, lastName: string) {
    let foundIndex = personOfInterestList.findIndex(
      (val) => val.firstName === firstName && val.lastName === lastName
    );
    if (foundIndex !== -1) {
      let dummy = [...personOfInterestList];
      dummy.splice(foundIndex, 1);
      setPersonOfInterestList([...dummy]);
      onUpdate([...dummy]);
    }
    setEditPersonOfInterest(undefined);
  }
  return (
    <>
      {allowEdit && (
        <AddPersonOfInterest
          onSave={savePersonOfInterest}
          selectedPersonOfInterest={editPersonOfInterest}
          allowEdit={allowEdit}
          allPersons={personOfInterestList}
          questionId={questionId}
        />
      )}

      {personOfInterestList?.length > 0 && (
        <ShowPersonOfInterest
          personOfInterestList={personOfInterestList}
          onEdit={(data) => setEditPersonOfInterest({ ...data })}
          onDelete={(firstName, lastName) =>
            deletePersonOfInterest(firstName, lastName)
          }
          allowEdit={allowEdit}
        />
      )}
    </>
  );
};

export default PersonOfInterest;
