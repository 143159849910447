import React, { useEffect } from "react";
import QuestionComponent from "../QuestionComponent";
import SubSectionComponent from "../SubSectionComponent";
import { Section } from "./types";
import { Question } from "../QuestionComponent/types";
import { deductionDetails } from "../../presentation/Deductions/types";
import { ValidationError } from "../../container/QuestionComponent/types";
import { updateQuestionInSection, updateSubSections } from "./service";
import { useTranslation } from "react-i18next";
import { QueryPrameters } from "../../../types/applicationData";
type Props = {
  queryParams?: QueryPrameters | null | undefined;
  itemId: string | number;
  section: Section;
  isParent?: boolean;
  showComments?: (commentData: any | null) => void;
  showPublicComment?: boolean;
  showPrivateComment?: boolean;
  editPublicComment?: boolean;
  editPrivateComment?: boolean;
  hiddenSections?: any;
  showAnswers?: boolean;
  sectionViewType: string;
  allowAnswerSubmit?: boolean;
  deductionDetails?: deductionDetails;
  validationErrors?: ValidationError[];
  showErrors?: boolean;
  submittedQuestionnaireId?: string | number;
  accountQuestionnaireId?: string | number;
  currentUserId: string | number;
  updateParent?: (section: Section) => void;
  onFieldValChange?: ({
    ques,
    answer,
    additionalInfo,
    updatedSection
  }: {
    ques: Question;
    answer: any;
    additionalInfo?: any;
    updatedSection: Section;
  }) => void;
  fileParams?: any;
  dataSetSectionList?: Section[];
};

const SectionComponent: React.FC<Props> = ({
  queryParams,
  itemId,
  section,
  isParent,
  showComments,
  showPublicComment,
  showPrivateComment,
  editPublicComment,
  editPrivateComment,
  hiddenSections,
  showAnswers,
  sectionViewType,
  allowAnswerSubmit = false,
  deductionDetails,
  validationErrors,
  showErrors,
  onFieldValChange,
  submittedQuestionnaireId,
  accountQuestionnaireId,
  currentUserId,
  updateParent,
  dataSetSectionList,
  fileParams
}) => {
  const { t } = useTranslation();
  function getSecOrQues(
    position: number,
    index: number,
    sectionDetails: Section = section
  ) {
    if (sectionDetails.questions && sectionDetails.questions.length) {
      const question = sectionDetails.questions.find((question: Question) => {
        return question.position === position;
      });
      if (question) {
        return (
          <React.Fragment key={index}>
            <QuestionComponent
              queryParams={queryParams}
              question={question}
              itemIndex={itemId + "." + index}
              showComments={showComments}
              showAnswers={showAnswers}
              showPublicComment={showPublicComment}
              showPrivateComment={showPrivateComment}
              editPublicComment={editPublicComment}
              editPrivateComment={editPrivateComment}
              sectionViewType={sectionViewType}
              allowAnswerSubmit={allowAnswerSubmit}
              isInParentSection={isParent}
              hiddenSections={hiddenSections}
              deductionDetails={deductionDetails}
              validationErrors={validationErrors}
              showErrors={showErrors}
              submittedQuestionnaireId={submittedQuestionnaireId}
              accountQuestionnaireId={accountQuestionnaireId}
              currentUserId={currentUserId}
              updateParent={(receivedQuestion) => {
                updateParent &&
                  updateParent(
                    updateQuestionInSection({
                      section,
                      question: receivedQuestion
                    })
                  );
              }}
              onFieldValChange={({
                ques,
                answer,
                additionalInfo,
                updatedQuestion
              }) => {
                onFieldValChange &&
                  onFieldValChange({
                    ques,
                    answer,
                    additionalInfo,
                    updatedSection: updateQuestionInSection({
                      section,
                      question: updatedQuestion
                    })
                  });
              }}
              fileParams={{
                ...fileParams,
                ...{ questionId: question.id }
              }}
              dataSetSectionList={dataSetSectionList}
            />
          </React.Fragment>
        );
      }
    }
    if (sectionDetails.subSections && sectionDetails.subSections.length) {
      const subSec = sectionDetails.subSections.find((subSection: any) => {
        return subSection.position === position;
      });
      if (subSec) {
        return (
          <div className="form-group" key={index}>
            <SubSectionComponent
              queryParams={queryParams}
              subSec={subSec}
              itemIndex={itemId + "." + index}
              showComments={showComments}
              showPublicComment={showPublicComment}
              showPrivateComment={showPrivateComment}
              editPublicComment={editPublicComment}
              editPrivateComment={editPrivateComment}
              hiddenSections={hiddenSections}
              deductionDetails={deductionDetails}
              showAnswers={showAnswers}
              sectionViewType={sectionViewType}
              allowAnswerSubmit={allowAnswerSubmit}
              currentUserId={currentUserId}
              submittedQuestionnaireId={submittedQuestionnaireId}
              accountQuestionnaireId={accountQuestionnaireId}
              validationErrors={validationErrors}
              showErrors={showErrors}
              updateParent={(updatedSection) => {
                updateParent &&
                  updateParent(
                    updateSubSections({
                      subSections: sectionDetails.subSections,
                      subSection: updatedSection,
                      parentSection: section
                    })
                  );
              }}
              onFieldValChange={({
                ques,
                answer,
                additionalInfo,
                updatedSection
              }) => {
                onFieldValChange &&
                  onFieldValChange({
                    ques,
                    answer,
                    additionalInfo,
                    updatedSection: updateSubSections({
                      subSections: sectionDetails.subSections,
                      subSection: updatedSection,
                      parentSection: section
                    })
                  });
              }}
              fileParams={{
                ...fileParams
              }}
              dataSetSectionList={dataSetSectionList}
            />
          </div>
        );
      }
    }

    return null;
  }
  function getCombinedQuesSecPositions() {
    let questionPostions: any = [];
    let sectionPositions: any = [];
    if (section.questions) {
      questionPostions = section.questions.map((val) => val.position);
    }
    if (section.subSections) {
      sectionPositions = section.subSections.map((val) => val.position);
    }
    return questionPostions.concat(sectionPositions).sort((a: any, b: any) => {
      return a - b;
    });
  }
  return (
    <React.Fragment>
      {allowAnswerSubmit &&
        isParent &&
        showErrors &&
        validationErrors &&
        validationErrors.length > 0 && (
          <div className="locked-Error-Msg validation-error-msg">
            {t(`Error in {{value}} fields, Please correct and continue.`,{value:validationErrors.length})}
          </div>
        )}
      {(section.questions || section.subSections) &&
        getCombinedQuesSecPositions().map(
          (combinedPosition: number, index: number) => {
            return getSecOrQues(combinedPosition, index + 1);
          }
        )}
    </React.Fragment>
  );
};
export default SectionComponent;
