import React from "react";

export default function RemoveButton(props: any) {
  let row = props.cell._cell.row;
  return (
    <span className="has-icon" onClick={() => props.onBtnClick(row)}>
      <span>
        <svg
          width="20"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="15.5" cy="15.5" r="15.5" fill="#1C1B1B" />
          <line
            x1="7.75"
            y1="15.9229"
            x2="23.25"
            y2="15.9229"
            stroke="white"
            strokeWidth="3"
          />
        </svg>
      </span>
    </span>
  );
}
