import gql from "graphql-tag";

export const GET_ASSIGNMENTS_LIST_QUERY = gql`
  query QuestionnaireAssignmentsList(
    $counterpartyId: ID!
    $pageNumber: Int!
    $pageSize: Int!
    $search: String
    $sort: [SortingAttributes!]
    $filters: [QuestionnaireFilterAttributes!]
  ) {
    counterPartyQuestionnaireList(
      counterpartyId: $counterpartyId
      pageNumber: $pageNumber
      pageSize: $pageSize
      search: $search
      sort: $sort
      filters: $filters
    ) {
      id
      sectionId
      questionnaireTitle
      assetManager
      questionnaireId
      submittedQuestionnaireId
      questionnairePdfError
      status {
        id
        label
        name
      }
      completePercentage
      submissionDate
      sectionsCount
      lastUpdatedDate
      sectionSubmission
      reopenRequest {
        requestedAt
        requestedBy
      }
      notificationSubscribed {
        allPreferences {
          publicPrivateComments
          submissionStatusChange
        }
      }
    }
    counterPartyQuestionnaireCount(counterpartyId: $counterpartyId)
    counterpartyQuestionnaireFilterList(
      filters: $filters
      counterpartyId: $counterpartyId
    ) {
      assetManagerList
      questionnaireList
      statusList {
        label
        name
      }
      validUntilList
    }
    counterpartyQuestionnaireSearchCount(
      filters: $filters
      counterpartyId: $counterpartyId
      search: $search
    )
    counterpartyLatestSubmissionDate(counterpartyId: $counterpartyId)
  }
`;

export const GET_QUESTIONNAIRE_SECTIONS_LIST_QUERY = gql`
  query CounterpartyAccountQuestionnaireSections(
    $counterpartyId: ID!
    $accountQuestionnaireId: ID!
  ) {
    counterpartyAccountQuestionnaireSections(
      accountQuestionnaireId: $accountQuestionnaireId
      counterpartyId: $counterpartyId
    ) {
      id
      title
      status {
        id
        label
        name
      }
      submittedQuestionnaireStatus {
        id
        name
        label
      }
      lastUpdatedDate
      submissionDate
      completePercentage
      accountQuestionnaireId
      sectionSubmission
      reopenRequest {
        requestedAt
        requestedBy
      }
      permissions {
        name
      }
      submittedQuestionnaireId
      groups {
        id
        name
      }
    }
  }
`;

export const GET_SECTION_ASSIGNMENT_QUERY = gql`
  query GetSectionAssignment($accountQuestionnaireId: ID!, $accountId: ID!) {
    getSectionAssignment(accountQuestionnaireId: $accountQuestionnaireId) {
      id
      questionnaireTitle
      accountQuestionnaireId
      sections {
        id
        name
        groups {
          id
          name
          roles {
            id
            label
          }
        }
      }
    }
    getAccountGroups(accountId: $accountId) {
      id
      name
    }
    getUserRoles(roleType: "counterparty_group_access") {
      id
      label
    }
  }
`;

export const SAVE_SECTION_GROUP_ASSIGNMENT = gql`
  mutation SaveSectionGroupAssignment(
    $input: SaveSectionGroupAssignmentInput!
  ) {
    saveSectionGroupAssignment(input: $input) {
      message
    }
  }
`;
export const SAVE_CP_NOTIFICATION_PREFERENCES = gql`
  mutation saveCpNotificationPreferences(
    $input: SaveCpNotificationPreferencesInput!
  ) {
    saveCpNotificationPreferences(input: $input) {
      message
    }
  }
`;
