import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useTranslation } from "react-i18next";

type Props = {
  content: any;
  trigger?: any;
  placement?: any;
  header?: string;
  contentType?: string;
};

const CustomPopover: React.FC<Props> = ({
  content,
  trigger = "click",
  placement = "bottom",
  children,
  header,
  contentType
}) => {
  const { t } = useTranslation();
  const popoverBasic = (
    <Popover id="popover-basic">
      {header && <Popover.Title as="h3">{t(header)}</Popover.Title>}
      <Popover.Content>{contentType === "html" ? content : t(content)}</Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger={trigger}
      placement={placement}
      overlay={popoverBasic}
    >
      <span>{children}</span>
    </OverlayTrigger>
  );
};

export default CustomPopover;
