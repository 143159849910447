import React, { useState, useEffect } from "react";
import BootstrapPaginator from "./pagination.bootstrap.theme";

type Props = {
  totalRecordsCount: number;
  pageSize: number;
  defaultStartPage: number;
  boundaryPagesRange?: number;
  siblingPagesRange?: number;
  hideEllipsis?: boolean;
  handleChange: (page: number) => void;
};

/**
 * The Pagination component maintains the state of the pagination control. Also, it instantiates the bootstrap themed react ultimate pagination control (BootstrapPaginator)
 * and intialize it with required props like totalPages and listens to pagination link buttons and accordingly updates the currentPage state.
 * BootstrapPaginator has following interface:
 * 1. currentPage: number - current page number
 * 2. totalPages: number - total number of pages
 * 3. boundaryPagesRange: number, optional, default: 1 - number of always visible pages at the beginning and end
 * 4. siblingPagesRange: number, optional, default: 1 - number of always visible pages before and after the current one
 * 5. hideEllipsis: bool, optional, default: false - boolean flag to hide ellipsis
 * 6. onChange: function - callback that will be called with new page when it should be changed by user interaction (optional)
 */

const Pagination: React.FC<Props> = ({
  totalRecordsCount,
  pageSize,
  defaultStartPage,
  boundaryPagesRange = 1,
  siblingPagesRange = 1,
  hideEllipsis = false,
  handleChange
}) => {
  const [currentPage, setCurrentPage] = useState<number>(defaultStartPage);

  let pageCount = Math.ceil(totalRecordsCount / pageSize);

  const showPaginationControl = pageCount > 1 ? true : false; // Show pagination component only if the pageCount is greater than 1

  // If user navigates to a different page and then changes the page size/page sort or page search
  // In that case, we have to reset the currentPage state to 1 as currentPage cannot be greater than pageCount.

  useEffect(() => {
    setCurrentPage(defaultStartPage);
  }, [totalRecordsCount, pageSize, defaultStartPage]);

  return showPaginationControl && pageCount >= currentPage ? (
    <BootstrapPaginator
      currentPage={currentPage}
      totalPages={pageCount}
      boundaryPagesRange={boundaryPagesRange}
      siblingPagesRange={siblingPagesRange}
      hideEllipsis={hideEllipsis}
      onChange={(page) => {
        setCurrentPage(page);
        handleChange(page);
      }}
    />
  ) : null;
};

export default Pagination;
