import React, { useState, useEffect } from "react";
import { getAppOverlay } from "./services";

const Overlay: React.FC = () => {
  let overlayCount = 0;
  const [overlay, setOverlay] = useState<boolean>(false);
  const [disableOverlay, setDisableOverlay] = useState<boolean>(false);

  const show = function () {
    overlayCount = overlayCount + 1;
    !overlay && setOverlay(true);
  };

  const hide = function () {
    overlayCount = overlayCount - 1;
    if (overlayCount <= 0) setOverlay(false);
  };

  const toggle = function () {
    overlay ? setOverlay(false) : setOverlay(true);
  };

  const disable = function () {
    setOverlay(false);
    setDisableOverlay(true);
  };

  const enable = function () {
    setDisableOverlay(false);
  };

  useEffect(() => {
    !getAppOverlay.isOverlayDetailsPresent() &&
      getAppOverlay.registerOverlay({
        showOverlay: show,
        hideOverlay: hide,
        toggleOverlay: toggle,
        enableOverlay: enable,
        disableOverlay: disable
      });
  }, []);

  return <>{!disableOverlay && overlay && <div className="overlay"></div>}</>;
};

export default Overlay;
