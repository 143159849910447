import React, {
  FunctionComponent,
  useMemo,
  MouseEvent as ReactMouseEvent,
  useEffect,
  useRef
} from "react";
import { NodeProps } from "../types";
import styles from "../css/styles";
import RoundedRect from "../RoundedRect";
import DropdownMenu from "../DropdownMenu";
import DropdownTrigger from "../DropdownTrigger";
import { getFallbackFrameStyle, isAvatarUsed, isDescendant } from "./service";
import {
  addNodeDimension,
  getRectWidth,
  getTextWidth,
  nodeHeight,
  useHover
} from "../service";
import Avatar from "./Avatar";
import AddIcon from "./AddIcon";

const Node: FunctionComponent<NodeProps> = ({
  node,
  infoShown,
  selected,
  filterId,
  onAction
}) => {
  const ref = useRef<SVGGElement>(null);
  const measured = getRectWidth(node.data);
  const borderRadius = 5;
  const hover = useHover(ref);

  const corners = useMemo(
    () =>
      infoShown
        ? [borderRadius, borderRadius, 0, 0]
        : [borderRadius, borderRadius, borderRadius, borderRadius],
    [infoShown]
  );

  const width = infoShown
    ? Math.max(measured, getTextWidth("Overall voting rights: 100% in bold"))
    : measured;
  const height = node.data.type === "add" ? addNodeDimension : nodeHeight;

  useEffect(() => {
    const eventListener = (event: MouseEvent) => {
      if (!isDescendant(event.target as HTMLElement, ref.current)) {
        onAction(node, "hide dropdown");
      }
    };

    if (infoShown) {
      window.addEventListener("click", eventListener);
    }

    return () => {
      eventListener && window.removeEventListener("click", eventListener);
    };
  }, [infoShown, node, onAction]);

  const avatarUsed = isAvatarUsed(node.data);
  const classNames = [
    styles.node,
    selected && styles.selected,
    infoShown && styles.dropdown
  ].filter(Boolean);

  const handleNodeClick = () => {
    if (node.data.type === "add") {
      onAction(node, "add");
    } else {
      onAction(node, "edit");
    }
  };

  const handleToggleClick = (event: ReactMouseEvent<SVGElement>) => {
    event.stopPropagation();
    onAction(node, infoShown ? "hide dropdown" : "show dropdown");
  };

  return (
    <g
      ref={ref}
      key={node.data.id}
      transform={`translate(${node.x - width / 2},${
        node.y - nodeHeight / 2 - (height - nodeHeight)
      })`}
      className={classNames.join(" ")}
      data-entity-type={node.data.type}
      onClick={handleNodeClick}
    >
      <RoundedRect
        className={styles.frame}
        style={getFallbackFrameStyle(node, selected, hover)}
        corners={corners}
        width={width}
        height={height}
        filter={`url(#${filterId})`}
      />
      {node.data.type === "add" && <AddIcon />}
      <g transform={`translate(10, ${height / 2})`}>
        <g transform="translate(0, -12)">
          {avatarUsed && <Avatar node={node.data} />}
        </g>
        <text className={styles.name} x={avatarUsed ? 36 : 6}>
          {node.data.name}
        </text>
        {avatarUsed && (
          <>
            <g transform={`translate(${width - 32}, -4)`}>
              <DropdownTrigger
                onClick={handleToggleClick}
                expanded={infoShown}
              />
            </g>
          </>
        )}
      </g>
      {infoShown && (
        <g transform={`translate(0, ${height})`}>
          <DropdownMenu
            nodeSelected={selected}
            node={node.data}
            width={width}
            filterId={filterId}
          />
        </g>
      )}
    </g>
  );
};

export default Node;
