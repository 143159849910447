import axios from "axios";
import { getAppLoader as Load } from "../components/presentation/Loader/services";
import { NO_LOADER_APIS } from "../constants/app-constants";
import { getCookieValue } from "../lib/utils";
import UserService from "./userServices";
export const initializeAxiosInterceptors = () => {
  // Add a request interceptor
  axios.interceptors.request.use(
    (config:any) => {
      if (UserService.isLoggedIn()) {
        const cb = () => {
          if(config.method !== "get") {
            config.headers["X-CSRF-TOKEN"] = getCookieValue('csrf-token') || null
          }
          if (config?.url && NO_LOADER_APIS.indexOf(config.url) === -1) {
            Load.showLoader();
          }
          config.headers.Authorization = `Bearer ${UserService.getToken()}`;
          return Promise.resolve(config);
        };
        return UserService.updateToken(300).then(cb).catch(UserService.doLogout);
      }
      return config;
    },
    (error) => {
      Load.hideLoader();
      Promise.reject(error);
    }
  );

  axios.defaults.headers.common = {
    "Accept-Language": localStorage.getItem("i18nextLng") || "de"
  };
  //Add a response interceptor
  axios.interceptors.response.use(
    (response) => {
      Load.hideLoader();
      return response;
    },
    function (error) {
      Load.hideLoader();
      if (error.response && error.response.status === 401) {
        UserService.doLogout();
      }
      return Promise.reject(error);
    }
  );
};
