import React, { useState, useEffect } from "react";
import loaderImg from "../../../assets/images/loaderTransperant.svg";
import bhyLoaderImg from "../../../assets/images/bhy-loader.svg";
import { getAppLoader } from "./services";

const Loader: React.FC = () => {
  let loadingCount = 0;
  const [loading, setLoading] = useState<boolean>(false);
  const [disableLoader, setDisableLoader] = useState<boolean>(false);

  const show = function () {
    loadingCount = loadingCount + 1;
    !loading && setLoading(true);
  };

  const hide = function () {
    loadingCount = loadingCount - 1;
    if (loadingCount <= 0) setLoading(false);
  };

  const toggle = function () {
    loading ? setLoading(false) : setLoading(true);
  };

  const disable = function () {
    setLoading(false);
    setDisableLoader(true);
  };

  const enable = function () {
    setDisableLoader(false);
  };

  useEffect(() => {
    !getAppLoader.isLoaderDetailsPresent() &&
      getAppLoader.registerLoader({
        showLoader: show,
        hideLoader: hide,
        toggleLoader: toggle,
        enableLoader: enable,
        disableLoader: disable
      });
  });

  const loaderStyle: React.CSSProperties = loading
    ? {
        display: "block",
        position: "fixed",
        zIndex: 100000,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        bottom: 0,
        right: 0,
        width: "50px",
        height: "50px",
        left: "50%",
        top: "50%"
      }
    : {
        display: "none"
      };

  return (
    <>
      {!disableLoader && loading && (
        <div
          style={{
            width: "100%",
            height: "100%",
            zIndex: 999999,
            backgroundColor: "rgba(102, 102, 102, 0.44)",
            position: "fixed",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center"
          }}
        >
          <div id="custom-loader" style={loaderStyle}>
            <img
              src={
                process.env.REACT_APP_ORGANIZATION === "BHY"
                  ? bhyLoaderImg
                  : loaderImg
              }
              alt="loading..."
              style={{ margin: "1px" }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Loader;
