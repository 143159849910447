import { OrgTreeDetails, UboList } from "../../types";
import { snakeCase } from "lodash";
import { getNodeLevel } from "../../../../lib/utils";
export const NATURAL_PERSON = "naturalPerson";
export const LEGAL_ENTITY = "legalEntity";
export function findNodeElement({
  nodeId,
  orgData
}: {
  nodeId: string;
  orgData: OrgTreeDetails;
}) {
  if (nodeId === orgData.nodeId) {
    return orgData;
  } else if (orgData?.subsidiaries?.length) {
    for (let node of orgData.subsidiaries) {
      let foundData: any = findNodeElement({ nodeId, orgData: node });
      if (foundData) {
        return foundData;
      }
    }
  }
}
function updateNodeChildren({
  node,
  parentAbsoluteVoteShare = 0,
  parentAbsoluteShareHolding = 0
}: {
  node: OrgTreeDetails;
  parentAbsoluteVoteShare?: number;
  parentAbsoluteShareHolding?: number;
}) {
  node = {
    ...node,
    ...calculateAbsoluteOwnerShip({
      parentAbsoluteVoteShare,
      parentAbsoluteShareHolding,
      curruntVotePercent: node.votingRights || 0,
      currentSharePercent: node.shareHolding || 0
    })
  };
  if (node.subsidiaries?.length) {
    for (let subIndex = 0; subIndex < node.subsidiaries.length; subIndex++) {
      node.subsidiaries[subIndex] = updateNodeChildren({
        parentAbsoluteVoteShare: node.absoluteVotePercent,
        parentAbsoluteShareHolding: node.absoluteSharePercent,
        node: node.subsidiaries[subIndex]
      });
    }
  }
  return node;
}
export function updateNodeElement({
  node,
  orgData
}: {
  node: OrgTreeDetails;
  orgData: OrgTreeDetails;
}) {
  if (node.nodeId === orgData.nodeId) {
    orgData = { ...orgData, ...node };
    return orgData;
  } else if (orgData?.subsidiaries?.length) {
    for (let subIndex = 0; subIndex < orgData.subsidiaries.length; subIndex++) {
      let foundData: any = updateNodeElement({
        node,
        orgData: orgData.subsidiaries[subIndex]
      });
      if (foundData) {
        //This is done because when any parent node is updated, all the subsidiaries should
        //have latest absolute ownership details
        orgData.subsidiaries[subIndex] = updateNodeChildren({
          parentAbsoluteVoteShare: orgData.absoluteVotePercent,
          parentAbsoluteShareHolding: orgData.absoluteSharePercent,
          node: foundData
        });
        return orgData;
      }
    }
    // return orgData;
  }
}
export function addNodeElement({
  parentNodeId,
  node,
  orgData
}: {
  parentNodeId: string;
  node: OrgTreeDetails;
  orgData: OrgTreeDetails;
}) {
  if (parentNodeId === orgData.nodeId) {
    let cloneArray = orgData.subsidiaries?.length
      ? JSON.parse(JSON.stringify(orgData.subsidiaries))
      : [];
    cloneArray.push({ ...node });
    orgData.subsidiaries = [...cloneArray];
    return { ...orgData };
  } else if (orgData?.subsidiaries?.length) {
    for (let subsidiary of orgData.subsidiaries) {
      let foundData: any = addNodeElement({
        parentNodeId,
        node,
        orgData: subsidiary
      });
      if (foundData) {
        subsidiary = foundData;
        return orgData;
      }
    }
  }
}
export function calculateRemainingPercent({
  subsidiaries
}: {
  subsidiaries: OrgTreeDetails[] | undefined;
}) {
  if (subsidiaries?.length) {
    let totalVotePercent: number = 0;
    let totalSharePercent: number = 0;
    subsidiaries.forEach((subsidiary: OrgTreeDetails) => {
      totalVotePercent += Number(subsidiary.votingRights || 0);
      totalSharePercent += Number(subsidiary.shareHolding || 0);
    });
    return {
      remainingVotePercent: Number((100 - Number(totalVotePercent)).toFixed(5)),
      remainingSharePercent: Number(
        (100 - Number(totalSharePercent)).toFixed(5)
      )
    };
  } else {
    return { remainingVotePercent: 100, remainingSharePercent: 100 };
  }
}
export function calculateAbsoluteOwnerShip({
  parentAbsoluteVoteShare,
  parentAbsoluteShareHolding,
  curruntVotePercent,
  currentSharePercent
}: {
  parentAbsoluteVoteShare: number;
  parentAbsoluteShareHolding: number;
  curruntVotePercent: number;
  currentSharePercent: number;
}) {
  let absoluteVoteShare: number = 0;
  let absoluteShareHolding: number = 0;
  if (parentAbsoluteVoteShare && curruntVotePercent) {
    absoluteVoteShare = (curruntVotePercent / 100) * parentAbsoluteVoteShare;
  }
  if (parentAbsoluteShareHolding && currentSharePercent) {
    absoluteShareHolding =
      (currentSharePercent / 100) * parentAbsoluteShareHolding;
  }
  return {
    absoluteVotePercent: parseFloat(absoluteVoteShare.toFixed(5)),
    absoluteSharePercent: parseFloat(absoluteShareHolding.toFixed(5))
  };
}
// export function identifyUBOs({
//   orgTree,
//   shareHolding,
//   voteRights,
//   parentEntity,
//   parentNodeId,
//   ubos = [],
//   voteControlMechanism = true,
//   shareControlMechanism = true,
//   combinedShareHolding = {},
//   combinedVotingRights = {}
// }: {
//   orgTree: OrgTreeDetails;
//   shareHolding: number;
//   voteRights: number;
//   parentEntity?: string;
//   parentNodeId?: string;
//   ubos?: UboList[];
//   voteControlMechanism?: boolean;
//   shareControlMechanism?: boolean;
//   combinedShareHolding?: any;
//   combinedVotingRights?: any;
// }): UboList[] {
//   const nodeLevel = getNodeLevel(orgTree?.nodeId || "");
//   if (orgTree.entityType === NATURAL_PERSON) {
//     //this is done to find total holding of person in different chains
//     combinedShareHolding[snakeCase(`${orgTree.firstName}${orgTree.lastName}`)] =
//       Number(
//         combinedShareHolding[
//           snakeCase(`${orgTree.firstName}${orgTree.lastName}`)
//         ] || 0
//       ) +
//       (orgTree?.majorityShareHolding
//         ? Number(orgTree?.majorityShareHolding || 0)
//         : Number(nodeLevel === 1 ? orgTree?.absoluteSharePercent : 0));
//     combinedVotingRights[snakeCase(`${orgTree.firstName}${orgTree.lastName}`)] =
//       Number(
//         combinedVotingRights[
//           snakeCase(`${orgTree.firstName}${orgTree.lastName}`)
//         ] || 0
//       ) +
//       (orgTree?.majorityVoteHolding
//         ? Number(orgTree?.majorityVoteHolding || 0)
//         : Number(nodeLevel === 1 ? orgTree?.absoluteVotePercent : 0));
//     let potentialUbo = {
//       ...orgTree,
//       firstName: orgTree.firstName,
//       lastName: orgTree.lastName,
//       absoluteVotePercent: orgTree.absoluteVotePercent,
//       absoluteSharePercent: orgTree.absoluteSharePercent,
//       parentEntity: parentEntity || "",
//       documents: orgTree.documents,
//       combinedShareHolding: orgTree.combinedShareHolding,
//       combinedVoteHolding: orgTree.combinedVoteHolding,
//       isUbo: orgTree.isUBO
//     };
//     let isAlreadyPresent: number = ubos.findIndex(
//       (val) =>
//         val.firstName === potentialUbo.firstName &&
//         val.lastName === potentialUbo.lastName
//     );
//     if (potentialUbo?.isUbo) {
//       if (isAlreadyPresent === -1) {
//         ubos.push(potentialUbo);
//       } else {
//         if (ubos?.length) {
//           if (potentialUbo?.combinedShareHolding) {
//             ubos[isAlreadyPresent].combinedShareHolding = Number(
//               potentialUbo?.combinedShareHolding
//             );
//           }
//           if (potentialUbo?.combinedVoteHolding) {
//             ubos[isAlreadyPresent].combinedVoteHolding = Number(
//               potentialUbo?.combinedVoteHolding
//             );
//           }
//           if (potentialUbo?.isUbo) {
//             ubos[isAlreadyPresent].isUbo = true;
//           }
//         }
//       }
//     }

//     // if (
//     //   Number(orgTree?.absoluteVotePercent || 0) > Number(voteRights) ||
//     //   Number(orgTree?.absoluteSharePercent || 0) > Number(shareHolding)
//     // ) {
//     //   if (isAlreadyPresent === -1) {
//     //     ubos.push(potentialUbo);
//     //   }
//     // } else if (
//     //   (parentNodeId !== "1" &&
//     //     voteControlMechanism &&
//     //     Number(orgTree.votingRights || 0) > 50) ||
//     //   //||  Number(orgTree.absoluteVotePercent || 0) > voteRights
//     //   (parentNodeId !== "1" &&
//     //     shareControlMechanism &&
//     //     Number(orgTree.shareHolding || 0) > 50)
//     //   // || Number(orgTree.absoluteSharePercent || 0) > shareHolding
//     // ) {
//     //   if (isAlreadyPresent === -1) {
//     //     ubos.push({
//     //       ...potentialUbo,
//     //       controllingMechanism: true
//     //     });
//     //   }
//     // }
//     // isAlreadyPresent = ubos.findIndex(
//     //   (val) =>
//     //     val.firstName === potentialUbo.firstName &&
//     //     val.lastName === potentialUbo.lastName
//     // );
//     // let combinationData = isCombinedUBO({
//     //   nodeId: orgTree.nodeId || "",
//     //   combinedShareHolding,
//     //   combinedVotingRights,
//     //   firstName: orgTree.firstName || "",
//     //   lastName: orgTree.lastName || "",
//     //   shareCheck: shareHolding,
//     //   voteCheck: voteRights
//     // });
//     // if (combinationData) {
//     //   if (isAlreadyPresent === -1) {
//     //     ubos.push({
//     //       ...potentialUbo,
//     //       combinedHoldings: true,
//     //       ...combinationData
//     //     });
//     //   } else {
//     //     let dummy = { ...ubos[isAlreadyPresent] };
//     //     dummy = { ...dummy, combinedHoldings: true, ...combinationData };
//     //     ubos[isAlreadyPresent] = { ...dummy };
//     //   }
//     // }

//     return ubos;
//   } else {
//     if (orgTree.subsidiaries?.length) {
//       Number(nodeLevel) > 0 &&
//         calculateMajorityHolder({
//           parentNode: orgTree,
//           subsidiaries: orgTree?.subsidiaries || [],
//           nodeLevel
//         });
//       orgTree.subsidiaries?.forEach((subsidiary) => {
//         ubos = identifyUBOs({
//           shareHolding,
//           voteRights,
//           orgTree: subsidiary,
//           parentEntity: orgTree.legalEntityName,
//           ubos,
//           //This is done because the control mechanism starts from first subsidiary
//           voteControlMechanism:
//             voteControlMechanism &&
//             Boolean(
//               Number(orgTree.votingRights || 0) > 50 ||
//                 Number(orgTree.absoluteVotePercent || 0) > voteRights
//             ),
//           shareControlMechanism:
//             shareControlMechanism &&
//             Boolean(
//               Number(orgTree.shareHolding || 0) > 50 ||
//                 Number(orgTree.absoluteSharePercent || 0) > shareHolding
//             ),
//           combinedShareHolding,
//           combinedVotingRights,
//           parentNodeId: orgTree.nodeId
//         });
//       });
//     }
//     return ubos;
//   }
// }
export function getRemainingPercent(
  selectedNode?: OrgTreeDetails,
  orgData?: OrgTreeDetails,
  parentNodeId?: string
) {
  if (parentNodeId) {
    let parentNode =
      orgData && findNodeElement({ orgData, nodeId: parentNodeId });
    if (!parentNode?.subsidiaries.length) {
      return { remainingVotePercent: 0, remainingSharePercent: 0 };
    }
    let remainingPercent = calculateRemainingPercent({
      subsidiaries: parentNode?.subsidiaries
    });
    //This is done to handle edit condition.
    //In edit we need to add the value of the node being edited to get accurate result
    return {
      //toFixed() since 0.1+0.2 != 0.3 but 0.30000000000000004
      remainingVotePercent: +(
        Number(remainingPercent.remainingVotePercent) +
        Number(selectedNode?.votingRights || 0)
      ).toFixed(5),
      remainingSharePercent: +(
        Number(remainingPercent.remainingSharePercent) +
        Number(selectedNode?.shareHolding || 0)
      ).toFixed(5)
    };
  } else {
    return { remainingVotePercent: 100, remainingSharePercent: 100 };
  }
}
export function removeNodeElement({
  parentNodeId,
  nodeId,
  orgData
}: {
  parentNodeId: string;
  nodeId: string;
  orgData: OrgTreeDetails;
}) {
  if (parentNodeId === orgData.nodeId) {
    let updatedData = removeNode(
      parentNodeId,
      nodeId,
      orgData.subsidiaries || []
    );
    orgData.subsidiaries = updatedData;
    return orgData;
  } else if (orgData?.subsidiaries?.length) {
    for (let subsidiary of orgData.subsidiaries) {
      let updatedData: any = removeNodeElement({
        parentNodeId,
        nodeId,
        orgData: subsidiary
      });
      if (updatedData) {
        subsidiary = updatedData;
        return orgData;
      }
    }
  }
}
function removeNode(
  parentNodeId: string,
  nodeId: string,
  subsidaries: OrgTreeDetails[]
) {
  let findNodeToRemoveIndex = subsidaries.findIndex(
    (val) => val.nodeId === nodeId
  );
  let dummy = [...subsidaries];
  dummy.splice(findNodeToRemoveIndex, 1);
  return reOrderNodes(parentNodeId, dummy);
}
function reOrderNodes(parentNodeId: string, subsidaries: OrgTreeDetails[]) {
  subsidaries.forEach((node, index) => {
    node.nodeId = parentNodeId + "." + (index + 1);
    if (node.subsidiaries?.length) {
      let returnedData = reOrderNodes(node.nodeId, node.subsidiaries);
      node.subsidiaries = [...returnedData];
    }
  });
  return subsidaries;
}
export function markUBOsInOrgStructure({
  orgTree,
  shareHolding,
  voteRights
}: {
  orgTree: OrgTreeDetails;
  shareHolding: number;
  voteRights: number;
}) {
  const updatedOrgChart = identifyEntityOwners({
    orgChart: orgTree
  });
  const ubos = identifyUBOSInOrgChart({
    levelOneSubsidiaries: updatedOrgChart.subsidiaries || [],
    votingRightsThreshold: voteRights,
    shareHoldingThreshold: shareHolding
  });
  const updatedOrgTree = markUBOs({
    orgChart: updatedOrgChart,
    ubos
  });
  //This call is made to identify ubos directly, through control mechanism
  //and populate the total holding of a person is they are present in multiple chains
  // let updatedData = identifyMarkUBOsInOrgStructure({
  //   orgTree,
  //   shareHolding,
  //   voteRights
  // });
  // console.log("updatedData", updatedData);
  //This call is made to specifically made to mark all the people who might hold
  //majority through combining all their rights in multiple chains
  // return markCombinedUBOs({
  //   ...updatedData,
  //   shareHolding,
  //   voteRights
  // });
  return { ...updatedOrgTree, owners: ubos };
}
// export function identifyMarkUBOsInOrgStructure({
//   orgTree,
//   shareHolding,
//   voteRights,
//   voteControlMechanism = true,
//   shareControlMechanism = true,
//   parentNodeId,
//   combinedShareHolding = {},
//   combinedVotingRights = {}
// }: {
//   orgTree: OrgTreeDetails;
//   shareHolding: number;
//   voteRights: number;
//   parentNodeId?: string;
//   voteControlMechanism?: boolean;
//   shareControlMechanism?: boolean;
//   combinedShareHolding?: any;
//   combinedVotingRights?: any;
// }) {
//   const nodeLevel = getNodeLevel(orgTree?.nodeId || "");
//   if (orgTree.entityType === NATURAL_PERSON) {
//     combinedShareHolding[snakeCase(`${orgTree.firstName}${orgTree.lastName}`)] =
//       Number(
//         combinedShareHolding[
//           snakeCase(`${orgTree.firstName}${orgTree.lastName}`)
//         ] || 0
//       ) +
//       (orgTree?.majorityShareHolding
//         ? Number(orgTree?.majorityShareHolding || 0)
//         : Number(nodeLevel === 1 ? orgTree?.absoluteSharePercent : 0));

//     combinedVotingRights[snakeCase(`${orgTree.firstName}${orgTree.lastName}`)] =
//       Number(
//         combinedVotingRights[
//           snakeCase(`${orgTree.firstName}${orgTree.lastName}`)
//         ] || 0
//       ) +
//       (orgTree?.majorityVoteHolding
//         ? Number(orgTree?.majorityVoteHolding || 0)
//         : Number(nodeLevel === 1 ? orgTree?.absoluteVotePercent : 0));
//     if (
//       (nodeLevel === 1 &&
//         (Number(orgTree?.absoluteVotePercent || 0) > Number(voteRights) ||
//           Number(orgTree?.absoluteSharePercent || 0) > Number(shareHolding))) ||
//       (parentNodeId !== "1" &&
//         voteControlMechanism &&
//         Number(orgTree.votingRights || 0) > 50) ||
//       // || Number(orgTree.absoluteVotePercent || 0) > voteRights
//       (parentNodeId !== "1" &&
//         shareControlMechanism &&
//         Number(orgTree.shareHolding || 0) > 50)
//       //  || Number(orgTree.absoluteSharePercent || 0) > shareHolding
//     ) {
//       orgTree.isUBO = true;
//     } else {
//       orgTree.isUBO = false;
//     }
//     return { orgTree, combinedShareHolding, combinedVotingRights };
//   } else {
//     if (orgTree.subsidiaries?.length) {
//       Number(nodeLevel) > 0 &&
//         calculateMajorityHolder({
//           parentNode: orgTree,
//           subsidiaries: orgTree?.subsidiaries || [],
//           nodeLevel
//         });
//       orgTree.subsidiaries?.forEach((subsidiary) => {
//         let updatedData: any = identifyMarkUBOsInOrgStructure({
//           orgTree: subsidiary,
//           shareHolding,
//           voteRights,
//           // parentEntity: orgTree.legalEntityName,
//           //This is done because the control mechanism starts from first subsidiary
//           voteControlMechanism:
//             voteControlMechanism &&
//             Boolean(
//               Number(orgTree.votingRights || 0) > 50 ||
//                 Number(orgTree.absoluteVotePercent || 0) > voteRights
//             ),
//           shareControlMechanism:
//             shareControlMechanism &&
//             Boolean(
//               Number(orgTree.shareHolding || 0) > 50 ||
//                 Number(orgTree.absoluteSharePercent || 0) > shareHolding
//             ),
//           combinedShareHolding,
//           combinedVotingRights,
//           parentNodeId: orgTree.nodeId
//         });
//         subsidiary = { ...updatedData.orgTree };
//       });
//     }
//     return { orgTree, combinedShareHolding, combinedVotingRights };
//   }
// }

// function markCombinedUBOs({
//   orgTree,
//   combinedShareHolding,
//   combinedVotingRights,
//   shareHolding,
//   voteRights
// }: {
//   orgTree: OrgTreeDetails;
//   combinedShareHolding: any;
//   combinedVotingRights: any;
//   shareHolding: number;
//   voteRights: number;
// }) {
//   if (orgTree.entityType === NATURAL_PERSON) {
//     if (
//       isCombinedUBO({
//         nodeId: orgTree.nodeId || "",
//         combinedShareHolding,
//         combinedVotingRights,
//         firstName: orgTree.firstName || "",
//         lastName: orgTree.lastName || "",
//         shareCheck: shareHolding,
//         voteCheck: voteRights
//       })
//     ) {
//       orgTree.combinedShareHolding =
//         combinedShareHolding[
//           snakeCase(`${orgTree.firstName}${orgTree.lastName}`)
//         ];
//       orgTree.combinedVoteHolding =
//         combinedVotingRights[
//           snakeCase(`${orgTree.firstName}${orgTree.lastName}`)
//         ];
//       orgTree.isUBO = true;
//     }
//     return orgTree;
//   } else {
//     if (orgTree.subsidiaries?.length) {
//       orgTree.subsidiaries?.forEach((subsidiary) => {
//         let updatedData: any = markCombinedUBOs({
//           orgTree: subsidiary,
//           shareHolding,
//           voteRights,
//           combinedShareHolding,
//           combinedVotingRights
//         });
//         subsidiary = { ...updatedData };
//       });
//     }
//     return orgTree;
//   }
// }

// function isCombinedUBO({
//   nodeId,
//   combinedShareHolding,
//   combinedVotingRights,
//   firstName,
//   lastName,
//   shareCheck,
//   voteCheck
// }: {
//   nodeId: string;
//   combinedShareHolding: any;
//   combinedVotingRights: any;
//   firstName: string;
//   lastName: string;
//   shareCheck: number;
//   voteCheck: number;
// }) {
//   const nodeLevel = getNodeLevel(nodeId);
//   if (
//     nodeLevel === 1 &&
//     (Number(combinedShareHolding[snakeCase(`${firstName}${lastName}`)] || 0) >
//       Number(shareCheck) ||
//       Number(combinedVotingRights[snakeCase(`${firstName}${lastName}`)] || 0) >
//         Number(voteCheck))
//   ) {
//     return {
//       combinedVotes: combinedVotingRights[snakeCase(`${firstName}${lastName}`)],
//       combinedShares: combinedShareHolding[snakeCase(`${firstName}${lastName}`)]
//     };
//   }
//   return false;
// }
// function calculateMajorityHolder({
//   parentNode,
//   subsidiaries,
//   nodeLevel
// }: {
//   parentNode: OrgTreeDetails;
//   subsidiaries: OrgTreeDetails[];
//   nodeLevel: number;
// }) {
//   let majorityVoteHolding;
//   let majorityShareHolding;
//   if (nodeLevel === 1) {
//     majorityVoteHolding = parentNode.votingRights;
//     majorityShareHolding = parentNode.shareHolding;
//   } else {
//     majorityVoteHolding = parentNode.majorityVoteHolding;
//     majorityShareHolding = parentNode.majorityShareHolding;
//   }
//   if (subsidiaries?.length) {
//     for (let count = 0; count < subsidiaries.length; count++) {
//       if (Number(subsidiaries[count]?.votingRights || 0) > 50) {
//         subsidiaries[count] = { ...subsidiaries[count], majorityVoteHolding };
//       } else {
//         subsidiaries[count] = {
//           ...subsidiaries[count],
//           majorityVoteHolding: 0
//         };
//       }
//       if (Number(subsidiaries[count]?.shareHolding || 0) > 50) {
//         subsidiaries[count] = {
//           ...subsidiaries[count],
//           majorityShareHolding
//         };
//       } else {
//         subsidiaries[count] = {
//           ...subsidiaries[count],
//           majorityShareHolding: 0
//         };
//       }
//     }
//   }
// }
function identifyEntityOwners({ orgChart }: { orgChart: OrgTreeDetails }) {
  const nodeLevel = getNodeLevel(orgChart.nodeId || "");

  if (orgChart?.subsidiaries?.length) {
    let subs = [...orgChart.subsidiaries];
    for (let counter = 0; counter < subs.length; counter++) {
      //check if there is any legal entity in subsidiary
      const isAnyLegalEntityPresent = isEntityPresent({
        entity: subs[counter],
        type: LEGAL_ENTITY
      });
      if (!isAnyLegalEntityPresent) {
        //find the majority holders from natural persons
        const owners = findMajorityNaturalPerson({
          entity: subs[counter]
        });
        subs[counter] = { ...subs[counter], owners: owners };
      } else {
        let updatedData: OrgTreeDetails = identifyEntityOwners({
          orgChart: subs[counter]
        });
        subs[counter] = { ...updatedData };
      }
    }
    if (nodeLevel > 0) {
      const entityOwner = findOwnersFromSubsidiaries({
        entities: subs,
        parentEntity: orgChart
      });
      orgChart.owners = [...entityOwner];
    }
    orgChart.subsidiaries = [...subs];
  }
  return orgChart;
}

function isEntityPresent({
  entity,
  type
}: {
  entity: OrgTreeDetails;
  type: string;
}) {
  switch (type) {
    case NATURAL_PERSON: {
      return entity.entityType === NATURAL_PERSON ? true : false;
    }
    case LEGAL_ENTITY: {
      return entity.entityType === LEGAL_ENTITY &&
        entity.subsidiaries?.some((val) => val.entityType === LEGAL_ENTITY)
        ? true
        : false;
    }
    default:
      return false;
  }
}
function findMajorityNaturalPerson({ entity }: { entity: OrgTreeDetails }) {
  if (!entity.subsidiaries?.length) {
    return [];
  }
  const ownerObject = [...entity.subsidiaries];

  const combinedOwners: UboList[] = combineDuplicateOwners({
    owners: ownerObject
  });
  return findEntityOwners({ combinedOwners, parentEntity: entity });
}
function findOwnersFromSubsidiaries({
  entities,
  parentEntity
}: {
  entities: OrgTreeDetails[];
  parentEntity: OrgTreeDetails;
}) {
  let owners: UboList[] = [];
  for (let entity of entities) {
    if (entity?.owners?.length) {
      owners = [...owners, ...entity?.owners];
    }
    if (entity.entityType === NATURAL_PERSON) {
      owners.push(entity);
    }
  }
  const combinedOwners: UboList[] = combineDuplicateOwners({
    owners
  });
  return findEntityOwners({ combinedOwners, parentEntity });
}
function findEntityOwners({
  combinedOwners,
  parentEntity
}: {
  combinedOwners: UboList[];
  parentEntity: OrgTreeDetails;
}) {
  if (!combinedOwners?.length) {
    return [];
  }
  const entityOwners: UboList[] = [];
  combinedOwners.forEach((owner: UboList) => {
    if (owner.entityType === NATURAL_PERSON) {
      if (
        Number(owner.votingRights || 0) > 50 ||
        Number(owner.shareHolding || 0) > 50
      ) {
        entityOwners.push({
          ...owner,
          votingRights:
            Number(owner.votingRights || 0) > 50
              ? parentEntity.votingRights || 0
              : owner.votingRights || 0,
          shareHolding:
            Number(owner.shareHolding || 0) > 50
              ? parentEntity.shareHolding || 0
              : owner.shareHolding || 0
        });
      }
    }
  });
  return entityOwners;
}
function combineDuplicateOwners({ owners }: { owners: UboList[] }) {
  let uniqueObjects: any = {};
  owners.forEach((owner) => {
    let id = snakeCase(`${owner.firstName}${owner.lastName}`);
    if (!uniqueObjects[id]) {
      uniqueObjects[id] = {
        ...owner
      };
    } else {
      uniqueObjects[id] = {
        ...owner,
        votingRights:
          Number(uniqueObjects[id].votingRights || 0) +
          Number(owner.votingRights || 0),
        shareHolding:
          Number(uniqueObjects[id].shareHolding || 0) +
          Number(owner.shareHolding || 0)
      };
    }
  });
  const uniqueOwners: UboList[] = Object.values(uniqueObjects);
  return uniqueOwners;
}
function identifyUBOSInOrgChart({
  levelOneSubsidiaries,
  votingRightsThreshold,
  shareHoldingThreshold
}: {
  levelOneSubsidiaries: OrgTreeDetails[];
  votingRightsThreshold: number;
  shareHoldingThreshold: number;
}) {
  let owners: UboList[] = [];
  for (let entity of levelOneSubsidiaries) {
    if (entity?.owners?.length) {
      owners = [...owners, ...entity?.owners];
    }
    if (entity?.entityType === NATURAL_PERSON) {
      owners.push(entity);
    }
  }
  const combinedOwners: UboList[] = combineDuplicateOwners({
    owners
  });
  const ubos: UboList[] = [];

  combinedOwners.forEach((owner: UboList) => {
    if (owner.entityType === NATURAL_PERSON) {
      if (
        Number(owner.votingRights || 0) > votingRightsThreshold ||
        Number(owner.shareHolding || 0) > shareHoldingThreshold
      ) {
        ubos.push({
          ...owner
        });
      }
    }
  });
  return ubos;
}
function markUBOs({
  orgChart,
  ubos
}: {
  orgChart: OrgTreeDetails;
  ubos: UboList[];
}) {
  if (orgChart?.subsidiaries?.length) {
    let subs = [...orgChart.subsidiaries];
    for (let counter = 0; counter < subs.length; counter++) {
      if (
        subs[counter].entityType === NATURAL_PERSON &&
        ubos.find(
          (ubo) =>
            ubo.firstName === subs[counter].firstName &&
            ubo.lastName === subs[counter].lastName
        )
      ) {
        subs[counter].isUBO = true;
      } else {
        subs[counter].isUBO = false;
      }
      if (subs[counter].entityType === LEGAL_ENTITY) {
        let updatedData: OrgTreeDetails = markUBOs({
          orgChart: subs[counter],
          ubos
        });
        subs[counter] = { ...updatedData };
      }
    }
    orgChart.subsidiaries = [...subs];
  }
  return orgChart;
}
