import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import Icons from "../../../presentation/Icons";
import Select from "react-select";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Params, HighRiskCounterparty, SelectedQuestionnaire } from "../types";
import * as apis from "../../../../apis/Dashboard";
import { toast } from "react-toastify";
import { formatGraphQLError } from "../../../../lib/utils";
import { UserContext } from "../../../../Contexts/UserContext";
import { getScoreValue } from "./service";
import { Link } from "react-router-dom";
import { OrgKeyMap } from "../../../../constants/app-constants";
import { useTranslation } from "react-i18next";

type Props = {
  questionnairesList: any;
};

const HighRiskCounterpartyList: React.FC<Props> = ({ questionnairesList }) => {
  const { t } = useTranslation();
  const { accountId }: Params = useParams<any>();
  const assetManagerId = accountId;
  const { userDetails }: any = useContext(UserContext);
  const defaultSelectedValue = { id: 1, value: "All", label: "All" };
  const [highRiskCounterpartiesList, setHighRiskCounterpartiesList] =
    useState<HighRiskCounterparty[]>();
  const [selectedQuestionnaire, setSelectedQuestionnaire] =
    useState<SelectedQuestionnaire>(defaultSelectedValue);
  useEffect(() => {
    const { value } = selectedQuestionnaire;
    const reqObj =
      value !== "All"
        ? {
            assetManagerId: assetManagerId,
            questionnaireId: value
          }
        : { assetManagerId: assetManagerId };
    apis
      .getHighRiskCounterparties(reqObj)
      .then((res) => {
        setHighRiskCounterpartiesList(
          res?.data?.highRiskCounterparties?.submittedQuestionnaires
        );
      })
      .catch((error) => {
        toast.error(formatGraphQLError(error.message));
      });
  }, [selectedQuestionnaire]);
  return (
    <div className="card card--chartjs mb-4">
      <div className="card__header">
        <div className="row row--minus-margin-fix justify-content-between">
          <div className="card__title col-12 col-lg-7 col-xl-8">
            <h4 className="mb-lg-0">
              {t(`Highest risk ${OrgKeyMap.end_user_names.toLowerCase()}`)}
              <OverlayTrigger
                placement="bottom"
                rootClose
                overlay={
                  <Tooltip id={`Highest-risk-counterparties`}>
                    {t(
                      "This section is only visible to you and the Zeidler team. Your client can't see it."
                    )}
                  </Tooltip>
                }
              >
                <span className="m-l-10">
                  <Icons className="icon icon--small" icon="info"></Icons>
                </span>
              </OverlayTrigger>
            </h4>
          </div>
          <div className="col-9 col-sm-8 col-lg-5 col-xl-4">
            <Select
              options={questionnairesList}
              name="select-single"
              placeholder={`${t("Select")}...`}
              onChange={(ele: any) => {
                setSelectedQuestionnaire(ele);
              }}
              defaultValue={defaultSelectedValue}
              isClearable={false}
              isMulti={false}
              noOptionsMessage={() => t("No options")}
            />
          </div>
        </div>
      </div>
      <div className="card__body pt-0 response_highest_risk">
        {highRiskCounterpartiesList?.length
          ? highRiskCounterpartiesList.map((cpQuestionnaire, index) => {
              return (
                <Link
                  to={`/submittedQuestionnaire/am/${cpQuestionnaire.accountQuestionnaireId}/${assetManagerId}/${cpQuestionnaire.id}/${cpQuestionnaire.sectionId}`}
                  className="high-risk-party"
                  key={index}
                >
                  <span
                    className={`high-risk-party__stats-inner rating--${
                      getScoreValue(userDetails, cpQuestionnaire.score) > 0
                        ? "low"
                        : "high"
                    }`}
                  >
                    {getScoreValue(userDetails, cpQuestionnaire.score)}
                  </span>
                  <p className="high-risk-party__title">
                    {cpQuestionnaire.counterpartyName}
                  </p>
                  <span className="high-risk-party__link">
                    {/* <svg className="icon icon--small" viewBox="0 0 16 16">
              <use xlink:href="/assets/icons-sprite-c855b358e91b4c8e9fae11e538f76b7102ee917145728d9e2012198af108d896.svg#arrow-right-16"></use>
            </svg> */}
                  </span>
                </Link>
              );
            })
          : t("No submissions")}
      </div>
    </div>
  );
};

export default HighRiskCounterpartyList;
