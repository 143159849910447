import { useEffect } from "react";
import { QueryParamActions } from "../types/applicationData";
export function useCommentScroll({
  callBack,
  commentRef,
  queryParamActions,
  idFromQueryParam,
  entityId
}: {
  callBack: () => void;
  commentRef: any;
  queryParamActions: QueryParamActions;
  idFromQueryParam?: string | number | null;
  entityId?: string | number;
}) {
  useEffect(() => {
    if (
      idFromQueryParam &&
      entityId &&
      String(entityId) === String(idFromQueryParam)
    ) {
      if (!queryParamActions.hasScrolled) {
        commentRef?.current?.scrollIntoView();
      }
      if (!queryParamActions.hasScrolled && !queryParamActions.hasPopupOpened) {
        callBack();
        queryParamActions.hasPopupOpened = true;
      }
    }
  });
}
