import React, { useEffect, useState } from "react";
import ApprovalSteps from "../ApprovalSteps";
import {
  ApprovalDetails,
  ApproveSubmissionData,
  AddComments,
  UpdateComments,
  DeleteComments
} from "../../SubmittedQuestionnaire/types";
import ApproveButton from "../ApproveButton";
import ApprovalComments from "../ApprovalComments";
import { useTranslation } from "react-i18next";
import { useQueryParams } from "../../../../Hooks/useQueryParams";
import { QueryPrameters } from "../../../../types/applicationData";
type Props = {
  approvalDetails: ApprovalDetails[];
  allowApprovals: boolean;
  onApprove: (approveData: ApproveSubmissionData) => void;
  onAddComment: (comments: AddComments) => void;
  onUpdateComment: (comments: UpdateComments) => void;
  onDeleteComment: (comments: DeleteComments) => void;
  // approvalDetails: (pageDetails: any) => void;
};

const QuestionnaireLevelApprovals: React.FC<Props> = ({
  approvalDetails,
  onApprove,
  onAddComment,
  onUpdateComment,
  onDeleteComment,
  allowApprovals
}) => {
  const queryParams: QueryPrameters | undefined = useQueryParams({
    paramNames: ["approvalCommentId"],
    isRemoveQueryParams: false
  });
  const { t } = useTranslation();
  const [showComments, setShowComments] = useState<boolean>(false);
  const [toggle, setToggle] = useState(false);
  useEffect(() => {
    if (queryParams?.approvalCommentId) {
      setShowComments(true);
      setToggle(true);
    }
  }, [queryParams]);
  return (
    <>
      <div className="step-arrow-action-heading questionnair-approval-workflow">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div id="accordion">
                {approvalDetails?.length ? (
                  <div className="card">
                    <div
                      className={`card-header ${
                        toggle ? "show-heading-btn" : ""
                      }`}
                      onClick={() => {
                        setToggle(!toggle);
                      }}
                    >
                      <div
                        className={`cardheading ${toggle ? "" : "collapsed"}`}
                        data-toggle="collapse"
                        // href="#reportOne"
                        aria-expanded={toggle ? "false" : "true"}
                        aria-controls="reportOne"
                      >
                        <div className="dropdown-toggle"></div>
                        <div className="card-header-text">{t("Approvals")}</div>
                      </div>
                      <div className="approve-heading-btn-wrp">
                        <div className="approve-heading-btn">
                          <button
                            className="btn btn-underline"
                            //data-toggle="modal"
                            //data-target="#approval-add-comment"
                            onClick={(e) => {
                              setShowComments(true);
                              e.stopPropagation();
                            }}
                          >
                            {t("View/Add comments")}
                          </button>

                          {allowApprovals && (
                            <ApproveButton
                              buttonName="Approve questionnaire"
                              approvalDetails={approvalDetails}
                              onApprove={onApprove}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      id="reportOne"
                      className={`collapse ${toggle ? "show" : ""}`}
                    >
                      <div className="card-body">
                        <ApprovalSteps approvalDetails={approvalDetails} />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div style={{ color: "white" }}>
                    {t("Approvals are not configured for this questionnaire")}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showComments && (
        <ApprovalComments
          allowApprovals={allowApprovals}
          onAddComment={onAddComment}
          showModal={showComments}
          approvalDetails={approvalDetails}
          onClose={() => setShowComments(false)}
          onUpdateComment={onUpdateComment}
          onDeleteComment={onDeleteComment}
        />
      )}
    </>
  );
};
export default QuestionnaireLevelApprovals;
