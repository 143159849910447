import client from "../apollo.config";
import * as queries from "./queries";
import { PageDetailsState,SectionParams } from "../../components/container/ResponseQuestionnaireList/types";

export const getQuestionnaireResponseList = ({
  pageNumber,
  pageSize,
  search,
  sort,
  assetManagerId,
  filters
}: PageDetailsState) => {
  //console.log(pageNumber, pageSize, search, sort, assetManagerId);
  return client.query({
    query: queries.GET_QUESTIONNAIRE_RESPONSE_LIST_QUERY,
    fetchPolicy: "network-only",
    variables: { pageNumber, pageSize, search, sort, assetManagerId, filters }
  });
};


export const getQuestionnaireSections = ({
  assetManagerId,
  accountQuestionnaireId
}:SectionParams)=>{
  return client.query({
    query: queries.GET_QUESTIONNAIRE_SECTIONS_LIST_QUERY,
    fetchPolicy: "network-only",
    variables: { assetManagerId, accountQuestionnaireId }
  });
};