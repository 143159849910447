import { LoaderDetails } from "./types";

export const getAppLoader = (function () {
  let loaderDetails: LoaderDetails;

  return {
    registerLoader: function (loaderData: LoaderDetails) {
      loaderDetails = loaderData;
    },
    showLoader: function () {
      loaderDetails && loaderDetails.showLoader();
    },
    hideLoader: function () {
      loaderDetails && loaderDetails.hideLoader();
    },
    toggleLoader: function () {
      loaderDetails && loaderDetails.toggleLoader();
    },
    enableLoader: function () {
      loaderDetails && loaderDetails.enableLoader();
    },
    disableLoader: function () {
      loaderDetails && loaderDetails.disableLoader();
    },
    isLoaderDetailsPresent: function () {
      return loaderDetails ? true : false;
    }
  };
})();
