import React, { useState, useEffect } from "react";
import { OrgTreeDetails } from "../../../types";
import { calculateRemainingPercent } from "../service";
import Icons from "../../../../presentation/Icons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useTranslation } from "react-i18next";
interface Props {
  treeDetails?: OrgTreeDetails[];
  nodeId: string;
  showAddShareButtons: boolean;
  onEdit: ({
    parentNodeId,
    nodeId
  }: {
    parentNodeId: string | null;
    nodeId: string;
  }) => void;
  onAdd: ({
    parentNodeId,
    nodeId
  }: {
    parentNodeId: string | null;
    nodeId: string;
  }) => void;
  remainingVotePercent: number;
  remainingSharePercent: number;
  isShowUboOnly?: boolean;
  allowEdit: boolean;
}

const OrgSubsidiariesTree: React.FC<Props> = ({
  nodeId,
  treeDetails,
  onEdit,
  onAdd,
  remainingSharePercent,
  remainingVotePercent,
  showAddShareButtons,
  isShowUboOnly,
  allowEdit
}) => {
  const { t } = useTranslation();
  const [toggle, setToggle] = useState<any>({});
  useEffect(() => {
    let dummy: any = {};
    treeDetails?.forEach((val, index) => {
      if (val.entityType === "legalEntity") {
        dummy[index] = isShowUboOnly ? true : false;
      }
    });
    setToggle(dummy);
  }, [isShowUboOnly]);

  function getNaturalPersonTemplate(
    treeDetail: any,
    index: number,
    remainingPercentage: any
  ) {
    return (
      <div className="left-nav-link-wrp" key={index}>
        <div
          className={`nav level-one ${
            !treeDetail.entityType
              ? "disabled-line"
              : Number(remainingSharePercent) > 0 ||
                Number(remainingVotePercent) > 0
              ? "danger-line"
              : "success-line"
          } ${isShowUboOnly ? "" : "nav-list-in"}`}
        >
          <span className="nav-icon">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`${nodeId}-${index}-ubo-details`}>
                  <>
                    <div>
                      {t("Voting rights")} : {treeDetail.votingRights || 0}%{" "}
                    </div>
                    <div>
                      {t("Share held")} : {treeDetail.shareHolding || 0}%{" "}
                    </div>
                    <div>
                      {t("Overall voting rights")}:
                      {treeDetail.absoluteVotePercent || 0}%
                    </div>
                    <div>
                      {t("Overall shares held")}:
                      {treeDetail.absoluteSharePercent || 0}%
                    </div>
                  </>
                </Tooltip>
              }
            >
              {treeDetail.isUBO ? (
                <span
                  data-tip
                  data-for={`${nodeId}-${index}-ubo-details`}
                  className="nav-status-text danger"
                >
                  UBO
                </span>
              ) : (
                <span>
                  <Icons icon={"uboUser-16"} />
                </span>
              )}
            </OverlayTrigger>
          </span>
          <span className="nav-icon-text">
            <span className="nav-text">
              {treeDetail.entityType
                ? treeDetail?.firstName + " " + treeDetail?.lastName
                : t("New Entity")}
            </span>
            <span className={`voting-status`}>
              <span className="count-share">
                {t("Voting rights")}:{" "}
                <span className="count-number">
                  {treeDetail.absoluteVotePercent || 0}%
                </span>
              </span>
              <span className="count-share">
                {t("Capital share")}:{" "}
                <span className="count-number">
                  {treeDetail.absoluteSharePercent || 0}%
                </span>
              </span>
            </span>
          </span>
        </div>
        <button
          className="nav-drop-arrow"
          onClick={() =>
            onEdit({ parentNodeId: nodeId, nodeId: treeDetail.nodeId })
          }
        >
          <Icons icon={"navArrowRight16"} />
        </button>
      </div>
    );
  }
  return (
    <>
      <div className="nav-link-in">
        {treeDetails?.map((treeDetail: any, index: number): any => {
          let remainingPercentage = calculateRemainingPercent({
            subsidiaries: treeDetail.subsidiaries
          });
          return treeDetail &&
            (!treeDetail?.entityType ||
              treeDetail?.entityType === "naturalPerson")
            ? isShowUboOnly
              ? treeDetail.isUBO
                ? getNaturalPersonTemplate(
                    treeDetail,
                    index,
                    remainingPercentage
                  )
                : ""
              : getNaturalPersonTemplate(treeDetail, index, remainingPercentage)
            : treeDetail?.entityType === "legalEntity" && (
                <div className="accordion-group level-step-one" key={index}>
                  {!isShowUboOnly && (
                    <div className="level-step-heading">
                      <div className="left-nav-link-wrp">
                        <div
                          data-toggle="collapse"
                          className={`nav level-one ${
                            Number(remainingPercentage.remainingSharePercent) >
                              0 ||
                            Number(remainingPercentage.remainingVotePercent) > 0
                              ? "danger-line"
                              : "success-line"
                          } nav-list-in ${toggle[index] ? "collapsed" : ""}`}
                          aria-expanded={
                            toggle[index]
                              ? (true as boolean)
                              : (false as boolean)
                          }
                          //  href="#level-B"
                        >
                          <span className="nav-icon">
                            <Icons icon={"homeEntity-16"} />
                          </span>
                          <span
                            className="nav-icon-text"
                            onClick={() => {
                              setToggle({ ...toggle, [index]: !toggle[index] });
                            }}
                          >
                            <span className="nav-text">
                              {treeDetail?.legalEntityName}
                            </span>
                            <span className={`voting-status`}>
                              <span className="count-share">
                                {t("Voting rights")}:{" "}
                                <span className="count-number">
                                  {treeDetail.absoluteVotePercent}%
                                </span>
                              </span>
                              <span className="count-share">
                                {t("Capital share")}:{" "}
                                <span className="count-number">
                                  {treeDetail.absoluteSharePercent}%
                                </span>
                              </span>
                            </span>
                          </span>
                        </div>
                        {showAddShareButtons &&
                          !(
                            treeDetail.isEntityWithNoUbo ||
                            treeDetail.isFreeFloatShareEntity
                          ) &&
                          (remainingPercentage.remainingVotePercent > 0 ||
                            remainingPercentage.remainingSharePercent > 0) && (
                            <>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id={`nested-addEntity-${index}`}>
                                    {t("Add entity")}
                                  </Tooltip>
                                }
                              >
                                <button
                                  className="nav-drop-plus btn btn-primary action"
                                  onClick={() => {
                                    onAdd({
                                      parentNodeId: nodeId,
                                      nodeId: treeDetail.nodeId
                                    });
                                  }}
                                  disabled={!allowEdit}
                                >
                                  <span
                                    data-tip
                                    data-for={`nested-addEntity-${index}`}
                                  >
                                    <Icons icon={"addEntity"} />
                                  </span>
                                </button>
                              </OverlayTrigger>
                            </>
                          )}
                        <button
                          className="nav-drop-arrow"
                          onClick={() => {
                            onEdit({
                              parentNodeId: nodeId,
                              nodeId: treeDetail.nodeId
                            });
                          }}
                        >
                          <Icons icon={"navArrowRight16"} />
                        </button>
                      </div>
                    </div>
                  )}
                  <div className={`collapse in ${toggle[index] ? "show" : ""}`}>
                    <OrgSubsidiariesTree
                      nodeId={nodeId + "." + (index + 1)}
                      treeDetails={treeDetail.subsidiaries}
                      onEdit={onEdit}
                      onAdd={onAdd}
                      allowEdit={allowEdit}
                      showAddShareButtons={
                        treeDetail.entityType === "legalEntity"
                      }
                      isShowUboOnly={isShowUboOnly}
                      {...remainingPercentage}
                    />
                  </div>
                </div>
              );
        })}
      </div>

      {/* {showAddShareButtons &&
        (remainingVotePercent > 0 || remainingSharePercent > 0) && (
          <div className="add-entity-btn">
            <button
              onClick={() => onAdd(nodeId)}
              className="btn btn-primary has-icon"
            >
              Add entity
            </button>
          </div>
        )} */}
    </>
  );
};

export default OrgSubsidiariesTree;

// return (
//   <div>
//     {treeDetails?.map((treeDetail: any, index: number): any => {
//       return (
//         <div key={index}>
//           <div
//             onClick={() =>
//               onEdit({ parentNodeId: nodeId, nodeId: treeDetail.nodeId })
//             }
//           >
//             {treeDetail.legalEntityName}
//           </div>
//           <span>vote rights {treeDetail.votingRights}%</span>
//           <span>share rights {treeDetail.shareHolding}%</span>
//           <span>absolute vote rights {treeDetail.absoluteVotePercent}%</span>
//           <span>
//             absolute share rights {treeDetail.absoluteSharePercent}%
//           </span>
//           <div style={{ border: "1px solid black", marginLeft: "10px" }}>
//             <OrgSubsidiariesTree
//               nodeId={nodeId + "." + (index + 1)}
//               treeDetails={treeDetail.subsidiaries}
//               onEdit={onEdit}
//               onAdd={onAdd}
//               showAddShareButtons={treeDetail.entityType === "legalEntity"}
//               {...calculateRemainingPercent({
//                 subsidiaries: treeDetail.subsidiaries
//               })}
//             />
//           </div>
//         </div>
//       );
//     })}
//     {showAddShareButtons && (
//       <div>
//         {remainingVotePercent > 0 && (
//           <button
//             type="button"
//             className="dropdown-item dropdown-item--apply"
//             onClick={() => onAdd(nodeId)}
//           >
//             Add remaining {remainingVotePercent} vote %
//           </button>
//         )}
//         {remainingSharePercent > 0 && (
//           <button
//             type="button"
//             className="dropdown-item dropdown-item--apply"
//             onClick={() => onAdd(nodeId)}
//           >
//             Add remaining {remainingSharePercent} share %
//           </button>
//         )}
//       </div>
//     )}
//   </div>
// );
