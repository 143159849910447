import React, { useState, useEffect } from "react";
import { OrgTreeDetails } from "../../../types";
import OrgSubsidiariesTree from "../OrgSubsidiariesTree";
import { calculateRemainingPercent } from "../service";
import Icons from "../../../../presentation/Icons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useTranslation } from "react-i18next";
interface Props {
  treeDetails?: OrgTreeDetails;
  nodeId: string;
  onEdit: ({
    parentNodeId,
    nodeId
  }: {
    parentNodeId: string | null;
    nodeId: string;
  }) => void;
  onAdd: ({
    parentNodeId,
    nodeId
  }: {
    parentNodeId: string | null;
    nodeId: string;
  }) => void;
  isShowUboOnly?: boolean;
  allowEdit: boolean;
}

const OrganizationTree: React.FC<Props> = ({
  nodeId,
  treeDetails,
  onEdit,
  onAdd,
  isShowUboOnly,
  allowEdit
}) => {
  const { t } = useTranslation();
  const [toggle, setToggle] = useState<boolean>(false);
  const [remainingPercent, setRemainingPercent] = useState({
    remainingVotePercent: 0,
    remainingSharePercent: 0
  });
  useEffect(() => {
    treeDetails &&
      setRemainingPercent({
        ...calculateRemainingPercent({
          subsidiaries: treeDetails.subsidiaries
        })
      });
  }, [treeDetails]);
  useEffect(() => {
    if (isShowUboOnly) {
      setToggle(true);
    }
  }, [isShowUboOnly]);
  function getNaturalPersonTemplate(treeDetails: any) {
    return (
      <button className="nav level-one nav-list-in">
        <div className="nav-icon-text">
          <div className="nav-icon">
            {treeDetails.isUBO ? (
              <div className="nav-status-text">UBO</div>
            ) : (
              <Icons icon={"uboUser-16"} />
            )}
          </div>
          <div className="nav-text">
            {treeDetails?.firstName + " " + treeDetails?.lastName}
          </div>
        </div>
        <button
          className="nav-drop-arrow"
          onClick={() => onEdit({ parentNodeId: null, nodeId })}
        >
          <Icons icon={"navArrowRight16"} />
          {/* <img src="images/icons/nav-arrow-right-16.svg" /> */}
        </button>
      </button>
    );
  }
  return (
    <>
      <div className="left-nav-step">
        <div className="accordion">
          {treeDetails &&
          (!treeDetails?.entityType ||
            treeDetails?.entityType === "naturalPerson")
            ? isShowUboOnly
              ? treeDetails.isUBO
                ? getNaturalPersonTemplate(treeDetails)
                : ""
              : getNaturalPersonTemplate(treeDetails)
            : treeDetails?.entityType === "legalEntity" && (
                <div className="accordion-group level-step-one">
                  <div className="level-step-heading">
                    <div className="left-nav-link-wrp">
                      <div
                        onClick={() => {
                          setToggle(!toggle);
                        }}
                        className={`nav ${
                          Number(remainingPercent.remainingSharePercent) > 0 ||
                          Number(remainingPercent.remainingVotePercent) > 0
                            ? "danger-line"
                            : "success-line"
                        } level-one nav-list-in ${toggle ? "collapsed" : ""}`}
                        data-toggle="collapse"
                        aria-expanded={
                          toggle ? (true as boolean) : (false as boolean)
                        }
                        //  href="#level-A"
                      >
                        <span className="nav-icon">
                          <Icons icon={"homeEntity-16"} />
                        </span>
                        <span className="nav-icon-text">
                          {treeDetails?.entityType === "legalEntity" && (
                            <span className="nav-text">
                              {treeDetails?.legalEntityName}
                            </span>
                          )}

                          <span className={`voting-status`}>
                            <div className="count-share">
                              {/* Voting rights:{" "} */}
                              <span className="count-number">
                                {/* {treeDetails?.votingRights}% */}
                              </span>
                            </div>
                            <div className="count-share">
                              {/* Capital share:{" "} */}
                              <span className="count-number">
                                {/* {treeDetails?.shareHolding}% */}
                              </span>
                            </div>
                          </span>
                        </span>
                      </div>
                      {treeDetails.entityType === "legalEntity" &&
                        !(
                          treeDetails.isEntityWithNoUbo ||
                          treeDetails.isFreeFloatShareEntity
                        ) &&
                        (Number(remainingPercent.remainingVotePercent) > 0 ||
                          Number(remainingPercent.remainingSharePercent) >
                            0) && (
                          <>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id="addEntity">
                                  {t("Add entity")}
                                </Tooltip>
                              }
                            >
                              <button
                                className="nav-drop-plus btn btn-primary action"
                                onClick={() => {
                                  onAdd({ parentNodeId: null, nodeId });
                                }}
                                disabled={!allowEdit}
                              >
                                <span data-tip data-for="addEntity">
                                  <Icons icon={"addEntity"} />
                                </span>
                              </button>
                            </OverlayTrigger>
                          </>
                        )}
                      <button
                        className="nav-drop-arrow"
                        onClick={() => {
                          onEdit({ parentNodeId: null, nodeId });
                        }}
                      >
                        <Icons icon={"navArrowRight16"} />
                      </button>
                    </div>
                  </div>
                  <div
                    id="level-parent"
                    className={`collapse in ${toggle ? "show" : ""}`}
                  >
                    {treeDetails && (
                      <OrgSubsidiariesTree
                        nodeId={nodeId}
                        treeDetails={treeDetails.subsidiaries}
                        {...remainingPercent}
                        onEdit={onEdit}
                        onAdd={onAdd}
                        showAddShareButtons={
                          treeDetails.entityType === "legalEntity"
                        }
                        isShowUboOnly={isShowUboOnly}
                        allowEdit={allowEdit}
                      />
                    )}
                  </div>
                </div>
              )}
        </div>
      </div>
      {/* <div className="add-entity-btn">
        <button className="btn btn-primary has-icon">Add entity</button>
      </div> */}
    </>
  );
};

export default OrganizationTree;

// return treeDetails ? (
//   <div style={{ border: "1px solid black" }}>
//     <div onClick={() => onEdit({ parentNodeId: null, nodeId })}>
//       {treeDetails.legalEntityName}
//     </div>
//     {/* <span>{treeDetails.votingRights}%</span>
//     <span>{treeDetails.shareHolding}%</span> */}

//     <div style={{ border: "1px solid black", marginLeft: "10px" }}>
//       <OrgSubsidiariesTree
//         nodeId={nodeId}
//         treeDetails={treeDetails.subsidiaries}
//         {...calculateRemainingPercent({
//           subsidiaries: treeDetails.subsidiaries
//         })}
//         onEdit={onEdit}
//         onAdd={onAdd}
//         showAddShareButtons={treeDetails.entityType === "legalEntity"}
//       />
//     </div>
//   </div>
// ) : (
//   <div>No details</div>
// );
