import React from "react";
import { Switch, Redirect, useRouteMatch } from "react-router-dom";
import ClientQuestionnaireList from "../ClientQuestionnaireList";
import ResponseQuestionnaireList from "../ResponseQuestionnaireList";
import ReceivedAssignmentsQuestionnaireList from "../ReceivedAssigmentsQuestionnaireList";
import Dashboard from "../Dashboard";
import UsersList from "../UsersList";
import GroupsList from "../GroupsList";

import GuardedRoute from "../../../GuardedRoute";
import {
  ADMIN_USER,
  ASSET_MANAGER_USER,
  COUNTERPARTY_USER
} from "../../../lib/questionnairePermissionsAccess";

type Props = {
  getPageHeaderDetails: (pageDetails: any) => void;
  defaultPath: string;
};

const AccountRoutes: React.FC<Props> = ({
  getPageHeaderDetails,
  defaultPath
}) => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <GuardedRoute
        exact
        path={`${path}/responseList`}
        component={ResponseQuestionnaireList}
        compProps={{ getPageHeaderDetails }}
        accessUserTypes={[ADMIN_USER, ASSET_MANAGER_USER]}
      />
      <GuardedRoute
        exact
        path={`${path}/assignmentsList`}
        component={ReceivedAssignmentsQuestionnaireList}
        compProps={{ getPageHeaderDetails }}
        accessUserTypes={[COUNTERPARTY_USER]}
      />
      <GuardedRoute
        exact
        path={`${path}/questionnairesList`}
        component={ClientQuestionnaireList}
        compProps={{ getPageHeaderDetails }}
        accessUserTypes={[ADMIN_USER, ASSET_MANAGER_USER]}
      />
      <GuardedRoute
        exact
        path={`${path}/dashboard`}
        component={Dashboard}
        compProps={{ getPageHeaderDetails }}
        accessUserTypes={[ADMIN_USER, ASSET_MANAGER_USER]}
      />
      <GuardedRoute
        exact
        path={`${path}/userManagement`}
        component={UsersList}
        compProps={{ getPageHeaderDetails }}
        accessUserTypes={[ADMIN_USER, ASSET_MANAGER_USER, COUNTERPARTY_USER]}
      />
      <GuardedRoute
        exact
        path={`${path}/groupManagement`}
        component={GroupsList}
        compProps={{ getPageHeaderDetails }}
        accessUserTypes={[ADMIN_USER, ASSET_MANAGER_USER, COUNTERPARTY_USER]}
      />

      <Redirect from="/accountManagerInfo/" to={defaultPath} />
    </Switch>
  );
};

export default AccountRoutes;
