const BHYKeys: any = {
  module_name: "KYC",
  client_name: "Department",
  client_names: "Departments",
  end_user_name: "Client",
  end_user_names: "Clients",
  send_zeilder_review: "Send for Admin review",
  send_client_review: "Send for Department review",
  "Awaiting client approval": "Awaiting Department approval",
  "Awaiting Zeidler review": "Awaiting Admin review",
  "Awaiting comments review": "Awaiting comments review",
  Approved: "Approved"
};

export default BHYKeys;
