import { OverlayDetails } from "./types";

export const getAppOverlay = (function () {
  let overlayDetails: OverlayDetails;

  return {
    registerOverlay: function (overlayData: OverlayDetails) {
      overlayDetails = overlayData;
    },
    showOverlay: function () {
      overlayDetails && overlayDetails.showOverlay();
    },
    hideOverlay: function () {
      overlayDetails && overlayDetails.hideOverlay();
    },
    toggleOverlay: function () {
      overlayDetails && overlayDetails.toggleOverlay();
    },
    enableOverlay: function () {
      overlayDetails && overlayDetails.enableOverlay();
    },
    disableOverlay: function () {
      overlayDetails && overlayDetails.disableOverlay();
    },
    isOverlayDetailsPresent: function () {
      return overlayDetails ? true : false;
    }
  };
})();
