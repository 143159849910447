import React from "react";
import Modal from "react-bootstrap/esm/Modal";
import { useTranslation } from "react-i18next";

type Props = {
  modalTitle: string;
  message: string;
  callBack: () => void;
  showConfirmationModal: boolean;
  onModalClose: () => void;
  showConfirmButton?: boolean;
};

const ConfirmationModal: React.FC<Props> = ({
  modalTitle,
  message,
  callBack,
  showConfirmationModal,
  onModalClose,
  showConfirmButton = true
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      show={showConfirmationModal}
      onHide={onModalClose}
      className="modal modal--large fade"
      id="cdd-questionnaire-approve-modal--confirm"
      aria-labelledby="cdd-questionnaire-approve-modal--confirm"
      tabIndex={-1}
    >
      <Modal.Header className="modal__header" closeButton>
        <Modal.Title className="modal__title">{t(modalTitle)}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal__body modal__body--comments">
        {t(message)}
      </Modal.Body>
      <Modal.Footer className="modal__footer border-top justify-content-between">
        <button
          type="button"
          className="btn btn-outline-secondary"
          data-dismiss="modal"
          aria-label="Close"
          onClick={onModalClose}
        >
          {t("Cancel")}
        </button>
        {showConfirmButton && (
          <button
            type="button"
            className="btn btn-primary"
            data-dismiss="modal"
            aria-label="Close"
            onClick={callBack}
          >
            {t("Confirm")}
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
