import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback
} from "react";
import SideBarSections from "../../presentation/SideBarSections";
import PageHeader from "../../presentation/PageHeader";
import SectionComponent from "../SectionComponent";
import { useParams } from "react-router-dom";
import {
  Params,
  SavedQuestion,
  Questionnaire,
  SectionLink,
  StatusLevelAccess,
  SectionLocks
} from "./types";
import { Section } from "../SectionComponent/types";
import * as apis from "../../../apis/CounterpartySubmittedQuestionnaire";
import { toast } from "react-toastify";
import * as utils from "../../../lib/utils";
import Icons from "../../presentation/Icons";
import { Question, ValidationError } from "../QuestionComponent/types";
import { getAppLoader as Load } from "../../presentation/Loader/services";
import "./styles.scss";
import ConfirmationModal from "../../presentation/Modals/ConfirmationModal";
import { UserContext } from "../../../Contexts/UserContext";
import {
  allowPublicPrivateComments,
  allowReopen,
  isEditAllowedForQuesStatus
} from "../../../lib/questionnaireStatusAccess";
import PublicPrivateComments from "../PublicPrivateComments";
import { updateCommentInSection } from "../SectionComponent/service";
import {
  subscribeToChanel,
  sendMessageToSocket,
  closeWebSocket
} from "../../../services/webSockets";
import {
  getSectionLockStatus,
  determineEditForLock,
  findSubQuesToRemove
} from "./service";
import { isEqual } from "lodash";
import {
  LOCKED,
  UN_LOCKED,
  SUBMIT,
  PDF_FILE_EXTENSION,
  ZIP_FILE_EXTENSION,
  FILE_TYPE_QUESTIONNAIRE_PDF,
  FILE_TYPE_QUESTIONNAIRE_PDF_IN_ZIP
} from "../../../constants/app-constants";
import { getDate, getTime } from "../../../lib/utils";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { downloadReports } from "../../../services/FileUploadDownloadService";
import {
  sectionAccessPermission,
  COUNTERPARTY_USER,
  findUserPermissionsFromRoles,
  ADMIN_REGENERATE_PDF_PERMISSION,
  permissionError,
  USER_ADMIN,
  ADMIN_QUESTIONNAIRE_SUBMIT_PERMISSION,
  QUESTIONNAIRE_SUBMIT_PERMISSION
} from "../../../lib/questionnairePermissionsAccess";
import NoDataFound from "../../presentation/NoDataFound";
import { useTranslation } from "react-i18next";
import RiskCatOverview from "../RiskCategorizationOverview";
import {
  fetchSectionLocks,
  handleWindowCloseForLocks
} from "../../../services/sectionLock";
import { useInterval } from "../../../Hooks/useInterval";
import { useQueryParams } from "../../../Hooks/useQueryParams";
import { QueryPrameters } from "../../../types/applicationData";
import { enableES5 } from "immer";
import produce from "immer";
enableES5();
let customInterval: any;
const CounterpartySubmittedQuestionnaire: React.FC = () => {
  const queryParams: QueryPrameters | undefined = useQueryParams({
    paramNames: ["sectionId", "questionId", "datasetId"]
  });
  const { t } = useTranslation();
  const [isWSReady, setIsWSReady] = useState<Boolean>(false);
  //Here the counterpartyQuestionnaireId is nothing but accountLevelOneQuestionnaireId
  const {
    accountType,
    counterpartyId,
    accountQuestionnaireId,
    sectionId
  }: Params = useParams<any>();
  const [questionnaire, setQuestionnaire] = useState<Questionnaire | null>(
    null
  );
  const [selectedSection, setSelectedSection] = useState<Section | null>(null);
  const [sidebarCollpased, setSidebarCollapsed] = useState<boolean>(false);
  const [questionsAnswered, setQuestionsAnswered] = useState<SavedQuestion[]>(
    []
  );

  const [completePercentage, setCompletePercentage] = useState<number>(0);
  const [savingStatus, setSavingStatus] = useState<string>();
  const [confirmationBoxData, setConfirmationBoxData] = useState({
    title: "",
    message: "",
    callBack: () => {},
    showConfirmButton: true
  });
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [sectionLevelSubmission, setSectionLevelSubmission] =
    useState<boolean>(false);
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [enableSubmitQuestionnaire, setEnableSubmitQuestionnaire] =
    useState<boolean>();
  const [showPPC, setShowPPC] = useState<Boolean>(false);
  const [isPolling, setIsPolling] = useState<boolean>(false);
  const isFallBackToPolling = useRef<boolean>(false);
  const quesAnsweredRef = useRef(questionsAnswered);
  const selectedSectionRef = useRef(selectedSection);
  quesAnsweredRef.current = questionsAnswered;

  selectedSectionRef.current = selectedSection;

  const { userDetails }: any = useContext(UserContext);
  let userTypeFromPage = utils.getUserTypeFromPage(accountType);
  const [statusLevelAccess, setStatusLevelAccess] =
    useState<StatusLevelAccess>();
  const [client, setClient] = useState<any>();
  const [sectionLocks, setSectionLocks] = useState<SectionLocks[]>();
  //This ref is used because in the setInterval, the latest state is not available
  const sectionLocksRef = useRef<SectionLocks[]>();
  const [currentSectionLockData, setCurrentSectionLockData] =
    useState<SectionLocks>();
  const [refreshPage, setRefreshPage] = useState(0);
  useEffect(() => {
    if (String(queryParams?.sectionId) === String(sectionId)) {
      setShowPPC(true);
    }
  }, [queryParams, sectionId]);

  const [callSaveDraft, setCallSaveDraft ] = useState<boolean>(false)

  useEffect(()=>{
    saveDraft(false)
  },[callSaveDraft])

  useEffect(() => {
    let dummyClient: any;
    let fallBackToPolling = false;
    async function setWebSocketClient() {
      //subscribe to the client
      try {
        dummyClient = await subscribeToChanel(accountQuestionnaireId);
        //put client in state
        setClient(dummyClient);
        isFallBackToPolling.current = fallBackToPolling;
        setIsPolling(fallBackToPolling);
      } catch (error) {
        console.info("Unable to establish websocket connection", error);
        console.info("Falling back to polling");
        fallBackToPolling = true;
        isFallBackToPolling.current = fallBackToPolling;
        setIsPolling(fallBackToPolling);
      }
    }

    function closeWS() {
      closeWebSocket(client || dummyClient);
    }

    function removeAllLocksForPolling() {
      handleWindowCloseForLocks({
        account_questionnaire_id: accountQuestionnaireId
      });
    }

    function handleScroll() {
      let cardElement = document.querySelector(".questionnaire .card") as any;
      if (cardElement?.offsetHeight >= window.innerHeight) {
        requestAnimationFrame(utils.makeNavPanelSticky);
      }
    }

    (async () => {
      setWebSocketClient();
      //initiate the listener to hanlde socket close on window/tab close
      window.addEventListener("beforeunload", closeWS);
      (isFallBackToPolling.current || fallBackToPolling) &&
        window.addEventListener("beforeunload", removeAllLocksForPolling);
      window.addEventListener("online", setWebSocketClient);
      window.addEventListener("offline", closeWS);
      window.addEventListener("scroll", handleScroll);
    })();
    //this gets called when component is unmounted
    return () => {
      (async () => {
        setIsWSReady(false);
        (isFallBackToPolling.current || fallBackToPolling) &&
          removeAllLocksForPolling();
        fallBackToPolling = false;
        isFallBackToPolling.current = fallBackToPolling;
        setIsPolling(fallBackToPolling);
        window.removeEventListener("online", setWebSocketClient);
        window.removeEventListener("offline", closeWS);
        window.removeEventListener("beforeunload", closeWS);
        window.removeEventListener("scroll", handleScroll);
        (isFallBackToPolling.current || fallBackToPolling) &&
          window.removeEventListener("beforeunload", removeAllLocksForPolling);
        //close websocket when component is unmounted
        closeWebSocket(client || dummyClient);
        //clean up the event listener
        clearInterval(customInterval); // clear autosaving form
      })();
    };
  }, []);

  useEffect(() => {
    if (questionnaire && sectionId) {
      let currentSection = questionnaire.sections?.find(
        (val) => val.id === sectionId
      );
      const { editSection, submitSection } = sectionAccessPermission({
        permissions: currentSection?.permissions,
        permissionType: userTypeFromPage
      });
      const allowEdit = isEditAllowedForQuesStatus({
        questionnaireStatus: questionnaire?.status?.name,
        userType: userTypeFromPage,
        sectionStatus: currentSection?.status?.name,
        isSectionLevelSubmission: questionnaire.sectionSubmission
      });
      currentSection &&
        setStatusLevelAccess({
          allowEdit: allowEdit && editSection,
          allowSubmit: allowEdit && submitSection,
          ...allowPublicPrivateComments({
            questionnaireStatus: questionnaire.status?.name,
            userType: userTypeFromPage,
            sectionStatus: currentSection.status?.name,
            isSectionLevelSubmission: questionnaire?.sectionSubmission
          })
        });
    }
  }, [questionnaire, sectionId]);

  let performLockMechanism = (sectionLocks: SectionLocks[]) => {
    sectionLocksRef.current = sectionLocks;
    //set the lock list for all sections in state
    setSectionLocks((prev) => {
      if (!isEqual(prev, sectionLocksRef.current)) {
        return [...sectionLocks];
      } else {
        return prev;
      }
    });
    // setSectionLocks([...sectionLocks]);

    if (sectionId) {
      //find the lock data for current active section
      let currentSecLocData = utils.showLock({
        sectionId: sectionId,
        lockData: sectionLocks
      });
      if (currentSecLocData) {
        //set current active section in state
        setCurrentSectionLockData((prev) => {
          if (!isEqual(prev, currentSecLocData)) {
            return { ...currentSecLocData };
          } else {
            return prev;
          }
        });
        // setCurrentSectionLockData({ ...currentSecLocData });
        //If the current active section is broadcasted as unlocked
        //from server then gain the lock on that section as it is active section
        if (currentSecLocData.status === UN_LOCKED) {
          client &&
            sendMessageToSocket({
              accountQuestionnaireId,
              client,
              data: {
                account_questionnaire_id: accountQuestionnaireId,
                section_locks: [
                  {
                    section_id: currentSecLocData.section_id,
                    status: LOCKED
                  }
                ]
              }
            });

          // isFallBackToPolling &&
          //   setSectionLocks([
          //     {
          //       section_id: currentSecLocData.section_id,
          //       status: LOCKED
          //     }
          //   ]);
        }
      }
    }
  };
  //funtion to handle data which is received from websocket broadcast
  let onWebSocketDataReceive = (message: any) => {
    let receivedData = JSON.parse(message.data);
    if (receivedData.type === "confirm_subscription") {
      setIsWSReady(true);
    }
    if (receivedData.type === "disconnect") {
      let fallBackToPolling = true;
      isFallBackToPolling.current = fallBackToPolling;
      setIsPolling(fallBackToPolling);
    }
    //check if broadcasted data matches with the open questionnaire
    if (
      isWSReady &&
      receivedData?.message?.lock_data?.account_questionnaire_id ===
        accountQuestionnaireId
    ) {
      performLockMechanism(receivedData.message.lock_data.section_locks);
    }
  };
  const enablePolling = useCallback(async () => {
    if (isFallBackToPolling.current && sectionLocksRef.current) {
      let data = await fetchSectionLocks({
        account_questionnaire_id: accountQuestionnaireId,
        section_locks: sectionLocksRef.current,
        current_section_id: sectionId
      });
      if (data?.data?.data?.section_locks) {
        performLockMechanism(data.data.data.section_locks);
      } else {
        setSectionLocks([...sectionLocksRef.current]);
      }
    }
  }, [sectionId]);
  useInterval({
    callBack: enablePolling,
    delay: isFallBackToPolling.current ? 5000 : null
  });
  function isQuestionnaireEditAllowed() {
    let isEditAllowed = false;
    if (questionnaire) {
      let currentSectionStatus = questionnaire.sections?.find(
        (val) => val.id === sectionId
      );
      isEditAllowed = currentSectionStatus?.status?.name
        ? isEditAllowedForQuesStatus({
            questionnaireStatus: questionnaire.status?.name.toLowerCase(),
            userType: userTypeFromPage,
            sectionStatus: currentSectionStatus.status?.name.toLowerCase(),
            isSectionLevelSubmission: questionnaire.sectionSubmission
          }) &&
          sectionAccessPermission({
            permissions: currentSectionStatus.permissions,
            permissionType: userTypeFromPage
          }).editSection
        : false;
    }
    return isEditAllowed;
  }
  useEffect(() => {
    if (sectionId) {
      if (client) {
        //initiate the recieve broadcast message callback
        client.onmessage = onWebSocketDataReceive;
      }

      if (questionnaire && questionnaire.sections?.length) {
        if (isQuestionnaireEditAllowed()) {
          //send the message to server web socket to gain the lock on
          //current section and remove the lock form all other sections
          client &&
            isWSReady &&
            sendMessageToSocket({
              accountQuestionnaireId,
              client,
              data: {
                account_questionnaire_id: accountQuestionnaireId,
                section_locks: getSectionLockStatus({
                  currentLockedSectionId: sectionId,
                  sectionList: questionnaire.sections
                })
              }
            });
        }
      }
    }
  }, [client, sectionId, questionnaire, isWSReady]);
  useEffect(() => {
    if (
      isQuestionnaireEditAllowed() &&
      isFallBackToPolling.current &&
      questionnaire
    ) {
      let lockedSections = getSectionLockStatus({
        currentLockedSectionId: sectionId,
        sectionList: questionnaire.sections
      });
      sectionLocksRef.current = lockedSections;
      enablePolling();
    }
  }, [isPolling, questionnaire, sectionId]);

  function toggleShowErrors(flag: boolean) {
    setShowErrors(flag);
  }

  function onFieldValChange({
    ques,
    answer,
    additionalInfo,
    updatedSection
  }: {
    ques: Question;
    answer: any;
    additionalInfo?: any;
    updatedSection: Section;
  }) {
    if (additionalInfo?.completePercentage) {
      setCompletePercentage(additionalInfo?.completePercentage);
    }

    if (answer == null) return false;
    let quesObj: SavedQuestion = {
      questionId: ques.id,
      answer: answer,
      questionType: ques.fieldType.name,
      questionBankId: ques.questionBankId,
      answerId: ques.answerDetails.id
    };

    let arr = [...questionsAnswered];
    const index = questionsAnswered.findIndex(
      (val: SavedQuestion) => val.questionId === ques.id
    );

    if (index === -1) {
      arr.push(quesObj);
    } else {
      arr[index] = quesObj;
    }

    setQuestionsAnswered(arr);

    // Table resets other field to the initial state
    if (ques.fieldType.name !== "table") {
      setSelectedSection({ ...updatedSection });
    }
  }
  
  function saveDraft(manual: boolean) {
    if (selectedSectionRef.current) {
      if (manual && quesAnsweredRef.current.length === 0) {
        toast.success(t("Answers for current section are already saved"));
      }
      if (quesAnsweredRef.current && quesAnsweredRef.current.length) {
        setSavingStatus("saving");
        const savingQuestionsAnsweredList = [...quesAnsweredRef.current];
        apis
          .saveAnswers({
            sectionId: selectedSectionRef.current.id,
            accountQuestionnaireId: accountQuestionnaireId,
            questionAnswers: savingQuestionsAnsweredList
          })
          .then((res) => {
            if(res?.data.saveAnswers.errors.length > 0) {
              res?.data.saveAnswers.errors.forEach((err:any) => {
                if (
                  !errors?.find((ele: any) => ele.questionId === err.questionId)
                ) {
                  setErrors((prev: any) => {
                    console.log('err',err)
                    return prev ? [...prev, err] : [err];
                  });
                }
              })
            } else {
              savingQuestionsAnsweredList.forEach((err:any)=>{
                if(errors.find((ele:any)=> ele.questionId === err.questionId)) {
                  setErrors((prev: any) => {
                    return prev?.filter((error: any)=> err.questionId !== error.questionId)
                  });                  
                }
              })
            }
            //This is done because, submit questionnaire cannot be enabled if autosave call is made
            setEnableSubmitQuestionnaire(false);
            //This is done to set the correct status of the current section
            if (
              res?.data?.sectionStatus !== selectedSectionRef.current?.status
            ) {
              setSelectedSection(
                produce((draftState) => {
                  if (draftState && res?.data?.saveAnswers?.sectionStatus)
                    draftState.status = res.data.saveAnswers.sectionStatus;
                })
              );
            }
            //this is done to reflect the correct status in the left side sections list
            setQuestionnaire(
              produce((draft) => {
                if (draft) {
                  for (let section of draft?.sections) {
                    if (section.id === selectedSectionRef?.current?.id) {
                      section.status = res?.data?.saveAnswers?.sectionStatus;
                      return;
                    }
                  }
                }
              })
            );
            setSavingStatus("saved");
            setCompletePercentage(res.data?.saveAnswers.completePercentage);
            // Using Immer library for mutating state. Produce returns new state
            setSelectedSection(
              produce((draftState) => {
                findSubQuesToRemove(draftState, quesAnsweredRef.current);
              })
            );
            removeSavedAnswerPostAutoSave(savingQuestionsAnsweredList);
            manual && toast.success(res.data?.saveAnswers.message);
          })
          .catch((error) => {
            setSavingStatus(t("error"));
          })
          .finally(() => {
            Load.enableLoader();
          });
      }
    }
  }

  function removeSavedAnswerPostAutoSave(
    savingQuestionsAnsweredList: SavedQuestion[]
  ) {
    let currentQuestionsAnsweredList = [...quesAnsweredRef.current];
    savingQuestionsAnsweredList?.forEach(function (item: SavedQuestion) {
      var itemIndex = currentQuestionsAnsweredList?.findIndex(
        (ques: SavedQuestion) =>
          ques.questionId === item.questionId && ques.answer === item.answer
      );
      if (itemIndex > -1) currentQuestionsAnsweredList.splice(itemIndex, 1);
    });

    setQuestionsAnswered(currentQuestionsAnsweredList);
  }

  function onCompleteBtnClick() {
    setConfirmationBoxData(
      Object.assign(
        {},
        {
          title: "Confirm submission",
          message: "Do you want to submit your answers?",
          callBack: () => {
            setShowConfirmationModal(false);
            saveAndCompleteSection();
          },
          showConfirmButton: true
        }
      )
    );
    setShowConfirmationModal(true);
  }

  function saveAndCompleteSection() {
    if (selectedSectionRef.current) {
      let currentSectionId = selectedSectionRef.current.id;
      if (quesAnsweredRef.current?.length) {
        apis
          .saveAnswers({
            sectionId: currentSectionId,
            accountQuestionnaireId: accountQuestionnaireId,
            questionAnswers: quesAnsweredRef.current
          })
          .then((res) => {
            setQuestionsAnswered([]);
            setCompletePercentage(res.data?.saveAnswers.completePercentage);
            completeSubmission(currentSectionId);
          })
          .catch((error) => {
            toast.error(utils.formatGraphQLError(error.message));
            setSavingStatus("");
          });
      } else {
        completeSubmission(currentSectionId);
      }
    }
  }

  function completeSubmission(currentSectionId: string | number) {
    if (currentSectionId) {
      apis
        .completeSubmission({
          sectionId: currentSectionId,
          accountQuestionnaireId: accountQuestionnaireId
        })
        .then((res: any) => {
          let errors = res?.data.completeSubmission.errors;
          setErrors(errors);
          if (errors && errors.length) {
            setShowErrors(true);
          } else {
            toast.success(t("Section completed successfully"));
            setEnableSubmitQuestionnaire(
              res?.data.completeSubmission.enableSubmitQuestionnaire
            );

            let submittedSections =
              res?.data.completeSubmission.submittedSections;
            if (
              questionnaire &&
              submittedSections &&
              submittedSections.length
            ) {
              let tempQuestionnaire = { ...questionnaire };
              let tempSections = tempQuestionnaire.sections.map((section) => {
                let status = submittedSections.filter(
                  (submittedSection: any) => {
                    if (
                      selectedSection &&
                      selectedSection.id === currentSectionId &&
                      submittedSection.sectionId === selectedSection.id
                    ) {
                      setSelectedSection({
                        ...selectedSection,
                        status: submittedSection.status
                      });
                    }
                    return submittedSection.sectionId === section.id;
                  }
                )[0]?.status;

                return {
                  ...section,
                  status: status || section.status
                };
              });
              tempQuestionnaire.sections = [...tempSections];
              setQuestionnaire({ ...tempQuestionnaire });
              if (res?.data.completeSubmission.enableSubmitQuestionnaire) {
                submitQuestionnaire(tempQuestionnaire);
              } else {
                //This is done to resolve the commenting feature which is tied with the answer id
                setRefreshPage(refreshPage + 1);
              }
            }
          }
        })
        .catch((error: any) => {
          toast.error(utils.formatGraphQLError(error.message));
          setErrors([]);
          setShowErrors(false);
        });
    }
  }
  function submitQuestionnaire(inputQuestionnaire: Questionnaire) {
    setConfirmationBoxData({
      title: "Confirm submission",
      message:
        "All the sections are completed. Do you want to submit the questionnaire?",
      showConfirmButton: true,
      callBack: () => {
        if (
          findUserPermissionsFromRoles([
            { name: USER_ADMIN },
            { name: ADMIN_QUESTIONNAIRE_SUBMIT_PERMISSION }
          ]) ||
          findUserPermissionsFromRoles(
            [{ name: QUESTIONNAIRE_SUBMIT_PERMISSION }],
            selectedSection?.permissions
          )
        ) {
          setShowConfirmationModal(false);
          apis
            .submitQuestionnaire({
              accountQuestionnaireId: accountQuestionnaireId
            })
            .then((res: any) => {
              // setEnableSubmitQuestionnaire(false);
              toast.success(res.data?.submitQuestionnaire.message);
              setRefreshPage(refreshPage + 1);
              // inputQuestionnaire &&
              //   setQuestionnaire({
              //     ...inputQuestionnaire,
              //     status: res.data?.submitQuestionnaire?.questionnaireStatus
              //   });
              // if (statusLevelAccess) {
              //   setStatusLevelAccess({
              //     ...statusLevelAccess,
              //     allowEdit: false
              //   });
              // }
            })
            .catch((error: any) => {
              toast.error(utils.formatGraphQLError(error.message));
            });
        } else {
          permissionError();
        }
      }
    });
    setShowConfirmationModal(true);
  }
  function autoSave() {
    clearInterval(customInterval); // clear autosaving form
    setSavingStatus("");
    if (selectedSectionRef.current) {
      customInterval = setInterval(() => {
        if (quesAnsweredRef.current && quesAnsweredRef.current.length) {
          Load.disableLoader();
          // saveDraft(false);
          setCallSaveDraft((prev: any)=> {
            return !prev
          })
        }
        
      }, 5000);
    }
  }

  function autofillSection() {
    let hasPermission = sectionAccessPermission({
      permissions: selectedSection?.permissions,
      permissionType: userTypeFromPage
    }).editSection;

    if (hasPermission) {
      apis
        .autofillSection({
          accountQuestionnaireId: accountQuestionnaireId,
          sectionId: sectionId
        })
        .then((res: any) => {
          toast.success(t(res.data?.savePrefillData.message));
          setRefreshPage(refreshPage + 1);
        })
        .catch((error: any) => {
          toast.error(utils.formatGraphQLError(error.message));
        });
    } else {
      permissionError();
    }
  }

  function getQuestionnaireDetails() {
    apis
      .getQuestionnaireAnswers(accountQuestionnaireId, sectionId)
      .then((response: any) => {
        if (
          response?.data?.getQuestionnaireAnswers &&
          response?.data?.getQuestionnaireAnswers.sections
        ) {
          setQuestionnaire(
            Object.assign({}, response?.data?.getQuestionnaireAnswers)
          );
          //set section if counterpartyQuestionnaireSectionId is present in starting

          const selectedSec =
            response?.data?.getQuestionnaireAnswers?.sectionDetail;

          if (selectedSec) {
            setSelectedSection(selectedSec);
            setSavingStatus("");
            setCompletePercentage(
              response.data?.getQuestionnaireAnswers.completePercentage
            );
            setSectionLevelSubmission(
              response?.data?.getQuestionnaireAnswers.sectionSubmission
            );
            setEnableSubmitQuestionnaire(
              response?.data?.getQuestionnaireAnswers.enableSubmitQuestionnaire
            );
            if (selectedSectionRef.current) autoSave();
            utils.resetStickyNavBar();
          }
        }
      })
      .catch((error) => {
        toast.error(utils.formatGraphQLError(error.message));
        setQuestionnaire(null);
        utils.resetStickyNavBar();
      });
  }

  function regenerateQuestionnairePDF() {
    apis
      .regenerateQuestionnairePDF({
        accountQuestionnaireId
      })
      .then((response: any) => {
        toast.success(response.data?.reGenerateQuestionnairePdf.message);
        setShowConfirmationModal(false);
      })
      .catch((e: any) => {
        toast.error(utils.formatGraphQLError(e.message));
      });
    setShowConfirmationModal(false);
  }

  function reopenQuestionnaire() {
    apis
      .requestReopen({
        sectionId: null,
        accountQuestionnaireId
      })
      .then(() => {
        toast.success(t("Request sent successfully"));
        setShowConfirmationModal(false);
        setRefreshPage(refreshPage + 1);
      })
      .catch((e) => {
        toast.error(utils.formatGraphQLError(e.message));
      });
    setShowConfirmationModal(false);
  }

  function reopenSection() {
    apis
      .requestReopen({
        sectionId: sectionId,
        accountQuestionnaireId
      })
      .then(() => {
        toast.success(t("Request sent successfully"));
        setShowConfirmationModal(false);
        setRefreshPage(refreshPage + 1);
      })
      .catch((e) => {
        toast.error(utils.formatGraphQLError(e.message));
      });
  }

  useEffect(() => {
    setQuestionnaire(null);
    setSelectedSection(null);
    setQuestionsAnswered([]);
    getQuestionnaireDetails();

    return () => {
      if (
        selectedSectionRef.current &&
        quesAnsweredRef.current &&
        quesAnsweredRef.current.length
      ) {
        Load.disableLoader();
        saveDraft(false);
      }
    };
  }, [sectionId, refreshPage]);

  return questionnaire && statusLevelAccess ? (
    <React.Fragment>
      <PageHeader
        title={`${questionnaire.title}`}
        accountLogo={questionnaire.accountLogo}
        backLinkLabel={
          utils.getUserTypeFromPage(accountType) === COUNTERPARTY_USER
            ? t("Received Assignments List")
            : t("Responses List")
        }
        backLink={`/accountManagerInfo/${counterpartyId}/${
          utils.getUserTypeFromPage(accountType) === COUNTERPARTY_USER
            ? "assignmentsList"
            : "responseList"
        }`}
        details={[
          `${t("Request from")}: ${questionnaire.requestFrom} | ${t(
            "Date started"
          )}: ${questionnaire.dateStarted || "-"}`
        ]}
        headerLeftMenuTriggerButton={
          <span className="ml-3">
            <Icons className="small-icon" icon="circleMenuIcon" />
          </span>
        }
        headerLeftMenu={
          <>
            {allowReopen({
              userType: userTypeFromPage,
              status: questionnaire.status?.name
            }) &&
              !questionnaire.sectionSubmission && (
                <span
                  className="dropdown-item flex-center py-3 px-4"
                  onClick={() => {
                    setShowConfirmationModal(true);
                    setConfirmationBoxData({
                      title: questionnaire.reopenRequest?.requestedAt
                        ? t("Request to reopen the questionnaire has been sent")
                        : t("Confirm request to reopen"),
                      message: questionnaire.reopenRequest?.requestedAt
                        ? `${
                            questionnaire.reopenRequest?.requestedBy
                          } has requested to reopen the questionnaire at ${getDate(
                            questionnaire.reopenRequest?.requestedAt,
                            "MMMM Do YYYY, h:mm:ss a"
                          )}`
                        : t(
                            "Are you sure you want to send the request to reopen the questionnaire"
                          ),
                      showConfirmButton: questionnaire.reopenRequest
                        ?.requestedAt
                        ? false
                        : true,
                      callBack: reopenQuestionnaire
                    });
                  }}
                >
                  <Icons className="mr-3 small-icon" icon="arrow-right-16" />
                  {questionnaire.reopenRequest?.requestedAt
                    ? t("Request to reopen the questionnaire has been sent")
                    : t("Confirm request to reopen")}
                </span>
              )}
            <span
              onClick={async () => {
                if (questionnaire.questionnairePdfError) {
                  toast.error(questionnaire.questionnairePdfError);
                } else {
                  downloadReports({
                    accountQuestionnaireId: accountQuestionnaireId,
                    fileName: questionnaire.title,
                    fileType: FILE_TYPE_QUESTIONNAIRE_PDF,
                    fileExtension: PDF_FILE_EXTENSION
                  });
                }
              }}
              className="dropdown-item flex-center py-3 px-4"
            >
              <Icons className="mr-3 small-icon" icon="darkDownloadIcon" />
              {t("Questionnaire PDF")}
            </span>
            <span
              className="dropdown-item flex-center py-3 px-4"
              onClick={async () => {
                if (questionnaire.questionnairePdfError) {
                  toast.error(questionnaire.questionnairePdfError);
                } else {
                  downloadReports({
                    accountQuestionnaireId: accountQuestionnaireId,
                    fileName: questionnaire.title,
                    fileType: FILE_TYPE_QUESTIONNAIRE_PDF_IN_ZIP,
                    fileExtension: ZIP_FILE_EXTENSION
                  });
                }
              }}
            >
              <Icons className="mr-3 small-icon" icon="darkDownloadIcon" />
              {t("All Documents Zip")}
            </span>
            {findUserPermissionsFromRoles([
              { name: ADMIN_REGENERATE_PDF_PERMISSION }
            ]) && (
              <span
                className="dropdown-item flex-center py-3 px-4"
                onClick={() => {
                  setShowConfirmationModal(true);
                  setConfirmationBoxData({
                    title: "Regenerate Questionnaire PDF",
                    message: "Do you want to regenerate the questionnaire PDF?",
                    showConfirmButton: true,
                    callBack: regenerateQuestionnairePDF
                  });
                }}
              >
                <Icons className="mr-3 small-icon" icon="regeneratePdf" />
                {t("Regenerate Questionnaire PDF")}
              </span>
            )}
          </>
        }
      >
        <div className="row cdd-auto-feature">
          <div className="col-12 page-header__progress">
            <span className="text--muted d-block mb-2">
              {completePercentage || 0}% {t("complete")}
            </span>
            <div className="saving-loader-wrp">
              {savingStatus === "saving" && (
                <div className="saving-loading">
                  <Icons className="text-primary" icon="saving" /> {t("Saving")}
                </div>
              )}
              {savingStatus === "saved" && (
                <div className="saving-form">{t("Saved")}</div>
              )}
              {savingStatus === "error" && (
                <div className="saving-form error-saving">
                  {t("Error in saving answers")}
                </div>
              )}
            </div>
          </div>
        </div>
      </PageHeader>
      <div
        className={`page-content cdd-auto-feature editable ${
          sidebarCollpased ? "hide-questionnaire-sidebar" : ""
        }`}
      >
        <div className="container">
          <div className="row">
            <div
              className={`questionnaire-sidebar d-block col-lg-3 ${
                sectionLevelSubmission
                  ? "section-level-submission"
                  : "questionnaire-level-submission"
              }`}
            >
              <nav className="side-nav">
                {!sectionLevelSubmission && (
                  <>
                    <button
                      id="submit_questionnaire"
                      className={`btn btn-full btn-primary mb-2 ${
                        enableSubmitQuestionnaire ? "" : "disabled"
                      }`}
                      type="button"
                      onClick={() => {
                        enableSubmitQuestionnaire &&
                          submitQuestionnaire(questionnaire);
                      }}
                    >
                      {t("Submit Questionnaire")}
                    </button>
                    <div className="text-muted text-12 mb-4">
                      {t(
                        "To submit the questionnaire, first complete each section and submit the answers."
                      )}
                    </div>
                  </>
                )}
                <SideBarSections
                  sections={questionnaire.sections}
                  sectionLocks={sectionLocks}
                  sidebarCollapse={() => setSidebarCollapsed(!sidebarCollpased)}
                  allowAnswerSubmit={statusLevelAccess?.allowEdit}
                  sidebarType={SUBMIT}
                  userTypeFromPage={userTypeFromPage}
                  validationErrors={errors}
                  showErrors={showErrors}
                  accountQuestionnaireId={accountQuestionnaireId}
                  accountId={counterpartyId}
                  sectionId={sectionId}
                  toggleShowErrors={toggleShowErrors}
                />
              </nav>
            </div>
            {selectedSection ? (
              <>
                <article className="questionnaire col-lg-9">
                  <div
                    className="show-qu-icon"
                    onClick={() => setSidebarCollapsed(!sidebarCollpased)}
                  >
                    <Icons icon="expand-sidebar" />
                  </div>
                  <div className="card">
                    <div className="questionnaire__header">
                      <h2 className="h2">
                        {selectedSection.title}
                        {selectedSection.internalUseOnly && (
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip
                                id={`internal-use-${selectedSection.id}`}
                              >
                                {t("For internal use")}
                              </Tooltip>
                            }
                          >
                            <>
                              <span style={{ marginLeft: "10px" }}>
                                <Icons
                                  className="text-primary"
                                  icon="privateInternalShield"
                                />
                              </span>
                            </>
                          </OverlayTrigger>

                          // <span className="badge que-badge">
                          //   For internal use
                          // </span>
                        )}
                      </h2>
                      {selectedSection.description && (
                        <div
                          className="text--muted custom_edit_text"
                          dangerouslySetInnerHTML={{
                            __html: selectedSection.description
                          }}
                        ></div>
                      )}
                      <div className="questionnaire__actions action-group">
                        {isEditAllowedForQuesStatus({
                          questionnaireStatus: questionnaire.status?.name,
                          userType: userTypeFromPage,
                          sectionStatus: selectedSection.status?.name,
                          isSectionLevelSubmission:
                            questionnaire?.sectionSubmission
                        }) &&
                          statusLevelAccess?.allowEdit &&
                          currentSectionLockData &&
                          determineEditForLock({
                            lockData: currentSectionLockData,
                            loggedInUserId: userDetails.user_id
                          }) &&
                          selectedSection.autofill && (
                            <button
                              id="autofill"
                              className="btn btn-outline-secondary mr-2"
                              type="button"
                              onClick={autofillSection}
                            >
                              {t("Autofill Section")}
                            </button>
                          )}
                        {allowReopen({
                          userType: userTypeFromPage,
                          status: selectedSection.status?.name
                        }) &&
                          questionnaire.sectionSubmission && (
                            <OverlayTrigger
                              placement="top"
                              rootClose
                              overlay={
                                <Tooltip id={`reopen-${selectedSection.id}`}>
                                  {t("Request to reopen the section")}
                                </Tooltip>
                              }
                            >
                              <span
                                className="action"
                                onClick={() => {
                                  setShowConfirmationModal(true);
                                  setConfirmationBoxData({
                                    title: selectedSection.reopenRequest
                                      ?.requestedAt
                                      ? t(
                                          "Request to reopen the section has been sent"
                                        )
                                      : t("Confirm request to reopen"),
                                    message: selectedSection.reopenRequest
                                      ?.requestedAt
                                      ? t(
                                          "dynamicStringTranslation.sectionReopenRequest",
                                          {
                                            username:
                                              selectedSection.reopenRequest
                                                ?.requestedBy,
                                            datetimestamp: getDate(
                                              selectedSection.reopenRequest
                                                ?.requestedAt,
                                              "MMMM Do YYYY, h:mm:ss a"
                                            )
                                          }
                                        )
                                      : t(
                                          "Are you sure you want to send the request to reopen the section ?"
                                        ),
                                    callBack: reopenSection,
                                    showConfirmButton: selectedSection
                                      .reopenRequest?.requestedAt
                                      ? false
                                      : true
                                  });
                                }}
                              >
                                <Icons className="icon" icon="activeDocument" />
                              </span>
                            </OverlayTrigger>
                          )}

                        {(statusLevelAccess?.showPrivateComment ||
                          statusLevelAccess?.showPublicComment) && (
                          <span
                            className="action"
                            style={{ display: "inline" }}
                            data-placement="bottom"
                            title={
                              selectedSection?.publicPrivateComments &&
                              selectedSection.publicPrivateComments.length > 0
                                ? t("View comments")
                                : t("Add comments for questions.")
                            }
                            onClick={() => setShowPPC(true)}
                          >
                            <Icons
                              className={`icon ${
                                selectedSection?.publicPrivateComments &&
                                selectedSection.publicPrivateComments.length > 0
                                  ? "text-primary"
                                  : ""
                              }`}
                              icon={
                                selectedSection?.publicPrivateComments &&
                                selectedSection.publicPrivateComments.length > 0
                                  ? "comment"
                                  : "add-comment"
                              }
                            />
                          </span>
                        )}
                      </div>
                    </div>

                    {statusLevelAccess?.allowEdit && currentSectionLockData ? (
                      String(currentSectionLockData.locked_by_id) ===
                      String(userDetails.user_id) ? (
                        <div className="locked-Error-Msg success-msg">
                          <Icons icon="unlocked" />
                          {t("dynamicStringTranslation.sectionLockBySelf")}
                        </div>
                      ) : (
                        <div className="locked-Error-Msg">
                          <Icons icon="locked" />
                          {t(
                            `This section is locked for editing by {{username}} at {{date}} {{time}}, contact {{username}} to edit this section.`,
                            {
                              username: currentSectionLockData.locked_by,
                              time: getTime(
                                currentSectionLockData?.locked_at || ""
                              ),
                              date: getDate(
                                currentSectionLockData.locked_at || ""
                              )
                            }
                          )}
                        </div>
                      )
                    ) : (
                      ""
                    )}
                    <div className="questionnaire__body">
                      <div className="form response">
                        <React.Fragment>
                          {selectedSection.isRiskSection &&
                            selectedSection.riskCatOverview && (
                              <RiskCatOverview
                                overallQuestionnaireRiskLevel={
                                  selectedSection.questionnaireRiskLevel
                                }
                                riskCategorizationOverview={
                                  selectedSection.riskCatOverview
                                }
                                allowAnswerSubmit={true}
                                onPageRefresh={() => {
                                  setRefreshPage(refreshPage + 1);
                                }}
                              />
                            )}
                          <SectionComponent
                            queryParams={queryParams}
                            itemId={selectedSection.position}
                            section={selectedSection}
                            currentUserId={userDetails.user_id}
                            isParent={true}
                            accountQuestionnaireId={accountQuestionnaireId}
                            showPublicComment={
                              statusLevelAccess?.showPublicComment
                            }
                            showPrivateComment={
                              statusLevelAccess?.showPrivateComment
                            }
                            editPublicComment={
                              statusLevelAccess?.editPublicComment
                            }
                            editPrivateComment={
                              statusLevelAccess?.editPrivateComment
                            }
                            updateParent={(data) =>
                              setSelectedSection({ ...data })
                            }
                            showAnswers={true}
                            sectionViewType={SUBMIT}
                            allowAnswerSubmit={
                              statusLevelAccess?.allowEdit &&
                              currentSectionLockData &&
                              determineEditForLock({
                                lockData: currentSectionLockData,
                                loggedInUserId: userDetails.user_id
                              })
                            }
                            hiddenSections={questionnaire.sections.filter(
                              (section: SectionLink) => !section.visibility
                            )}
                            validationErrors={
                              errors &&
                              errors.filter(
                                (error) =>
                                  error.sectionId === selectedSection.id
                              )
                            }
                            showErrors={showErrors}
                            onFieldValChange={onFieldValChange}
                            fileParams={{
                              accountLevelOneQuestionnaireId:
                                accountQuestionnaireId,
                              permissions: selectedSection?.permissions
                            }}
                          />
                        </React.Fragment>
                      </div>
                      {statusLevelAccess?.allowSubmit &&
                        currentSectionLockData &&
                        determineEditForLock({
                          lockData: currentSectionLockData,
                          loggedInUserId: userDetails.user_id
                        }) && (
                          <div className="footer-buttons">
                            <button
                              id="save-draft"
                              className="btn btn-outline-primary"
                              type="button"
                              onClick={() => saveDraft(true)}
                            >
                              {t("Save As Draft")}
                            </button>

                            <button
                              id="complete"
                              className="btn btn-primary"
                              type="button"
                              onClick={() => onCompleteBtnClick()}
                            >
                              {t("Submit Section")}
                            </button>
                          </div>
                        )}
                    </div>
                  </div>
                </article>
                {/* Modal for confirmation box start */}
                <ConfirmationModal
                  modalTitle={confirmationBoxData.title}
                  message={confirmationBoxData.message}
                  callBack={confirmationBoxData.callBack}
                  showConfirmButton={confirmationBoxData.showConfirmButton}
                  showConfirmationModal={showConfirmationModal}
                  onModalClose={() => {
                    setShowConfirmationModal(false);
                  }}
                />
              </>
            ) : (
              <NoDataFound message="Please select a section" />
            )}
          </div>
        </div>
      </div>
      {showPPC && selectedSection && (
        <PublicPrivateComments
          commentDetails={selectedSection.publicPrivateComments}
          accountQuestionnaireId={accountQuestionnaireId}
          commentableId={selectedSection.id}
          commentableType="Section"
          allowComments={{
            showPublicComment: statusLevelAccess?.showPublicComment,
            showPrivateComment: statusLevelAccess?.showPrivateComment,
            editPublicComment: statusLevelAccess?.editPublicComment,
            editPrivateComment: statusLevelAccess?.editPrivateComment
          }}
          onClose={(comments) => {
            setShowPPC(false);
            (statusLevelAccess?.editPublicComment ||
              statusLevelAccess?.editPrivateComment) &&
              setSelectedSection({
                ...updateCommentInSection(selectedSection, comments)
              });
          }}
          currentUserId={userDetails.user_id}
        />
      )}
      {/* {showWSModal && (
        <CustomModal
          classNames="modal__body p-0"
          showModal={showWSModal}
          onClose={() => {
            setShowWSModal(false);
          }}
          showFooter={true}
          footer={
            <div className="centered-buttons">
              <button
                className="btn btn-outline-primary"
                onClick={(e) => {
                  setShowWSModal(false);
                }}
              >
                {t("OK")}
              </button>
            </div>
          }
        >
          <p>
            Please note that your firewall settings are blocking the connection
            established between the WebSocket on the Zeidler online portal and
            the Zeidler server.
          </p>
          <p>
            Please contact dd@zeidlerduediligence.com to help you further in
            completing the due diligence request.
          </p>
        </CustomModal>
      )} */}
    </React.Fragment>
  ) : (
    <></>
  );
};
export default CounterpartySubmittedQuestionnaire;
