// Polyfills start
import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js/features/array/find";
import "core-js/features/array/includes";
import "core-js/features/number/is-nan";
import "core-js/features/promise";
import "svg-classlist-polyfill";
import "url-search-params-polyfill";
//Polyfills end

import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./assets/css/styles.css";
import "./App.scss";

// Internationalization i18n
import "./i18n";
import UserService from "./services/userServices"

const renderApp = () => ReactDOM.render(
  <Suspense fallback={""}>
    <App />
  </Suspense>,
  document.getElementById("root")
);

UserService.initKeyCloak(renderApp)
