import client from "../apollo.config";
import * as queries from "./queries";
import {
  PageDetailsState,
  TUser
} from "../../components/container/UsersList/types";

export const getUsersList = ({
  pageNumber,
  pageSize,
  accountId,
  search,
  sort,
  filters
}: PageDetailsState) => {
  return client.query({
    query: queries.GET_USERS_LIST_QUERY,
    fetchPolicy: "network-only",
    variables: { pageNumber, pageSize, accountId, search, sort, filters }
  });
};

export const createUser = (input: TUser | any) => {
  return client.mutate({
    mutation: queries.CREATE_USER_QUERY,
    variables: { input }
  });
};

export const getUserRoles = (roleType: string[]) => {
  return client.query({
    query: queries.GET_USER_ROLES_QUERY,
    fetchPolicy: "network-only",
    variables: { roleType }
  });
};

export const inviteUser = (input: {
  userId: string | number;
  accountId: string | number;
}) => {
  return client.mutate({
    mutation: queries.INVITE_USER_QUERY,
    variables: { input }
  });
};

export const deleteUser = (input: {
  userId: string | number;
  accountId: string | number;
}) => {
  return client.mutate({
    mutation: queries.DELETE_USER_QUERY,
    variables: { input }
  });
};

export const editUser = (input: TUser | any) => {
  return client.mutate({
    mutation: queries.EDIT_USER_QUERY,
    variables: { input }
  });
};
