import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Icons from "../../../presentation/Icons";
import Select from "react-select";
import Tooltip from "react-bootstrap/Tooltip";
import DoughnutChart from "../../../presentation/DoughnutChart";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { DoughnutChartState, Params, SelectedQuestionnaire } from "../types";
import * as apis from "../../../../apis/Dashboard";
import { toast } from "react-toastify";
import { formatGraphQLError } from "../../../../lib/utils";
import { getChartDataset, getResponseStatusCount } from "./service";
import { useTranslation } from "react-i18next";

type Props = {
  questionnairesList: any;
};

const SubmittedQuestionnaireCountByStatusesGraph: React.FC<Props> = ({
  questionnairesList
}) => {
  const { t } = useTranslation();
  const { accountId }: Params = useParams<any>();
  const assetManagerId = accountId;
  const defaultSelectedValue = { id: 1, value: "All", label: "All" };
  const [chartData, setChartData] = useState<DoughnutChartState>();
  const [totalResponseCount, setTotalResponseCount] = useState<number>(0);
  const [statusCounts, setStatusCounts] = useState<any[]>([]);
  const [selectedQuestionnaire, setSelectedQuestionnaire] =
    useState<SelectedQuestionnaire>(defaultSelectedValue);
  useEffect(() => {
    const { value } = selectedQuestionnaire;
    const reqObj =
      value !== "All"
        ? {
            assetManagerId: assetManagerId,
            questionnaireId: value
          }
        : { assetManagerId: assetManagerId };
    apis
      .getResponseByStatus(reqObj)
      .then((res) => {
        setStatusCounts(getResponseStatusCount(res?.data?.responseByStatus));
        setChartData(getChartDataset(res?.data?.responseByStatus));
        setTotalResponseCount(res?.data?.responseByStatus?.totalResponse);
      })
      .catch((error) => {
        toast.error(formatGraphQLError(error.message));
      });
  }, [selectedQuestionnaire]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: false
  };
  return (
    <div className="card card--chartjs mb-4">
      <div className="card__header">
        <div className="row row--minus-margin-fix justify-content-between">
          <div className="card__title col-12 col-lg-7">
            <h4 className="mb-lg-0">
              {t("Responses by status")}
              <OverlayTrigger
                placement="bottom"
                rootClose
                overlay={
                  <Tooltip id={`status-responses`}>
                    {t("The status of responses for specific questionnaires.")}
                  </Tooltip>
                }
              >
                <span className="m-l-10">
                  <Icons className="icon icon--small" icon="info"></Icons>
                </span>
              </OverlayTrigger>
            </h4>
          </div>

          <div className="col-9 col-sm-8 col-lg-5 col-xl-4">
            <Select
              options={questionnairesList}
              name="select-single"
              placeholder={`${t("Select")}...`}
              onChange={(ele: any) => {
                setSelectedQuestionnaire(ele);
              }}
              defaultValue={defaultSelectedValue}
              isClearable={false}
              isMulti={false}
              noOptionsMessage={() => t("No options")}
            />
          </div>
        </div>
      </div>
      <div className="card__body pt-0">
        <div className="row row--minus-margin-fix">
          <div className="col-6 col-md-7">
            {statusCounts &&
              statusCounts.map(({ label, value, dotClassName }, index) => {
                return (
                  <span
                    className={`d-block status status__dot status__dot--${dotClassName}`}
                    key={index}
                  >
                    {t(label)}{" "}
                    <span
                      className={`text-muted js-response-by-status-${dotClassName}`}
                    >
                      {t(value)}
                    </span>
                  </span>
                );
              })}

            <p className="mt-5 pt-3 align-self-end">
              {t("Total responses")}:{" "}
              <span className="js-response-by-status-total">
                {totalResponseCount}
              </span>
            </p>
          </div>
          <div className="col-6 col-md-5" id="chartResponsesByStatus">
            {/* <canvas id="canvasResponsesByStatus"></canvas> */}
            {chartData ? (
              <DoughnutChart data={chartData} options={options} />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubmittedQuestionnaireCountByStatusesGraph;
