import React, { useContext } from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";
import ErrorHandler from "./components/presentation/ErrorComponent";
import { UserContext } from "./Contexts/UserContext";
import { COUNTERPARTY_USER } from "./lib/questionnairePermissionsAccess";
import * as utils from "./lib/utils";

interface IProps extends RouteProps {
  component: React.FC<any>;
  accessUserTypes?: string[];
  compProps?: any;
}

const GuardedRoute = ({
  component: Component,
  accessUserTypes,
  compProps,
  ...rest
}: IProps) => {
  const { userDetails } = useContext(UserContext);
  let userTypes = utils.getUserTypes(userDetails?.user_type);
  let path = window.location.pathname;
  if (path?.includes("/counterpartySubmittedQuestionnaire/cp/")) {
    accessUserTypes = [COUNTERPARTY_USER];
  }
  let isAccessAllowed =
    (accessUserTypes &&
      accessUserTypes.some((val) => userTypes?.includes(val))) ||
    false;

  return (
    <Route
      {...rest}
      render={() => {
        return isAccessAllowed ? (
          <ErrorHandler>
            <section className="content">
              <Component {...compProps} />
            </section>
          </ErrorHandler>
        ) : (
          <Redirect to={"/"} />
        );
      }}
    />
  );
};

export default GuardedRoute;
