import React, { useState, useEffect, useContext, useCallback } from "react";
import { toast } from "react-toastify";
import * as utils from "../../../lib/utils";
import * as apis from "../../../apis/ReceivedAssignmentsList";
import {
  Params,
  Columns,
  CounterpartyQuestionnaireFilterList,
  PageDetailsState,
  NotificationDetailsType
} from "./types";
import { useParams, useHistory } from "react-router-dom";
import Pagination from "../../presentation/Pagination";
import PageSize from "../../presentation/PageSize";
import GridFilters from "../../presentation/GridFilters";
import { FilterItem } from "../../presentation/GridFilters/types";
import {
  getQuestionnaireColumnConfig,
  getSectionColumnConfig,
  getGridFiltersConfig
} from "./services";
import { UserContext } from "../../../Contexts/UserContext";
import { OrgKeyMap } from "../../../constants/app-constants";
import NoDataFound from "../../presentation/NoDataFound";
import "./styles.scss";
import CustomModal from "../../presentation/Modals/CustomModal";
import ConfirmationModal from "../../presentation/Modals/ConfirmationModal";
import SectionGroupAssignment from "../SectionGroupsAssignments";
import {
  groupsSectionAssignmentQuestionnaire,
  groupsSectionAssignmentGroups,
  groupsSectionAssignmentRoles
} from "../SectionGroupsAssignments/types";
import GridComponent from "../../presentation/Grid";
import { requestReopen } from "../../../apis/CounterpartySubmittedQuestionnaire";
import {
  permissionError,
  COUNTERPARTY_USER
} from "../../../lib/questionnairePermissionsAccess";
import { useTranslation } from "react-i18next";
import CounterpartyNotifications from "../ManageEmailNotifications/CounterpartyNotifications";
import { CounterpartyNotificationTypes } from "../../../types/applicationData";
type Props = {
  getPageHeaderDetails: (pageDetails: any) => void;
};

const ReceivedAssignmentsQuestionnaireList: React.FC<Props> = ({
  getPageHeaderDetails
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [confirmationBoxData, setConfirmationBoxData] = useState({
    title: "",
    message: "",
    callBack: () => {},
    showConfirmButton: true
  });
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const { accountId }: Params = useParams<any>();
  const counterpartyId = accountId;
  const { userDetails }: any = useContext(UserContext);
  const DEFAULT_PAGE_SIZE = 20;
  const DEFAULT_START_PAGE = 1;
  const [pageDetails, setPageDetails] = useState<PageDetailsState>({
    counterpartyId: accountId,
    pageSize: DEFAULT_PAGE_SIZE,
    pageNumber: DEFAULT_START_PAGE,
    filters: utils.getLocalStorage("RECEIVED_ASSIGNMENTS_LIST_FILTERS") || []
  });
  const [questionnaireResponseCount, setQuestionnaireResponseCount] =
    useState<number>(0);
  const [
    questionnaireSearchResponseCount,
    setQuestionnaireSearchResponseCount
  ] = useState<number>(0);
  const [latestSubmissionDate, setLatestSubmissionDate] = useState<
    number | string
  >("");
  const [responseList, setResponseList] = useState<Columns[]>();
  const gridData = React.useMemo(() => responseList, [responseList]);
  const [sectionGroupQuestionnaire, setSectionGroupQuestionnaire] =
    useState<groupsSectionAssignmentQuestionnaire>();

  const [sectionGroup, setSectionGroup] = useState<
    groupsSectionAssignmentGroups[]
  >([]);

  const [sectionGroupRoles, setSectionGroupRoles] = useState<
    groupsSectionAssignmentRoles[]
  >([]);

  const [filtersList, setFiltersList] =
    useState<CounterpartyQuestionnaireFilterList | null>();
  const filtersData = React.useMemo(() => filtersList, [filtersList]);
  const [accountQuestionnaireId, setAccountQuestionnaireId] = useState<any>();
  //const [reopenRequestDetails, setReopenRequestDetails] = useState<any>({});
  const [refreshQuestionnaires, setRefreshQuestionnaires] = useState(0);
  const [refreshSections, setRefreshSections] = useState(0);
  const [notificationDetails, setNotificationDetails] =
    useState<NotificationDetailsType | null>();
  // useCallback is used to rerender a function on dependancy change
  const assignGroupsToSection = useCallback(
    (accountQuestionnaireId: string | number) => {
      apis
        .getSectionAssignment({
          accountQuestionnaireId,
          accountId
        })
        .then((res: any) => {
          setSectionGroupQuestionnaire(res?.data.getSectionAssignment);
          setSectionGroup(res?.data.getAccountGroups);
          setSectionGroupRoles(res?.data.getUserRoles);
          setShowGroupSectionAssignmentModal(true);
        })
        .catch((error: any) => {
          toast.error(utils.formatGraphQLError(error.message));
          setShowGroupSectionAssignmentModal(false);
        });
    },
    [accountQuestionnaireId, accountId]
  );
  const updateNotificationPreferences = useCallback(
    ({
      accountQuestionnaireId,
      notificationPreferences,
      parentId
    }: {
      accountQuestionnaireId: string | number;
      notificationPreferences: any;
      parentId: string;
    }) => {
      setNotificationDetails({
        accountQuestionnaireId,
        notificationPreferences,
        parentId
      });
    },
    []
  );

  const reopen = useCallback(
    ({
      reopenSectionId,
      reopenAccountQuestionnaireId,
      reopenedSectionData,
      reopenedQuestionnaireData
    }: {
      reopenSectionId?: string | number;
      reopenAccountQuestionnaireId?: string | number;
      reopenedSectionData?: any;
      reopenedQuestionnaireData?: any;
    }) => {
      setShowConfirmationModal(true);
      if (reopenSectionId) {
        // setReopenRequestDetails({
        //   sectionId: reopenSectionId,
        //   accountQuestionnaireId: accountQuestionnaireId
        // });
        setConfirmationBoxData({
          title: reopenedSectionData?.requestedAt
            ? t("Request to reopen the section has been sent")
            : t("Confirm request to reopen"),
          message: reopenedSectionData?.requestedAt
            ? t("dynamicStringTranslation.sectionReopenRequest", {
                username: reopenedSectionData?.requestedBy,
                datetimestamp: utils.getDate(
                  reopenedSectionData?.requestedAt,
                  "MMMM Do YYYY, h:mm:ss a"
                )
              })
            : t(
                "Are you sure you want to send the request to reopen the section ?"
              ),
          showConfirmButton: reopenedSectionData?.requestedAt ? false : true,
          callBack: () => {
            requestReopen({
              sectionId: reopenSectionId,
              accountQuestionnaireId: accountQuestionnaireId
            })
              .then(() => {
                toast.success(t("Request sent successfully"));
                setShowConfirmationModal(false);
                //  setReopenRequestDetails({});
                // setShowConfirmationModal(false);
                setRefreshSections(refreshSections + 1);
              })
              .catch((e) => {
                toast.error(utils.formatGraphQLError(e.message));
              });
          }
        });
      }
      if (reopenAccountQuestionnaireId) {
        /*setReopenRequestDetails({
          sectionId: null,
          accountQuestionnaireId: reopenAccountQuestionnaireId
        });*/
        setConfirmationBoxData({
          title: reopenedQuestionnaireData?.requestedAt
            ? t("Request to reopen the questionnaire has been sent")
            : t("Confirm request to reopen"),
          message: reopenedQuestionnaireData?.requestedAt
            ? `${
                reopenedQuestionnaireData?.requestedBy
              } has requested to reopen the questionnaire at ${utils.getDate(
                reopenedQuestionnaireData?.requestedAt,
                "MMMM Do YYYY, h:mm:ss a"
              )}`
            : t(
                "Are you sure you want to send the request to reopen the questionnaire"
              ),
          showConfirmButton: reopenedQuestionnaireData?.requestedAt
            ? false
            : true,
          callBack: () => {
            requestReopen({
              sectionId: null,
              accountQuestionnaireId: reopenAccountQuestionnaireId
            })
              .then(() => {
                toast.success(t("Request sent successfully"));
                //setShowConfirmationModal(false);
                // setReopenRequestDetails({});
                setShowConfirmationModal(false);
                setRefreshQuestionnaires(refreshQuestionnaires + 1);
              })
              .catch((e) => {
                toast.error(utils.formatGraphQLError(e.message));
              });
          }
        });
      }
    },
    [accountQuestionnaireId]
  );

  const questionnaireColumnConfig = React.useMemo(
    () =>
      getQuestionnaireColumnConfig({
        userDetails,
        assignGroupsToSection,
        reopen,
        updateNotificationPreferences
      }),
    [
      accountId,
      userDetails,
      assignGroupsToSection,
      reopen,
      updateNotificationPreferences
    ]
  );
  const sectionColumnConfig = React.useMemo(
    () =>
      getSectionColumnConfig({
        counterpartyId: accountId,
        userDetails,
        reopen
      }),
    [accountId, userDetails, reopen]
  );

  const [showGroupSectionAssignmentModal, setShowGroupSectionAssignmentModal] =
    useState<boolean>(false);

  const [accountQuestionnaireSections, setAccountQuestionnaireSections] =
    useState([] as any);

  useEffect(() => {
    if (accountQuestionnaireId) {
      setAccountQuestionnaireSections([]);
      getQuestionnaireSectionsData(accountQuestionnaireId)
        .then((sections: any) => {
          setAccountQuestionnaireSections(
            sections.data.counterpartyAccountQuestionnaireSections
          );
        })
        .catch((err: any) => {
          toast.error(utils.formatGraphQLError(err.message));
        });
    }
  }, [accountQuestionnaireId, refreshSections]);

  const navigateToLink = (link: string, hasPermission: boolean) => {
    if (hasPermission) {
      history.push(link);
    } else {
      permissionError();
    }
  };

  const handlePageSize = (pageSize: number) => {
    setPageDetails({
      ...pageDetails,
      pageNumber: DEFAULT_START_PAGE,
      pageSize
    });
  };

  const handleSorting = (value: any) => {
    if (value) {
      setPageDetails({
        ...pageDetails,
        pageNumber: DEFAULT_START_PAGE,
        sort: value.sortDirection
          ? [
              {
                column: value.id,
                direction: value.sortDirection,
                priority: 1
              }
            ]
          : []
      });
    }
  };

  const handlePagination = (page: number) => {
    setPageDetails({
      ...pageDetails,
      pageNumber: page
    });
  };

  const handleFilter = (key: string, filter: FilterItem[]) => {
    let filtersList = utils.setActiveFilter(pageDetails.filters, {
      filterName: key,
      filterValue: filter?.length ? filter : []
    });
    utils.setLocalStorage("RECEIVED_ASSIGNMENTS_LIST_FILTERS", filtersList);
    setPageDetails({
      ...pageDetails,
      pageNumber: DEFAULT_START_PAGE,
      filters: filtersList
    });
  };

  const getQuestionnaireSectionsData = (accountQuestionnaireId: number) => {
    return apis
      .getQuestionnaireSections({ counterpartyId, accountQuestionnaireId })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  };

  const resetAllFilters = () => {
    utils.removeLocalStorage("RECEIVED_ASSIGNMENTS_LIST_FILTERS");
    setPageDetails({
      ...pageDetails,
      filters: []
    });
  };

  useEffect(() => {
    apis
      .getQuestionnaireAssignmentsList(pageDetails)
      .then((res) => {
        let data = [];
        data = res.data?.counterPartyQuestionnaireList.map((item: any) => ({
          ...item,
          actions: "placeholder"
        }));
        setResponseList(data);
        setQuestionnaireResponseCount(res.data?.counterPartyQuestionnaireCount);
        setFiltersList(res.data?.counterpartyQuestionnaireFilterList);
        setQuestionnaireSearchResponseCount(
          res.data?.counterpartyQuestionnaireSearchCount
        );
        setLatestSubmissionDate(res.data?.counterpartyLatestSubmissionDate);
      })
      .catch((error) => {
        setResponseList([]);
        setQuestionnaireResponseCount(0);
        setFiltersList(null);
        setQuestionnaireSearchResponseCount(0);
        setLatestSubmissionDate("");
        toast.error(utils.formatGraphQLError(error.message));
      });
  }, [pageDetails, refreshQuestionnaires]);

  useEffect(() => {
    getPageHeaderDetails({
      title: `${OrgKeyMap.module_name} ${
        utils.isAdminUser(userDetails?.user_type) ? t("Admin") : ""
      }`,
      details: [
        `${questionnaireResponseCount || 0} ${t("Questionnaires")} | ${t(
          "Latest submission"
        )}: ${
          utils.getDate(latestSubmissionDate, "MMMM Do YYYY, h:mm:ss a") || ""
        }`
      ]
    });
  }, [
    questionnaireResponseCount,
    latestSubmissionDate,
    getPageHeaderDetails,
    userDetails,
    t
  ]);
  const updateReceivedAssignmentListNotifications = useCallback(
    (
      notificationDetails: CounterpartyNotificationTypes,
      accountQuestionnaireId: string | number
    ) => {
      if (responseList?.length) {
        let selectedItemIndex = responseList.findIndex(
          (val) => val.id === accountQuestionnaireId
        );
        let updatedDetails = [...responseList];
        updatedDetails[selectedItemIndex] = {
          ...updatedDetails[selectedItemIndex],
          notificationSubscribed: { allPreferences: { ...notificationDetails } }
        };
        setResponseList([...updatedDetails]);
      }
    },
    [responseList]
  );

  return (
    <>
      {filtersData &&
        Object.keys(filtersData as CounterpartyQuestionnaireFilterList)
          .length && (
          <div className="assignments">
            <GridFilters
              onSelect={handleFilter}
              filters={getGridFiltersConfig(
                filtersData as CounterpartyQuestionnaireFilterList
              )}
              selectedFilters={pageDetails?.filters}
              resetAllFilters={resetAllFilters}
            ></GridFilters>
          </div>
        )}
      {gridData && gridData.length > 0 ? (
        <div>
          <GridComponent
            gridData={gridData}
            columnConfig={questionnaireColumnConfig}
            pageIndex={0}
            pageSize={1000}
            onSort={handleSorting}
            hiddenColumns={[
              "questionnaireId",
              "riskLevel",
              "accountQuestionnaireId",
              "id",
              "submittedQuestionnaireId",
              "permissions",
              "submittedQuestionnaireStatus",
              "completePercentage"
            ]}
            totalRecordsCount={questionnaireSearchResponseCount}
            defaultPageSize={1000}
            rowAccordion={true}
            selectedRow={(row: any) => {
              let id = row.original.id;
              setAccountQuestionnaireId(id);
            }}
            userType={COUNTERPARTY_USER}
            nestedComponentTemplate={
              <GridComponent
                gridData={accountQuestionnaireSections}
                columnConfig={sectionColumnConfig}
                pageIndex={0}
                pageSize={1000}
                hiddenColumns={[
                  "questionnaireId",
                  "riskLevel",
                  "accountQuestionnaireId",
                  "id",
                  "submittedQuestionnaireId",
                  "permissions",
                  "submittedQuestionnaireStatus",
                  "completePercentage"
                ]}
                totalRecordsCount={accountQuestionnaireSections?.length}
                defaultPageSize={1000}
                disableSort={true}
                tableClassName={"questionnaire-inner-table"}
                action={{ navigateToLink }}
                userType={COUNTERPARTY_USER}
              />
            }
          />
          <div className="row">
            <div className="col-sm-12 mt-4">
              <div className="pagination-container">
                <div className="row">
                  <div
                    className="col-sm-4 col-md-4"
                    style={{
                      display: "block"
                    }}
                  >
                    <PageSize
                      handleChange={handlePageSize}
                      defaultPageSize={DEFAULT_PAGE_SIZE}
                    />
                  </div>

                  <div className="col-sm-8 col-md-8">
                    <div className="float-right">
                      <Pagination
                        totalRecordsCount={questionnaireSearchResponseCount}
                        pageSize={pageDetails.pageSize}
                        defaultStartPage={DEFAULT_START_PAGE}
                        handleChange={handlePagination}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {sectionGroupQuestionnaire && setShowGroupSectionAssignmentModal && (
            <CustomModal
              classNames="cdd-groups-management"
              showModal={showGroupSectionAssignmentModal}
              onClose={() => {}}
            >
              <SectionGroupAssignment
                questionnaire={sectionGroupQuestionnaire}
                groups={sectionGroup}
                roles={sectionGroupRoles}
                onClose={() => {
                  setShowGroupSectionAssignmentModal(false);
                }}
              />
            </CustomModal>
          )}
          <ConfirmationModal
            modalTitle={confirmationBoxData.title}
            message={confirmationBoxData.message}
            callBack={confirmationBoxData.callBack}
            showConfirmButton={confirmationBoxData.showConfirmButton}
            showConfirmationModal={showConfirmationModal}
            onModalClose={() => {
              setShowConfirmationModal(false);
            }}
          />
          {notificationDetails && (
            <CounterpartyNotifications
              parentElementId={notificationDetails.parentId}
              onClose={() => {
                setNotificationDetails(null);
              }}
              show={notificationDetails ? true : false}
              notificationDetails={notificationDetails.notificationPreferences}
              applyChanges={updateReceivedAssignmentListNotifications}
              accountId={accountId}
              accountQuestionnaireId={
                notificationDetails.accountQuestionnaireId
              }
              userId={userDetails.user_id}
            />
          )}
        </div>
      ) : (
        gridData && <NoDataFound />
      )}
    </>
  );
};

export default ReceivedAssignmentsQuestionnaireList;
