import gql from "graphql-tag";
import { publicPrivateComments } from "../CounterpartySubmittedQuestionnaire/queries";
const questionFragment = `
    id
    questionBankId
    title
    label
    placeholder
    helpInfo
    mandatory
    internalUseOnly
    deletedAt
    position
    answerDetails(datasetQuestionAnswerId: $datasetQuestionAnswerId){ 
        answer
        answeredBy
        answeredByAccountName
        updatedAt
        publicPrivateComments{
            ${publicPrivateComments}
          }
        id
        uploadedFiles{
            id
            originalFileName
            timeStampedFileName
        }
    }
`;

const fieldValuesFragment = `
    id
    label
    value
    position
    deletedAt
    sectionId
`;

const fieldTypeFragment = `
    name
    id
`;

const questionListFragment = `
    ${questionFragment}
    fieldType {
        ${fieldTypeFragment}
    }
    fieldValues {
        ${fieldValuesFragment}
        subQuestions {
            question {
                ${questionFragment}
                fieldType {
                    ${fieldTypeFragment}
                }
                fieldValues {
                    ${fieldValuesFragment}
                    subQuestions {
                        question {
                            ${questionFragment}            
                            fieldType {
                                ${fieldTypeFragment}
                            }
                            fieldValues {
                                ${fieldValuesFragment}
                                subQuestions {
                                    question {
                                        ${questionFragment}                    
                                        fieldType {
                                            ${fieldTypeFragment}
                                        }
                                        fieldValues {
                                            ${fieldValuesFragment}
                                            subQuestions {
                                                question {
                                                    ${questionFragment}
                                                    fieldType {
                                                        ${fieldTypeFragment}
                                                    }
                                                    fieldValues {
                                                        ${fieldValuesFragment}
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const sectionFragment = `
    id
    title
    
    parentId
    position
    description
    autofill
    visibility
    internalUseOnly
    deletedAt
    publicPrivateComments{
        ${publicPrivateComments}
      }
    questions{
        ${questionListFragment}
    }
`;
export const GET_DATASET_DETAILS = gql`
  query GetDataSetDetails(
      $datasetQuestionAnswerId: ID!
      $questionId: ID! 
      $accountQuestionnaireId: ID! 
      $sectionId: ID!
  ) {
    datasetSectionDetails(
      datasetQuestionAnswerId: $datasetQuestionAnswerId
      questionId: $questionId
      accountQuestionnaireId: $accountQuestionnaireId
      sectionId: $sectionId
    ) {
        id
        title
        questions{
            ${questionListFragment}
        }

        subSections{
            ${sectionFragment}
            subSections {
              ${sectionFragment}
              subSections{
                ${sectionFragment}
              }
            }
          }

    }
  }
`;

export const DELETE_DATASET = gql`
  mutation DeleteDataSet($input: DeleteDatasetAnswerInput!) {
    deleteDatasetAnswer(input: $input) {
      message
      completePercentage
    }
  }
`;

export const SAVE_DATASET = gql`
  mutation SaveDataSet($input: SaveDatasetAnswersInput!) {
    saveDatasetAnswers(input: $input) {
      message
      datasetQuestionAnswerId
      firstAnswer
      completePercentage
      errors {
        errors
        questionId
      }
      questionId
    }
  }
`;
