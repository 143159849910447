import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as utils from "../../../lib/utils";
import GridComponent from "../../presentation/Grid";
import { getColumnConfig, getGridFiltersConfig } from "./services";
import * as apis from "../../../apis/GroupsList";
import Pagination from "../../presentation/Pagination";
import PageSize from "../../presentation/PageSize";
import {
  Params,
  PageDetailsState,
  Columns,
  TGroup,
  GroupsFilterList,
  Role,
  User
} from "./types";
import { UserContext } from "../../../Contexts/UserContext";
import { OrgKeyMap } from "../../../constants/app-constants";
import CustomModal from "../../presentation/Modals/CustomModal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import MultiSelect from "../../presentation/MultiSelect";
import "./styles.scss";
import GridFilters from "../../presentation/GridFilters";
import ConfirmationModal from "../../presentation/Modals/ConfirmationModal";
import NoDataFound from "../../presentation/NoDataFound";
import { useTranslation } from "react-i18next";
import { FilterItem } from "../../presentation/GridFilters/types";

type Props = {
  getPageHeaderDetails: (pageDetails: any) => void;
};

const GroupsList: React.FC<Props> = ({ getPageHeaderDetails }) => {
  const { t } = useTranslation();
  const { accountId }: Params = useParams<any>();
  const DEFAULT_PAGE_SIZE = 20;
  const DEFAULT_START_PAGE = 1;
  const [pageDetails, setPageDetails] = useState<PageDetailsState>({
    accountId,
    pageSize: DEFAULT_PAGE_SIZE,
    pageNumber: DEFAULT_START_PAGE,
    filters: utils.getLocalStorage("GROUPS_LIST_FILTERS") || []
  });
  const [responseList, setResponseList] = useState<Columns[]>();
  const [totalGroupsCount, setTotalGroupsCount] = useState<number>(0);
  const [accountGroupListSearchCount, setAccountGroupListSearchCount] =
    useState<number>(0);
  const gridData = React.useMemo(() => responseList, [responseList]);
  const [usersList, setUsersList] = useState<User[]>([]);
  const [filtersList, setFiltersList] = useState<GroupsFilterList | null>();
  const filtersData = React.useMemo(() => filtersList, [filtersList]);
  const columnConfig = React.useMemo(() => getColumnConfig(), []);
  const { userDetails }: any = useContext(UserContext);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [confirmationBoxData, setConfirmationBoxData] = useState({
    title: "",
    message: "",
    callBack: () => {}
  });

  const [initialValues, setInitialValues] = useState<TGroup>({
    id: "",
    name: "",
    roles: [],
    accountId,
    users: []
  });
  const [roles, setRoles] = useState<Role[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [reloadData, setReloadData] = useState<number>(1);
  const [editMode, setEditMode] = useState<boolean>(false);

  const validationSchema = yup.object().shape({
    name: yup.string().required(t("Please enter group name")),
    // roles: yup.array().min(1, t("Please add a role")) // remove role field
  });

  const handlePageSize = (pageSize: number) => {
    //console.log("selected page size is >>> ", pageSize);
    setPageDetails({
      ...pageDetails,
      pageNumber: DEFAULT_START_PAGE,
      pageSize
    });
  };

  const handlePagination = (page: number) => {
    //console.log("Current Page Number >>> ", page);
    setPageDetails({
      ...pageDetails,
      pageNumber: page
    });
  };

  const handleSorting = (value: any) => {
    //console.log("sort is >>> ", value);
    if (value) {
      setPageDetails({
        ...pageDetails,
        pageNumber: DEFAULT_START_PAGE,
        sort: value.sortDirection
          ? [
              {
                column: value.id,
                direction: value.sortDirection,
                priority: 1
              }
            ]
          : []
      });
    }
  };

  const handleSubmit = (values: TGroup, { setSubmitting }: any) => {
    if (editMode) {
      apis
        .editAccountGroup({
          name: values.name,
          roles: values.roles
            ? values.roles.map((item: any) => {
                return { id: item.id };
              })
            : [],
          accountId,
          id: values.id,
          users: values.users
            ? values.users.map((item: any) => {
                return { userId: item.id || item.userId }; // on edit of user multiselect id field is avaialable else userId is saved
              })
            : []
        })
        .then((res) => {
          //console.log("Result is >>> ", res);
          setShowModal(false);
          const message = res.data?.editAccountGroup?.message;
          toast.success(message);
          setReloadData(reloadData + 1);
        })
        .catch((error) => {
          //console.log("Error is >> ", error);
          toast.error(utils.formatGraphQLError(error.message));
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      apis
        .createAccountGroup({
          name: values.name,
          roles: values.roles
            ? values.roles.map((item: any) => {
                return { id: item.id };
              })
            : [],
          accountId,
          users: values.users
            ? values.users.map((item: any) => {
                return { userId: item.id };
              })
            : []
        })
        .then((res) => {
          //console.log("Result is >>> ", res);
          setShowModal(false);
          const message = res.data?.createAccountGroup?.message;
          toast.success(message);
          setReloadData(reloadData + 1);
        })
        .catch((error) => {
          //console.log("Error is >> ", error);
          toast.error(utils.formatGraphQLError(error.message));
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  const handleFilter = (key: string, filter: FilterItem[]) => {
    let filtersList = utils.setActiveFilter(pageDetails.filters, {
      filterName: key,
      filterValue: filter?.length ? filter : []
    });
    utils.setLocalStorage("GROUPS_LIST_FILTERS", filtersList);
    setPageDetails({
      ...pageDetails,
      filters: filtersList
    });
  };

  const resetAllFilters = () => {
    utils.removeLocalStorage("GROUPS_LIST_FILTERS");
    setPageDetails({
      ...pageDetails,
      filters: []
    });
  };

  const deleteAccountGroup = (id: string | number) => {
    setConfirmationBoxData(
      Object.assign(
        {},
        {
          title: "Confirm Delete",
          message: "Are you sure want to delete this account group?",
          callBack: () => {
            setShowConfirmationModal(false);
            apis
              .deleteAccountGroup({
                id
              })
              .then((res) => {
                const message = res.data?.deleteAccountGroup?.message;
                toast.success(message);
                setReloadData(reloadData + 1);
              })
              .catch((error) => {
                toast.error(utils.formatGraphQLError(error.message));
              });
          }
        }
      )
    );
    setShowConfirmationModal(true);
  };

  const openEditDialog = (userInfo: TGroup) => {
    setShowModal(true);
    setEditMode(true);
    setInitialValues({
      ...userInfo
    });
  };

  useEffect(() => {
    apis
      .getAccountGroupsList(pageDetails)
      .then((res: any) => {
        setResponseList(res.data?.accountGroupList);
        setFiltersList(res.data?.accountGroupFilterList);
        setAccountGroupListSearchCount(res.data?.accountGroupListSearchCount);
        setTotalGroupsCount(res.data?.totalAccountGroupsCount);
        setUsersList(res.data?.usersList);
      })
      .catch((error) => {
        setResponseList([]);
        setFiltersList(null);
        setUsersList([]);
        setAccountGroupListSearchCount(0);
        toast.error(utils.formatGraphQLError(error.message));
      });
  }, [pageDetails, reloadData]);

  useEffect(() => {
    getPageHeaderDetails({
      title: `${OrgKeyMap.module_name} ${
        utils.isAdminUser(userDetails?.user_type) ? t("Admin") : ""
      }`,
      details: [`${t("Total Groups")}: ${totalGroupsCount || 0}`]
    });
  }, [totalGroupsCount, getPageHeaderDetails, userDetails, reloadData]);

  useEffect(() => {
    const roleType = utils.isCounterPartyOnly(userDetails?.user_type)
      ? ["counterpartyGroupAccess"]
      : ["clientGroupAccess"];
    apis
      .getUserRoles(roleType)
      .then((res: any) => {
        setRoles(res.data?.getUserRoles);
      })
      .catch((error) => {
        setRoles([]);
        toast.error(utils.formatGraphQLError(error.message));
      });
  }, [userDetails]);

  useEffect(() => {
    if (!showModal) {
      setInitialValues({
        name: "",
        roles: [],
        accountId,
        users: []
      });
    }
  }, [showModal, accountId]);

  return (
    <>
      {filtersData && Object.keys(filtersData as GroupsFilterList).length && (
        <GridFilters
          onSelect={handleFilter}
          filters={getGridFiltersConfig(filtersData as GroupsFilterList)}
          selectedFilters={pageDetails?.filters}
          resetAllFilters={resetAllFilters}
        >
          <div className="row">
            <div className="page-action col-auto">
              <button
                onClick={() => setShowModal(true)}
                type="button"
                className="btn btn-primary"
              >
                {t("Add Group")}
              </button>
            </div>
          </div>
        </GridFilters>
      )}

      {gridData && gridData.length > 0 ? (
        <div>
          <GridComponent
            pageIndex={pageDetails.pageNumber - 1}
            pageSize={pageDetails.pageSize}
            gridData={gridData}
            columnConfig={columnConfig}
            defaultPageSize={DEFAULT_PAGE_SIZE}
            totalRecordsCount={accountGroupListSearchCount}
            action={{ deleteAccountGroup, openEditDialog }}
            onSort={handleSorting}
            hiddenColumns={["id", "roles"]}
          />
          <div className="row">
            <div className="col-sm-12 mt-4">
              <div className="pagination-container">
                <div className="row">
                  <div
                    className="col-sm-4 col-md-4"
                    style={{
                      display: "block"
                    }}
                  >
                    <PageSize
                      handleChange={handlePageSize}
                      defaultPageSize={DEFAULT_PAGE_SIZE}
                    />
                  </div>

                  <div className="col-sm-8 col-md-8">
                    <div className="float-right">
                      <Pagination
                        totalRecordsCount={accountGroupListSearchCount}
                        pageSize={pageDetails.pageSize}
                        defaultStartPage={DEFAULT_START_PAGE}
                        handleChange={handlePagination}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        responseList && <NoDataFound />
      )}

      <CustomModal
        modalTitle={editMode ? "Edit Group" : "Create Group"}
        showModal={showModal}
        onClose={() => {
          setShowModal(false);
          setEditMode(false);
        }}
      >
        <React.Fragment>
          <div id="add-group-container">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                isSubmitting,
                isValid,
                dirty,
                setFieldValue,
                setFieldTouched
              }) => (
                <Form>
                  <div className="form-group">
                    <label htmlFor="name">{t("Group Name")}:</label>
                    <Field
                      name="name"
                      type="text"
                      className="form-control"
                      placeholder={t("Please enter group name")}
                    />
                    <div className="mt-1" style={{ color: "red" }}>
                      <ErrorMessage name="name" />
                    </div>
                  </div>
                  {/* <div className="form-group">
                    <label htmlFor="roles">{t("Roles")}:</label>
                    <MultiSelect
                      name="roles"
                      options={utils.modifyForSelectBox(roles, "id", "label")}
                      defaultValue={utils.modifyForSelectBox(
                        initialValues.roles,
                        "id",
                        "label"
                      )}
                      placeholder={`${t("Select")}...`}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                    <div className="mt-1" style={{ color: "red" }}>
                      <ErrorMessage name="roles" />
                    </div>
                  </div> */}
                  <div className="form-group">
                    <label htmlFor="users">{t("Users")}:</label>
                    <MultiSelect
                      name="users"
                      options={utils.modifyForSelectBox(
                        usersList,
                        "userId",
                        "name",
                        "email"
                      )}
                      defaultValue={utils.modifyForSelectBox(
                        initialValues.users,
                        "userId",
                        "name",
                        "email"
                      )}
                      placeholder={`${t("Select")}...`}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                    <div className="mt-1" style={{ color: "red" }}>
                      <ErrorMessage name="users" />
                    </div>
                  </div>
                  <div className="form-group mt-5">
                    <div className="row align-items-baseline">
                      <button
                        type="submit"
                        className={`col-sm-auto btn btn-primary ${
                          !(dirty && isValid) || isSubmitting ? "disabled" : ""
                        }`}
                        disabled={!(dirty && isValid) || isSubmitting}
                      >
                        {editMode ? t("Update") : t("Create")}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </React.Fragment>
      </CustomModal>

      {/* Modal for confirmation box start */}
      <ConfirmationModal
        modalTitle={confirmationBoxData.title}
        message={confirmationBoxData.message}
        callBack={confirmationBoxData.callBack}
        showConfirmationModal={showConfirmationModal}
        onModalClose={() => {
          setShowConfirmationModal(false);
        }}
      />
    </>
  );
};

export default GroupsList;
