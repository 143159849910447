import { useRef, useEffect } from "react";
export function useInterval({
  callBack,
  delay
}: {
  callBack: () => void;
  delay: number | null;
}) {
  const saveCallBack = useRef<any>();
  useEffect(() => {
    saveCallBack.current = callBack;
  }, [callBack]);

  useEffect(() => {
    function startPolling() {
      saveCallBack.current();
    }
    if (delay) {
      const intervalId = setInterval(startPolling, delay);

      //This takes care of removing interval and stop polling when component unmounts
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [callBack, delay]);
}
