import React, { useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import Container from "react-bootstrap/Container";
import SwiftLogo from "../../../assets/images/zeidler-swift-logo.svg";
import BHYLogo from "../../../assets/images/berlin-hyp-logo.svg";
import sectionLogo from "../../../assets/images/sections/Zeidler_CDD_Logo_BlueNavy.svg";
import TopHeaderDropDown from "../TopHeaderDropDown";
import { ZEIDLER } from "../../../constants/app-constants";

const AppHeader: React.FC = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    // Adding location hook in App Header component as we cannot use any of the
    // hooks from within the same component that puts the Router into the tree.

    if (location?.search) {
      const queryParams = new URLSearchParams(location.search);
      if (queryParams?.has("jwt_token")) {
        queryParams.delete("jwt_token");
        history.replace({
          search: queryParams.toString()
        });
      }
    }
  }, []);

  return (
    <header className="site-header">
      <nav className="navbar navbar-expand-lg">
        <Container fluid>
          <div className="navbar-header">
            <a
              className="navbar-brand full-page-link"
              href={
                process.env.REACT_APP_ORGANIZATION === ZEIDLER
                  ? `${process.env.REACT_APP_SWIFT_APP_URL}`
                  : "/"
              }
            >
              <div className="navbar-logo">
                {process.env.REACT_APP_ORGANIZATION === ZEIDLER ? (
                  <img src={SwiftLogo} alt="Zeilder Swift" />
                ) : (
                  <img src={BHYLogo} alt="Berlin Hyp" />
                )}
              </div>
            </a>
            {process.env.REACT_APP_ORGANIZATION === ZEIDLER ? (
              <div className="section-specific">
                <Link to="/" className="section-logo">
                  <img src={sectionLogo} alt="Counterparty Due Diligence" />
                </Link>
              </div>
            ) : null}
          </div>
          <TopHeaderDropDown />
        </Container>
      </nav>
    </header>
  );
};

export default AppHeader;
