import React, { FunctionComponent, MouseEvent } from "react";
import styles from "../css/styles";

const collapsedPath = "M10.5 3.75L6 8.25L1.5 3.75";
const expandedPath = "M1 6.25L5.5 1.75L10 6.25";

const DropdownTrigger: FunctionComponent<{
  onClick: (event: MouseEvent<SVGElement>) => void;
  expanded: boolean;
}> = ({ onClick, expanded }) => (
  <g className={styles.trigger} onClick={onClick}>
    <rect
      width={20}
      height={20}
      transform="translate(-5,-5)"
      className={styles.hitArea}
    />
    <path
      d={expanded ? expandedPath : collapsedPath}
      className={styles.graphics}
    />
  </g>
);

export default DropdownTrigger;
