import React, { useState, useEffect } from "react";
import {
  groupsSectionAssignmentQuestionnaire,
  groupsSectionAssignmentSections,
  groupsSectionAssignmentGroups,
  groupsSectionAssignmentRoles
} from "./types";
import Icons from "../../presentation/Icons";
import Select from "react-select";
import MultiSelect from "../../presentation/MultiSelectControlled";
import * as utils from "../../../lib/utils";
import * as apis from "../../../apis/ReceivedAssignmentsList";
import { toast } from "react-toastify";
import CustomModal from "../../presentation/Modals/CustomModal";
import "./styles.scss";
import { saveAssetmanagerSectionGroupAssignment } from "../../../apis/ReviewQuestionnaire";
import { useTranslation } from "react-i18next";
type Props = {
  assetManagerQuestionnaireId?: number | string;
  questionnaire: groupsSectionAssignmentQuestionnaire;
  groups: groupsSectionAssignmentGroups[];
  roles: groupsSectionAssignmentRoles[];
  onClose: () => void;
};

const SectionGroupAssignment: React.FC<Props> = ({
  assetManagerQuestionnaireId,
  questionnaire,
  groups,
  roles,
  onClose
}) => {
  const { t } = useTranslation();
  let questionnaireMap: groupsSectionAssignmentQuestionnaire;
  const [questionnaireState, setQuestionnaireState] =
    useState<groupsSectionAssignmentQuestionnaire>(questionnaire);
  const [selectedSection, setSelectedSection] =
    useState<groupsSectionAssignmentSections>(questionnaire.sections[0]);
  const [cloneFromOptions, setCloneFromOptions] = useState<boolean>(false);
  const [cloneFromSectionId, setCloneFromSectionId] = useState<string | number>(
    ""
  );
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedCloneOptions, setSelectedCloneOptions] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const saveAll = () => {
    updateQuestionnaireState();
    if (assetManagerQuestionnaireId) {
      saveAssetmanagerSectionGroupAssignment({
        assetManagerQuestionnaireId: assetManagerQuestionnaireId,
        sections: questionnaireMap.sections.map(
          (section: groupsSectionAssignmentSections) => {
            let tempSection = JSON.parse(JSON.stringify(section));
            tempSection.groups = tempSection.groups.filter(
              (group: groupsSectionAssignmentGroups) => group.id !== null
            );
            return tempSection;
          }
        )
      })
        .then((res: any) => {
          toast.success(
            res?.data.saveAssetManagerSectionGroupAssignment.message
          );
        })
        .catch((error: any) => {
          toast.error(utils.formatGraphQLError(error.message));
        });
    } else {
      apis
        .saveSectionGroupAssignment({
          accountQuestionnaireId: questionnaireState.accountQuestionnaireId,
          sections: questionnaireMap.sections.map(
            (section: groupsSectionAssignmentSections) => {
              let tempSection = JSON.parse(JSON.stringify(section));
              tempSection.groups = tempSection.groups.filter(
                (group: groupsSectionAssignmentGroups) => group.id !== null
              );
              return tempSection;
            }
          )
        })
        .then((res: any) => {
          toast.success(res?.data.saveSectionGroupAssignment.message);
        })
        .catch((error: any) => {
          toast.error(utils.formatGraphQLError(error.message));
        });
    }
  };

  const save = () => {
    let tempSelectedSection = JSON.parse(JSON.stringify(selectedSection));
    if (assetManagerQuestionnaireId) {
      saveAssetmanagerSectionGroupAssignment({
        assetManagerQuestionnaireId: assetManagerQuestionnaireId,
        sections: [tempSelectedSection].map(
          (section: groupsSectionAssignmentSections) => {
            let groups = [];
            groups = section.groups?.filter(
              (group: groupsSectionAssignmentGroups) => group.id !== null
            );
            section.groups = groups;
            return section;
          }
        )
      })
        .then((res: any) => {
          toast.success(
            res?.data.saveAssetManagerSectionGroupAssignment.message
          );
        })
        .catch((error: any) => {
          toast.error(utils.formatGraphQLError(error.message));
        });
    } else {
      apis
        .saveSectionGroupAssignment({
          accountQuestionnaireId: questionnaireState.accountQuestionnaireId,
          sections: [tempSelectedSection].map(
            (section: groupsSectionAssignmentSections) => {
              let groups = [];
              groups = section.groups?.filter(
                (group: groupsSectionAssignmentGroups) => group.id !== null
              );
              section.groups = groups;
              return section;
            }
          )
        })
        .then((res: any) => {
          toast.success(res?.data.saveSectionGroupAssignment.message);
        })
        .catch((error: any) => {
          toast.error(utils.formatGraphQLError(error.message));
        });
    }
  };

  const changeSelectedSection = (
    newSection: groupsSectionAssignmentSections
  ) => {
    updateQuestionnaireState();
    setSelectedSection(newSection);
  };

  const updateQuestionnaireState = () => {
    let tempQuestionnaire = JSON.parse(JSON.stringify(questionnaireState));
    let tempList = tempQuestionnaire.sections.map(
      (section: groupsSectionAssignmentSections, index: number) => {
        if (section.id === selectedSection.id) {
          const updatedSection = {
            ...section,
            groups: selectedSection.groups
          };
          return updatedSection;
        }
        return section;
      }
    );
    let obj = { ...tempQuestionnaire, sections: tempList };
    questionnaireMap = obj;
    setQuestionnaireState(obj);
  };

  const addSectionGroupRow = (index: number) => {
    let tempState = JSON.parse(JSON.stringify(selectedSection));
    tempState.groups?.splice(index + 1, 0, {
      id: null,
      name: "",
      roles: []
    });
    setSelectedSection(tempState);
  };

  const removeSectionGroupRow = (index: number) => {
    let tempState = JSON.parse(JSON.stringify(selectedSection));
    if (tempState.groups.length === 1) {
      tempState.groups = [
        {
          id: null,
          name: "",
          roles: []
        }
      ];
    } else {
      tempState.groups.splice(index, 1);
    }

    setSelectedSection(tempState);
  };

  const updateSectionGroupRoles = (options: any, index: number) => {
    let tempState = JSON.parse(JSON.stringify(selectedSection));
    if (tempState.groups[index]) {
      tempState.groups[index].roles = options;
      setSelectedSection(tempState);
    }
  };

  const updateSectionGroup = (
    option: any,
    id: string | number,
    index: number
  ) => {
    let tempState = JSON.parse(JSON.stringify(selectedSection));
    if (tempState.groups[index]) {
      tempState.groups[index].name = option;
      tempState.groups[index].id = id;
      setSelectedSection(tempState);
    }
  };

  const cloneReplaceFromSection = (id: string | number) => {
    let tempQuestionnaire = JSON.parse(JSON.stringify(questionnaireState));
    let sectionToCloneFrom = tempQuestionnaire.sections.filter(
      (section: groupsSectionAssignmentSections, index: number) =>
        section.id === id
    );
    let tempSelectedSection = JSON.parse(JSON.stringify(selectedSection));
    tempSelectedSection.groups = sectionToCloneFrom[0].groups;
    setSelectedSection(tempSelectedSection);
  };

  const cloneMergeFromSection = (id: string | number) => {
    let tempQuestionnaire = JSON.parse(JSON.stringify(questionnaireState));
    let sectionToCloneFrom = tempQuestionnaire.sections.filter(
      (section: groupsSectionAssignmentSections, index: number) =>
        section.id === id
    );
    let tempSelectedSection = JSON.parse(JSON.stringify(selectedSection));

    let combinedGroups = [
      ...tempSelectedSection.groups,
      ...sectionToCloneFrom[0].groups
    ];

    let groupsObj: any = {};

    combinedGroups?.forEach((group: any, index: number) => {
      if (groupsObj.hasOwnProperty(group.name)) {
        groupsObj[group.name].roles = [
          ...groupsObj[group.name].roles,
          ...group.roles
        ];
      } else {
        groupsObj[group.name] = group;
      }
    });

    let mergedGroups = [];

    for (const group in groupsObj) {
      groupsObj[group].roles = uniqueArrays(groupsObj[group].roles, "label");
      mergedGroups.push(groupsObj[group]);
    }

    tempSelectedSection.groups = mergedGroups;

    setSelectedSection(tempSelectedSection);
  };

  const uniqueArrays = (mergedArray: any, identifier: string) => {
    const uniqueArray = [
      ...mergedArray
        .reduce(
          (map: any, obj: any) => map.set(obj[identifier], obj),
          new Map()
        )
        .values()
    ];
    return uniqueArray;
  };

  useEffect(() => {
    let tempState = JSON.parse(JSON.stringify(questionnaireState));
    tempState.sections.map(
      (section: groupsSectionAssignmentSections, index: number) => {
        if (section.groups.length === 0) {
          section.groups.push({
            id: null,
            name: "",
            roles: []
          });
        }
        return section;
      }
    );

    setQuestionnaireState(tempState);
    setSelectedSection(tempState.sections[0]);
  }, [questionnaire]);

  return (
    <>
      <div id="cdd-groups-management">
        <div className="groups-management-form">
          <div className="groups-management-form-top">
            <div className="groups-management-form-top-select">
              <div className="form-group">
                <label htmlFor="counterparty" className="mb-2">
                  {questionnaire.questionnaireTitle}
                </label>
              </div>
            </div>
            <div className="groups-management-form-top-btn">
              <button
                className="btn btn-primary"
                onClick={() => {
                  onClose();
                }}
              >
                {t("Close")}
              </button>
              <button
                className="btn btn-primary"
                onClick={() => {
                  saveAll();
                }}
              >
                {t("Save All")}
              </button>
            </div>
          </div>
          <div className="groups-management-form-content">
            <div className="groups-management-form-content-header">
              <div className="assign-heading">{t("Assign Section(s)")}</div>
            </div>
            <div className="groups-management-form-content-in">
              <div className="groups-management-form-content-left">
                <div className="assignSectionTab">
                  <ul className="nav sub-nav">
                    {questionnaireState.sections.map(
                      (
                        section: groupsSectionAssignmentSections,
                        index: number
                      ) => {
                        return (
                          <li className="nav-item" key={index}>
                            <a
                              className={`nav-link ${
                                selectedSection.id === section.id
                                  ? "active"
                                  : ""
                              }`}
                              onClick={(e) => {
                                e.preventDefault();
                                changeSelectedSection(section);
                                setSelectedCloneOptions(null);
                              }}
                            >
                              {section.name}
                            </a>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </div>
              </div>
              <div className="groups-management-form-content-right">
                <div className="formGroupLoopWrp">
                  <div className="formGroupLoop">
                    <div className="formGroupLoopIN">
                      <div className="formGroupLoopList fullScreenView">
                        <div className="form-group">
                          <label htmlFor="counterparty" className="mb-2">
                            {t("Groups")}
                          </label>
                        </div>
                        <div className="form-group">
                          <label htmlFor="counterparty" className="mb-2">
                            {t("Permissions")}
                          </label>
                        </div>
                      </div>
                      {selectedSection.groups.map(
                        (
                          group: groupsSectionAssignmentGroups,
                          index: number
                        ) => {
                          return (
                            <div className="formGroupLoopList" key={index}>
                              <div className="form-group">
                                <label
                                  htmlFor="counterparty"
                                  className="mb-2 mb-Show"
                                >
                                  {t("Groups")}
                                </label>
                                <Select
                                  options={utils.modifyForSelectBox(
                                    groups,
                                    "id",
                                    "name"
                                  )}
                                  value={
                                    group.name && {
                                      label: group.name,
                                      value: group.name
                                    }
                                  }
                                  noOptionsMessage={() => t("No options")}
                                  name={`single-select-${group.id}`}
                                  placeholder={`${t("Select")}...`}
                                  isClearable={false}
                                  isMulti={false}
                                  onChange={({
                                    value,
                                    id
                                  }: {
                                    value: any;
                                    id: any;
                                  }) => {
                                    updateSectionGroup(value, id, index);
                                  }}
                                />
                              </div>
                              <div className="form-group">
                                <label
                                  htmlFor="counterparty"
                                  className="mb-2 mb-Show"
                                >
                                  {t("Permissions")}
                                </label>
                                <MultiSelect
                                  name={`roles-${group.id}`}
                                  options={utils.modifyForSelectBox(
                                    roles,
                                    "id",
                                    "label"
                                  )}
                                  defaultValue={utils.modifyForSelectBox(
                                    group.roles,
                                    "id",
                                    "label"
                                  )}
                                  placeholder={`${t("Select")}...`}
                                  onChange={(name: any, options: any) => {
                                    updateSectionGroupRoles(options, index);
                                  }}
                                />
                              </div>
                              <button
                                className="btn btn-base"
                                onClick={() => {
                                  removeSectionGroupRow(index);
                                }}
                              >
                                <Icons icon="delete-section" />
                              </button>
                              <button
                                className="btn btn-primary"
                                onClick={() => {
                                  addSectionGroupRow(index);
                                }}
                              >
                                <Icons icon="add-section" />
                              </button>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                  <div className="formGroupLoopFooter">
                    <div className="form-group">
                      {selectedSection && (
                        <Select
                          options={utils.modifyForSelectBox(
                            questionnaire.sections.filter(
                              (section: groupsSectionAssignmentSections) =>
                                section.id !== selectedSection.id
                            ),
                            "id",
                            "name"
                          )}
                          name={`single-select-clone`}
                          value={selectedCloneOptions}
                          placeholder={`${t("Clone from")} ...`}
                          isMulti={false}
                          noOptionsMessage={() => t("No options")}
                          onChange={({
                            id,
                            value
                          }: {
                            id: string | number;
                            value: any;
                          }) => {
                            setSelectedCloneOptions({
                              label: value,
                              value: value
                            });
                            setCloneFromOptions(true);
                            setCloneFromSectionId(id);
                            setShowModal(true);
                          }}
                        />
                      )}
                    </div>
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        save();
                      }}
                    >
                      {t("Save")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {cloneFromOptions && (
        <CustomModal
          // modalTitle={datasetSection.title}
          classNames="modal modal--medium"
          showModal={showModal}
          modalTitle={false}
          onClose={() => {
            setShowModal(false);
            setCloneFromOptions(false);
          }}
          showFooter={false}
        >
          <>
            <div className="m-3" style={{ textAlign: "center" }}>
              {t("Please select clone type")}:
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
              style={{ textAlign: "center" }}
            >
              <button
                className="btn btn-outline-primary"
                onClick={(e) => {
                  cloneReplaceFromSection(cloneFromSectionId);
                  setShowModal(false);
                }}
              >
                {t("Replace")}
              </button>
              <button
                className="btn btn-primary"
                onClick={(e) => {
                  cloneMergeFromSection(cloneFromSectionId);
                  setShowModal(false);
                }}
              >
                {t("Merge")}
              </button>
            </div>
          </>
        </CustomModal>
      )}
    </>
  );
};

export default SectionGroupAssignment;
