import React from "react";
import Modal from "react-bootstrap/esm/Modal";
import { useTranslation } from "react-i18next";

type Props = {
  modalTitle?: string | any;
  content?: any;
  onApply?: (data: any) => void;
  onClose?: () => void;
  showModal: boolean;
  showFooter?: boolean;
  classNames?: string;
  footer?: any;
  bodyStyle?: any;
  contentClassName?: string;
  modalBodyClasses?: string;
};

const CustomModal: React.FC<Props> = ({
  modalTitle,
  content,
  onApply,
  onClose,
  showModal,
  children,
  showFooter = false,
  classNames,
  footer,
  bodyStyle,
  contentClassName,
  modalBodyClasses = "modal__body modal__body--comments"
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      show={showModal}
      onHide={onClose}
      className={`modal--large ${classNames}`}
      id="cdd-custom-modal"
      //tabIndex={0}
      enforceFocus={false}
      contentClassName={contentClassName || ""}
    >
      {modalTitle && (
        <Modal.Header className="modal__header" closeButton>
          <Modal.Title className="modal__title">{t(modalTitle)}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body className={modalBodyClasses} style={bodyStyle || {}}>
        {children}
      </Modal.Body>
      {showFooter && footer && (
        <Modal.Footer
          className="modal__footer border-top"
          style={{ display: "block" }}
        >
          {footer}
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default CustomModal;
