import { Questionnaire, Comment, ApprovalStatus } from "./types";
import { Section } from "../SectionComponent/types";
import { Question } from "../QuestionComponent/types";
import * as utils from "../../../lib/utils";
import { QuestionnaireStatus } from "../../../constants/app-constants";
import { ADMIN_USER } from "../../../lib/questionnairePermissionsAccess";

export function populateAddedComments(
  questionnaire: Questionnaire,
  commentArray: Comment[],
  commentableType: string,
  commentableId: string | number
) {
  let sections = JSON.parse(JSON.stringify(questionnaire.sections));
  let clonedQuestionnaire = { ...questionnaire, sections: sections };
  switch (commentableType) {
    case "Questionnaire":
      return Object.assign({}, clonedQuestionnaire, { comments: commentArray });
    case "Section":
      setSectionComment(commentableId, sections, commentArray, "Section");
      return clonedQuestionnaire;
    case "Question":
      setSectionComment(commentableId, sections, commentArray, "Question");
      return clonedQuestionnaire;
    default:
      return clonedQuestionnaire;
  }
}

function setSectionComment(
  commentableId: string | number,
  sections: Section[],
  commentArray: Comment[],
  commentableType: string
) {
  for (let section of sections) {
    if (
      commentableType === "Section" &&
      parseInt(section.id as string) === parseInt(commentableId as string)
    ) {
      section.comments = commentArray;
      return true;
    }
    if (section.subSections && section.subSections.length) {
      if (
        setSectionComment(
          commentableId,
          section.subSections,
          commentArray,
          commentableType
        )
      ) {
        return true;
      }
    }
    if (commentableType === "Question") {
      if (section.questions && section.questions.length) {
        for (let question of section.questions) {
          if (setQuestionComment(commentableId, question, commentArray)) {
            return true;
          }
        }
      }
    }
  }
}

function setQuestionComment(
  questionId: string | number,
  question: Question,
  commentArray: Comment[]
) {
  if (parseInt(question.id as string) === parseInt(questionId as string)) {
    question.comments = commentArray;
    return true;
  }
  if (question.fieldValues && question.fieldValues.length) {
    for (let field of question.fieldValues) {
      if (field.subQuestions && field.subQuestions.length) {
        for (let subQ of field.subQuestions) {
          if (setQuestionComment(questionId, subQ.question, commentArray)) {
            return true;
          }
        }
      }
    }
  }
}

export function getTotalComments(questionnaire: Questionnaire) {
  if (questionnaire && Object.keys(questionnaire).length) {
    let totalComments = questionnaire.comments.length;
    totalComments += getTotalSectionComments(questionnaire.sections);
    return totalComments;
  } else {
    return 0;
  }
}

function getTotalSectionComments(sections: Section[]) {
  let totalComments = 0;
  sections.forEach((section) => {
    totalComments += section.comments.length;
    if (section.subSections && section.subSections.length) {
      totalComments += getTotalSectionComments(section.subSections);
    }
    if (section.questions && section.questions.length) {
      section.questions.forEach((question) => {
        totalComments += getTotalQuestionComments(question);
      });
    }
  });
  return totalComments;
}

function getTotalQuestionComments(question: Question) {
  let totalComments = question.comments.length;
  if (question.fieldValues && question.fieldValues.length) {
    question.fieldValues.forEach((field) => {
      if (field.subQuestions && field.subQuestions.length) {
        field.subQuestions.forEach((subQ) => {
          totalComments += getTotalQuestionComments(subQ.question);
        });
      }
    });
  }
  return totalComments;
}

/*export function allowAddComment(questionnaireStatus: string, userType: string) {
  if (questionnaireStatus && userType) {
    if (
      questionnaireStatus === QuestionnaireStatus.Approved ||
      (questionnaireStatus === QuestionnaireStatus.AwaitingZeidlerReview &&
        utils.isAssetManager(userType)) ||
      ((questionnaireStatus === QuestionnaireStatus.AwaitingClientApproval ||
        questionnaireStatus === QuestionnaireStatus.AwaitingCommentsReview) &&
        utils.isAdminUser(userType))
    ) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}*/

export function getApprovalCount(approvalDetails: ApprovalStatus[]) {
  let approvedCount = 0;
  approvalDetails &&
    approvalDetails.length &&
    approvalDetails.forEach((value) => {
      if (value.approvedBy) {
        approvedCount += 1;
      }
    });
  return approvedCount;
}

export function showApprovalButton(
  questionnaireStatus: string,
  userType: string
) {
  if (questionnaireStatus && userType) {
    if (
      utils.isAssetManager(userType) &&
      (questionnaireStatus === QuestionnaireStatus.Approved ||
        questionnaireStatus === QuestionnaireStatus.AwaitingZeidlerReview)
    ) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}

export function showReviewButton(
  questionnaireStatus: string,
  userType: string
) {
  if (questionnaireStatus && userType) {
    if (
      questionnaireStatus === QuestionnaireStatus.Approved ||
      (questionnaireStatus === QuestionnaireStatus.AwaitingZeidlerReview &&
        utils.isAssetManager(userType)) ||
      ((questionnaireStatus === QuestionnaireStatus.AwaitingClientApproval ||
        questionnaireStatus === QuestionnaireStatus.AwaitingCommentsReview) &&
        utils.isAdminUser(userType))
    ) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}
export function getDataSetSectionsList(questionnaire: Questionnaire) {
  return questionnaire.sections?.filter((section) => !section.visibility);
}
export function isAssetManagerView(assetManagerId: string | number) {
  return assetManagerId !== ADMIN_USER;
}
