import client from "../apollo.config";
import * as queries from "./queries";
import * as configQueries from "./configQueries";
import { SaveScoringType } from "../../components/presentation/Deductions/types";

export const getSubmittedQuestionnaire = (
  submittedQuestionnaireId: string | number,
  sectionId: string | number,
  accountQuestionnaireId?: string | number | null
) => {
  return client.query({
    query: queries.GET_SUBMITTED_QUESTIONNAIRE_QUERY,
    fetchPolicy: "network-only",
    variables: { accountQuestionnaireId, submittedQuestionnaireId, sectionId }
  });
};

export const getAllSubmissionStatus = () => {
  return client.query({
    query: configQueries.GET_ALL_SUBMISSION_STATUS_QUERY,
    fetchPolicy: "network-only"
  });
};

export const changeQuestionnaireStatus = ({
  sectionId,
  accountQuestionnaireId,
  statusId
}: {
  sectionId?: number | string | null;
  accountQuestionnaireId: number | string;
  statusId: number | string;
}) => {
  return client.mutate({
    mutation: queries.CHANGE_QUESTIONNAIRE_STATUS,
    variables: { input: { sectionId, accountQuestionnaireId, statusId } }
  });
};

export const getScoringNotes = ({
  fieldValueId,
  questionAnswerId
}: {
  fieldValueId: string | number;
  questionAnswerId: string | number;
}) => {
  return client.query({
    query: queries.GET_SCORING_NOTES_QUERY,
    fetchPolicy: "network-only",
    variables: { fieldValueId, questionAnswerId }
  });
};

export const saveScoringDetails = (input: SaveScoringType) => {
  return client.mutate({
    mutation: queries.SAVE_SCORING_DETAILS_QUERY,
    variables: { input }
  });
};

export const rejectReopenRequest = ({
  sectionId,
  accountQuestionnaireId
}: {
  sectionId?: number | string | null;
  accountQuestionnaireId: number | string;
}) => {
  return client.mutate({
    mutation: queries.REJECT_REOPEN_REQUEST_QUERY,
    variables: { input: { sectionId, accountQuestionnaireId } }
  });
};

export const regenerateQuestionnairePDF = ({
  accountQuestionnaireId
}: {
  accountQuestionnaireId: number | string;
}) => {
  return client.mutate({
    mutation: queries.REGENERATE_QUESTIONNAIRE_PDF,
    variables: { input: { accountQuestionnaireId } }
  });
};
