import React, { useState, useContext, useEffect, useRef } from "react";
import axios, { AxiosResponse } from "axios";
import { UserContext } from "../../../Contexts/UserContext";
import Icons from "../Icons";
import { isAdminUser, getCookieConsent } from "../../../lib/utils";
import { getAppOverlay as Overlay } from "../Overlay/services";
import LanguageSelector from "../LanguageSelector";
import {
  findUserPermissionsFromRoles,
  USER_ADMIN
} from "../../../lib/questionnairePermissionsAccess";
import CustomModal from "../../presentation/Modals/CustomModal";
import loaderImg from "../../../assets/images/loaderTransperant.svg";
import bhyLoaderImg from "../../../assets/images/bhy-loader.svg";
import { useTranslation } from "react-i18next";
import { ZEIDLER } from "../../../constants/app-constants";
import i18n from "../../../i18n";
import UserService from "../../../services/userServices";
import { toast } from "react-toastify";
import * as utils from "../../../lib/utils";

const TopHeaderDropDown: React.FC = () => {
  const { t } = useTranslation();
  const { userDetails } = useContext(UserContext);
  const [showMenu, setShowMenu] = useState(false);

  function toggle() {
    showMenu ? Overlay.hideOverlay() : Overlay.showOverlay();
    setShowMenu(!showMenu);
  }
  const node = useRef<HTMLDivElement>(null);
  const [showHelpModal, setShowHelpModal] = useState<boolean>(false);

  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = (e: any) => {
    if (node.current && node.current.contains(e.target)) {
      return;
    }
    // outside click
    // ... do whatever on click outside here ...
    setShowMenu(false);
    Overlay.hideOverlay();
  };

  return (
    <>
      <div
        className={`navbar-userBlock ${showMenu ? "navbar-open" : ""}`}
        ref={node}
      >
        <button
          type="button"
          className="navbar-toggle d-lg-none"
          aria-expanded="false"
          onClick={(e) => {
            e.stopPropagation();
            toggle();
          }}
        >
          <span className="sr-only">Toggle navigation</span>
          <span className="icon-bar"></span>
          <span className="icon-bar close-cross"></span>
          <span className="icon-bar close-cross"></span>
          <span className="icon-bar"></span>
        </button>
        <div className="navbar-content navbar-collapse">
          <div className="navbar-nav ml-auto">
            {process.env.REACT_APP_ORGANIZATION === ZEIDLER && (
              <div
                className="help-center ml-3"
                onClick={() => {
                  setShowHelpModal(true);
                }}
              >
                <div className="help-icon">
                  <Icons className="icon" icon="help-videos" />
                </div>
                <div className="help-text">Help Center</div>
              </div>
            )}
            <ul
              className={`navbar-user-item dropdown-menu-right dropdown ${
                showMenu ? "show" : ""
              }`}
            >
              <button
                className="buttonAsLink dropdown-toggle dropdown-toggle--no-caret"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={(e) => {
                  e.stopPropagation();
                  toggle();
                }}
              >
                <span className="user-group my-3">
                  <span className="user-group__user-name">
                    {userDetails.user_name}
                  </span>
                  <span className="user-group__company-name">
                    {userDetails.current_account_name}
                  </span>
                </span>
                <div className="avatar avatar--no-image">
                  {userDetails.logo ? (
                    <img src={userDetails.logo} alt=""></img>
                  ) : (
                    <Icons className="icon" icon="user" />
                  )}
                </div>
              </button>
              <ul
                className={`dropdown-menu dropdown-menu-right dropdown-menu-user ${
                  showMenu ? "show" : ""
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowMenu(false);
                  Overlay.hideOverlay();
                }}
              >
                <li className="dropdown-toggle dropdown-toggle--no-caret pr-3">
                  <span className="user-group m-3">
                    <span className="user-group__user-name">
                      {userDetails.user_name}
                    </span>
                    <span className="user-group__company-name">
                      {userDetails.current_account_name}
                    </span>
                  </span>
                  <div className="avatar avatar--no-image ml-auto">
                    {userDetails.logo ? (
                      <img src={userDetails.logo} alt=""></img>
                    ) : (
                      <Icons className="icon" icon="user" />
                    )}
                  </div>
                </li>
                <div className="px-3 pb-4 pb-lg-3 pt-0 border-bottom">
                  <a
                    href={`${process.env.REACT_APP_SSO_LOGIN_URL}/realms/bhy/account`}
                    className="btn btn-outline-primary ml-2"
                  >
                    {t("Settings")}
                  </a>
                  <a
                    // href={`${process.env.REACT_APP_SSO_LOGIN_URL}/users/sign_out`}
                    onClick={()=>{ 
                      UserService.doLogout() 
                    }}
                    className="btn btn-outline-primary ml-2"
                  >
                    {t("Log out")}
                  </a>
                </div>
                {/* {!isAdminUser(userDetails.user_type) &&
                  findUserPermissionsFromRoles([{name: USER_ADMIN}]) && (
                    <a
                      href={`${process.env.REACT_APP_SSO_LOGIN_URL}/admin/users`}
                      className="dropdown-item d-flex justify-content-between align-items-center text-secondary px-3"
                    >
                      {t("User management")}
                      <Icons className="icon" icon="user" />
                    </a>
                  )} */}
                {isAdminUser(userDetails.user_type) && (
                  <a
                    href={`${process.env.REACT_APP_CDD_ADMIN_URL}`}
                    className="dropdown-item d-flex justify-content-between align-items-center text-secondary px-3"
                  >
                    {t("Admin panel")}
                    <Icons className="icon" icon="admin" />
                  </a>
                )}
                {getCookieConsent() &&
                process.env.REACT_APP_ORGANIZATION === "BHY" ? (
                  <LanguageSelector />
                ) : null}

                {userDetails.other_accounts?.map((account, index) => {
                  return (
                    <a
                      // href={`${process.env.REACT_APP_SSO_LOGIN_URL}/user/change_account?aclo_id=${account.id}&&redirect_url=${process.env.REACT_APP_REDIRECT_URL}`}
                      onClick={()=>{
                        axios({
                          method: "post",
                          url: `${process.env.REACT_APP_REST_ENDPOINT}/api/v1/changeAccount`,
                          withCredentials: true,
                          params: {
                            accountId: account.id
                          }
                        }).then(()=> {
                          window.location.href = process.env.REACT_APP_REDIRECT_URL!;
                        }).catch((error: Error)=>{
                          toast.error(utils.formatGraphQLError(error.message));
                        })
                      }}
                      className="dropdown-item d-flex align-items-center py-3 px-3"
                      key={index}
                    >
                      <span className="user-group">
                        <span className="user-group__user-name">
                          {userDetails.user_name}
                        </span>
                        <span className="user-group__company-name">
                          {account.name}
                        </span>
                      </span>
                      <div className="avatar avatar--no-image ml-auto">
                        {account.logo ? (
                          <img src={account.logo} alt=""></img>
                        ) : (
                          <Icons className="icon" icon="user" />
                        )}
                      </div>
                    </a>
                  );
                })}
              </ul>
            </ul>
          </div>
        </div>
      </div>
      {showHelpModal && (
        <CustomModal
          modalTitle="Help Center"
          classNames="modal fade full-size-modal"
          showModal={showHelpModal}
          onClose={() => {
            setShowHelpModal(false);
          }}
          showFooter={false}
        >
          <div
            className="embed-responsive embed-responsive-16by9"
            style={{
              backgroundImage: `url(${
                process.env.REACT_APP_ORGANIZATION === "BHY"
                  ? bhyLoaderImg
                  : loaderImg
              })`,
              backgroundPosition: "center",
              backgroundSize: "50px",
              backgroundRepeat: "no-repeat"
            }}
          >
            <iframe
              title="Help Center Videos"
              className="embed-responsive-item"
              src={process.env.REACT_APP_HELP_VIDEO_URL}
              id="help-video"
              allow="autoplay"
              allowFullScreen
            ></iframe>
          </div>
        </CustomModal>
      )}
    </>
  );
};
export default TopHeaderDropDown;
