import gql from "graphql-tag";

export const GET_ACCOUNT_GROUPS_LIST_QUERY = gql`
  query GetAccountGroupsList(
    $pageNumber: Int!
    $pageSize: Int!
    $accountId: ID!
    $search: String
    $sort: [SortingAttributes!]
    $filters: [AccountGroupFilterAttributes!]
  ) {
    accountGroupList(
      pageNumber: $pageNumber
      pageSize: $pageSize
      accountId: $accountId
      search: $search
      sort: $sort
      filters: $filters
    ) {
      id
      name
      roles {
        label
        id
      }
      users {
        name
        email
        userId
      }
    }
    totalAccountGroupsCount(accountId: $accountId)
    accountGroupFilterList(accountId: $accountId, filters: $filters) {
      nameList
      rolesList
    }
    accountGroupListSearchCount(
      accountId: $accountId
      search: $search
      filters: $filters
    )
    usersList(accountId: $accountId) {
      name
      userId
      email
    }
  }
`;

export const CREATE_ACCOUNT_GROUP_QUERY = gql`
  mutation CreateAccountGroup($input: CreateAccountGroupInput!) {
    createAccountGroup(input: $input) {
      message
    }
  }
`;

export const GET_USER_ROLES_QUERY = gql`
  query GetUserRoles($roleType: [String!]!) {
    getUserRoles(roleType: $roleType) {
      label
      id
    }
  }
`;

export const DELETE_ACCOUNT_GROUP_QUERY = gql`
  mutation DeleteAccountGroup($input: DeleteAccountGroupInput!) {
    deleteAccountGroup(input: $input) {
      message
    }
  }
`;

export const EDIT_ACCOUNT_GROUP_QUERY = gql`
  mutation EditAccountGroup($input: EditAccountGroupInput!) {
    editAccountGroup(input: $input) {
      message
    }
  }
`;
