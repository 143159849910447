import React, { useState, useEffect, useContext, useMemo } from "react";
import SideBarSections from "../../presentation/SideBarSections";
import SectionComponent from "../SectionComponent";
import { useParams, useHistory } from "react-router-dom";
import {
  Params,
  StatusLevelAccess,
  Questionnaire,
  ApprovalDetails,
  ApproveSubmissionData,
  AddComments,
  UpdateComments,
  DeleteComments,
  ChangeStatusType
} from "./types";
import { Section } from "../SectionComponent/types";
import * as apis from "../../../apis/SubmittedQuestionnaire";
import { toast } from "react-toastify";
import * as utils from "../../../lib/utils";
import Icons from "../../presentation/Icons";
import NoDataFound from "../../presentation/NoDataFound";
import { UserContext } from "../../../Contexts/UserContext";
import { REVIEW } from "../../../lib/utils";
import {
  allowPublicPrivateComments,
  allowApprovals,
  allowStatusChange,
  getChangableStatus,
  scoreAccess,
  DRAFT,
  INTERNAL_DRAFT,
  isEditAllowedForQuesStatus,
  acceptRequestToReopen,
  showEditWarning
} from "../../../lib/questionnaireStatusAccess";
import PublicPrivateComments from "../PublicPrivateComments";
import { updateCommentInSection } from "../SectionComponent/service";
import QuestionnaireLevelApprovals from "../Approvals/QuestionnaireLevelApprovals";
import SectionLevelApprovals from "../Approvals/SectionLevelApprovals";
import {
  getSubmissionApprovalDetails,
  approveSubmission,
  createComment,
  updateComment,
  deleteComment
} from "../../../apis/SubmissionApprovals";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { AllSubmissionStatus } from "../../../types/applicationData";
import { appData } from "../../../services/appConfig";
import CustomModal from "../../presentation/Modals/CustomModal";
import Select from "react-select";
import {
  ASSET_MANAGER_USER,
  sectionAccessPermission,
  findUserPermissionsFromRoles,
  USER_ADMIN,
  ADMIN_SECTION_REOPEN_PERMISSION,
  permissionError,
  ADMIN_STATUS_CHANGE_PERMISSION
} from "../../../lib/questionnairePermissionsAccess";
import { ReopenRequestType } from "../ResponseQuestionnaireList/types";
import ConfirmationModal from "../../presentation/Modals/ConfirmationModal";
import { useTranslation } from "react-i18next";
import { useQueryParams } from "../../../Hooks/useQueryParams";
import { QueryPrameters } from "../../../types/applicationData";
import RiskCatOverview from "../RiskCategorizationOverview";

const SubmittedQuestionnaire: React.FC<any> = ({ pageHeaderPropsChange }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const queryParams: QueryPrameters | undefined = useQueryParams({
    paramNames: ["sectionId", "questionId", "fieldId", "datasetId"]
  });
  const {
    assetManagerId,
    submittedQuestionnaireId,
    accountType,
    sectionId,
    accountQuestionnaireId
  }: Params = useParams<any>();
  const [questionnaire, setQuestionnaire] = useState<Questionnaire | null>(
    null
  );
  const [selectedSection, setSelectedSection] = useState<Section>();
  const [sidebarCollpased, setSidebarCollapsed] = useState<boolean>(false);
  const [editQuestionnaireModal, setEditQuestionnaireModal] =
    useState<boolean>(false);
  const [selectedSectionPosition, setSelectedSectionPosition] =
    useState<number>(0);
  const [confirmationBoxData, setConfirmationBoxData] = useState({
    title: "",
    message: "",
    callBack: () => {},
    showConfirmButton: true
  });
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const { userDetails }: any = useContext(UserContext);
  const [showPPC, setShowPPC] = useState<Boolean>(false);
  const [statusLevelAccess, setStatusLevelAccess] =
    useState<StatusLevelAccess>();
  const [approvalDetails, setApprovalDetails] = useState<ApprovalDetails[]>();
  const [refreshApprovals, setRefreshApprovals] = useState(0);
  const [allSubmissionStatus, setAllSubmissionStatus] =
    useState<AllSubmissionStatus[]>();
  const [changeStatusData, setChangeStatusData] = useState<ChangeStatusType>();
  const [refreshQuestionnaire, setRefreshQuestionnaire] = useState<number>(0);
  const scoreAccessDetails = useMemo(
    () =>
      scoreAccess({
        questionaireStatus: questionnaire?.questionnaireStatus.name,
        sectionStatus: selectedSection?.status.name,
        riskCategorizationMethod: questionnaire?.riskCategorizationMethod || "",
        isSectionLevelSubmission: questionnaire?.sectionSubmission || false
      }),
    [questionnaire, selectedSection]
  );
  const [reopenRequestData, setReopenRequestData] =
    useState<ChangeStatusType>();

  function handleScroll() {
    let cardElement = document.querySelector(".questionnaire .card") as any;
    if (cardElement?.offsetHeight >= window.innerHeight) {
      requestAnimationFrame(utils.makeNavPanelSticky);
    }
  }

  useEffect(() => {
    if (String(queryParams?.sectionId) === String(sectionId)) {
      setShowPPC(true);
    }
  }, [queryParams, sectionId]);

  useEffect(() => {
    (async () => {
      let response: any = await appData.fetchAllSubmissionStatus();
      setAllSubmissionStatus(response);
      window.addEventListener("scroll", handleScroll);
    })();
    //this gets called when component is unmounted
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    apis
      .getSubmittedQuestionnaire(
        submittedQuestionnaireId === "new" ? "" : submittedQuestionnaireId,
        sectionId,
        accountQuestionnaireId
      )
      .then((response: any) => {
        if (
          response?.data?.getSubmittedQuestionnaireDetails &&
          response?.data?.getSubmittedQuestionnaireDetails.sections
        ) {
          setQuestionnaire(
            Object.assign({}, response?.data?.getSubmittedQuestionnaireDetails)
          );
          //set section if counterpartyQuestionnaireSectionId is present in starting
          const selectedSec =
            response?.data?.getSubmittedQuestionnaireDetails?.sectionDetail;
          if (selectedSec) {
            setSelectedSection(selectedSec);
            setSelectedSectionPosition(selectedSec.position || 0);
          }
          let publicPrivateCommentAccess = allowPublicPrivateComments({
            questionnaireStatus:
              response?.data?.getSubmittedQuestionnaireDetails
                .questionnaireStatus?.name,
            userType: userDetails.user_type,
            sectionStatus: selectedSec.status?.name,
            isSectionLevelSubmission:
              response?.data?.getSubmittedQuestionnaireDetails
                ?.sectionSubmission
          });
          setStatusLevelAccess({
            allowEdit:
              isEditAllowedForQuesStatus({
                questionnaireStatus:
                  response?.data?.getSubmittedQuestionnaireDetails
                    .questionnaireStatus?.name,
                userType: ASSET_MANAGER_USER,
                sectionStatus: selectedSec.status?.name,
                isSectionLevelSubmission: questionnaire?.sectionSubmission
              }) &&
              sectionAccessPermission({
                permissions: selectedSec.permissions,
                permissionType: ASSET_MANAGER_USER
              }).editSection,
            ...allowApprovals({
              questionnaireStatus:
                response?.data?.getSubmittedQuestionnaireDetails
                  .questionnaireStatus?.name,
              isSectionLevelApproval:
                response?.data?.getSubmittedQuestionnaireDetails
                  .isSectionLevelApproval,
              sectionStatus: selectedSec.status?.name
            }),
            ...publicPrivateCommentAccess
          });
          utils.resetStickyNavBar();
        }
      })
      .catch((error) => {
        toast.error(utils.formatGraphQLError(error.message));
        setQuestionnaire(null);
        utils.resetStickyNavBar();
      });
  }, [
    assetManagerId,
    submittedQuestionnaireId,
    sectionId,
    refreshQuestionnaire,
    pageHeaderPropsChange
  ]);

  useEffect(() => {
    (async () => {
      if (
        questionnaire &&
        submittedQuestionnaireId &&
        submittedQuestionnaireId !== "new" &&
        sectionId
      ) {
        let approvalData: any = await getSubmissionApprovalDetails({
          sectionId: questionnaire.isSectionLevelApproval ? sectionId : null,
          submittedQuestionnaireId
        }).catch((e) => {
          toast.error(utils.formatGraphQLError(e.message));
        });
        approvalData?.data?.getSubmissionApprovalDetails
          ?.approvalStepGroupRole &&
          setApprovalDetails([
            ...approvalData?.data?.getSubmissionApprovalDetails
              ?.approvalStepGroupRole
          ]);
      }
    })();
  }, [questionnaire, refreshApprovals]);

  function getSelectedSection(sectionId: number | string) {
    const selectedSec = questionnaire?.sections.find(
      (section) => section.id === sectionId
    );
    setSelectedSection(Object.assign({}, selectedSec));
    setSelectedSectionPosition((selectedSec && selectedSec.position) || 0);
  }

  function editQuestionnaire() {
    history.push(
      `/counterpartySubmittedQuestionnaire/am/${accountQuestionnaireId}/${assetManagerId}/${sectionId}`
    );
  }

  function approveSubmissionDetails(data: ApproveSubmissionData) {
    approveSubmission({
      ...data,
      submittedQuestionnaireId: submittedQuestionnaireId,
      sectionId: questionnaire?.isSectionLevelApproval ? sectionId : null
    })
      .then(() => {
        setRefreshApprovals(refreshApprovals + 1);
        toast.success(t("Approved successfully"));
      })
      .catch((e) => {
        toast.error(utils.formatGraphQLError(e.message));
      });
  }

  async function addApprovalComments(data: AddComments) {
    createComment({
      ...data,
      submittedQuestionnaireId: submittedQuestionnaireId,
      sectionId: questionnaire?.isSectionLevelApproval ? sectionId : null
    })
      .then(() => {
        setRefreshApprovals(refreshApprovals + 1);
        toast.success(t("Comment added successfully"));
      })
      .catch((e) => {
        toast.error(utils.formatGraphQLError(e.message));
      });
  }

  async function updateApprovalComments(data: UpdateComments) {
    updateComment({
      ...data,
      submittedQuestionnaireId: submittedQuestionnaireId,
      sectionId: questionnaire?.isSectionLevelApproval ? sectionId : null
    })
      .then(() => {
        setRefreshApprovals(refreshApprovals + 1);
        toast.success(t("Comment updated successfully"));
      })
      .catch((e) => {
        toast.error(utils.formatGraphQLError(e.message));
      });
  }
  async function deleteApprovalComments(data: DeleteComments) {
    deleteComment({
      ...data,
      submittedQuestionnaireId: submittedQuestionnaireId,
      sectionId: questionnaire?.isSectionLevelApproval ? sectionId : null
    })
      .then(() => {
        setRefreshApprovals(refreshApprovals + 1);
        toast.success(t("Comment deleted successfully"));
      })
      .catch((e) => {
        toast.error(utils.formatGraphQLError(e.message));
      });
  }

  function changeStatus() {
    if (changeStatusData?.statusId) {
      apis
        .changeQuestionnaireStatus({
          accountQuestionnaireId,
          statusId: changeStatusData?.statusId,
          sectionId: changeStatusData?.sectionId || null
        })
        .then(() => {
          toast.success(t("Status changed successfully"));
          setChangeStatusData(undefined);
          setRefreshQuestionnaire(refreshQuestionnaire + 1);
          setRefreshApprovals(refreshApprovals + 1);
          setReopenRequestData(undefined);
        })
        .catch((e) => {
          toast.error(utils.formatGraphQLError(e.message));
        });
    } else {
      toast.error(t("Please select status"));
    }
  }

  function regenerateQuestionnairePDF() {
    apis
      .regenerateQuestionnairePDF({
        accountQuestionnaireId
      })
      .then((response: any) => {
        toast.success(response.data?.reGenerateQuestionnairePdf.message);
        setShowConfirmationModal(false);
      })
      .catch((e: any) => {
        toast.error(utils.formatGraphQLError(e.message));
      });
    setShowConfirmationModal(false);
  }

  function reopenRequest({
    changeSectionId,
    changeAccountQuestionnaireId,
    reopenRequest,
    sectionName,
    questionnaireName,
    currentStatus
  }: {
    changeSectionId?: string | number;
    changeAccountQuestionnaireId?: string | number;
    reopenRequest: ReopenRequestType | null;
    sectionName?: string;
    questionnaireName?: string;
    currentStatus: string;
  }) {
    let draftStatus = getChangableStatus({
      statusList: allSubmissionStatus,
      currentStatus,
      userType: userDetails.user_type
    })?.find((val: any) => val.name === DRAFT);
    if (changeSectionId) {
      setReopenRequestData({
        showModal: true,
        accountQuestionnaireId: accountQuestionnaireId,
        sectionId: changeSectionId,
        reopenRequest,
        sectionName,
        currentStatus
      });
      setChangeStatusData({
        accountQuestionnaireId: accountQuestionnaireId,
        sectionId: changeSectionId,
        statusId: draftStatus?.id,
        currentStatus
      });
    }
    if (changeAccountQuestionnaireId) {
      setReopenRequestData({
        showModal: true,
        accountQuestionnaireId: changeAccountQuestionnaireId,
        sectionId: undefined,
        reopenRequest,
        questionnaireName,
        currentStatus
      });
      setChangeStatusData({
        accountQuestionnaireId: changeAccountQuestionnaireId,
        sectionId: undefined,
        statusId: draftStatus?.id,
        currentStatus
      });
    }
  }
  function denyEditRequest() {
    if (reopenRequestData) {
      apis
        .rejectReopenRequest({
          accountQuestionnaireId: reopenRequestData.accountQuestionnaireId,
          sectionId: reopenRequestData?.sectionId || null
        })
        .then(() => {
          toast.success(t("Operation successfully"));
          setChangeStatusData(undefined);
          setReopenRequestData(undefined);
          setRefreshQuestionnaire(refreshQuestionnaire + 1);
          setRefreshApprovals(refreshApprovals + 1);
        })
        .catch((e: any) => {
          toast.error(utils.formatGraphQLError(e.message));
        });
    }
  }
  return questionnaire ? (
    <React.Fragment>
      <div
        className={`page-content cdd-auto-feature ${
          sidebarCollpased ? "hide-questionnaire-sidebar" : ""
        }`}
      >
        {!questionnaire?.isSectionLevelApproval &&
          statusLevelAccess?.showApprovals &&
          approvalDetails && (
            <QuestionnaireLevelApprovals
              allowApprovals={statusLevelAccess.allowApprovals}
              onAddComment={addApprovalComments}
              onUpdateComment={updateApprovalComments}
              onDeleteComment={deleteApprovalComments}
              onApprove={approveSubmissionDetails}
              approvalDetails={approvalDetails}
            />
          )}
        <div className="container">
          <div className="row">
            <div className="questionnaire-sidebar d-block col-lg-3">
              <nav className="side-nav">
                <SideBarSections
                  sections={questionnaire.sections}
                  selectedSection={getSelectedSection}
                  sidebarCollapse={() => setSidebarCollapsed(!sidebarCollpased)}
                  allowAnswerSubmit={false}
                  accountId={assetManagerId}
                  sectionId={sectionId}
                  submittedQuestionnaireId={submittedQuestionnaireId}
                  sidebarType={REVIEW}
                  accountQuestionnaireId={accountQuestionnaireId}
                />
              </nav>
            </div>
            {selectedSection ? (
              <>
                <article className="questionnaire col-lg-9">
                  <div
                    className="show-qu-icon"
                    onClick={() => setSidebarCollapsed(!sidebarCollpased)}
                  >
                    <Icons icon="expand-sidebar" />
                  </div>
                  <div className="card">
                    <div className="questionnaire__header">
                      <h2 className="h2 flex-center">
                        {selectedSection.title}
                        {selectedSection.internalUseOnly && (
                          // <span className="badge que-badge">
                          //   For internal use
                          // </span>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip
                                id={`internal-use-${selectedSection.id}`}
                              >
                                {t("For internal use")}
                              </Tooltip>
                            }
                          >
                            <span style={{ marginLeft: "10px" }}>
                              <Icons
                                className="text-primary"
                                icon="privateInternalShield"
                              />
                            </span>
                          </OverlayTrigger>
                        )}
                        {selectedSection.riskLevel && (
                          <div
                            className={`rating rating--medium ml-2 rating--${utils.getScoreColor(
                              selectedSection.riskLevel
                            )}`}
                          >
                            <div className="rating__number h5">
                              {utils.convertRiskLabels(
                                selectedSection.riskLevel
                              )}
                            </div>
                          </div>
                        )}
                      </h2>
                      {selectedSection.description && (
                        <div
                          className="text--muted custom_edit_text"
                          dangerouslySetInnerHTML={{
                            __html: selectedSection.description
                          }}
                        ></div>
                      )}

                      <div className="questionnaire__actions action-group">
                        {statusLevelAccess?.allowEdit && (
                          <>
                            {sectionAccessPermission({
                              permissions: selectedSection.permissions,
                              permissionType: ASSET_MANAGER_USER
                            }).editSection && (
                              <span
                                className="action"
                                onClick={() => {
                                  showEditWarning({
                                    status: selectedSection.status.name
                                  })
                                    ? setEditQuestionnaireModal(true)
                                    : editQuestionnaire();
                                }}
                              >
                                <Icons className="icon" icon={"edit"} />
                              </span>
                            )}

                            {questionnaire?.sectionSubmission &&
                              acceptRequestToReopen({
                                status: selectedSection.status.name
                              }) && (
                                <>
                                  {selectedSection?.reopenRequest
                                    ?.requestedAt && (
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip
                                          id={`reopen-info-questionnaire`}
                                        >
                                          {t(
                                            "dynamicStringTranslation.sectionReopenRequest",
                                            {
                                              username:
                                                selectedSection?.reopenRequest
                                                  ?.requestedBy,
                                              datetimestamp: utils.getDate(
                                                selectedSection?.reopenRequest
                                                  ?.requestedAt,
                                                "MMMM Do YYYY, h:mm:ss a"
                                              )
                                            }
                                          )}
                                        </Tooltip>
                                      }
                                    >
                                      <span
                                        className="action"
                                        onClick={() => {
                                          if (
                                            findUserPermissionsFromRoles([
                                              {
                                                name: USER_ADMIN,
                                                permissionType:
                                                  ASSET_MANAGER_USER
                                              },
                                              {
                                                name: ADMIN_SECTION_REOPEN_PERMISSION
                                              }
                                            ])
                                          ) {
                                            reopenRequest({
                                              changeSectionId:
                                                selectedSection.id,
                                              reopenRequest:
                                                selectedSection.reopenRequest ||
                                                null,
                                              sectionName:
                                                selectedSection.title,
                                              currentStatus:
                                                selectedSection.status.name
                                            });
                                          } else {
                                            permissionError();
                                          }
                                        }}
                                      >
                                        <Icons
                                          className="icon"
                                          icon="activeDocument"
                                        />
                                      </span>
                                    </OverlayTrigger>
                                  )}
                                </>
                              )}
                            {questionnaire?.sectionSubmission &&
                              allowStatusChange({
                                status: selectedSection.status?.name,
                                userType: userDetails.user_type
                              }) && (
                                <OverlayTrigger
                                  placement="top"
                                  rootClose
                                  overlay={
                                    <Tooltip id={`change-section-status`}>
                                      {t("Change status")}
                                    </Tooltip>
                                  }
                                >
                                  <span
                                    className="action"
                                    onClick={() => {
                                      if (
                                        findUserPermissionsFromRoles([
                                          {
                                            name: USER_ADMIN,
                                            permissionType: ASSET_MANAGER_USER
                                          },
                                          {
                                            name: ADMIN_STATUS_CHANGE_PERMISSION
                                          }
                                        ])
                                      ) {
                                        setChangeStatusData({
                                          showModal: true,
                                          accountQuestionnaireId,
                                          sectionId: selectedSection.id,
                                          currentStatus:
                                            selectedSection.status.name
                                        });
                                      } else {
                                        permissionError();
                                      }
                                    }}
                                  >
                                    <Icons
                                      className="icon"
                                      icon="circleStatusChange"
                                    />
                                  </span>
                                </OverlayTrigger>
                              )}
                          </>
                        )}
                        {(statusLevelAccess?.showPrivateComment ||
                          statusLevelAccess?.showPublicComment) && (
                          <span
                            className="action"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title={
                              selectedSection?.publicPrivateComments &&
                              selectedSection.publicPrivateComments.length > 0
                                ? t("View comments")
                                : t("Add comments for questions.")
                            }
                            onClick={() => setShowPPC(true)}
                          >
                            <Icons
                              className={`icon ${
                                selectedSection?.publicPrivateComments &&
                                selectedSection.publicPrivateComments.length > 0
                                  ? "text-primary"
                                  : ""
                              }`}
                              icon={
                                selectedSection?.publicPrivateComments &&
                                selectedSection.publicPrivateComments.length > 0
                                  ? "comment"
                                  : "add-comment"
                              }
                            />
                          </span>
                        )}
                      </div>
                    </div>
                    {questionnaire?.isSectionLevelApproval &&
                      statusLevelAccess?.showApprovals &&
                      approvalDetails && (
                        <SectionLevelApprovals
                          allowApprovals={statusLevelAccess.allowApprovals}
                          onAddComment={addApprovalComments}
                          onUpdateComment={updateApprovalComments}
                          onDeleteComment={deleteApprovalComments}
                          onApprove={approveSubmissionDetails}
                          approvalDetails={approvalDetails}
                        />
                      )}
                    <div className="questionnaire__body">
                      <div className="form response">
                        <React.Fragment>
                          {selectedSection.isRiskSection &&
                            selectedSection.riskCatOverview && (
                              <RiskCatOverview
                                overallQuestionnaireRiskLevel={
                                  selectedSection.questionnaireRiskLevel
                                }
                                riskCategorizationOverview={
                                  selectedSection.riskCatOverview
                                }
                                allowAnswerSubmit={false}
                              />
                            )}
                          {selectedSection && (
                            <SectionComponent
                              queryParams={queryParams}
                              itemId={selectedSectionPosition}
                              section={selectedSection}
                              isParent={true}
                              showPublicComment={
                                statusLevelAccess?.showPublicComment
                              }
                              showPrivateComment={
                                statusLevelAccess?.showPrivateComment
                              }
                              editPublicComment={
                                statusLevelAccess?.editPublicComment
                              }
                              editPrivateComment={
                                statusLevelAccess?.editPrivateComment
                              }
                              showAnswers={true}
                              sectionViewType={utils.REVIEW}
                              allowAnswerSubmit={false}
                              updateParent={(data) =>
                                setSelectedSection({ ...data })
                              }
                              currentUserId={userDetails.user_id}
                              submittedQuestionnaireId={
                                submittedQuestionnaireId
                              }
                              hiddenSections={questionnaire.sections.filter(
                                (section: Section) => !section.visibility
                              )}
                              deductionDetails={{
                                showDefaultScore: scoreAccessDetails.showScore,
                                showCurrentScore: scoreAccessDetails.showScore,
                                editScoring:
                                  scoreAccessDetails.editScore &&
                                  sectionAccessPermission({
                                    permissions: selectedSection.permissions,
                                    permissionType: ASSET_MANAGER_USER
                                  }).editSection,
                                scoreLabel: scoreAccessDetails.scoreLabel
                              }}
                              fileParams={{
                                accountLevelOneQuestionnaireId:
                                  accountQuestionnaireId,
                                permissions: selectedSection?.permissions
                              }}
                            />
                          )}
                        </React.Fragment>
                      </div>
                    </div>
                  </div>
                </article>
                {/* Modal for confirmation box start */}
                <ConfirmationModal
                  modalTitle={confirmationBoxData.title}
                  message={confirmationBoxData.message}
                  callBack={confirmationBoxData.callBack}
                  showConfirmButton={confirmationBoxData.showConfirmButton}
                  showConfirmationModal={showConfirmationModal}
                  onModalClose={() => {
                    setShowConfirmationModal(false);
                  }}
                />
              </>
            ) : (
              <NoDataFound message="Please select a section" />
            )}
          </div>
        </div>
      </div>
      {showPPC && selectedSection && (
        <PublicPrivateComments
          commentDetails={selectedSection.publicPrivateComments}
          submittedQuestionnaireId={submittedQuestionnaireId}
          // accountQuestionnaireId={accountQuestionnaireId}
          commentableId={selectedSection.id}
          commentableType="Section"
          allowComments={{
            showPublicComment: statusLevelAccess?.showPublicComment,
            showPrivateComment: statusLevelAccess?.showPrivateComment,
            editPublicComment: statusLevelAccess?.editPublicComment,
            editPrivateComment: statusLevelAccess?.editPrivateComment
          }}
          onClose={(comments) => {
            setShowPPC(false);
            (statusLevelAccess?.editPublicComment ||
              statusLevelAccess?.editPrivateComment) &&
              setSelectedSection({
                ...updateCommentInSection(selectedSection, comments)
              });
          }}
          currentUserId={userDetails.user_id}
        />
      )}
      {changeStatusData?.showModal && (
        <CustomModal
          modalTitle="Change status"
          classNames="modal__body p-0"
          showModal={changeStatusData?.showModal}
          onClose={() => {
            setChangeStatusData(undefined);
          }}
          showFooter={true}
          footer={
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <button
                className="btn btn-outline-primary"
                onClick={(e) => {
                  setChangeStatusData(undefined);
                }}
              >
                {t("Cancel")}
              </button>
              <button
                className="btn btn-primary"
                onClick={() => changeStatus()}
              >
                {t("Confirm")}
              </button>
            </div>
          }
        >
          <form
            className="form"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <div className="form-group">
              <div className="mt-3">
                *
                {t(
                  "On change of status, all the existing approvals will be reset (if present)."
                )}
              </div>
              <div className="mt-3">
                <label className="mb-1">{t("Change Status")}</label>
                <Select
                  options={utils.modifyForSelectBox(
                    (changeStatusData &&
                      getChangableStatus({
                        statusList: allSubmissionStatus,
                        currentStatus: changeStatusData.currentStatus,
                        userType: userDetails.user_type,
                        isClientManaged: !!questionnaire?.isClientManaged
                      })) ||
                      [],
                    "id",
                    "label"
                  )}
                  value={
                    changeStatusData.statusId
                      ? {
                          label: changeStatusData.statusLabel,
                          value: changeStatusData.statusId
                        }
                      : null
                  }
                  noOptionsMessage={() => t("No options")}
                  placeholder={`${t("Select")}...`}
                  isClearable={true}
                  isMulti={false}
                  onChange={({
                    id,
                    value,
                    label
                  }: {
                    id: string | number;
                    value: string | number;
                    label: string;
                  }) => {
                    setChangeStatusData({
                      ...changeStatusData,
                      statusId: id,
                      statusLabel: label
                    });
                  }}
                />
              </div>
            </div>
          </form>
        </CustomModal>
      )}
      {editQuestionnaireModal && (
        <CustomModal
          modalTitle="Edit"
          classNames="modal__body p-0"
          showModal={editQuestionnaireModal}
          onClose={() => {
            setEditQuestionnaireModal(false);
          }}
          showFooter={true}
          footer={
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <button
                className="btn btn-outline-primary"
                onClick={(e) => {
                  setEditQuestionnaireModal(false);
                }}
              >
                {t("Cancel")}
              </button>
              <button
                className="btn btn-primary"
                onClick={() => editQuestionnaire()}
              >
                {t("Confirm")}
              </button>
            </div>
          }
        >
          <div className="form-group">
            <div className="mt-3">
              {t(
                "If there are any existing approvals, they will be reset once you change the answers and re-submit section"
              )}
              .
            </div>
          </div>
        </CustomModal>
      )}
      <CustomModal
        modalTitle="Request to edit"
        classNames="modal"
        modalBodyClasses="modal__body p-0"
        showModal={reopenRequestData?.showModal || false}
        onClose={() => {
          setReopenRequestData(undefined);
        }}
      >
        <form
          className="form"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <div className="form-group">
            <div className="mt-3">
              <div className="loop-edit-questionnaire">
                <div className="text-muted">
                  {utils.getDate(
                    reopenRequestData?.reopenRequest?.requestedAt,
                    "MMMM Do YYYY, h:mm:ss a"
                  )}
                </div>
                <div className="loop-question-level">
                  {reopenRequestData?.reopenRequest?.requestedBy}{" "}
                  {t("has requested to edit")}{" "}
                  <strong>
                    {reopenRequestData?.sectionName ||
                      reopenRequestData?.questionnaireName}
                  </strong>
                  <br />
                  {reopenRequestData?.currentStatus === INTERNAL_DRAFT && (
                    <div className="color-red">
                      {t(
                        `Request to reopen cannot be allowed if ${
                          reopenRequestData?.sectionName
                            ? "section"
                            : "questionnaire"
                        } status is in Internal draft. Please complete ${
                          reopenRequestData?.sectionName
                            ? "the section"
                            : "all the sections"
                        } first`
                      )}
                    </div>
                  )}
                </div>
                <div className="loop-edit-questionnaire-btn">
                  <button
                    className={`btn ${
                      reopenRequestData?.currentStatus === INTERNAL_DRAFT
                        ? "btn-disabled"
                        : "btn-primary"
                    }`}
                    onClick={() => {
                      if (reopenRequestData?.currentStatus === INTERNAL_DRAFT)
                        return false;
                      changeStatusData?.statusId
                        ? changeStatus()
                        : toast.error(t("Request to edit cannot be allowed"));
                    }}
                  >
                    {t("Allow")}
                  </button>
                  <button
                    onClick={denyEditRequest}
                    className="btn btn-outline-secondary"
                  >
                    {t("Deny")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </CustomModal>
    </React.Fragment>
  ) : (
    <></>
  );
};

export default SubmittedQuestionnaire;
