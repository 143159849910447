import axios, { AxiosResponse } from "axios";
import { TUserDetails } from "../types/user-details";
import { CDD_MODULE_NAME } from "../constants/app-constants";
import UserService from "../services/userServices";

/**
 * The getUserDetails service uses Axios library to request user details. If the response status code is 401,
 * the user will be redirected to the SSO login page. Once successfully logged in via SSO, the user will be redirected
 * back to the requested page.
 *
 * @return {Promise} AxiosResponse<TUserDetails>
 */
export const getUserDetails = async (): Promise<
  AxiosResponse<TUserDetails>
> => {
  try {
    const userDetails: AxiosResponse<TUserDetails> = await axios.get(
      `
      ${process.env.REACT_APP_REST_ENDPOINT}/getUserDetails
    `,
      {
        withCredentials: true // For cross domain request, if cookies needs to be passed along request
      }
    );

    return userDetails;
  } catch (error) {
    let response = (error as any).response;
    // Axios error object provides api response within response.data field
    if (
      response &&
      response.status === 401 &&
      response.data.errors === "Session Expired"
    ) {
      // Redirect to SSO login page with redirect_url set to requested page if status code 401
      // window.location.href =
      //   process.env.REACT_APP_SSO_LOGIN_URL +
      //   `?module_name=${CDD_MODULE_NAME}&redirect_url=` +
      //   window.location.href;
      UserService.doLogout();

      throw new Error("redirect");
    } else if (
      response &&
      response.status === 403 &&
      response.data.errors === "Module Access Forbidden"
    ) {
      // Redirect user to swift landing page if current user account does not have access to CDD module
      window.location.href = process.env.REACT_APP_SWIFT_APP_URL as string;
      throw new Error("redirect");
    } else {
      throw new Error((error as any).message); // Throw generic error if status code is other than 401
    }
  }
};
