import client from "../apollo.config";
import * as queries from "./queries";

export const getQuestionnaireAnswers = (
  accountQuestionnaireId: string | number,
  sectionId: string | number
) => {
  return client.query({
    query: queries.EDIT_QUESTIONNAIRE_QUERY,
    fetchPolicy: "network-only",
    variables: { accountQuestionnaireId, sectionId }
  });
};

export const saveAnswers = (input: any) => {
  return client.mutate({
    mutation: queries.SAVE_ANSWERS,
    variables: { input }
  });
};

export const completeSubmission = (input: any) => {
  return client.mutate({
    mutation: queries.COMPLETE_SUBMISSION,
    variables: { input }
  });
};

export const submitQuestionnaire = (input: any) => {
  return client.mutate({
    mutation: queries.SUBMIT_QUESTIONNAIRE,
    variables: { input }
  });
};

export const requestReopen = ({
  sectionId,
  accountQuestionnaireId
}: {
  sectionId?: number | string | null;
  accountQuestionnaireId: number | string;
}) => {
  return client.mutate({
    mutation: queries.REQUEST_REOPEN_QUERY,
    variables: { input: { sectionId, accountQuestionnaireId } }
  });
};

export const autofillSection = (input: any) => {
  return client.mutate({
    mutation: queries.AUTOFILL_SECTION,
    variables: { input }
  });
};

export const regenerateQuestionnairePDF = ({
  accountQuestionnaireId
}: {
  accountQuestionnaireId: number | string;
}) => {
  return client.mutate({
    mutation: queries.REGENERATE_QUESTIONNAIRE_PDF,
    variables: { input: { accountQuestionnaireId } }
  });
};

export const changeQuestionnaireRiskLevel = ({
  accountQuestionnaireId,
  sectionId,
  riskLevelId
}: {
  accountQuestionnaireId: number | string;
  sectionId: number | string;
  riskLevelId: number | string;
}) => {
  return client.mutate({
    mutation: queries.CHANGE_QUESTIONNAIRE_RISK_LEVEL,
    variables: { input: { accountQuestionnaireId, sectionId, riskLevelId } }
  });
};
