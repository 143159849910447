import gql from "graphql-tag";

export const GET_QUESTIONNAIRES_LIST_QUERY = gql`
  query AssetManagerQuestionnaires(
    $assetManagerId: ID!
    $pageNumber: Int!
    $pageSize: Int!
    $search: String
    $sort: [SortingAttributes!]
  ) {
    assetManagerQuestionnaires(
      assetManagerId: $assetManagerId
      pageNumber: $pageNumber
      pageSize: $pageSize
      search: $search
      sort: $sort
    ) {
      assetManagerQuestionnaireId
      id
      questionnaireStatus
      title
      statusChangedAt
      notificationSubscribed {
        allPreferences {
          publicPrivateComments
          submissionStatusChange
          approvalComments
        }
      }
    }
    searchClientQuestionnaireCount(
      search: $search
      assetManagerId: $assetManagerId
    )
    totalClientQuestionnaireCount(assetManagerId: $assetManagerId)
  }
`;

export const SAVE_AM_NOTIFICATION_PREFERENCES = gql`
  mutation saveAmNotificationPreferences(
    $input: SaveAmNotificationPreferencesInput!
  ) {
    saveAmNotificationPreferences(input: $input) {
      message
    }
  }
`;
export const GET_ALL_QUESTIONNAIRES_FOR_AM = gql`
  query AssetManagerQuestionnaires($assetManagerId: ID!) {
    assetManagerQuestionnaires(assetManagerId: $assetManagerId) {
      assetManagerQuestionnaireId
      id
      title
    }
  }
`;
