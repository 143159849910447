import ZeidlerKeys from "../OrgKeyMap/zeidler";
import BHYKeys from "../OrgKeyMap/BHY";

export const CDD_MODULE_NAME = "cdd";

export enum QuestionnaireStatus {
  AwaitingClientApproval = "Awaiting client approval",
  AwaitingZeidlerReview = "Awaiting Zeidler review",
  AwaitingCommentsReview = "Awaiting comments review",
  Approved = "Approved"
}

export const OrgKeyMap =
  process.env.REACT_APP_ORGANIZATION === "zeidler" ? ZeidlerKeys : BHYKeys;
export const LOCKED = "locked";
export const UN_LOCKED = "unlocked";
export const APPROVED = "approved";
export const READY_FOR_APPROVAL = "ready for approval";
export const NOT_READY_FOR_APPROVAL = "not ready for approval";
export const SECTION_PENDING = "pending";
export const SECTION_COMPLETED = "complete";
export const SUBMIT = "Submit";
export const RISK_COUNTRY = "risk_country";
export const RISK_INDUSTRY = "risk_industry";
export const RISK_LEGAL_FORM = "risk_legal_form";
export const FOUR_EYE_CHECK = "4 Eye Check";
export const STATUS_CAPSULE_INFO = "info";
export const STATUS_CAPSULE_PRIMARY = "primary";
export const STATUS_CAPSULE_DISABLED = "disabled";
export const STATUS_CAPSULE_SUCCESS = "success";
export const ADDITION_RISK_METHOD = "addition";
export const DEDUCTION_RISK_METHOD = "deduction";
export const PDF_FILE_EXTENSION = "pdf";
export const ZIP_FILE_EXTENSION = "zip";
export const FILE_TYPE_QUESTIONNAIRE_PDF = "questionnairePdf";
export const FILE_TYPE_QUESTIONNAIRE_PDF_IN_ZIP = "questionnairePdfInZip";
export const FILE_TYPE_AML_REPORT = "amlReport";
export const ZEIDLER = "zeidler";
export const BHY = "BHY";
export const AM = "am";

// Risk constants

export const HIGH_RISK = "High Risk";
export const MODERATE_RISK = "Moderate Risk";
export const LOW_RISK = "Low Risk";
export const INCREASED_RISK = "Increased Risk";
export const HIGH_RISK_LABEL = "High";
export const LOW_RISK_LABEL = "Low";
export const MEDIUM_RISK_LABEL = "Medium";
export const INCREASED_RISK_LABEL = "Increased";
export const NO_LOADER_APIS = [
  `${process.env.REACT_APP_REST_ENDPOINT}/api/v1/createSectionLock`
];
export const ADMIN = "Admin";
export const NOTIFICATION_PREFERENCES_MAPPING: { [key: string]: string } = {
  publicPrivateComments: "Public/Private Comments",
  submissionStatusChange: "Status Change",
  approvalComments: "Approval Comments"
};
export const UNSUBSCRIBE_ALL = "Deselect";
export const SUBSCRIBE_ALL = "Select";
export const NOTIFICATION_PREFERENCES_CP_MAPPING: { [key: string]: string } = {
  publicPrivateComments: "Comments",
  submissionStatusChange: "Status Change"
};
export const SHARES_IN_FREE_FLOAT = "Shares in free float";
