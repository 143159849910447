import { SectionLink, SectionLocks } from "./types";
import { LOCKED, UN_LOCKED } from "../../../constants/app-constants";
import { SavedQuestion } from "./types";
import { Section } from "../SectionComponent/types";
import { Question, FieldValue, SubQuestion } from "../QuestionComponent/types";

export function getSectionLockStatus({
  currentLockedSectionId,
  sectionList
}: {
  currentLockedSectionId: number | string;
  sectionList: SectionLink[];
}) {
  let sectinLockDetails: SectionLocks[] = [];
  sectionList.forEach((section) => {
    let status;
    if (Number(section.id) === Number(currentLockedSectionId)) {
      status = LOCKED;
    } else {
      status = UN_LOCKED;
    }
    sectinLockDetails.push({
      section_id: section.id,
      status: status
    });
  });

  return sectinLockDetails;
}
export function determineEditForLock({
  lockData,
  loggedInUserId
}: {
  lockData: SectionLocks;
  loggedInUserId: string | number;
}) {
  if (String(lockData.locked_by_id) === String(loggedInUserId)) {
    return true;
  } else {
    return false;
  }
}

/*****************************************************************************************
 This function accepts mutable draft state of selected section and questions answered list. 
 Only checkbox and radio questions are considered for processing as these types of questions 
 might contain nested subquestions, whose answers needs to be removed (after the autosave call
 is successfull) once the parent option is unselected.
 Once the required question IDs are fetched, the questions are searched within the selected section.
 ***************************************************************************************************/

export function findSubQuesToRemove(
  draftState: any,
  questionAnsweredList: SavedQuestion[]
) {
  let filteredList = questionAnsweredList?.filter(
    (quesAnsweredObj: SavedQuestion) => {
      return ["checkbox", "radio_button"].includes(
        quesAnsweredObj.questionType
      );
    }
  );

  let questionIDList = filteredList?.map((quesAnswered: SavedQuestion) => {
    return quesAnswered.questionId;
  });

  draftState &&
    questionIDList?.length &&
    recursiveQuestionSearch(draftState, questionIDList);
}

/****************************************************************************************** 
 This function recursively search the answered questions in the root of the selected section.
 If match is found than it calls a function to identify the options whose answers needs to be removed.
 If match is not found than it calls the subQuestion search recursive function.
******************************************************************************************/

function recursiveQuestionSearch(
  currentSection: Section,
  questionIDList: (string | number)[]
) {
  currentSection.questions?.some((question: Question) => {
    if (questionIDList.includes(question.id)) {
      identifyOptionsToRemove(question);
      questionIDList.splice(questionIDList.indexOf(question.id), 1);
    } else {
      question.fieldValues?.some((fieldVal: FieldValue) => {
        questionIDList?.length &&
          recursiveSubQuestionSearch(fieldVal, questionIDList);
      });
    }
    if (!questionIDList.length) return true; // exit search when question answered list is empty
  });

  currentSection.subSections?.forEach((section: Section) => {
    questionIDList?.length && recursiveQuestionSearch(section, questionIDList);
  });
}

/****************************************************************************************** 
 This function recursively search the answered questions in the nested subquestions.
 If match is found than it calls a function to identify the options whose answers needs to be removed.
******************************************************************************************/

function recursiveSubQuestionSearch(
  fieldVal: FieldValue,
  questionIDList: (string | number)[]
) {
  fieldVal?.subQuestions?.some((subQ: SubQuestion) => {
    if (questionIDList?.includes(subQ.question.id)) {
      identifyOptionsToRemove(subQ.question);
      questionIDList?.splice(questionIDList.indexOf(subQ.question.id), 1);
      if (!questionIDList.length) return true; // exit search when question answered list is empty
    }
    if (["checkbox", "radio_button"].includes(subQ.question.fieldType.name)) {
      subQ.question.fieldValues?.forEach((fieldVal: FieldValue) => {
        fieldVal &&
          fieldVal.subQuestions?.length &&
          questionIDList?.length &&
          recursiveSubQuestionSearch(fieldVal, questionIDList);
      });
    }
  });
}

/****************************************************************************************** 
 This function identifies the options whose answers needs to be removed based on 
 whether the option is selected or not. If option is not selected then that option is eligible 
 for further processing where the answers are removed for the nested subquestions
******************************************************************************************/

function identifyOptionsToRemove(question: Question) {
  let answer: any;
  if (question.fieldType.name === "checkbox") {
    answer = question.answerDetails?.answer
      ? JSON.parse(question.answerDetails?.answer)
      : [];
  }
  if (question.fieldType.name === "radio_button") {
    answer = question.answerDetails?.answer || "";
  }
  let filteredFieldValues = question.fieldValues.filter(
    (fieldVal: FieldValue) => {
      return question.fieldType.name === "checkbox"
        ? answer?.indexOf(fieldVal.value) === -1
        : answer !== fieldVal.value;
    }
  );
  filteredFieldValues?.forEach((fieldVal: FieldValue) => {
    removeSubQuesAnswer(fieldVal);
  });
}

/****************************************************************************************** 
 This function recursively sets the answers for the subquestions to null once the autosave call
 is successfull
******************************************************************************************/

function removeSubQuesAnswer(fieldVal: FieldValue) {
  fieldVal?.subQuestions?.forEach((subQ: SubQuestion) => {
    let answerDetails: any = subQ.question.answerDetails;
    for (let key in answerDetails) {
      answerDetails[key] = null;
    }

    if (["checkbox", "radio_button"].includes(subQ.question.fieldType.name)) {
      subQ.question.fieldValues?.forEach((fieldVal: FieldValue) => {
        fieldVal &&
          fieldVal.subQuestions?.length &&
          removeSubQuesAnswer(fieldVal);
      });
    }
  });
}
