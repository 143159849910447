import React from "react";
import { useTranslation } from "react-i18next";
interface Props {
  errorMessage: string;
}
const FieldErrorMessage: React.FC<Props> = ({ errorMessage }) => {
  const { t } = useTranslation();
  return (
    <span style={{ color: "red", fontSize: "small" }}>* {t(errorMessage)}</span>
  );
};
export default FieldErrorMessage;
