import React, { useState, useRef, useEffect, useCallback } from "react";
import NotificationDetails from "../NotificationDetails";
import { CounterpartyNotificationTypes } from "../../../../types/applicationData";
import { saveCPNotificationPreferences } from "../../../../apis/ReceivedAssignmentsList";
import { toast } from "react-toastify";
import { formatGraphQLError } from "../../../../lib/utils";
import { NOTIFICATION_PREFERENCES_CP_MAPPING } from "../../../../constants/app-constants";
import { useTranslation } from "react-i18next";
interface Props {
  parentElementId: string;
  show: boolean;
  onClose: () => void;
  notificationDetails?: CounterpartyNotificationTypes;
  applyChanges?: (
    notifications: CounterpartyNotificationTypes,
    accountQuestionnaireId: string | number
  ) => void;
  accountId: string | number;
  accountQuestionnaireId?: string | number;
  userId: string | number;
}
const CounterpartyNotifications: React.FC<Props> = ({
  parentElementId,
  onClose,
  notificationDetails,
  applyChanges,
  accountId,
  accountQuestionnaireId,
  userId
}) => {
  const [boxPositionStyles, setBoxPositionStyles] =
    useState<{ top: string; left: string }>();
  const node = useRef<HTMLDivElement>(null);
  const handleClick = useCallback(
    (e: any) => {
      if (node.current && node.current.contains(e.target)) {
        return;
      }
      // outside click
      // ... do whatever on click outside here ...
      onClose();
    },
    [onClose]
  );
  const { t } = useTranslation();
  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [handleClick]);
  useEffect(() => {
    if (parentElementId) {
      let elementRef = document.getElementById(parentElementId);
      if (elementRef) {
        setBoxPositionStyles({
          top: `${
            elementRef.getBoundingClientRect().top + 30 + window?.pageYOffset
          }px`,
          left: `${
            elementRef.getBoundingClientRect().left - 300 + window?.pageXOffset
          }px`
        });
      }
    }
  }, [parentElementId]);
  const saveNotificationPreferences = useCallback(
    (
      notificationDetails: CounterpartyNotificationTypes,
      accountQuestionnaireId: string[] | number[]
    ) => {
      accountQuestionnaireId?.length &&
        saveCPNotificationPreferences({
          userId,
          accountId,
          accountQuestionnaireId: accountQuestionnaireId,
          ...notificationDetails
        })
          .then(() => {
            toast.success(t("Notification preferences saved successfully"));
            applyChanges &&
              applyChanges(notificationDetails, accountQuestionnaireId[0]);
            onClose();
          })
          .catch((e: Error) => {
            console.error(e);
            toast.error(formatGraphQLError(e.message));
          });
    },
    [userId, accountId, applyChanges, onClose]
  );
  return (
    <span ref={node}>
      {notificationDetails && accountQuestionnaireId && (
        <NotificationDetails
          notificationPreferences={NOTIFICATION_PREFERENCES_CP_MAPPING}
          boxPosition={boxPositionStyles}
          show={accountQuestionnaireId ? true : false}
          notificationDetails={notificationDetails}
          applyChanges={(notifications: CounterpartyNotificationTypes) => {
            accountQuestionnaireId &&
              saveNotificationPreferences(notifications, [
                String(accountQuestionnaireId)
              ]);
          }}
          onClose={onClose}
        />
      )}
    </span>
  );
};

export default CounterpartyNotifications;
