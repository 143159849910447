import React from "react";
import Select from "react-select";
import "./styles.scss";
import i18n from "../../../i18n";

type PropsType = {
  name: string;
  options: any[];
  defaultValue?: any[];
  placeholder?: string;
  onChange: any;
  onBlur?: any;
};

const MultiSelect: React.FC<PropsType> = ({
  name,
  options,
  defaultValue = [],
  placeholder,
  onChange,
  onBlur
}) => {
  return (
    <>
      <Select
        options={options}
        name={name}
        onChange={(options: any) => {
          onChange && onChange(name, options || []);
        }}
        noOptionsMessage={() => i18n.t("No options")}
        onBlur={() => onBlur && onBlur(name, true)}
        placeholder={placeholder}
        isClearable={true}
        isMulti={true}
        defaultValue={defaultValue}
        className="cdd-dropdown-multiselect"
      />
    </>
  );
};

export default MultiSelect;
