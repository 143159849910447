import { isAssetManager, isCounterParty, isAdminUser } from "./utils";
import {
  USER_ADMIN,
  findUserPermissionsFromRoles,
  COUNTERPARTY_USER,
  ASSET_MANAGER_USER
} from "./questionnairePermissionsAccess";
import {
  ADDITION_RISK_METHOD,
  DEDUCTION_RISK_METHOD,
  ZEIDLER,
  BHY
} from "../constants/app-constants";

export const NEW = "new";
export const READY_FOR_CLIENT_APPROVAL = "ready_for_client_approval";
export const UNDER_REVIEW = "under_review";
export const VALID = "valid";
export const ARCHIVED = "archived";
export const MISSING_INFORMATION = "missing_information";
export const DRAFT = "draft";
export const COMPLETE = "complete";
export const INTERNAL_DRAFT = "internal_draft";

export function isEditAllowedForQuesStatus({
  questionnaireStatus,
  userType,
  sectionStatus,
  isSectionLevelSubmission
}: {
  questionnaireStatus: string;
  userType: string;
  sectionStatus?: string;
  isSectionLevelSubmission?: boolean;
}) {
  if (
    isCounterParty(userType) &&
    sectionStatus?.toLowerCase() !== UNDER_REVIEW.toLowerCase() &&
    sectionStatus?.toLowerCase() !== READY_FOR_CLIENT_APPROVAL.toLowerCase() &&
    [UNDER_REVIEW, READY_FOR_CLIENT_APPROVAL, ARCHIVED, VALID].indexOf(
      questionnaireStatus
    ) === -1
  ) {
    return true;
  }
  if (
    (isAssetManager(userType) || isAdminUser(userType)) &&
    [ARCHIVED].indexOf(questionnaireStatus) === -1
    && sectionStatus && [ARCHIVED].indexOf(sectionStatus) === -1
  ) {
    return true;
  }
  return false;
}

export function allowPublicPrivateComments({
  questionnaireStatus,
  userType,
  sectionStatus,
  isSectionLevelSubmission
}: {
  questionnaireStatus: string;
  userType: string;
  sectionStatus?: string;
  isSectionLevelSubmission?: boolean;
}) {
  let permissions = {
    showPublicComment: false,
    showPrivateComment: false,
    editPublicComment: false,
    editPrivateComment: false
  };
  // if (isAssetManager(userType) || isAdminUser(userType)) {
  if (
    [VALID, ARCHIVED].indexOf(questionnaireStatus) !== -1 ||
    [VALID, ARCHIVED].indexOf(sectionStatus || "") !== -1
  ) {
    return {
      showPublicComment: true,
      showPrivateComment: true,
      editPublicComment: false,
      editPrivateComment: false
    };
  }
  if (
    (!isSectionLevelSubmission &&
      [
        UNDER_REVIEW,
        READY_FOR_CLIENT_APPROVAL,
        MISSING_INFORMATION,
        INTERNAL_DRAFT
      ].indexOf(questionnaireStatus) !== -1) ||
    (isSectionLevelSubmission &&
      [
        UNDER_REVIEW,
        READY_FOR_CLIENT_APPROVAL,
        MISSING_INFORMATION,
        INTERNAL_DRAFT
      ].indexOf(sectionStatus || "") !== -1)
  ) {
    if (isAssetManager(userType) || isAdminUser(userType)) {
      return {
        showPublicComment: true,
        showPrivateComment: true,
        editPublicComment: true,
        editPrivateComment: true
      };
    }
    if (isCounterParty(userType)) {
      return {
        showPublicComment: true,
        showPrivateComment: false,
        editPublicComment: true,
        editPrivateComment: false
      };
    }
  }
  //}

  return permissions;
}
export function allowApprovals({
  questionnaireStatus,
  isSectionLevelApproval,
  sectionStatus
}: {
  questionnaireStatus: string;
  sectionStatus: string;
  isSectionLevelApproval: boolean;
}) {
  let permissions = {
    showApprovals: false,
    allowApprovals: false
  };
  if (isSectionLevelApproval) {
    if ([NEW.toLowerCase()].indexOf(sectionStatus.toLowerCase()) === -1) {
      permissions.showApprovals = true;
    }
    if (
      [READY_FOR_CLIENT_APPROVAL.toLowerCase()].indexOf(
        sectionStatus.toLowerCase()
      ) !== -1
    ) {
      permissions.allowApprovals = true;
    }
    return permissions;
  }
  if (!isSectionLevelApproval) {
    if ([NEW.toLowerCase()].indexOf(questionnaireStatus.toLowerCase()) === -1) {
      permissions.showApprovals = true;
    }
    if (
      [READY_FOR_CLIENT_APPROVAL.toLowerCase()].indexOf(
        questionnaireStatus.toLowerCase()
      ) !== -1
    ) {
      permissions.allowApprovals = true;
    }
    return permissions;
  }
  return permissions;
}

export function showEditWarning({ status }: { status: string }) {
  if ([READY_FOR_CLIENT_APPROVAL, VALID].indexOf(status) !== -1) {
    return true;
  }
  return false;
}

export function allowReopen({
  status,
  userType
}: {
  status: string;
  userType: string;
}) {
  if (
    isCounterParty(userType) &&
    findUserPermissionsFromRoles([
      { name: USER_ADMIN, permissionType: COUNTERPARTY_USER }
    ]) &&
    [UNDER_REVIEW, READY_FOR_CLIENT_APPROVAL, INTERNAL_DRAFT].indexOf(
      status
    ) !== -1
  ) {
    return true;
  }
  return false;
}

export function acceptRequestToReopen({ status }: { status: string }) {
  if (
    [UNDER_REVIEW, READY_FOR_CLIENT_APPROVAL, INTERNAL_DRAFT].indexOf(
      status
    ) !== -1
  ) {
    return true;
  }

  return false;
}

export function allowStatusChange({
  status,
  userType
}: {
  status: string;
  userType: string;
}) {
  switch (process.env.REACT_APP_ORGANIZATION) {
    case ZEIDLER:
      if (
        (isAdminUser(userType) && [NEW].indexOf(status) === -1) ||
        (isAssetManager(userType) &&
          [READY_FOR_CLIENT_APPROVAL].indexOf(status) !== -1)
      ) {
        return true;
      }
      break;
    default:
      if (
        (isAdminUser(userType) || isAssetManager(userType)) &&
        [
          UNDER_REVIEW,
          READY_FOR_CLIENT_APPROVAL,
          INTERNAL_DRAFT,
          VALID
        ].indexOf(status) !== -1
      ) {
        return true;
      }
  }

  return false;
}

export function getChangableStatus({
  statusList,
  currentStatus,
  userType,
  isClientManaged = false
}: {
  statusList: any;
  currentStatus: string;
  userType: string;
  isClientManaged?: boolean;
}) {
  if (!statusList.length) {
    return statusList;
  }
  switch (process.env.REACT_APP_ORGANIZATION) {
    case ZEIDLER:
      if (isAdminUser(userType)) {
        if (currentStatus === UNDER_REVIEW) {
          return statusList.filter((status: any) => {
            return (
              [
                MISSING_INFORMATION,
                DRAFT,
                READY_FOR_CLIENT_APPROVAL,
                ARCHIVED
              ].indexOf(status.name) !== -1
            );
          });
        }
        if (currentStatus === READY_FOR_CLIENT_APPROVAL) {
          return statusList.filter((status: any) => {
            let list = [MISSING_INFORMATION, ARCHIVED, DRAFT];
            if (!isClientManaged) list.push(UNDER_REVIEW);
            return list.indexOf(status.name) !== -1;
          });
        }
        return statusList.filter((status: any) => {
          return [ARCHIVED].indexOf(status.name) !== -1;
        });
      }
      if (isAssetManager(userType)) {
        if (currentStatus === READY_FOR_CLIENT_APPROVAL) {
          return statusList.filter((status: any) => {
            return (
              [MISSING_INFORMATION, DRAFT, ARCHIVED].indexOf(status.name) !== -1
            );
          });
        }
      }
      break;
    default:
      if (currentStatus === VALID) {
        return statusList.filter((status: any) => {
          return [ARCHIVED].indexOf(status.name) !== -1;
        });
      }
      return statusList.filter((status: any) => {
        return (
          [MISSING_INFORMATION, DRAFT, ARCHIVED].indexOf(status.name) !== -1
        );
      });
  }
  return [];
}

export function scoreAccess({
  riskCategorizationMethod,
  questionaireStatus = "",
  sectionStatus = "",
  isSectionLevelSubmission
}: {
  riskCategorizationMethod: string;
  questionaireStatus?: string;
  sectionStatus?: string;
  isSectionLevelSubmission: boolean;
}) {
  let scoreAccess = {
    showScore: false,
    editScore: false,
    scoreLabel:
      riskCategorizationMethod === ADDITION_RISK_METHOD ? "Score" : "Deductions"
  };
  if (
    [ADDITION_RISK_METHOD, DEDUCTION_RISK_METHOD].indexOf(
      riskCategorizationMethod
    ) !== -1
  ) {
    if (
      (!isSectionLevelSubmission &&
        [UNDER_REVIEW, READY_FOR_CLIENT_APPROVAL].indexOf(
          questionaireStatus
        ) !== -1) ||
      (isSectionLevelSubmission &&
        [UNDER_REVIEW, READY_FOR_CLIENT_APPROVAL].indexOf(sectionStatus) !== -1)
    ) {
      scoreAccess.showScore = true;
      scoreAccess.editScore = true;
    }
    if (
      (!isSectionLevelSubmission &&
        [VALID, ARCHIVED, MISSING_INFORMATION].indexOf(questionaireStatus) !==
          -1) ||
      (isSectionLevelSubmission &&
        [VALID, ARCHIVED, MISSING_INFORMATION].indexOf(sectionStatus) !== -1)
    ) {
      scoreAccess.showScore = true;
      scoreAccess.editScore = false;
    }
  }
  return scoreAccess;
}
