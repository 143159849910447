import gql from "graphql-tag";
import { publicPrivateComments } from "../CounterpartySubmittedQuestionnaire/queries";

const questionFragment = `
    id
    questionBankId
    title
    label
    placeholder
    helpInfo
    mandatory
    internalUseOnly
    deletedAt
    position
    answerDetails(submittedQuestionnaireId: $submittedQuestionnaireId){
        answer
        answeredBy
        answeredByAccountName
        updatedAt
        riskLevel
        publicPrivateComments{
            ${publicPrivateComments}
          }
        datasetAnswers {
            datasetQuestionAnswerId
            datasetQuestionId
            firstAnswer
          }
        id
        uploadedFiles{
            id
            originalFileName
            timeStampedFileName
        }
    }
`;

const fieldValuesFragment = `
    id
    label
    value
    defaultDeductions 
    currentDeductions
    isDeductionNotesPresent
    position
    deletedAt
    sectionId
`;

const fieldTypeFragment = `
    name
    id
`;

const questionListFragment = `
    ${questionFragment}
    fieldType {
        ${fieldTypeFragment}
    }
    fieldValues (submittedQuestionnaireId: $submittedQuestionnaireId){
        ${fieldValuesFragment}
        subQuestions {
            question {
                ${questionFragment}
                fieldType {
                    ${fieldTypeFragment}
                }
                fieldValues (submittedQuestionnaireId: $submittedQuestionnaireId){
                    ${fieldValuesFragment}
                    subQuestions {
                        question {
                            ${questionFragment}            
                            fieldType {
                                ${fieldTypeFragment}
                            }
                            fieldValues (submittedQuestionnaireId: $submittedQuestionnaireId){
                                ${fieldValuesFragment}
                                subQuestions {
                                    question {
                                        ${questionFragment}                    
                                        fieldType {
                                            ${fieldTypeFragment}
                                        }
                                        fieldValues (submittedQuestionnaireId: $submittedQuestionnaireId){
                                            ${fieldValuesFragment}
                                            subQuestions {
                                                question {
                                                    ${questionFragment}
                                                    fieldType {
                                                        ${fieldTypeFragment}
                                                    }
                                                    fieldValues (submittedQuestionnaireId: $submittedQuestionnaireId){
                                                        ${fieldValuesFragment}
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const sectionFragment = `
id
title
parentId
position
description
permissions{
  name
}
autofill
visibility
internalUseOnly
deletedAt
submittedQuestionnaireStatus{
  id
  name
  label
}
status{
  id
  label
  name
}
reopenRequest{
  requestedAt
  requestedBy
}
publicPrivateComments{
    ${publicPrivateComments}
  }
questions{
    ${questionListFragment}
}
isRiskSection
riskLevel
riskCatOverview{
  sections{
    title
    riskLevel
    questions{
      title
      score
      riskLevel
      answer
    }
  }
}
questionnaireRiskLevel
`;

export const GET_SUBMITTED_QUESTIONNAIRE_QUERY = gql`
  query getSubmittedQuestionnaireDetails($submittedQuestionnaireId: ID!,$accountQuestionnaireId:ID,$sectionId:ID!) { 
    getSubmittedQuestionnaireDetails(
        submittedQuestionnaireId: $submittedQuestionnaireId,
        accountQuestionnaireId:$accountQuestionnaireId,
        sectionId:$sectionId
    ) {
      id
      amlReport
      questionnairePdfError
      title
      accountLogo
      validUntil
      score 
      riskCategorizationMethod
      questionnaireStatus{
        id
        label
        name
      }
      isClientManaged
      isSectionLevelApproval
      sectionSubmission
      reopenRequest{
        requestedAt
        requestedBy
      }
      sections{
        id
        title
        autofill
        visibility
        riskLevel
        internalUseOnly
        permissions{
          name
        }
        status{
          id
          label
          name
        }
        submissionDate
        submittedQuestionnaireId
        submittedQuestionnaireStatus{
          id
          label
          name
        }
        accountQuestionnaireId
      }
      sectionDetail{
        ${sectionFragment}
        subSections {
          ${sectionFragment}
          subSections{
            ${sectionFragment}
          }
        }
      }
    }
  }
`;
export const CHANGE_QUESTIONNAIRE_STATUS = gql`
  mutation ChangeSubmissionStatus($input: ChangeSubmissionStatusInput!) {
    changeSubmissionStatus(input: $input) {
      message
    }
  }
`;
export const GET_SCORING_NOTES_QUERY = gql`
  query deductions($fieldValueId: ID!, $questionAnswerId: ID!) {
    deductions(
      fieldValueId: $fieldValueId
      questionAnswerId: $questionAnswerId
    ) {
      createdAt
      id
      note
      point
      userId
      userName
    }
  }
`;
export const SAVE_SCORING_DETAILS_QUERY = gql`
  mutation saveDeductionAndNotes($input: SaveDeductionAndNotesInput!) {
    saveDeductionAndNotes(input: $input) {
      message
      latestDeduction
      latestNote
    }
  }
`;
export const REJECT_REOPEN_REQUEST_QUERY = gql`
  mutation rejectReopenRequest($input: RejectReopenRequestInput!) {
    rejectReopenRequest(input: $input) {
      message
    }
  }
`;

export const REGENERATE_QUESTIONNAIRE_PDF = gql`
  mutation RegenerateQuestionnairePDF(
    $input: ReGenerateQuestionnairePdfInput!
  ) {
    reGenerateQuestionnairePdf(input: $input) {
      message
    }
  }
`;
