import gql from "graphql-tag";
export const GET_ALL_SUBMISSION_STATUS_QUERY = gql`
  query getSubmissionStatuses {
    getSubmissionStatuses {
      label
      id
      name
    }
  }
`;
