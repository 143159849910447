import React, { useState, useEffect } from "react";
import ApprovalSteps from "../ApprovalSteps";
import ApprovalComments from "../ApprovalComments";
import {
  ApprovalDetails,
  ApproveSubmissionData,
  AddComments,
  UpdateComments,
  DeleteComments
} from "../../SubmittedQuestionnaire/types";
import ApproveButton from "../ApproveButton";
import { useTranslation } from "react-i18next";
import { useQueryParams } from "../../../../Hooks/useQueryParams";
import { QueryPrameters } from "../../../../types/applicationData";
type Props = {
  approvalDetails: ApprovalDetails[];
  onApprove: (approveData: ApproveSubmissionData) => void;
  onAddComment: (comments: AddComments) => void;
  onUpdateComment: (comments: UpdateComments) => void;
  onDeleteComment: (comments: DeleteComments) => void;
  allowApprovals: boolean;
};

const SectionLevelApprovals: React.FC<Props> = ({
  approvalDetails,
  onApprove,
  onAddComment,
  onUpdateComment,
  onDeleteComment,
  allowApprovals
}) => {
  const queryParams: QueryPrameters | undefined = useQueryParams({
    paramNames: ["approvalCommentId"],
    isRemoveQueryParams: false
  });
  const { t } = useTranslation();
  const [showComments, setShowComments] = useState<boolean>(false);
  useEffect(() => {
    if (queryParams?.approvalCommentId) {
      setShowComments(true);
    }
  }, [queryParams]);
  return (
    <>
      {approvalDetails?.length ? (
        <div className="approval-workflow-wrp">
          <div className="approve-heading-wrp">
            <div className="approve-heading-box">
              <div className="approve-heading">{t("Approval")}</div>
              <div className="approve-heading-sub-text">
                {t("The section requires the following approval")}
              </div>
            </div>
            <div className="approve-heading-btn-wrp">
              <div className="approve-heading-btn">
                <button
                  className="btn btn-underline"
                  data-toggle="modal"
                  data-target="#approval-add-comment"
                  onClick={() => setShowComments(true)}
                >
                  {t("View/Add comments")}
                </button>
                {allowApprovals && (
                  <ApproveButton
                    buttonName="Approve section"
                    approvalDetails={approvalDetails}
                    onApprove={onApprove}
                  />
                )}
              </div>
            </div>
          </div>
          <ApprovalSteps approvalDetails={approvalDetails} />
        </div>
      ) : (
        <div className="approval-workflow-wrp">
          {t("Approvals are not configured for this section")}
        </div>
      )}
      {showComments && (
        <ApprovalComments
          allowApprovals={allowApprovals}
          onAddComment={onAddComment}
          showModal={showComments}
          approvalDetails={approvalDetails}
          onClose={() => setShowComments(false)}
          onUpdateComment={onUpdateComment}
          onDeleteComment={onDeleteComment}
        />
      )}
    </>
  );
};
export default SectionLevelApprovals;
