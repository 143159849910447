import { Section } from "../SectionComponent/types";
import { DataSetRecord } from "./types";
export function updateSectionsWithComment({
  records,
  section
}: {
  records: DataSetRecord[];
  section: Section;
}) {
  let secIndex = records.findIndex(
    (val: DataSetRecord) => val.datasetSection?.id === section.id
  );
  let updatedRecords = [...records];
  updatedRecords[secIndex].datasetSection = section;
  return updatedRecords;
}
