import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import Table from "../../presentation/Table";
import Flatpickr from "react-flatpickr";
import DOMPruify from "dompurify";
import {
  FieldValue,
  SubQuestion,
  Question,
  Answer,
  ValidationError,
  TAnsweredByDetails
} from "./types";
import Icons from "../../presentation/Icons";
import { CommentableType } from "../ReviewQuestionnaire/types";
import InputField from "../../presentation/InputField";
import MultiSelect from "../../presentation/MultiSelect";
import Deductions from "../../presentation/Deductions";
import Checkbox from "../../presentation/Checkbox";
import RadioButton from "../../presentation/RadioButton";
import Files from "../../presentation/Files";
import * as utils from "../../../lib/utils";
import {
  getSelectDefaultValue,
  showSubQuestions,
  updateAnswerInSubQuestion,
  getUpdatedQuestion,
  updateCommentsInAnswer,
  updateFieldValueScore
} from "./services";
import { deductionDetails } from "../../presentation/Deductions/types";
import Select from "react-select";
import DataSet from "../DataSetComponent";
import PublicPrivateComments from "../../container/PublicPrivateComments";
import UBOEngine from "../../UBOEngine";
import {
  RiskLegalForm,
  RiskIndustry,
  RiskCountry,
  QueryParamActions,
  QueryPrameters
} from "../../../types/applicationData";
import {
  RISK_COUNTRY,
  RISK_INDUSTRY,
  RISK_LEGAL_FORM
} from "../../../constants/app-constants";
import { appData } from "../../../services/appConfig";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Section } from "../SectionComponent/types";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { useCommentScroll } from "../../../Hooks/useCommentScroll";
type Props = {
  queryParams?: QueryPrameters | null | undefined;
  itemIndex: number | string;
  question: Question;
  showComments?: (commentData: any | null) => void;
  showPublicComment?: boolean;
  showPrivateComment?: boolean;
  editPublicComment?: boolean;
  editPrivateComment?: boolean;
  updateParent?: (question: Question) => void;
  isSubQuestion?: boolean;
  isInParentSection?: boolean;
  indexKey?: number;
  hiddenSections?: any;
  showAnswers?: boolean;
  sectionViewType: string;
  allowAnswerSubmit?: boolean;
  deductionDetails?: deductionDetails;
  validationErrors?: ValidationError[];
  showErrors?: boolean;
  submittedQuestionnaireId?: string | number;
  accountQuestionnaireId?: string | number;
  currentUserId: string | number;

  onFieldValChange?: ({
    ques,
    answer,
    additionalInfo,
    updatedQuestion
  }: {
    ques: Question;
    answer: any;
    additionalInfo?: any;
    updatedQuestion: Question;
  }) => void;
  fileParams?: any;
  dataSetSectionList?: Section[];
};

const QuestionComponent: React.FC<Props> = ({
  queryParams,
  itemIndex,
  question,
  showComments,
  showPublicComment,
  showPrivateComment,
  editPublicComment,
  editPrivateComment,
  updateParent,
  isSubQuestion,
  isInParentSection,
  indexKey,
  hiddenSections,
  showAnswers,
  sectionViewType,
  allowAnswerSubmit,
  deductionDetails,
  validationErrors,
  showErrors,
  onFieldValChange,
  submittedQuestionnaireId,
  accountQuestionnaireId,
  currentUserId,
  fileParams,
  dataSetSectionList
}) => {
  const commentRef = useRef<any>();
  const queryParamActions = useRef<QueryParamActions>({
    hasScrolled: false,
    hasPopupOpened: false
  });
  const { t } = useTranslation();
  const { sectionId }: { sectionId: number | string } = useParams<any>();
  const [riskQuestionOptions, setRiskQuestionOptions] = useState<
    RiskCountry[] | RiskLegalForm[] | RiskIndustry[]
  >([]);
  useEffect(() => {
    if (
      question.fieldType.name === RISK_COUNTRY &&
      !riskQuestionOptions.length
    ) {
      appData.fetchRiskCountries().then((res: any) => {
        setRiskQuestionOptions(res);
      });
    }
    if (
      question.fieldType.name === RISK_LEGAL_FORM &&
      !riskQuestionOptions.length
    ) {
      appData.fetchRiskLegalForm().then((res: any) => {
        setRiskQuestionOptions(res);
      });
    }
    if (
      question.fieldType.name === RISK_INDUSTRY &&
      !riskQuestionOptions.length
    ) {
      appData.fetchRiskIndustries().then((res: any) => {
        setRiskQuestionOptions(res);
      });
    }
  }, []);
  const [showPPC, setShowPPC] = useState<Boolean>(false);
  //This effect is used to open the comment pop up if query params are present
  // useEffect(() => {
  //   if (
  //     queryParams?.questionId &&
  //     question &&
  //     String(question.id) === String(queryParams.questionId)
  //   ) {
  //     if (!queryParamActions.current.hasScrolled) {
  //       commentRef?.current?.scrollIntoView();
  //     }
  //     if (
  //       !queryParamActions.current.hasScrolled &&
  //       !queryParamActions.current.hasPopupOpened
  //     ) {
  //       setShowPPC(true);
  //       queryParamActions.current.hasPopupOpened = true;
  //     }
  //   }
  // });
  useCommentScroll({
    callBack: () => {
      setShowPPC(true);
    },
    commentRef,
    queryParamActions: queryParamActions.current,
    idFromQueryParam: queryParams?.questionId,
    entityId: question?.id
  });
  let questionlevel =
    question.fieldValues.length > 0 ? (indexKey ? indexKey + 1 : 1) : 0;
  //PPC= public private comments

  function handleComments(e: any) {
    e.stopPropagation();
    showComments &&
      showComments({
        commentableId: question.id,
        commentableType: CommentableType.Question,
        comments: question.comments
      });
  }
  function handlePPC() {
    setShowPPC(true);
  }
  // function updateParent({
  //   allSubQuestions,
  //   parentQuestion,
  //   currentQuestion,
  //   answer,
  //   additionalInfo
  // }: {
  //   allSubQuestions: any;

  //   parentQuestion: Question;
  //   currentQuestion: Question;
  //   answer: any;
  //   additionalInfo?: any;
  // }) {
  //   let updatedList = updateAnswerInSubQuestion({
  //     subQuestions: allSubQuestions,
  //     question: currentQuestion,
  //     answer
  //   });
  //   // onFieldValChange &&
  //   //   onFieldValChange(parentQuestion, answer, additionalInfo, updatedList);
  // }
  const AnsweredByDetails = ({
    answeredBy,
    answeredByAccountName,
    updatedAt
  }: TAnsweredByDetails) => {
    return (
      <>
        <div className="user-basic-detail-in">
          <span>{t("Last Update on")} </span>
          <span className="user-basic-detail-date">
            &nbsp;
            {utils.getDate(updatedAt)}&nbsp;
            {utils.getTime(updatedAt, "hh:mm a")}
            &nbsp;
          </span>
          <span className="user-basic-detail-by">{t("by")}</span>
          <span className="user-basic-detail-name notranslate">
            &nbsp;{answeredBy}&nbsp;
          </span>
          <span className="user-basic-detail-user notranslate">
            {" "}
            ({answeredByAccountName})
          </span>
        </div>
      </>
    );
  };
  function getQuestionTypeFields(question: Question) {
    const questionType = question.fieldType.name;
    let answer = {} as Answer;
    if (showAnswers && question?.answerDetails) {
      answer = question.answerDetails;
    }
    switch (questionType) {
      case "text_area":
        return (
          <>
            <InputField
              inputType={questionType}
              id={itemIndex}
              name={`text-area-${itemIndex}`}
              isDisabled={!allowAnswerSubmit}
              value={answer.answer}
              placeholder={question.placeholder || ""}
              onChange={({ target }: { target: any }) => {
                onFieldValChange &&
                  onFieldValChange({
                    ques: question,
                    updatedQuestion: getUpdatedQuestion({
                      question,
                      answer: target.value
                    }),
                    answer: target.value
                  });
              }}
            />
            {question?.answerDetails?.answeredBy &&
              question?.answerDetails?.updatedAt && (
                <AnsweredByDetails
                  answeredBy={question.answerDetails?.answeredBy}
                  answeredByAccountName={
                    question.answerDetails?.answeredByAccountName
                  }
                  updatedAt={question.answerDetails?.updatedAt}
                />
              )}
            {showErrors &&
              validationErrors &&
              validationErrors
                .filter((error) => error.questionId === question.id)
                .map((error, index: number) => {
                  return (
                    <div key={index} style={{ color: "red" }}>
                      {error.errors.join(" ")}
                    </div>
                  );
                })}
          </>
        );
      case "file":
        return (
          <>
            <Files
              uploadFiles={allowAnswerSubmit}
              downloadFiles={showAnswers}
              files={answer.uploadedFiles}
              onChange={(
                files: [
                  {
                    originalFileName: string;
                    timeStampedFileName: string;
                  }
                ]
              ) => {
                onFieldValChange &&
                  onFieldValChange({
                    ques: question,
                    updatedQuestion: getUpdatedQuestion({
                      question,
                      answer: JSON.stringify(files)
                    }),
                    answer: JSON.stringify(files)
                  });
              }}
              uploaderId={question.id.toString()}
              fileParams={{
                ...fileParams,
                ...{ fileType: "answerDoc", questionId: question.id, sectionId }
              }}
            />
            {question?.answerDetails?.answeredBy &&
              question?.answerDetails?.updatedAt && (
                <AnsweredByDetails
                  answeredBy={question.answerDetails?.answeredBy}
                  answeredByAccountName={
                    question.answerDetails?.answeredByAccountName
                  }
                  updatedAt={question.answerDetails?.updatedAt}
                />
              )}
            {showErrors &&
              validationErrors &&
              validationErrors
                .filter((error) => error.questionId === question.id)
                .map((error, index: number) => {
                  return (
                    <div key={index} style={{ color: "red" }}>
                      {error.errors.join(" ")}
                    </div>
                  );
                })}
          </>
        );
      case "date":
        return (
          <>
            <Flatpickr
              disabled={!allowAnswerSubmit}
              value={answer.answer}
              onChange={(date) => {
                let dateStr = utils.getDate(date[0]);
                onFieldValChange &&
                  onFieldValChange({
                    ques: question,
                    updatedQuestion: getUpdatedQuestion({
                      question,
                      answer: dateStr
                    }),
                    answer: dateStr
                  });
              }}
              className="form-control form-control--bordered"
              options={{
                dateFormat: "d-m-Y",
                mode: "single"
              }}
            />
            {question?.answerDetails?.answeredBy &&
              question?.answerDetails?.updatedAt && (
                <AnsweredByDetails
                  answeredBy={question.answerDetails?.answeredBy}
                  answeredByAccountName={
                    question.answerDetails?.answeredByAccountName
                  }
                  updatedAt={question.answerDetails?.updatedAt}
                />
              )}
            {showErrors &&
              validationErrors &&
              validationErrors
                .filter((error) => error.questionId === question.id)
                .map((error, index: number) => {
                  return (
                    <div key={index} style={{ color: "red" }}>
                      {error.errors.join(" ")}
                    </div>
                  );
                })}
          </>
        );
      case "table":
        return (
          <div style={{ marginBottom: "20px" }}>
            <Table
              tableData={question.fieldValues[0]?.value}
              tableAnswer={answer}
              onChange={(target: any) => {
                onFieldValChange &&
                  onFieldValChange({
                    ques: question,
                    updatedQuestion: getUpdatedQuestion({
                      question,
                      answer: target
                    }),
                    answer: target
                  });
              }}
              isDisabled={!allowAnswerSubmit}
              itemIndex={itemIndex}
            />
            {question?.answerDetails?.answeredBy &&
              question?.answerDetails?.updatedAt && (
                <AnsweredByDetails
                  answeredBy={question.answerDetails?.answeredBy}
                  answeredByAccountName={
                    question.answerDetails?.answeredByAccountName
                  }
                  updatedAt={question.answerDetails?.updatedAt}
                />
              )}
          </div>
        );
      case "select_multiple":
        return (
          <>
            {allowAnswerSubmit ? (
              <MultiSelect
                options={utils.modifyForSelectQuestionBox(
                  question.fieldValues,
                  "id",
                  "label",
                  "value"
                )}
                name="select-multiple"
                onChange={(name: any, option: any) => {
                  let arrayOfValues = option.map((ele: any) => {
                    return ele?.value.trim();
                  });
                  onFieldValChange &&
                    onFieldValChange({
                      ques: question,
                      updatedQuestion: getUpdatedQuestion({
                        question,
                        answer: JSON.stringify(arrayOfValues)
                      }),
                      answer: JSON.stringify(arrayOfValues)
                    });
                }}
                defaultValue={
                  answer.answer &&
                  getSelectDefaultValue(
                    JSON.parse(answer.answer),
                    question.fieldValues
                  )
                }
                placeholder={`${t("Select")}...`}
              />
            ) : (
              <React.Fragment>
                {itemIndex.toString().split(".").length > 2 ? (
                  <div className="form-group form-group--inset-1">
                    {getSelectBody(answer ? answer : null, true)}
                  </div>
                ) : (
                  <React.Fragment>
                    {getSelectBody(answer ? answer : null, true)}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
            {question?.answerDetails?.answeredBy &&
              question?.answerDetails?.updatedAt && (
                <AnsweredByDetails
                  answeredBy={question.answerDetails?.answeredBy}
                  answeredByAccountName={
                    question.answerDetails?.answeredByAccountName
                  }
                  updatedAt={question.answerDetails?.updatedAt}
                />
              )}
          </>
        );
      case "select_single":
        return (
          <>
            {allowAnswerSubmit ? (
              <>
                <Select
                  options={utils.modifyForSelectQuestionBox(
                    question.fieldValues,
                    "id",
                    "label",
                    "value"
                  )}
                  name="select-single"
                  placeholder={`${t("Select")}...`}
                  onChange={(ele: any) => {
                    onFieldValChange &&
                      onFieldValChange({
                        ques: question,
                        updatedQuestion: getUpdatedQuestion({
                          question,
                          answer: ele?.value.trim() || ""
                        }),
                        answer: ele?.value.trim() || ""
                      });
                  }}
                  defaultValue={
                    answer.answer &&
                    getSelectDefaultValue([answer.answer], question.fieldValues)
                  }
                  isClearable={true}
                  isMulti={false}
                  noOptionsMessage={() => t("No options")}
                />
                {showErrors &&
                  validationErrors &&
                  validationErrors
                    .filter((error) => error.questionId === question.id)
                    .map((error, index: number) => {
                      return (
                        <div key={index} style={{ color: "red" }}>
                          {error.errors.join(" ")}
                        </div>
                      );
                    })}
              </>
            ) : (
              <React.Fragment>
                {itemIndex.toString().split(".").length > 2 ? (
                  <div className="form-group form-group--inset-1">
                    {getSelectBody(answer ? answer : null)}
                  </div>
                ) : (
                  <React.Fragment>
                    {getSelectBody(answer ? answer : null)}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
            {question?.answerDetails?.answeredBy &&
              question?.answerDetails?.updatedAt && (
                <AnsweredByDetails
                  answeredBy={question.answerDetails?.answeredBy}
                  answeredByAccountName={
                    question.answerDetails?.answeredByAccountName
                  }
                  updatedAt={question.answerDetails?.updatedAt}
                />
              )}
          </>
        );
      case RISK_COUNTRY:
      case RISK_INDUSTRY:
      case RISK_LEGAL_FORM:
        return (
          <>
            <Select
              noOptionsMessage={() => t("No options")}
              options={utils.modifyForSelectQuestionBox(
                riskQuestionOptions,
                "id",
                i18n.language === "de" ? "germanName" : "name",
                "id"
              )}
              name={questionType}
              placeholder={`${t("Select")}...`}
              onChange={(ele: any) => {
                onFieldValChange &&
                  onFieldValChange({
                    ques: question,
                    updatedQuestion: getUpdatedQuestion({
                      question,
                      answer: ele?.value || ""
                    }),
                    answer: ele?.value || ""
                  });
              }}
              value={
                answer.answer
                  ? getSelectDefaultValue(
                      [answer.answer],
                      utils.modifyForSelectQuestionBox(
                        riskQuestionOptions,
                        "id",
                        i18n.language === "de" ? "germanName" : "name",
                        "id"
                      )
                    )
                  : null
              }
              isClearable={true}
              isMulti={false}
              isDisabled={!allowAnswerSubmit}
            />
            {question?.answerDetails?.answeredBy &&
              question?.answerDetails?.updatedAt && (
                <AnsweredByDetails
                  answeredBy={question.answerDetails?.answeredBy}
                  answeredByAccountName={
                    question.answerDetails?.answeredByAccountName
                  }
                  updatedAt={question.answerDetails?.updatedAt}
                />
              )}
            {showErrors &&
              validationErrors &&
              validationErrors
                .filter((error) => error.questionId === question.id)
                .map((error, index: number) => {
                  return (
                    <div key={index} style={{ color: "red" }}>
                      {error.errors.join(" ")}
                    </div>
                  );
                })}
          </>
        );
      default:
        return (
          <>
            <InputField
              inputType="text"
              id={itemIndex}
              name={`text-${itemIndex}`}
              isDisabled={!allowAnswerSubmit}
              placeholder={question.placeholder || ""}
              value={answer.answer}
              onChange={({ target }: { target: any }) => {
                onFieldValChange &&
                  onFieldValChange({
                    ques: question,
                    updatedQuestion: getUpdatedQuestion({
                      question,
                      answer: target.value
                    }),
                    answer: target.value
                  });
              }}
            />
            {question?.answerDetails?.answeredBy &&
              question?.answerDetails?.updatedAt && (
                <AnsweredByDetails
                  answeredBy={question.answerDetails?.answeredBy}
                  answeredByAccountName={
                    question.answerDetails?.answeredByAccountName
                  }
                  updatedAt={question.answerDetails?.updatedAt}
                />
              )}
            {showErrors &&
              validationErrors &&
              validationErrors
                .filter((error) => error.questionId === question.id)
                .map((error, index: number) => {
                  return (
                    <div key={index} style={{ color: "red" }}>
                      {error.errors.join(" ")}
                    </div>
                  );
                })}
          </>
        );
    }
  }

  function getTemplateBody({ showScoreLabel }: { showScoreLabel: boolean }) {
    return (
      <>
        <div
          className="response-row response-row--title"
          id={question.id as string}
        >
          <div className="response__cell response__cell--content">
            <h3 className="mb-1">
              {itemIndex}. {question.label}
              {question.internalUseOnly && (
                // <span className="badge que-badge">For internal use</span>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={`internal-use-${itemIndex}`}>
                      {t("For internal use")}
                    </Tooltip>
                  }
                >
                  <span style={{ marginLeft: "10px" }}>
                    <Icons
                      className="text-primary"
                      icon="privateInternalShield"
                    />
                  </span>
                </OverlayTrigger>
              )}
              {question.mandatory ? (
                <span className="mandatory-question" title="mandatory">
                  *
                </span>
              ) : (
                ""
              )}
            </h3>
            {question.helpInfo && (
              <div
                className="custom_edit_text"
                dangerouslySetInnerHTML={{
                  __html: DOMPruify.sanitize(question.helpInfo as any)
                }}
              ></div>
            )}
          </div>

          <div className="response__cell response__cell--deductions flex-right">
            {showScoreLabel &&
              deductionDetails?.scoreLabel &&
              (deductionDetails?.showCurrentScore ||
                deductionDetails?.showDefaultScore) && (
                <span className="text-muted">
                  {deductionDetails.scoreLabel}
                </span>
              )}
            {!allowAnswerSubmit && question?.answerDetails?.riskLevel && (
              <div
                className={`rating mt-2 mr-2 rating--medium rating--${utils.getScoreColor(
                  question?.answerDetails?.riskLevel
                )}`}
              >
                <div className="rating__number h5">
                  {utils.convertRiskLabels(question?.answerDetails?.riskLevel)}
                </div>
              </div>
            )}
          </div>

          {showComments && (
            <div className="response__cell response__cell--actions">
              <div className="action-group">
                <div
                  className="action"
                  data-toggle="modal"
                  data-target="#cdd-add-comment-modal"
                  onClick={handleComments}
                >
                  <span
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title={
                      question.comments && question.comments.length > 0
                        ? t("View comments")
                        : t("Add comments regarding questions or scoring.")
                    }
                  >
                    <Icons
                      className={`icon ${
                        question.comments && question.comments.length > 0
                          ? "text-primary"
                          : ""
                      }`}
                      icon={
                        question.comments && question.comments.length > 0
                          ? "comment"
                          : "add-comment"
                      }
                    />
                  </span>
                </div>
              </div>
            </div>
          )}
          {(showPublicComment || showPrivateComment) && (
            <div className="response__cell response__cell--actions">
              <div className="action-group">
                <div
                  ref={commentRef}
                  id={`ppc-comments-${question.id}`}
                  className="action"
                  data-toggle="modal"
                  data-target="#cdd-add-comment-modal"
                  onClick={handlePPC}
                >
                  <span
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title={
                      question.answerDetails?.publicPrivateComments &&
                      question.answerDetails.publicPrivateComments.length > 0
                        ? t("View comments")
                        : t("Add comments for questions.")
                    }
                  >
                    <Icons
                      className={`icon ${
                        question.answerDetails?.publicPrivateComments &&
                        question.answerDetails.publicPrivateComments.length > 0
                          ? "text-primary"
                          : ""
                      }`}
                      icon={
                        question.answerDetails?.publicPrivateComments &&
                        question.answerDetails.publicPrivateComments.length > 0
                          ? "comment"
                          : "add-comment"
                      }
                    />
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }

  function getDatasetBody() {
    let answer = {} as Answer;
    if (showAnswers && question?.answerDetails) {
      answer = question.answerDetails;
    }
    return (
      <React.Fragment>
        {getTemplateBody({ showScoreLabel: true })}
        <div className="response-row response-row--input">
          <div className="response__cell response__cell--deductions">
            {deductionDetails && (
              <Deductions
                queryParams={queryParams}
                answerId={question?.answerDetails?.id}
                fieldId={question?.fieldValues[0]?.id}
                defaultDeductions={question?.fieldValues[0]?.defaultDeductions}
                currentDeductions={question?.fieldValues[0]?.currentDeductions}
                editScoring={deductionDetails.editScoring || false}
                showDefaultScore={deductionDetails.showDefaultScore || false}
                showCurrentScore={deductionDetails.showCurrentScore || false}
                iconColor={
                  question?.fieldValues[0]?.isDeductionNotesPresent
                    ? "blue"
                    : "black"
                }
                updateParent={(points, fieldValueId) => {
                  updateParent &&
                    updateParent(
                      updateFieldValueScore({
                        question,
                        points,
                        fieldValueId
                      })
                    );
                }}
              />
            )}
          </div>
          {(showComments || showPublicComment || showPrivateComment) && (
            <div className="response__cell response__cell--actions"></div>
          )}
        </div>
        <DataSet
          queryParams={queryParams}
          itemIndex={itemIndex}
          dataSetSectionList={dataSetSectionList}
          list={answer.datasetAnswers}
          isDisabled={!allowAnswerSubmit}
          question={question}
          sectionViewType={sectionViewType}
          currentUserId={currentUserId}
          submittedQuestionnaireId={submittedQuestionnaireId}
          showPublicComment={showPublicComment}
          showPrivateComment={showPrivateComment}
          editPublicComment={editPublicComment}
          editPrivateComment={editPrivateComment}
          onChange={(data: any) => {
            onFieldValChange &&
              onFieldValChange({
                ques: question,
                updatedQuestion: getUpdatedQuestion({
                  question,
                  answer: null
                }),
                answer: null,
                additionalInfo: data
              });
          }}
        ></DataSet>
      </React.Fragment>
    );
  }
  function getUboBody() {
    let answer = {} as Answer;
    if (showAnswers && question?.answerDetails) {
      answer = question.answerDetails;
    }
    let uboAnswer: any;
    if (answer?.answer) {
      uboAnswer = JSON.parse(answer.answer);
    }
    return (
      <>
        {getTemplateBody({ showScoreLabel: true })}
        <div className="response-row response-row--input">
          <div className="response__cell response__cell--deductions">
            {deductionDetails && (
              <Deductions
                queryParams={queryParams}
                answerId={question?.answerDetails?.id}
                fieldId={question?.fieldValues[0]?.id}
                defaultDeductions={question?.fieldValues[0]?.defaultDeductions}
                currentDeductions={question?.fieldValues[0]?.currentDeductions}
                editScoring={deductionDetails.editScoring || false}
                showDefaultScore={deductionDetails.showDefaultScore || false}
                showCurrentScore={deductionDetails.showCurrentScore || false}
                iconColor={
                  question?.fieldValues[0]?.isDeductionNotesPresent
                    ? "blue"
                    : "black"
                }
                updateParent={(points, fieldValueId) => {
                  updateParent &&
                    updateParent(
                      updateFieldValueScore({
                        question,
                        points,
                        fieldValueId
                      })
                    );
                }}
              />
            )}
          </div>
          {(showComments || showPublicComment || showPrivateComment) && (
            <div className="response__cell response__cell--actions"></div>
          )}
        </div>
        <div className="ubo-question-wrp ubo-ui-sec m-r-15">
          {showErrors &&
            validationErrors &&
            validationErrors
              .filter((error) => error.questionId === question.id)
              .map((error, index: number) => {
                return (
                  <div key={index} style={{ color: "red" }}>
                    {t(
                      `{{error}}. Please use the "Launch UBO engine" button to provide valid UBO information`,
                      { error: error.errors.join(" ") }
                    )}
                  </div>
                );
              })}
          <UBOEngine
            fileUploadParams={{
              ...fileParams,
              ...{ fileType: "answerDoc", questionId: question.id }
            }}
            questionId={question.id}
            saveQuestionDetails={{
              sectionId,
              accountQuestionnaireId,
              questionAnswers: {
                questionType: question?.fieldType.name,
                answer,
                questionId: question.id,
                questionBankId: question.questionBankId
              }
            }}
            allowEdit={allowAnswerSubmit}
            answer={uboAnswer}
            onChange={(answer: any) => {
              onFieldValChange &&
                onFieldValChange({
                  ques: question,
                  updatedQuestion: getUpdatedQuestion({
                    question,
                    answer: JSON.stringify(answer)
                  }),
                  answer: JSON.stringify(answer)
                });
            }}
            {...JSON.parse(question?.fieldValues[0]?.value)}
          />
        </div>
      </>
    );
  }

  function getCheckboxRadioBody() {
    let answer: any = question.fieldType.name === "checkbox" ? [] : "";
    if (showAnswers && question?.answerDetails?.answer) {
      if (question.fieldType.name === "checkbox") {
        answer = JSON.parse(question.answerDetails?.answer) || [];
      }

      if (question.fieldType.name === "radio_button") {
        answer = question.answerDetails?.answer || "";
      }
    }

    return (
      <React.Fragment>
        {getTemplateBody({ showScoreLabel: true })}
        <div>
          {question.fieldValues &&
            question.fieldValues.length > 0 &&
            []
              .concat(question.fieldValues as any)
              .sort((a: FieldValue, b: FieldValue) => {
                return a.position - b.position;
              })
              .map((field: FieldValue, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <div className="response-row response-row--input">
                      <div className="response__cell response__cell--content">
                        {question.fieldType.name === "checkbox" ? (
                          <Checkbox
                            id={`${index}-${question.id}`}
                            name="checkbox"
                            label={field.label}
                            value={field.value}
                            checked={answer && answer.includes(field.value)}
                            isDisabled={!allowAnswerSubmit}
                            onChange={({ target }: { target: any }) => {
                              if (target.checked) {
                                if (!answer?.includes(target.value)) {
                                  answer.push(target.value);
                                }
                              } else {
                                if (answer.indexOf(target.value) > -1) {
                                  answer.splice(
                                    answer.indexOf(target.value),
                                    1
                                  );
                                }
                              }

                              onFieldValChange &&
                                onFieldValChange({
                                  ques: question,
                                  updatedQuestion: getUpdatedQuestion({
                                    question,
                                    answer: JSON.stringify(answer)
                                  }),
                                  answer: JSON.stringify(answer)
                                });
                            }}
                          />
                        ) : (
                          <RadioButton
                            id={`${index}-${question.id}-${itemIndex}`}
                            name={`radio-${question.id}-${itemIndex}`}
                            label={field.label}
                            value={field.value}
                            selection={answer}
                            isDisabled={!allowAnswerSubmit}
                            allowDeSelect={true}
                            onChange={({ target }: { target: any }) => {
                              onFieldValChange &&
                                onFieldValChange({
                                  ques: question,
                                  answer: target.value,
                                  updatedQuestion: getUpdatedQuestion({
                                    question,
                                    answer: target.value
                                  })
                                });
                            }}
                          />
                        )}
                      </div>
                      <div className="response__cell response__cell--deductions">
                        {deductionDetails && (
                          <Deductions
                            queryParams={queryParams}
                            answerId={question?.answerDetails?.id}
                            fieldId={field.id}
                            defaultDeductions={field.defaultDeductions}
                            currentDeductions={field.currentDeductions}
                            editScoring={deductionDetails.editScoring || false}
                            showDefaultScore={
                              deductionDetails.showDefaultScore || false
                            }
                            showCurrentScore={
                              deductionDetails.showCurrentScore || false
                            }
                            iconColor={
                              field.isDeductionNotesPresent ? "blue" : "black"
                            }
                            updateParent={(points, fieldValueId) => {
                              updateParent &&
                                updateParent(
                                  updateFieldValueScore({
                                    question,
                                    points,
                                    fieldValueId
                                  })
                                );
                            }}
                          />
                        )}
                      </div>
                      {(showComments ||
                        showPublicComment ||
                        showPrivateComment) && (
                        <div className="response__cell response__cell--actions"></div>
                      )}
                    </div>

                    {showSubQuestions({
                      answer,
                      currentField: field,
                      allowSubmit: allowAnswerSubmit,
                      fieldType: question.fieldType,
                      sectionViewType
                    }) &&
                      field.subQuestions &&
                      field.subQuestions.length > 0 &&
                      []
                        .concat(field.subQuestions as any)
                        .sort((a: SubQuestion, b: SubQuestion) => {
                          return a.question.position - b.question.position;
                        })
                        .map((subQ: SubQuestion, sIndex: number) => {
                          let subQItemIndex = itemIndex + "." + (sIndex + 1);
                          if (
                            question.fieldType.name === "checkbox" ||
                            question.fieldType.name === "radio_button"
                          ) {
                            subQItemIndex =
                              itemIndex +
                              "." +
                              (index + 1) +
                              "." +
                              (sIndex + 1);
                          }

                          return (
                            <React.Fragment key={sIndex}>
                              <QuestionComponent
                                queryParams={queryParams}
                                itemIndex={subQItemIndex}
                                question={subQ.question}
                                showComments={showComments}
                                showPublicComment={showPublicComment}
                                showPrivateComment={showPrivateComment}
                                editPublicComment={editPublicComment}
                                editPrivateComment={editPrivateComment}
                                submittedQuestionnaireId={
                                  submittedQuestionnaireId
                                }
                                accountQuestionnaireId={accountQuestionnaireId}
                                currentUserId={currentUserId}
                                updateParent={(updatedQuestion) => {
                                  updateParent &&
                                    updateParent(
                                      updateAnswerInSubQuestion({
                                        subQuestions: field.subQuestions,
                                        subQuestionQuestion: updatedQuestion,
                                        parentQuestion: question,
                                        fieldValue: field
                                      })
                                    );
                                }}
                                validationErrors={validationErrors}
                                showErrors={showErrors}
                                showAnswers={showAnswers}
                                allowAnswerSubmit={allowAnswerSubmit}
                                sectionViewType={sectionViewType}
                                isSubQuestion={true}
                                isInParentSection={true}
                                indexKey={questionlevel}
                                hiddenSections={hiddenSections}
                                deductionDetails={deductionDetails}
                                onFieldValChange={
                                  ({
                                    ques,
                                    answer,
                                    additionalInfo,
                                    updatedQuestion
                                  }) => {
                                    onFieldValChange &&
                                      onFieldValChange({
                                        ques,
                                        answer,
                                        additionalInfo,
                                        updatedQuestion:
                                          updateAnswerInSubQuestion({
                                            subQuestions: field.subQuestions,
                                            subQuestionQuestion:
                                              updatedQuestion,
                                            parentQuestion: question,
                                            fieldValue: field
                                          })
                                      });
                                  }

                                  //   (
                                  //   ques,
                                  //   answer,
                                  //   additionalInfo
                                  // ) => {
                                  //   updateParent({
                                  //     allSubQuestions: field.subQuestions,
                                  //     parentQuestion: ques,
                                  //     currentQuestion: subQ.question,
                                  //     answer,
                                  //     additionalInfo
                                  //   });
                                  // }
                                }
                                fileParams={{
                                  ...fileParams,
                                  ...{ questionId: subQ.question.id }
                                }}
                                dataSetSectionList={dataSetSectionList}
                              />
                            </React.Fragment>
                          );
                        })}
                  </React.Fragment>
                );
              })}
          {showErrors &&
            validationErrors &&
            validationErrors
              .filter((error) => error.questionId === question.id)
              .map((error, index: number) => {
                return (
                  <div key={index} style={{ color: "red" }}>
                    {error.errors.join(" ")}
                  </div>
                );
              })}
        </div>
      </React.Fragment>
    );
  }
  //TODO: We will remove subsection for select question in future
  function getSelectBody(ans: any, isMultiSelect: boolean = false) {
    let answer = ans?.answer || "";
    if (isMultiSelect && answer) {
      answer = JSON.parse(answer);
    }
    return (
      <React.Fragment>
        {question.fieldValues &&
          question.fieldValues.length > 0 &&
          []
            .concat(question.fieldValues as any)
            .sort((a: FieldValue, b: FieldValue) => {
              return a.position - b.position;
            })
            .map((field: FieldValue, index: number) => {
              return (
                <React.Fragment key={index}>
                  <div className="response-row response-row--input">
                    <div className="response__cell response__cell--content">
                      <div
                        className={`custom-control custom-checkbox option-selct-backcolor ${
                          ans &&
                          (answer === field.value ||
                            answer?.includes(field.value))
                            ? "active"
                            : ""
                        }`}
                      >
                        <div className="option-selct-label">{field.label}</div>
                      </div>
                    </div>
                    <div className="response__cell response__cell--deductions">
                      {deductionDetails && (
                        <Deductions
                          queryParams={queryParams}
                          answerId={ans.id}
                          fieldId={field.id}
                          defaultDeductions={field.defaultDeductions}
                          currentDeductions={field.currentDeductions}
                          editScoring={deductionDetails.editScoring || false}
                          showDefaultScore={
                            deductionDetails.showDefaultScore || false
                          }
                          showCurrentScore={
                            deductionDetails.showCurrentScore || false
                          }
                          iconColor={
                            field.isDeductionNotesPresent ? "blue" : "black"
                          }
                          updateParent={(points, fieldValueId) => {
                            updateParent &&
                              updateParent(
                                updateFieldValueScore({
                                  question,
                                  points,
                                  fieldValueId
                                })
                              );
                          }}
                        />
                      )}
                    </div>
                    {(showComments ||
                      showPublicComment ||
                      showPrivateComment) && (
                      <div className="response__cell response__cell--actions"></div>
                    )}
                  </div>
                </React.Fragment>
              );
            })}
      </React.Fragment>
    );
  }

  function getDefaultTemplateBody() {
    let selectQuestionTypeArr: string[] = ["select_multiple", "select_single"];
    let defaultTemplateBody: any = (
      <div className="response-row response-row--input">
        <div
          className={`response__cell response__cell--content ${
            question.fieldType.name === "table" ? "table-content" : ""
          }`}
        >
          {getQuestionTypeFields(question)}
        </div>

        <div className="response__cell response__cell--deductions">
          {deductionDetails && (
            <Deductions
              queryParams={queryParams}
              answerId={question?.answerDetails?.id}
              fieldId={question.fieldValues[0]?.id}
              defaultDeductions={
                question.fieldValues[0]?.defaultDeductions || 0
              }
              currentDeductions={
                question.fieldValues[0]?.currentDeductions || 0
              }
              editScoring={deductionDetails.editScoring || false}
              showDefaultScore={deductionDetails.showDefaultScore || false}
              showCurrentScore={deductionDetails.showCurrentScore || false}
              iconColor={
                question.fieldValues[0]?.isDeductionNotesPresent
                  ? "blue"
                  : "black"
              }
              updateParent={(points, fieldValueId) => {
                updateParent &&
                  updateParent(
                    updateFieldValueScore({
                      question,
                      points,
                      fieldValueId
                    })
                  );
              }}
            />
          )}
        </div>
        {(showComments || showPublicComment || showPrivateComment) && (
          <div className="response__cell response__cell--actions"></div>
        )}
      </div>
    );
    if (
      selectQuestionTypeArr?.includes(question.fieldType.name) &&
      !allowAnswerSubmit
    ) {
      defaultTemplateBody = getQuestionTypeFields(question);
    }
    return (
      <React.Fragment>
        {getTemplateBody({ showScoreLabel: true })}
        {defaultTemplateBody}
      </React.Fragment>
    );
  }

  function getTemplateForQuestionType() {
    switch (question.fieldType.name) {
      case "data_set":
        return (
          <React.Fragment>
            {itemIndex.toString().split(".").length > 2 ? (
              <div className="form-group form-group--inset-1">
                {getDatasetBody()}
                {question?.answerDetails?.answeredBy &&
                  question?.answerDetails?.updatedAt && (
                    <AnsweredByDetails
                      answeredBy={question.answerDetails?.answeredBy}
                      answeredByAccountName={
                        question.answerDetails?.answeredByAccountName
                      }
                      updatedAt={question.answerDetails?.updatedAt}
                    />
                  )}
              </div>
            ) : (
              <React.Fragment>
                {getDatasetBody()}
                {question?.answerDetails?.answeredBy &&
                  question?.answerDetails?.updatedAt && (
                    <AnsweredByDetails
                      answeredBy={question.answerDetails?.answeredBy}
                      answeredByAccountName={
                        question.answerDetails?.answeredByAccountName
                      }
                      updatedAt={question.answerDetails?.updatedAt}
                    />
                  )}
              </React.Fragment>
            )}
          </React.Fragment>
        );
      case "radio_button":
      case "checkbox":
        return (
          <React.Fragment>
            {itemIndex.toString().split(".").length > 2 ? (
              <div className="form-group form-group--inset-1">
                {getCheckboxRadioBody()}
                {question?.answerDetails?.answeredBy &&
                  question?.answerDetails?.updatedAt && (
                    <AnsweredByDetails
                      answeredBy={question.answerDetails?.answeredBy}
                      answeredByAccountName={
                        question.answerDetails?.answeredByAccountName
                      }
                      updatedAt={question.answerDetails?.updatedAt}
                    />
                  )}
              </div>
            ) : (
              <React.Fragment>
                {getCheckboxRadioBody()}
                {question?.answerDetails?.answeredBy &&
                  question?.answerDetails?.updatedAt && (
                    <AnsweredByDetails
                      answeredBy={question.answerDetails?.answeredBy}
                      answeredByAccountName={
                        question.answerDetails?.answeredByAccountName
                      }
                      updatedAt={question.answerDetails?.updatedAt}
                    />
                  )}
              </React.Fragment>
            )}
          </React.Fragment>
        );
      case "ubo":
        return (
          <React.Fragment>
            {itemIndex.toString().split(".").length > 2 ? (
              <div className="form-group form-group--inset-1">
                {getUboBody()}
                {question?.answerDetails?.answeredBy &&
                  question?.answerDetails?.updatedAt && (
                    <AnsweredByDetails
                      answeredBy={question.answerDetails?.answeredBy}
                      answeredByAccountName={
                        question.answerDetails?.answeredByAccountName
                      }
                      updatedAt={question.answerDetails?.updatedAt}
                    />
                  )}
              </div>
            ) : (
              <React.Fragment>
                {getUboBody()}
                {question?.answerDetails?.answeredBy &&
                  question?.answerDetails?.updatedAt && (
                    <AnsweredByDetails
                      answeredBy={question.answerDetails?.answeredBy}
                      answeredByAccountName={
                        question.answerDetails?.answeredByAccountName
                      }
                      updatedAt={question.answerDetails?.updatedAt}
                    />
                  )}
              </React.Fragment>
            )}
          </React.Fragment>
        );
      default:
        return (
          <React.Fragment>
            {itemIndex.toString().split(".").length > 2 ? (
              <div className="form-group form-group--inset-1">
                {getDefaultTemplateBody()}
              </div>
            ) : (
              <React.Fragment>{getDefaultTemplateBody()}</React.Fragment>
            )}
          </React.Fragment>
        );
    }
  }
  return (
    <>
      {isInParentSection ? (
        <div
          className={`form-group${
            isSubQuestion && indexKey ? "form-group--level-" + indexKey : ""
          }`}
        >
          {getTemplateForQuestionType()}
        </div>
      ) : (
        <React.Fragment> {getTemplateForQuestionType()} </React.Fragment>
      )}
      {showPPC && (
        <PublicPrivateComments
          commentDetails={question.answerDetails.publicPrivateComments}
          submittedQuestionnaireId={submittedQuestionnaireId}
          accountQuestionnaireId={accountQuestionnaireId}
          commentableId={question.answerDetails.id}
          commentableType="QuestionAnswer"
          allowComments={{
            showPublicComment,
            showPrivateComment,
            editPublicComment,
            editPrivateComment
          }}
          onClose={(comments) => {
            setShowPPC(false);
            queryParamActions.current.hasScrolled = true;
            queryParamActions.current.hasPopupOpened = true;
            (editPublicComment || editPrivateComment) &&
              updateParent &&
              updateParent(updateCommentsInAnswer(question, comments));
          }}
          currentUserId={currentUserId}
        />
      )}
    </>
  );
};

export default QuestionComponent;
