import { CSSProperties } from "react";
import * as fallbackVars from "../css/fallback-vars";
import { supportsCssVariables } from "../service";

/**
 * Fallback for browsers not supporting CSS variables
 */
export const getFallbackStyle = (
  selected: boolean
): CSSProperties | undefined => {
  if (supportsCssVariables()) {
    return;
  }

  const fallbackStyle: CSSProperties = {};
  if (selected) {
    fallbackStyle.stroke = fallbackVars.primaryColor;
  }

  return fallbackStyle;
};
