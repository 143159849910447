import client from "../apollo.config";
import * as queries from "./queries";
import {
  ApprovalSubmissionDetails,
  ApprovalSubmissionAddComments,
  ApprovalSubmissionUpdateComments,
  ApprovalSubmissionDeleteComments
} from "./types";
export const createComment = (input: ApprovalSubmissionAddComments) => {
  return client.mutate({
    mutation: queries.ADD_COMMENT_QUERY,
    variables: { input }
  });
};
export const updateComment = (input: ApprovalSubmissionUpdateComments) => {
  return client.mutate({
    mutation: queries.UPDATE_COMMENT_QUERY,
    variables: { input }
  });
};
export const deleteComment = (input: ApprovalSubmissionDeleteComments) => {
  return client.mutate({
    mutation: queries.DELETE_COMMENT_QUERY,
    variables: { input }
  });
};
export const approveSubmission = (input: ApprovalSubmissionDetails) => {
  return client.mutate({
    mutation: queries.APPROVE_SUBMISSION,
    variables: { input }
  });
};
export const getSubmissionApprovalDetails = ({
  sectionId,
  submittedQuestionnaireId
}: {
  sectionId: string | number;
  submittedQuestionnaireId: string | number;
}) => {
  return client.query({
    query: queries.GET_SUBMISSION_APPROVAL_DETAILS_QUERY,
    fetchPolicy: "network-only",
    variables: { sectionId, submittedQuestionnaireId }
  });
};
