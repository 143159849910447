import { getPastMonths } from "../../../../lib/utils";
import { ResponseTimeline } from "../types";

export function getChartDataset({
  oneMonthCount,
  twoMonthCount,
  threeMonthCount,
  fourMonthCount,
  fourPlusMonthCount
}: ResponseTimeline) {
  const dataset = {
    labels: [...getPastMonths(5, 3), "MTD"],
    datasets: [
      {
        label: "0-1 m",
        data: [...oneMonthCount],
        backgroundColor: "#35cb93"
        // barThickness: 55
      },
      {
        label: "1-2 m",
        data: [...twoMonthCount],
        backgroundColor: "#7DE293"
        // barThickness: 55
      },
      {
        label: "2-3 m",
        data: [...threeMonthCount],
        backgroundColor: "#F5E555"
        // barThickness: 55
      },
      {
        label: "3-4 m",
        data: [...fourMonthCount],
        backgroundColor: "#f5a623"
        // barThickness: 55
      },
      {
        label: "4+ m",
        data: [...fourPlusMonthCount],
        backgroundColor: "#f05959"
        // barThickness: 55
      }
    ]
  };
  return dataset;
}
