import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Dropdown from "react-bootstrap/Dropdown";
import { Filter, FilterItem, SelectedFilter } from "./types";
import Icons from "../Icons";
import * as utils from "../../../lib/utils";
import { useTranslation } from "react-i18next";

interface Props {
  filters: Filter[];
  selectedFilters: SelectedFilter[];
  onSelect: (key: string, filter: FilterItem[]) => void;
  resetAllFilters: () => void;
}

const GridFilters: React.FC<Props> = ({ filters, onSelect, selectedFilters, resetAllFilters, children }) => {
  const { t } = useTranslation();
  const [searchFor, setSearchFor] = useState<Record<string, string>>(
    filters
      .filter((x) => x.searchable === true)
      .map((x) => x.key)
      .reduce((acc, cur) => ({ ...acc, [cur]: "" }), {})
  );

  const [selected, setSelected] = useState<Record<string, any[]>>(
    filters.map((x) => x.key).reduce((acc, cur) => ({ ...acc, [cur]: [] }), {})
  );

  const [tempSelected, setTempSelected] = useState<Record<string, any[]>>(
    filters.map((x) => x.key).reduce((acc, cur) => ({ ...acc, [cur]: [] }), {})
  );

  const [open, setOpen] = useState<Record<string, boolean | undefined>>({});

  const applySelection = (key: string) => {
    setOpen({
      ...open,
      [key]: false
    });
    setTimeout(() => {
      setOpen({
        ...open,
        [key]: undefined
      });
    }, 100);

    setSearchFor((prevState) => ({
      ...prevState,
      ...{ [key]: "" }
    }));

    setSelected((prevState) => ({
      ...prevState,
      [key]: tempSelected[key]
    }));

    onSelect(key, tempSelected[key]);
  };

  const clearFilters = (key: string) => {
    onSelect(key, []);
    setSelected((prevState) => ({
      ...prevState,
      [key]: []
    }));
    setTempSelected((prevState) => ({
      ...prevState,
      [key]: []
    }));
  };

  const setSelectedFilters = (acc: any, cur: string) => {
    let filterValList: any = [];
    if(selectedFilters?.length){
      filterValList = selectedFilters?.find((filter) => filter.filterName === cur)?.filterValue || []
    }
    return { ...acc, [cur]: filterValList }
  }

  useEffect(() => {
    setSelected(filters.map((x) => x.key).reduce(setSelectedFilters, {}));
    setTempSelected(filters.map((x) => x.key).reduce(setSelectedFilters, {}));
  }, [selectedFilters]);

  return (
    <div className="page-actions page-actions--dark">
      <Container>
        <Row className="flex-wrap">
          <div className="page-action page-action--filters col-12 col-md-auto">
            <div className="filters-container">
              {filters.map((filter, i) => {
                const activeFilters =
                  selected[filter.key].map((item) => item.label) || [];
                return (
                  <Dropdown
                    key={i}
                    className="filter"
                    data-dropdown="fill"
                    data-dropdownstate={
                      activeFilters.length > 0 ? "filled" : "empty"
                    }
                    data-dropdownlabel={filter.label}
                    show={filter.multiple ? open[filter.key] : undefined}
                    onToggle={(isOpen, event, metadata) => {
                      setOpen({
                        ...open,
                        [filter.key]:
                          metadata.source === "select" ? true : undefined
                      });
                    }}
                  >
                    <Dropdown.Toggle
                      className="dropdown-toggle--no-caret"
                      variant="dark"
                    >
                      {activeFilters.length > 0
                        ? t(activeFilters?.toString())
                        : t(filter.label)}
                    </Dropdown.Toggle>
                    {activeFilters.length > 0 && (
                      <button
                        onClick={() => clearFilters(filter.key)}
                        type="button"
                        className="filter__clear"
                        data-dropdownaction="clear"
                      >
                        <Icons
                          icon="close"
                          className="icon--x-small clear-filter"
                        />
                      </button>
                    )}
                    <Dropdown.Menu className="dropdown-menu dropdown-menu--large">
                      <div className="dropdown-menu__arrow" />
                      {filter.searchable && (
                        <div className="dropdown-menu__header">
                          <div className="custom-search custom-search--loader">
                            <input
                              type="text"
                              className="form-control form-control--rounded"
                              placeholder={t("Search")}
                              value={searchFor[filter.key]}
                              onChange={(e) => {
                                setSearchFor((prevState) => ({
                                  ...prevState,
                                  ...{ [filter.key]: e.target.value }
                                }));
                              }}
                            />
                            <label className="custom-search__icon">
                              <Icons className="icon--small" icon="search" />
                            </label>
                          </div>
                        </div>
                      )}
                      <div className="dropdown-menu__inner-scroll">
                        {filter.items
                          .filter((x) =>
                            filter.searchable
                              ? x.label
                                  .toLowerCase()
                                  .includes(searchFor[filter.key].toLowerCase())
                              : true
                          )
                          .map((item, j) => (
                            <Dropdown.Item
                              onClick={() => {
                                if (filter.multiple) {
                                  let arr = tempSelected[filter.key];
                                  arr.find((x) => x.value === item.value)
                                    ? arr.splice(
                                        arr.findIndex(
                                          (x) => x.value === item.value
                                        ),
                                        1
                                      )
                                    : arr.push(item);
                                  setTempSelected((prevState) => ({
                                    ...prevState,
                                    [filter.key]: arr
                                  }));
                                } else {
                                  let arr: any = [];
                                  arr.push(item);
                                  setSelected((prevState) => ({
                                    ...prevState,
                                    [filter.key]: arr
                                  }));
                                  onSelect(filter.key, [
                                    { label: item.label, value: item.value }
                                  ]);
                                }
                              }}
                              key={item.value}
                              className={
                                filter.multiple ? "dropdown-item--check" : ""
                              }
                              as="span"
                            >
                              {filter.multiple ? (
                                <div className="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    readOnly
                                    checked={
                                      tempSelected[filter.key].find(
                                        (x) => x.value === item.value
                                      ) || ""
                                    }
                                    className="custom-control-input"
                                  />
                                  <label className="custom-control-label">
                                    {t(item.label)}
                                  </label>
                                </div>
                              ) : (
                                <>
                                  {filter.dots ? (
                                    <span
                                      className={`status status__dot status__dot--${utils.getDotColor(
                                        item.value
                                      )}`}
                                    >
                                      {t(item.label)}
                                    </span>
                                  ) : (
                                    t(item.label)
                                  )}
                                </>
                              )}
                            </Dropdown.Item>
                          ))}
                        {filter.items.filter((x) =>
                          filter.searchable
                            ? x.label
                                .toLowerCase()
                                .includes(searchFor[filter.key].toLowerCase())
                            : true
                        ).length === 0 && (
                          <span className="d-block py-2 px-3">
                            {t("No results matching your search criteria")}.
                          </span>
                        )}
                      </div>
                      {filter.multiple && (
                        <button
                          type="button"
                          className="dropdown-item dropdown-item--apply"
                          onClick={() => applySelection(filter.key)}
                        >
                          {t("Apply")}
                        </button>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                );
              })} 
              {selectedFilters?.length > 0 && resetAllFilters &&
              <a href="#" className="btn btn-link-underline text-white" onClick={(e) => {
                e.preventDefault();
                resetAllFilters();
              }}>
                <span className="btn__content">{t("Reset Filter")}</span>
              </a>
              }             
            </div>
          </div>
          {children}        
        </Row>
      </Container>
    </div>
  );
};

export default GridFilters;
