import React, { useRef } from "react";
import Icons from "../../presentation/Icons";
import * as utils from "../../../lib/utils";
import { Column } from "react-table";
import { Columns, CounterpartyQuestionnaireFilterList } from "./types";
import { Filter } from "../../presentation/GridFilters/types";

import {
  findUserPermissionsFromRoles,
  USER_ADMIN
} from "../../../lib/questionnairePermissionsAccess";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {
  isEditAllowedForQuesStatus,
  allowReopen,
  DRAFT
} from "../../../lib/questionnaireStatusAccess";
import {
  OrgKeyMap,
  PDF_FILE_EXTENSION,
  ZIP_FILE_EXTENSION,
  FILE_TYPE_QUESTIONNAIRE_PDF,
  FILE_TYPE_QUESTIONNAIRE_PDF_IN_ZIP
} from "../../../constants/app-constants";
import { toast } from "react-toastify";
import {
  sectionAccessPermission,
  COUNTERPARTY_USER
} from "../../../lib/questionnairePermissionsAccess";
import i18n from "../../../i18n";

import { downloadReports } from "../../../services/FileUploadDownloadService";

export function getSectionColumnConfig({
  counterpartyId,
  userDetails,
  reopen
}: {
  counterpartyId: number | string;
  userDetails: any;
  reopen: (data: {
    reopenSectionId?: string | number;
    reopenAccountQuestionnaireId?: string | number;
    reopenedSectionData?: any;
    reopenedQuestionnaireData?: any;
  }) => void;
}) {
  const columnConfig: Column<Columns>[] = [
    {
      Header: "Section Name",
      accessor: "title"
    },
    {
      Header: "Groups/Assigned Departments",
      accessor: "groups",
      Cell: (cellProps: any) => {
        let groups = "";
        if (cellProps.value && cellProps.value.length) {
          groups = cellProps.value.map((v: any, i: any) => v.name).join(", ");
        }
        return <span>{groups}</span>;
      }
    },
    {
      Header: "Date Submitted",
      accessor: "submissionDate",
      Cell: (cellProps: any) => (
        <span>
          {cellProps.value ? utils.getDate(cellProps.value, "DD/MM/YYYY") : ""}
        </span>
      )
    },
    {
      Header: "Last update",
      accessor: "lastUpdatedDate",
      Cell: (cellProps: any) => (
        <span>
          {cellProps.value ? utils.getDate(cellProps.value, "DD/MM/YYYY") : ""}
        </span>
      )
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (cellProps: any) => {
        return (
          <span
            className={`approval-label-status label-${utils.getStatusCapsule(
              cellProps.value.name
            )}`}
          >
            {`${i18n.t(cellProps.value.label)}${
              cellProps.value.name === DRAFT
                ? " - " + cellProps.row.original.completePercentage + "%"
                : ""
            }`}
          </span>
        );
      }
    },
    {
      Header: "Actions",
      accessor: "actions",
      disableSortBy: true,
      Cell: (cellProps: any) => {
        let sectionPermission = sectionAccessPermission({
          permissions: cellProps.row.original.permissions,
          permissionType: COUNTERPARTY_USER
        });
        return (
          <div className="action-group">
            {allowReopen({
              userType: userDetails.user_type,
              status: cellProps.row.original.status?.name
            }) &&
              cellProps.row.original.sectionSubmission && (
                <OverlayTrigger
                  placement="top"
                  rootClose
                  overlay={
                    <Tooltip id={`reopen-questionnaire`}>
                      {i18n.t("Request to reopen the section")}
                    </Tooltip>
                  }
                >
                  <div
                    className="action action--view"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      reopen &&
                        reopen({
                          reopenSectionId: cellProps.row.original.id,
                          reopenedSectionData:
                            cellProps.row.original.reopenRequest
                        });
                    }}
                  >
                    <Icons className="icon" icon="activeDocument" />
                  </div>
                </OverlayTrigger>
              )}
            {isEditAllowedForQuesStatus({
              questionnaireStatus:
                cellProps.row.original.submittedQuestionnaireStatus?.name.toLowerCase(),
              userType: COUNTERPARTY_USER,
              sectionStatus: cellProps.row.values.status?.name.toLowerCase(),
              isSectionLevelSubmission: cellProps.row.original.sectionSubmission
            }) && sectionPermission.editSection ? (
              <div
                className="action action--view"
                onClick={() =>
                  cellProps.action &&
                  cellProps.action.navigateToLink(
                    `/counterpartySubmittedQuestionnaire/cp/${cellProps.row.values.accountQuestionnaireId}/${counterpartyId}/${cellProps.row.values.id}`,
                    true
                  )
                }
              >
                <Icons className="icon" icon="edit" />
              </div>
            ) : (
              <div
                className="action action--view"
                onClick={() =>
                  cellProps.action &&
                  cellProps.action.navigateToLink(
                    `/counterpartySubmittedQuestionnaire/cp/${cellProps.row.values.accountQuestionnaireId}/${counterpartyId}/${cellProps.row.values.id}`,
                    sectionPermission.viewSection
                  )
                }
              >
                <Icons className="icon" icon="edit" />
              </div>
            )}
          </div>
        );
      }
    },
    {
      accessor: "accountQuestionnaireId",
      Cell: () => null,
      Header: () => null
    },
    {
      Cell: () => null,
      Header: () => null,
      accessor: "completePercentage"
    },
    {
      accessor: "id",
      Cell: () => null,
      Header: () => null
    },
    {
      accessor: "submittedQuestionnaireId",
      Cell: () => null,
      Header: () => null
    },
    {
      accessor: "permissions",
      Cell: () => null,
      Header: () => null
    },
    {
      accessor: "submittedQuestionnaireStatus",
      Cell: () => null,
      Header: () => null
    }
  ];
  return columnConfig;
}

export function getQuestionnaireColumnConfig({
  userDetails,
  assignGroupsToSection,
  reopen,
  updateNotificationPreferences
}: {
  userDetails: any;
  assignGroupsToSection: any;
  reopen: (data: {
    reopenSectionId?: string | number;
    reopenAccountQuestionnaireId?: string | number;
    reopenedSectionData?: any;
    reopenedQuestionnaireData?: any;
  }) => void;
  updateNotificationPreferences: (data: {
    accountQuestionnaireId: string | number;
    notificationPreferences: any;
    parentId: string;
  }) => void;
}) {
  const columnConfig: Column<Columns>[] = [
    {
      Header: "Questionnaire",
      accessor: "questionnaireTitle"
    },
    // {
    //   Header: `${OrgKeyMap.client_name}`,
    //   accessor: "assetManager"
    // },
    {
      Header: "Date Submitted",
      accessor: "submissionDate",
      Cell: (cellProps: any) => (
        <span>
          {cellProps.value ? utils.getDate(cellProps.value, "DD/MM/YYYY") : ""}
        </span>
      )
    },
    {
      Header: "Last update",
      accessor: "lastUpdatedDate",
      Cell: (cellProps: any) => (
        <span>
          {cellProps.value ? utils.getDate(cellProps.value, "DD/MM/YYYY") : ""}
        </span>
      )
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (cellProps: any) => {
        return (
          <span
            className={`approval-label-status label-${utils.getStatusCapsule(
              cellProps.value.name
            )}`}
          >
            {`${i18n.t(cellProps.value.label)}${
              cellProps.value.name === DRAFT
                ? " - " + cellProps.row.original.completePercentage + "%"
                : ""
            }`}
          </span>
        );
      }
    },

    {
      Header: "Actions",
      accessor: "actions",
      disableSortBy: true,
      Cell: (cellProps: any) => {
        return (
          <div className="action-group" onClick={(e) => e.stopPropagation()}>
            {findUserPermissionsFromRoles([
              { name: USER_ADMIN, permissionType: COUNTERPARTY_USER }
            ]) && (
              <div
                className="mr-2 action"
                onClick={(e: any) => {
                  assignGroupsToSection &&
                    assignGroupsToSection(cellProps.row.original.id);
                }}
              >
                <Icons className="icon" icon="section-assignment" />
              </div>
            )}

            {allowReopen({
              userType: userDetails.user_type,
              status: cellProps.row.original.status?.name
            }) &&
              !cellProps.row.original.sectionSubmission && (
                <OverlayTrigger
                  placement="top"
                  rootClose
                  overlay={
                    <Tooltip id={`reopen-questionnaire`}>
                      {i18n.t("Request to reopen the questionnaire")}
                    </Tooltip>
                  }
                >
                  <div
                    className="mr-2 action"
                    onClick={(e: any) => {
                      reopen &&
                        reopen({
                          reopenAccountQuestionnaireId:
                            cellProps.row.original.id,
                          reopenedQuestionnaireData:
                            cellProps.row.original.reopenRequest
                        });
                    }}
                  >
                    <Icons className="icon" icon="activeDocument" />
                  </div>
                </OverlayTrigger>
              )}
            <OverlayTrigger
              placement="left"
              rootClose
              trigger="click"
              overlay={
                <Tooltip id={`download-report-${cellProps.row.original.id}`}>
                  {cellProps.row.original.questionnairePdfError ? (
                    <div>
                      {i18n.t(cellProps.row.original.questionnairePdfError)}
                    </div>
                  ) : (
                    <div>
                      <button
                        onClick={async () => {
                          downloadReports({
                            accountQuestionnaireId: cellProps.row.original.id,
                            fileName: cellProps.row.original.questionnaireTitle,
                            fileType: FILE_TYPE_QUESTIONNAIRE_PDF,
                            fileExtension: PDF_FILE_EXTENSION
                          });
                        }}
                        className="dropdown-item"
                      >
                        {i18n.t("Questionnaire PDF")}
                      </button>
                      <button
                        className="dropdown-item"
                        onClick={async () => {
                          downloadReports({
                            accountQuestionnaireId: cellProps.row.original.id,
                            fileName: cellProps.row.original.questionnaireTitle,
                            fileType: FILE_TYPE_QUESTIONNAIRE_PDF_IN_ZIP,
                            fileExtension: ZIP_FILE_EXTENSION
                          });
                        }}
                      >
                        {i18n.t("All Documents Zip")}
                      </button>
                    </div>
                  )}
                </Tooltip>
              }
            >
              <div className="mr-2 action">
                <Icons className="icon" icon="download" />
              </div>
            </OverlayTrigger>
            {utils.isCounterParty(userDetails.user_type) && (
              <OverlayTrigger
                rootClose
                placement="top"
                overlay={
                  <Tooltip
                    id={`email-notifications-tooltip-${cellProps.row.original.id}`}
                  >
                    {i18n.t("Subscribe/Unsubscribe")}
                  </Tooltip>
                }
              >
                <div
                  id={`email-notifications-${cellProps.row.original.id}`}
                  className="mr-2 action"
                  onClick={(e: any) => {
                    updateNotificationPreferences({
                      accountQuestionnaireId: cellProps.row.original.id,
                      notificationPreferences:
                        cellProps.row.original.notificationSubscribed
                          .allPreferences,
                      parentId: `email-notifications-${cellProps.row.original.id}`
                    });
                    e.stopPropagation();
                  }}
                >
                  <Icons className="icon" icon={"notifications"} />
                </div>
              </OverlayTrigger>
            )}
          </div>
        );
      }
    },
    {
      accessor: "accountQuestionnaireId",
      Cell: () => null,
      Header: () => null
    },
    {
      Cell: () => null,
      Header: () => null,
      accessor: "completePercentage"
    },
    {
      accessor: "id",
      Cell: () => null,
      Header: () => null
    },
    {
      accessor: "submittedQuestionnaireId",
      Cell: () => null,
      Header: () => null
    },
    {
      accessor: "permissions",
      Cell: () => null,
      Header: () => null
    },
    {
      accessor: "submittedQuestionnaireStatus",
      Cell: () => null,
      Header: () => null
    }
  ];
  return columnConfig;
}

export function getGridFiltersConfig(
  filtersData: CounterpartyQuestionnaireFilterList
) {
  const gridFilters: Filter[] = [
    // Not required as per BHC-2123 since bhy will have only one dept
    // {
    //   label: `${OrgKeyMap.client_name}`,
    //   items: [
    //     ...new Set(
    //       filtersData["assetManagerList"].map((x: any) => {
    //         return { label: x, value: x };
    //       })
    //     )
    //   ],
    //   key: "assetManager",
    //   searchable: true,
    //   multiple: true
    // },
    {
      label: "Questionnaire",
      items: [
        ...new Set(
          filtersData["questionnaireList"].map((x: any) => {
            return { label: x, value: x };
          })
        )
      ],
      key: "questionnaireTitle",
      searchable: true,
      multiple: true
    },
    {
      label: "Status",
      items: [
        ...new Set(
          filtersData["statusList"].map((x: any) => {
            return { label: x.label, value: x.name };
          })
        )
      ],
      key: "status",
      dots: true
    },
    {
      label: "Valid until",
      items: [
        ...new Set(
          filtersData["validUntilList"].map((x: any) => {
            return { label: x, value: x };
          })
        )
      ],
      key: "expiryDate"
    }
  ];
  return gridFilters;
}
