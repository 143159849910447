import client from "../apollo.config";
import * as queries from "./queries";
import { DashboardVariables } from "../../components/container/Dashboard/types";

export const getDashboardQuestionnairesList = (
  assetManagerId: string | number
) => {
  return client.query({
    query: queries.GET_DASHBOARD_QUESTIONNAIRES_LIST_QUERY,
    fetchPolicy: "network-only",
    variables: { assetManagerId }
  });
};

export const getPublishedCompleted = ({
  assetManagerId,
  questionnaireId
}: DashboardVariables) => {
  return client.query({
    query: queries.GET_PUBLISHED_COMPLETED_QUERY,
    fetchPolicy: "network-only",
    variables: { assetManagerId, questionnaireId }
  });
};

export const getCounterpartiesOnboarded = (assetManagerId: string | number) => {
  return client.query({
    query: queries.GET_COUNTERPARTIES_ONBOARDED,
    fetchPolicy: "network-only",
    variables: { assetManagerId }
  });
};

export const getResponseByStatus = ({
  assetManagerId,
  questionnaireId
}: DashboardVariables) => {
  return client.query({
    query: queries.GET_RESPONSE_BY_STATUS,
    fetchPolicy: "network-only",
    variables: { assetManagerId, questionnaireId }
  });
};

export const getHighRiskCounterparties = ({
  assetManagerId,
  questionnaireId
}: DashboardVariables) => {
  return client.query({
    query: queries.GET_HIGH_RISK_COUNTERPARTIES,
    fetchPolicy: "network-only",
    variables: { assetManagerId, questionnaireId }
  });
};

export const getResponseTimeline = (assetManagerId: string | number) => {
  return client.query({
    query: queries.GET_RESPONSE_TIMELINE,
    fetchPolicy: "network-only",
    variables: { assetManagerId }
  });
};
