import client from "../apollo.config";
import * as queries from "./queries";
import {
  ApprovalBuildAddComments,
  ApprovalBuildDeleteComments,
  ApprovalBuildDetails,
  ApprovalBuildUpdateComments
} from "./types";
export const createComment = (input: ApprovalBuildAddComments) => {
  return client.mutate({
    mutation: queries.ADD_COMMENT_QUERY,
    variables: { input }
  });
};
export const updateComment = (input: ApprovalBuildUpdateComments) => {
  return client.mutate({
    mutation: queries.UPDATE_COMMENT_QUERY,
    variables: { input }
  });
};
export const deleteComment = (input: ApprovalBuildDeleteComments) => {
  return client.mutate({
    mutation: queries.DELETE_COMMENT_QUERY,
    variables: { input }
  });
};
export const approveBuild = (input: ApprovalBuildDetails) => {
  return client.mutate({
    mutation: queries.APPROVE_BUILD,
    variables: { input }
  });
};
export const getBuildApprovalDetails = ({
  questionnaireId,
  assetManagerId
}: {
  questionnaireId: string | number;
  assetManagerId: string | number;
}) => {
  return client.query({
    query: queries.GET_BUILD_APPROVAL_DETAILS_QUERY,
    fetchPolicy: "network-only",
    variables: { questionnaireId, assetManagerId }
  });
};
