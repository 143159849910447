import React from "react";
import { ReactSVG } from "react-svg";
import { getIcon } from "./services";

interface Props {
  icon: string;
  className?: string;
}

const Icons: React.FC<Props> = ({ icon, className }) => {
  return (
    <ReactSVG
      beforeInjection={(svg) => {
        if (className) {
          const classes = className.split(" ");
          classes.forEach((cls) => cls && svg.classList.add(cls));
        }
      }}
      wrapper="span"
      src={getIcon(icon)}
    />
  );
};

export default Icons;
