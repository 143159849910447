import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { KnownUBOType } from "../../types";
import { downloadFile } from "../../../../services/FileUploadDownloadService";
import { getSelectDefaultValue } from "../../AddUBOs/service";
import { appData } from "../../../../services/appConfig";

interface Props {
  uboData: KnownUBOType;
  fileParams?: any;
}

const ShowKownUboList: React.FC<Props> = ({ uboData, fileParams }) => {
  const { t } = useTranslation();
  const [toggle, setToggle] = useState<any>({});
  const [countries, setCountries] = useState<any>([]);
  useEffect(()=>{
    appData.fetchRiskCountries()
    .then((res)=>{
      setCountries(res)
    })
  },[])
  return (
    <div className="table-questionnair-customize">
      <div className="table">
        <div className="table__header">
          <div className="table__row--outer">
            <div className="table__row--inner">
              <div className="table__row row">
                <div className="table__cell col">{t("UBO Name")}</div>
                <div className="table__cell col">{t("Voting Rights")}</div>
                <div className="table__cell col">{t("Capital Shares")}</div>
                <div className="table__cell col-5">{t("Documentation")}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="table__body">
          {uboData?.knownUBOs?.length ? (
            uboData.knownUBOs.map((ubo, index) => {
              return (
                <span key={index}>
                  <div
                    className={`table__row--outer row-caret ${
                      toggle[index] ? "collapsed" : ""
                    }`}
                    data-toggle="collapse"
                    data-target={`#known-ubo-table-${index}`}
                    aria-expanded={
                      toggle[index] ? (true as boolean) : (false as boolean)
                    }
                    aria-controls={`#known-ubo-table-${index}`}
                    onClick={() => {
                      setToggle({ ...toggle, [index]: !toggle[index] });
                    }}
                  >
                    <div className="table__row--container dropdown-toggle">
                      <div className="table__row row">
                        <div className="table__cell col">
                          <div className="table-heading-label">
                            {t("UBO Name")}
                          </div>
                          <div>
                            {" "}
                            {ubo.firstName} {ubo.lastName}
                          </div>
                        </div>
                        <div className="table__cell col">
                          <div className="table-heading-label">
                            {t("Voting Rights")}
                          </div>
                          <div>{ubo.votingRights}%</div>
                        </div>
                        <div className="table__cell col">
                          <div className="table-heading-label">
                            {t("Capital Shares")}
                          </div>
                          <div>{ubo.shareHolding}%</div>
                        </div>
                        <div className="table__cell col-5">
                          <div className="table-heading-label">
                            {t("Documentation")}
                          </div>
                          <div>
                            {ubo.documents &&
                              ubo.documents.map((val: any, index: number) => {
                                return (
                                  <span
                                    key={index}
                                    className="download-link"
                                    onClick={() => {
                                      val &&
                                        downloadFile({
                                          accountLevelOneQuestionnaireId:
                                            fileParams.accountLevelOneQuestionnaireId,
                                          timeStampedFileName:
                                            val.timeStampedFileName,
                                          originalFileName:
                                            val.originalFileName,
                                          fileType: fileParams.fileType,
                                          sectionId: fileParams.sectionId
                                        });
                                    }}
                                  >
                                    {(index ? ", " : "") + val.originalFileName}
                                  </span>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id={`known-ubo-table-${index}`}
                    className={`collapse in ${toggle[index] ? "show" : ""}`}
                  >
                    <div className="questionnaire-inner-table">
                      <div
                        className="table questionnaire-inner-table"
                        role="table"
                      >
                        <div className="table__body" role="rowgroup">
                          <div className="table__row--outer" role="row">
                            <div className="table__row--container">
                              <div className="table__row row">
                                <div className="table__cell col">
                                  <div className="static-label">
                                    {t("Date of birth")}
                                  </div>
                                  <div>{ubo.dob}</div>
                                </div>
                                <div className="table__cell col">
                                  <div className="static-label">
                                    {t("Tax-ID number")}
                                  </div>
                                  <div>{ubo.taxIdNumber}</div>
                                </div>
                                <div className="table__cell col">
                                  <div className="static-label">
                                    {t("Address")}
                                  </div>
                                  <div>{ubo.address}</div>
                                </div>
                                <div className="table__cell col-5">
                                  <div className="static-label">
                                    {t("Country")}
                                  </div>
                                  <div>
                                    {
                                      getSelectDefaultValue(
                                        ubo.country?.id,
                                        countries
                                      )?.label
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </span>
              );
            })
          ) : (
            <div className="no-data-found">{t("No UBOs present")}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShowKownUboList;
