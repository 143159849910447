import { TUserDetails } from "../../../../types/user-details";
import {
  ApprovalDetails,
  ApproveSubmissionData
} from "../../SubmittedQuestionnaire/types";
import { ApproveButtonData } from "./types";
import { APPROVED } from "../../../../constants/app-constants";
export function identifyApprovalSteps({
  approvalDetails,
  loggedInUser
}: {
  approvalDetails: ApprovalDetails[];
  loggedInUser: TUserDetails;
}) {
  if (!approvalDetails?.length) {
    return approvalDetails;
  }
  let approvalData = [...approvalDetails];
  let approvalSteps: ApproveButtonData[] = [];
  let currentSequence: number = -1;
  let previousSequence: number = -1;
  approvalData
    .sort((a, b) => a.approvalStep?.sequence - b.approvalStep?.sequence)
    .forEach((step) => {
      if (Number(currentSequence) !== Number(step.approvalStep?.sequence)) {
        previousSequence = currentSequence;
        currentSequence = step.approvalStep?.sequence;
      }
      if (
        step.approval?.approvalStatus !== APPROVED &&
        isPreviousStepApproved(approvalData, previousSequence) &&
        loggedInUser.groups.find(
          (val) => String(val.id) === String(step.accountGroup.id)
        )
      ) {
        approvalSteps.push({
          approvalStepGroupRoleId: step.id,
          title: step.approvalStep.approvalName,
          group: step.accountGroup.name,
          approvalType: step.approvalStep?.approvalType?.name
        });
      }
    });
  return approvalSteps;
}
export function parseApproveDetails(
  data: ApproveButtonData
): ApproveSubmissionData {
  return {
    approvalStepGroupRoleId: data.approvalStepGroupRoleId,
    approvalStatus: APPROVED,
    comment: data.comment
  };
}
function isPreviousStepApproved(data: ApprovalDetails[], sequence: number) {
  let foundDetails = data.filter(
    (val) => Number(val.approvalStep.sequence) === Number(sequence)
  );

  if (foundDetails && foundDetails.length) {
    for (let step of foundDetails) {
      if (
        Number(step.approvalStep.sequence) === Number(sequence) &&
        (!step.approval || step.approval?.approvalStatus !== APPROVED)
      ) {
        return false;
      }
    }
  }
  return true;
}
