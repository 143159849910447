import React from "react";

export default function Buttons(props: any) {
  const row = props.cell._cell.row;
  const table = props.cell._cell.table;
  const rowPosition = table.getRowPosition(row);
  return (
    <div className="button-grp">
      <span className="has-icon" onClick={() => props.onAddRowClick(table)}>
        <span>
          <svg
            width="20"
            height="31"
            viewBox="0 0 31 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="15.5" cy="15.5" r="15.5" fill="#1C1B1B" />
            <line
              x1="7.75"
              y1="15.9229"
              x2="23.25"
              y2="15.9229"
              stroke="white"
              strokeWidth="3"
            />
            <line
              x1="15.2539"
              y1="23.8037"
              x2="15.2539"
              y2="8.30371"
              stroke="white"
              strokeWidth="3"
            />
          </svg>
        </span>
      </span>
      {rowPosition !== 0 ? (
        <span className="has-icon" onClick={() => props.onRowRemovedClick(row)}>
          <span>
            <svg
              width="20"
              height="31"
              viewBox="0 0 31 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="15.5" cy="15.5" r="15.5" fill="#1C1B1B" />
              <line
                x1="7.75"
                y1="15.9229"
                x2="23.25"
                y2="15.9229"
                stroke="white"
                strokeWidth="3"
              />
            </svg>
          </span>
        </span>
      ) : null}
    </div>
  );
}
