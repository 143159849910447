import React from "react";
import { Column } from "react-table";
import { Columns, GroupsFilterList } from "./types";
import { Filter } from "../../presentation/GridFilters/types";
import Icons from "../../presentation/Icons";

export function getColumnConfig() {
  const columnConfig: Column<Columns>[] = [
    {
      Header: "Group Name",
      accessor: "name"
    },
    {
      Header: "Users",
      accessor: "users",
      disableSortBy: true,
      Cell: (cellProps: any) => {
        return (
          <span className="users-count">
            {(cellProps.value && cellProps.value.length) || 0}
          </span>
        );
      }
    },
    {
      Header: "Roles",
      accessor: "roles",
      disableSortBy: true,
      Cell: (cellProps: any) => {
        return (
          <span className="user-roles">
            {cellProps.value &&
              cellProps.value.map((role: any) => role.label).join(", ")}
          </span>
        );
      }
    },
    {
      Header: "Action",
      accessor: "actions",
      disableSortBy: true,
      Cell: (cellProps: any) => {
        return (
          <>
            <div className="action-group">
              <div
                className="action action--view"
                onClick={() =>
                  cellProps.action &&
                  cellProps.action.openEditDialog({
                    name: cellProps.row.values.name || "",
                    roles: cellProps.row.values.roles,
                    users: cellProps.row.values.users || [],
                    id: cellProps.row.values.id
                  })
                }
              >
                <span>
                  <Icons className="icon" icon="edit" />
                </span>
              </div>
              <div
                className="action action--delete"
                onClick={() =>
                  cellProps.action &&
                  cellProps.action.deleteAccountGroup(cellProps.row.values.id)
                }
              >
                <span>
                  <Icons className="icon" icon="delete" />
                </span>
              </div>
            </div>
          </>
        );
      }
    },
    {
      accessor: "id",
      Cell: () => null,
      Header: () => null
    }
  ];
  return columnConfig;
}

export function getGridFiltersConfig(filtersData: GroupsFilterList) {
  const gridFilters: Filter[] = [
    {
      label: "Group Name",
      items: [...new Set(filtersData["nameList"].map((x: any) => {
        return { label: x, value: x }
      }))],
      key: "name",
      searchable: true,
      multiple: true
    },
    // {
    //   label: "Roles",
    //   items: [...new Set(filtersData["rolesList"].map((x: any) => {
    //     return { label: x, value: x }
    //   }))],
    //   key: "roles",
    //   searchable: true,
    //   multiple: true
    // }
  ];
  return gridFilters;
}
