import React from "react";
import { withTranslation } from "react-i18next";

type Props = {
  t: any;
};

type ErrorState = {
  errorOccured: boolean;
};

class ErrorHandler extends React.Component<Props, ErrorState> {
  constructor(props: Props) {
    super(props);
    this.state = { errorOccured: false };
  }

  componentDidCatch(error: any, info: any) {
    console.log(error, info);
    console.trace();
    this.setState({ errorOccured: true });
  }

  render() {
    const { t } = this.props;
    if (this.state.errorOccured) {
      return (
        <div className="page-content">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 mt-4">
                <h1>{t("Something went wrong in the Application!")}</h1>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}
export default withTranslation()(ErrorHandler);
