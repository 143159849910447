import { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
type ParameterType = {
  [key: string]: string | null;
};
export function useQueryParams({
  paramNames,
  isRemoveQueryParams = true
}: {
  paramNames: string[];
  isRemoveQueryParams?: boolean;
}) {
  const [queryParams, setQueryParams] = useState<ParameterType>();
  const location: any = useLocation();
  const history: any = useHistory();
  useEffect(() => {
    if (!queryParams) {
      const params = new URLSearchParams(location.search);
      if (params) {
        let paramValues: ParameterType = {};
        for (let param of paramNames) {
          paramValues[param] = params.get(param);
          isRemoveQueryParams && params.delete(param);
        }
        isRemoveQueryParams &&
          history.replace({
            search: params.toString()
          });
        setQueryParams(paramValues);
      }
    }
  }, []);
  return queryParams;
}
