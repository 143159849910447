import * as utils from "../../../lib/utils";
import {
  QuestionnaireStatus,
  NOTIFICATION_PREFERENCES_MAPPING
} from "../../../constants/app-constants";
import { NotificationTypes } from "../../../types/applicationData";
import i18n from "../../../i18n";

export function getIcon(userType: string, questionnaireStatus: string) {
  if (
    (utils.isAdminUser(userType) &&
      questionnaireStatus === QuestionnaireStatus.AwaitingZeidlerReview) ||
    (utils.isAssetManager(userType) &&
      (questionnaireStatus === QuestionnaireStatus.AwaitingClientApproval ||
        questionnaireStatus === QuestionnaireStatus.AwaitingCommentsReview))
  ) {
    return "arrow-right";
  } else {
    return "view";
  }
}
export function getActiveNotifications(notifications: NotificationTypes) {
  let activeNotifications: string[] = [];
  for (let [key, value] of Object.entries(notifications)) {
    if (value) {
      activeNotifications.push(NOTIFICATION_PREFERENCES_MAPPING[key]);
    }
  }
  return Object.keys(notifications).length === activeNotifications.length
    ? i18n.t("All")
    : activeNotifications.length
    ? activeNotifications.toString()
    : i18n.t("None");
}
