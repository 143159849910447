import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo
} from "react";
import * as apis from "../../../apis/QuestionnairesList";
import Search from "../../presentation/Search";
import Sort from "../../presentation/Sort";
import Pagination from "../../presentation/Pagination";
import PageSize from "../../presentation/PageSize";
import Panel from "../../presentation/Panel";
import { QuestionnairesListState, PageDetailsState, Params } from "./types";
import { useParams } from "react-router-dom";
import { UserContext } from "../../../Contexts/UserContext";
import * as utils from "../../../lib/utils";
import { toast } from "react-toastify";
import { OrgKeyMap } from "../../../constants/app-constants";
import NoDataFound from "../../presentation/NoDataFound";
import { Link } from "react-router-dom";
import Icons from "../../presentation/Icons";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { getAssetManagerSectionAssignment } from "../../../apis/ReviewQuestionnaire";
import CustomModal from "../../presentation/Modals/CustomModal";
import SectionGroupAssignment from "../SectionGroupsAssignments";
import {
  groupsSectionAssignmentQuestionnaire,
  groupsSectionAssignmentGroups,
  groupsSectionAssignmentRoles
} from "../SectionGroupsAssignments/types";
import { useRef } from "react";
import {
  findUserPermissionsFromRoles,
  USER_ADMIN,
  ASSET_MANAGER_USER,
  ADMIN_SECTION_ROLE_ASSIGNMENT_AM_PERMISSION
} from "../../../lib/questionnairePermissionsAccess";
import { useTranslation } from "react-i18next";
import { getReviewQuestionnaire } from "../../../apis/ReviewQuestionnaire";
import { downloadQuestionnaireInExcel } from "../../../services/createExcel";
import { getActiveNotifications } from "./services";
import ClientQuestionnaireNotifications from "../ManageEmailNotifications/ClientQuestionnaireNotifications";
import { TUserDetails } from "../../../types/user-details";
import { NotificationTypes } from "../../../types/applicationData";

type Props = {
  getPageHeaderDetails: (pageDetails: any) => void;
};

const ClientQuestionnaireList: React.FC<Props> = ({ getPageHeaderDetails }) => {
  const { t } = useTranslation();
  const [showGroupSectionAssignmentModal, setShowGroupSectionAssignmentModal] =
    useState<boolean>(false);
  const [sectionGroup, setSectionGroup] = useState<
    groupsSectionAssignmentGroups[]
  >([]);

  const [sectionGroupRoles, setSectionGroupRoles] = useState<
    groupsSectionAssignmentRoles[]
  >([]);
  const { accountId }: Params = useParams<any>();
  const assetManagerId = accountId;
  const DEFAULT_PAGE_SIZE = 20;
  const DEFAULT_START_PAGE = 1;
  const [searchQuestionnairesCount, setSearchQuestionnairesCount] =
    useState<number>(0);
  const [totalQuestionnairesCount, setTotalQuestionnairesCount] =
    useState<number>(0);
  const [questionnairesList, setQuestionnairesList] =
    useState<QuestionnairesListState[]>([]);
  const [pageDetails, setPageDetails] = useState<PageDetailsState>({
    assetManagerId,
    pageSize: DEFAULT_PAGE_SIZE,
    pageNumber: DEFAULT_START_PAGE
  });
  const { userDetails }: { userDetails: TUserDetails } =
    useContext(UserContext);
  const [sectionGroupQuestionnaire, setSectionGroupQuestionnaire] =
    useState<groupsSectionAssignmentQuestionnaire>();
  const selectedAssetManagerQueaId = useRef<string | number>();
  const [selectedItem, setSelectedItem] = useState<QuestionnairesListState>();
  const [showBulkNotificationPopUp, setShowBulkNotificationPopUp] =
    useState<boolean>(false);
  const [refresh, setRefresh] = useState<number>(0);
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
      apis
      .getQuestionnairesList(pageDetails)
      .then((res) => {
        setTotalQuestionnairesCount(res.data?.totalClientQuestionnaireCount);
        setSearchQuestionnairesCount(res.data?.searchClientQuestionnaireCount);
        setQuestionnairesList(res.data?.assetManagerQuestionnaires);
      
      })
      .catch((error) => {
        setQuestionnairesList([]);
        setMessage("")
        toast.error(utils.formatGraphQLError(error.message));
      });
  }, [pageDetails, refresh]);

  useEffect(() => {
    getPageHeaderDetails({
      title: `${OrgKeyMap.module_name} ${
        utils.isAdminUser(userDetails?.user_type) ? t("Admin") : ""
      }`,
      details: [`${totalQuestionnairesCount || 0} ${t("Questionnaires")}`]
    });
  }, [totalQuestionnairesCount, getPageHeaderDetails, userDetails]);

  const assignGroupsToSection = useCallback(
    (assetManagerQuestionnaireId: string | number) => {
      getAssetManagerSectionAssignment({
        assetManagerQuestionnaireId,
        accountId
      })
        .then((res: any) => {
          setSectionGroupQuestionnaire(
            res?.data.getAssetManagerSectionAssignment
          );
          setSectionGroup(res?.data.getAccountGroups);
          setSectionGroupRoles(res?.data.getUserRoles);
          selectedAssetManagerQueaId.current = assetManagerQuestionnaireId;
          setShowGroupSectionAssignmentModal(true);
        })
        .catch((error: any) => {
          toast.error(utils.formatGraphQLError(error.message));
          setShowGroupSectionAssignmentModal(false);
        });
    },
    [accountId]
  );
  const handlePageSize = (pageSize: number) => {
    setPageDetails({
      ...pageDetails,
      pageNumber: DEFAULT_START_PAGE,
      pageSize
    });
  };

  const handleSorting = (value: any) => {
    setPageDetails({
      ...pageDetails,
      pageNumber: DEFAULT_START_PAGE,
      sort: [{ column: value, direction: "asc", priority: 1 }]
    });
  };

  const handleSearch = (value: string) => {
    setPageDetails({
      ...pageDetails,
      pageNumber: DEFAULT_START_PAGE,
      search: value
    });
  };

  const handlePagination = (page: number) => {
    setPageDetails({
      ...pageDetails,
      pageNumber: page
    });
  };
  function downloadQuestionnaire(questionnaireId: string | number) {
    getReviewQuestionnaire({ assetManagerId, questionnaireId })
      .then((response: any) => {
        try {
          downloadQuestionnaireInExcel(response?.data?.reviewQuestionnaire);
        } catch (e) {
          console.error(e);
          toast.error(
            "Error while downloading the excel format for the questionnaire"
          );
        }
      })
      .catch((error: any) => {
        toast.error(utils.formatGraphQLError(error.message));
      });
  }
  const updateNotificationPreferences = useCallback(
    (
      notificationDetails: NotificationTypes,
      assetManagerQuestionnaireId: string | number
    ) => {
      if (questionnairesList?.length) {
        let selectedItemIndex = questionnairesList.findIndex(
          (val) =>
            val.assetManagerQuestionnaireId === assetManagerQuestionnaireId
        );
        let updatedDetails = [...questionnairesList];
        updatedDetails[selectedItemIndex] = {
          ...updatedDetails[selectedItemIndex],
          notificationSubscribed: { allPreferences: { ...notificationDetails } }
        };
        setQuestionnairesList([...updatedDetails]);
      }
    },
    [questionnairesList]
  );
  return (
    <>
      <div className="page-actions container">
        <div className="row">
          <div className="page-action page-action--search col-sm-4 col-md-auto">
            <Search onSearch={handleSearch} />
          </div>
          <div className="page-action dropdown  col-auto">
            <Sort
              sortOptions={[{ label: "Name", value: "name" }]}
              onSort={handleSorting}
            />
          </div>

          <div className="page-action page-action--search col-sm-4 col-md-auto">
            {utils.isAssetManager(userDetails.user_type) && (
              <div className="custom-big-dropdown">
                <button
                  className="btn btn-outline-secondary dropdown-toggle btn-outline-secondary-thin"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="false"
                  aria-expanded="false"
                  onClick={(e: any) => {
                    setShowBulkNotificationPopUp(true);
                    e.stopPropagation();
                  }}
                  disabled={
                    !questionnairesList ||
                    (questionnairesList && !questionnairesList.length)
                  }
                >
                  {t("Manage Email Notifications")}
                </button>

                {showBulkNotificationPopUp && (
                  <ClientQuestionnaireNotifications
                    isBulkUpdate={true}
                    onClose={() => {
                      setShowBulkNotificationPopUp(false);
                    }}
                    // notificationDetails={item.notificationSubscribed}
                    show={showBulkNotificationPopUp}
                    applyBulkChanges={() => {
                      setRefresh(refresh + 1);
                    }}
                    accountId={accountId}
                    // assetManagerQuestionnaireId={item.assetManagerQuestionnaireId}
                    userId={userDetails.user_id}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {utils.isAssetManager(userDetails.user_type) && (
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="p-3 mt-1 mb-4 yellow-bg flex flex-start radius-4">
                <div className="mr-2 mt-1"></div>
                <div>
                  {t("Note")}: {t("By Default you have been subscribed to all the notifications. You can manage your notifications from the Manage Email Notifications Option.")}
                  <br />
                  {t("For Individual Settings you can select the bell icon and make changes accordingly.")}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {questionnairesList && questionnairesList.length > 0 ? (
        <div className="container">
          <div className="row">
            <div className="col-sm-12 mt-4">
              {questionnairesList.map(
                (item: QuestionnairesListState, index: number) => {
                  return (
                    <div key={index}>
                      <Panel
                        title={t(item.title)}
                        link={`/review-questionnaires/${assetManagerId}/${item.id}`}
                        subHeader={
                          <div className="details__list">
                            <div className="detail">
                              <div>
                                {t("Status")}:
                                <b className="ml-1">
                                  {t(item.questionnaireStatus)}
                                </b>
                              </div>
                              {utils.isAssetManager(userDetails.user_type) && (
                                <>
                                  <div className="mx-2 mobile-768">|</div>
                                  <div>
                                    {t("Active Notification")}:
                                    <b className="ml-1">
                                      {getActiveNotifications(
                                        item.notificationSubscribed
                                          .allPreferences
                                      )}
                                    </b>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        }
                        icons={
                          <>
                            {findUserPermissionsFromRoles([
                              {
                                name: USER_ADMIN,
                                permissionType: ASSET_MANAGER_USER
                              },
                              {
                                name: ADMIN_SECTION_ROLE_ASSIGNMENT_AM_PERMISSION
                              }
                            ]) && (
                              <OverlayTrigger
                                rootClose
                                placement="top"
                                overlay={
                                  <Tooltip id={`section-permisions`}>
                                    {t("Configure section permissions")}
                                  </Tooltip>
                                }
                              >
                                <div
                                  className="mr-2 action"
                                  onClick={(e: any) => {
                                    assignGroupsToSection &&
                                      assignGroupsToSection(
                                        item.assetManagerQuestionnaireId
                                      );
                                    e.stopPropagation();
                                  }}
                                >
                                  <Icons
                                    className="icon"
                                    icon="section-assignment"
                                  />
                                </div>
                              </OverlayTrigger>
                            )}
                            <OverlayTrigger
                              rootClose
                              placement="top"
                              overlay={
                                <Tooltip id={`downlaod-excel-${item.id}`}>
                                  {t("Download Excel")}
                                </Tooltip>
                              }
                            >
                              <div
                                className="mr-2 action"
                                onClick={(e: any) => {
                                  downloadQuestionnaire(item.id);
                                  e.stopPropagation();
                                }}
                              >
                                <Icons className="icon" icon="download" />
                              </div>
                            </OverlayTrigger>

                            {utils.isAssetManager(userDetails.user_type) && (
                              <OverlayTrigger
                                rootClose
                                placement="top"
                                overlay={
                                  <Tooltip
                                    id={`email-notifications-${item.id}`}
                                  >
                                    {t("Subscribe/Unsubscribe")}
                                  </Tooltip>
                                }
                              >
                                <div
                                  className="mr-2 action"
                                  onClick={(e: any) => {
                                    setSelectedItem(item);
                                    e.stopPropagation();
                                  }}
                                >
                                  <Icons
                                    className="icon"
                                    icon={"notifications"}
                                  />

                                  {selectedItem?.id === item.id && (
                                    <ClientQuestionnaireNotifications
                                      onClose={() => {
                                        setSelectedItem(undefined);
                                      }}
                                      notificationDetails={
                                        item.notificationSubscribed
                                          .allPreferences
                                      }
                                      show={selectedItem.id === item.id}
                                      applyChanges={
                                        updateNotificationPreferences
                                      }
                                      accountId={accountId}
                                      assetManagerQuestionnaireId={
                                        item.assetManagerQuestionnaireId
                                      }
                                      userId={userDetails.user_id}
                                    />
                                  )}
                                </div>
                              </OverlayTrigger>
                            )}
                            <Link
                              className="action"
                              to={`/review-questionnaires/${assetManagerId}/${item.id}`}
                            >
                              <Icons
                                className="icon text-primary"
                                icon={"view"}
                              />
                            </Link>
                          </>
                        }
                      />
                    </div>
                  );
                }
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 mt-4">
              <div className="pagination-container">
                <div className="row">
                  <div
                    className="col-sm-4 col-md-4"
                    style={{
                      display: "block"
                    }}
                  >
                    <PageSize
                      handleChange={handlePageSize}
                      defaultPageSize={DEFAULT_PAGE_SIZE}
                    />
                  </div>

                  <div className="col-sm-8 col-md-8">
                    <div className="float-right">
                      <Pagination
                        totalRecordsCount={searchQuestionnairesCount}
                        pageSize={pageDetails.pageSize}
                        defaultStartPage={DEFAULT_START_PAGE}
                        handleChange={handlePagination}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <NoDataFound message={message} />
      )}

      {sectionGroupQuestionnaire && setShowGroupSectionAssignmentModal && (
        <CustomModal
          classNames="cdd-groups-management"
          showModal={showGroupSectionAssignmentModal}
          onClose={() => {}}
        >
          <SectionGroupAssignment
            assetManagerQuestionnaireId={selectedAssetManagerQueaId.current}
            questionnaire={sectionGroupQuestionnaire}
            groups={sectionGroup}
            roles={sectionGroupRoles}
            onClose={() => {
              setShowGroupSectionAssignmentModal(false);
            }}
          />
        </CustomModal>
      )}
    </>
  );
};

export default ClientQuestionnaireList;
