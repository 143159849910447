import React, { useState, useEffect } from "react";
import { AxiosResponse } from "axios";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
// import { IntercomProvider } from "react-use-intercom";
import "react-toastify/dist/ReactToastify.min.css";
import { TUserDetails } from "./types/user-details";
import { getUserDetails } from "./services/AuthenticateUserService";
import AppHeader from "./components/presentation/AppHeader";
import AppFooter from "./components/presentation/AppFooter";
import UserContextProvider from "./Contexts/UserContext";
import AccountManagerInfo from "./components/container/AccountManagerInfo";
import ReviewQuestionnaire from "./components/container/ReviewQuestionnaire";
import Loader from "./components/presentation/Loader";
import ScrollToTop from "./components/presentation/ScrollTop";
import { initializeAxiosInterceptors } from "./services/AxiosInterceptor";
import CounterpartySubmittedQuestionnaire from "./components/container/CounterpartySubmittedQuestionnaire";
import { appData } from "./services/appConfig";
import Overlay from "./components/presentation/Overlay";
import { ZEIDLER,BHY } from "./constants/app-constants";
import ReviewSubmittedQuestionnaire from "./components/container/ReviewSubmittedQuestionnaire";
import GuardedRoute from "./GuardedRoute";
import {
  ADMIN_USER,
  ASSET_MANAGER_USER,
  COUNTERPARTY_USER
} from "./lib/questionnairePermissionsAccess";
import { getCookieConsent } from "./lib/utils"

function App() {
  // Private userDetails state to hold user info after successully sign in. Initial state set to null
  const [userDetails, setUserDetails] = useState<TUserDetails | null>(null);
  initializeAxiosInterceptors();

  useEffect(() => {
    if(!getCookieConsent()) {
      localStorage.clear()
    }
    getUserDetails()
      .then((response: AxiosResponse<TUserDetails>) => {
        response && appData.setUserDetails(response.data);
        response && setUserDetails(response.data); // Populating userDetails state returned from service
      })
      .catch((err: Error) => {
        if (err.message !== "redirect") {
          toast.error(err.message);
        }
      });
  }, []);

  return (
    <>
      <Loader />
      <Overlay />
      <ToastContainer
        icon={true}
        draggable={false}
        autoClose={8000}
        hideProgressBar
        position="top-center"
        style={{ width: "50%" }}
        theme={"colored"}
      />
      {/* <IntercomProvider
        appId={
          process.env.REACT_APP_INTERCOM_APP_ID
            ? process.env.REACT_APP_INTERCOM_APP_ID
            : ""
        }
      > */}
      {userDetails && (
        <UserContextProvider user={userDetails}>
          <Router>
            <ScrollToTop />
            <AppHeader />
            <Switch>
              <Route
                exact
                path="/"
                render={() => (
                  <Redirect
                    to={`/accountManagerInfo/${userDetails?.current_account_id}`}
                  />
                )}
              />
              <GuardedRoute
                exact
                path="/review-questionnaires/:assetManagerId/:questionnaireId"
                component={ReviewQuestionnaire}
                accessUserTypes={[ADMIN_USER, ASSET_MANAGER_USER]}
              />
              <GuardedRoute
                path="/accountManagerInfo/:accountId/:nestedPath?"
                component={AccountManagerInfo}
                accessUserTypes={[
                  ADMIN_USER,
                  ASSET_MANAGER_USER,
                  COUNTERPARTY_USER
                ]}
              />
              <GuardedRoute
                exact
                path="/submittedQuestionnaire/:accountType/:accountQuestionnaireId/:assetManagerId/:submittedQuestionnaireId?/:sectionId?/:nestedPath?"
                component={ReviewSubmittedQuestionnaire}
                accessUserTypes={[ADMIN_USER, ASSET_MANAGER_USER]}
              />
              <GuardedRoute
                exact
                path="/counterpartySubmittedQuestionnaire/:accountType/:accountQuestionnaireId/:counterpartyId/:sectionId?"
                component={CounterpartySubmittedQuestionnaire}
                accessUserTypes={[ADMIN_USER, ASSET_MANAGER_USER]}
              />

              <Redirect to={"/"} />
            </Switch>
            {process.env.REACT_APP_ORGANIZATION === BHY ? (
              <AppFooter />
            ) : null}
          </Router>
        </UserContextProvider>
      )}
      {/* </IntercomProvider> */}
    </>
  );
}

export default App;
