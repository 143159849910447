import gql from "graphql-tag";

const commentsFragment = `
    commentId
    commentedById
    comment
    commentedAt
    commentedByName
`;

const questionFragment = `
    id
    questionBankId
    title
    label
    comments(
        assetManagerId: $assetManagerId
        questionnaireId: $questionnaireId
    ){
        ${commentsFragment}  
    }
    placeholder
    helpInfo
    mandatory
    internalUseOnly
    deletedAt
    position
`;

const fieldValuesFragment = `
    id
    label
    value
    defaultDeductions
    position
    deletedAt
    sectionId
`;

const fieldTypeFragment = `
    name
    id
`;

const questionListFragment = `
    ${questionFragment}
    fieldType {
        ${fieldTypeFragment}
    }
    fieldValues {
        ${fieldValuesFragment}
        subQuestions {
            question {
                ${questionFragment}
                fieldType {
                    ${fieldTypeFragment}
                }
                fieldValues {
                    ${fieldValuesFragment}
                    subQuestions {
                        question {
                            ${questionFragment}            
                            fieldType {
                                ${fieldTypeFragment}
                            }
                            fieldValues {
                                ${fieldValuesFragment}
                                subQuestions {
                                    question {
                                        ${questionFragment}                    
                                        fieldType {
                                            ${fieldTypeFragment}
                                        }
                                        fieldValues {
                                            ${fieldValuesFragment}
                                            subQuestions {
                                                question {
                                                    ${questionFragment}
                                                    fieldType {
                                                        ${fieldTypeFragment}
                                                    }
                                                    fieldValues {
                                                        ${fieldValuesFragment}
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const sectionFragment = `
    id
    title
    parentId
    position
    description
    autofill
    visibility
    internalUseOnly
    deletedAt
    comments(
        assetManagerId: $assetManagerId
        questionnaireId: $questionnaireId
    ){
       ${commentsFragment} 
    }
    questions{
        ${questionListFragment}
    }
`;

export const GET_REVIEW_ASSET_MANAGER_QUESTIONNAIRE_QUERY = gql`
  query GetReviewQuestionnaire($assetManagerId: ID!, $questionnaireId: ID!) { 
    reviewQuestionnaire(
        assetManagerId: $assetManagerId
        questionnaireId: $questionnaireId
    ) {
      id
      title
      comments(
        assetManagerId: $assetManagerId
        questionnaireId: $questionnaireId
      ){
        ${commentsFragment} 
      }
      sections{
        ${sectionFragment}
        subSections {
          ${sectionFragment}
          subSections{
            ${sectionFragment}
          }
        }
      }
    }

    questionnaireApprovalDetails(
      assetManagerId: $assetManagerId
      questionnaireId: $questionnaireId
    ) {
      approvedAt
      approvedBy
      approvalStatus
    }

    questionnaireStatus(
      assetManagerId: $assetManagerId
      questionnaireId: $questionnaireId
    )
  }
`;

export const GET_REVIEW_QUESTIONNAIRE_QUERY = gql`
  query GetReviewQuestionnaire($assetManagerId: ID, $questionnaireId: ID!) { 
    reviewQuestionnaire(
        assetManagerId: $assetManagerId
        questionnaireId: $questionnaireId
    ) {
      id
      title
      sections{
        ${sectionFragment}
        subSections {
          ${sectionFragment}
          subSections{
            ${sectionFragment}
          }
        }
      }
    }
  }
`;

export const ADD_COMMENTS_QUERY = gql`
  mutation AddComments($input: SaveQuestionnaireReviewCommentInput!) {
    saveQuestionnaireReviewComment(input: $input) {
      commentsList{
        ${commentsFragment} 
      }      
      data
    }
  }
`;

export const UPDATE_APPROVAL_QUERY = gql`
  mutation UpdateQuestionnaireApprovals($input: ApproveQuestionnaireInput!) {
    approveQuestionnaire(input: $input) {
      data
    }
  }
`;

export const UPDATE_STATUS_QUERY = gql`
  mutation UpdateQuestionnaireStatus(
    $input: UpdateQuestionnaireReviewStatusInput!
  ) {
    updateQuestionnaireReviewStatus(input: $input) {
      data
    }
  }
`;
export const GET_ASSET_MANAGER_SECTION_ASSIGNMENT_QUERY = gql`
  query getAssetManagerSectionAssignment(
    $assetManagerQuestionnaireId: ID!
    $accountId: ID!
  ) {
    getAssetManagerSectionAssignment(
      assetManagerQuestionnaireId: $assetManagerQuestionnaireId
    ) {
      sections {
        id
        name
        groups {
          id
          name
          roles {
            id
            label
          }
        }
      }
    }
    getAccountGroups(accountId: $accountId) {
      id
      name
    }
    getUserRoles(roleType: "client_group_access") {
      id
      label
    }
  }
`;
export const SAVE_ASSET_MANAGER_SECTION_GROUP_ASSIGNMENT = gql`
  mutation saveAssetManagerSectionGroupAssignment(
    $input: SaveAssetManagerSectionGroupAssignmentInput!
  ) {
    saveAssetManagerSectionGroupAssignment(input: $input) {
      message
    }
  }
`;
