import Tabulator from "tabulator-tables";
import { Answer } from "../../container/QuestionComponent/types";
import { ColumnConfiguration, TableStaticElements } from "./types";
import DateEditor from "react-tabulator/lib/editors/DateEditor";

export function createTableConfig(
  tableData: any,
  tableStaticElements: TableStaticElements,
  counter = 1,
  tableAnswer: Answer,
  onTableDataChange: any,
  tableIndex: string | number,
  editable?: boolean
) {
  tableData = typeof tableData === "string" ? JSON.parse(tableData) : tableData;
  let currentTable = tableData[`header${counter}`];
  let answer = findTableAnswerData(tableAnswer);
  // let childTableAnswer = answer && answer[counter - 1][`child${counter}`];
  // console.log("full table answer", answer);

  return {
    data: getPlaceholderData(currentTable, tableData, answer),
    columns: getColumnsDetails(currentTable, tableStaticElements, editable),
    options: Object.assign(
      getTableLayoutDetails(),
      formatRow(
        tableData,
        tableStaticElements,
        counter + 1,
        tableIndex,
        answer,
        onTableDataChange,
        editable
      )
    ),
    rowPlaceHolder: getRowPlaceHolder(currentTable),
    addNewRowButton: isTableEditable(currentTable)
      ? tableData[`button${counter}_label`]
      : false
  };
}

export function isTableEditable(columnData: any) {
  let count = 0;
  columnData?.forEach((col: any) => {
    if (col.editor) {
      count++;
    }
  });
  return count === columnData.length;
}

function getRowPlaceHolder(columnData: any) {
  let placeholderObj: any = {};
  columnData?.forEach((col: any) => {
    placeholderObj[col.field] = col.placeholder;
  });
  return placeholderObj;
}

function getTableLayoutDetails() {
  //console.log("Inside getTableLayoutDetails");
  let options: any = {
    height: "auto",
    minHeight: 20,
    layout: "fitDataFill",
    resizableColumns: false,
    headerSort: false
  };
  return options;
}

function getColumnsDetails(
  currentTable: any,
  tableStaticElements: TableStaticElements,
  editable?: boolean,
  isChildTable?: boolean
) {
  let colObjArray: any[] = [];
  let buttonColObj;
  if (isChildTable) {
    buttonColObj = tableStaticElements.childTableButtons;
  } else {
    buttonColObj = tableStaticElements.removeButtonColObj;
  }
  currentTable?.forEach((col: any) => {
    let colObj: ColumnConfiguration = {
      title: col.title,
      field: col.field,
      placeholder: col.placeholder,
      headerSort: col.headerSort,
      headerTooltip: true,
      minWidth: 50,
      maxWidth: 300,
      cellEditing: function (cell: any, e: any) {
        let colDef = cell.getColumn().getDefinition();
        let placeholder = colDef.placeholder;
        let cellValue = cell.getValue();
        if (cellValue === placeholder) {
          cell.setValue("", true);
        }
      },
      cellEditCancelled: function (cell: any) {
        let colDef = cell.getColumn().getDefinition();
        let placeholder = colDef.placeholder;
        let cellValue = cell._cell.value;
        let oldValue = cell._cell.getOldValue();
        if (!cellValue && !oldValue) {
          cell.setValue(placeholder, true);
        }
      },
      cellEdited: function (cell: any) {
        let colDef = cell.getColumn().getDefinition();
        let placeholder = colDef.placeholder;
        let cellValue = cell._cell.value;
        let oldValue = cell._cell.getOldValue();
        if (!cellValue && oldValue && oldValue !== placeholder) {
          cell.setValue(placeholder, true);
        }
      }
    };
    let editor: any;
    if (editable) {
      editor = col.editor ? setColumnEditor({ editorType: col.type }) : false;
    }
    colObjArray.push({ ...colObj, ...editor });
  });
  if (isTableEditable(currentTable) && editable) {
    colObjArray.push(buttonColObj);
  }
  return colObjArray;
}

function setColumnEditor({ editorType, editorParams }: any) {
  switch (editorType) {
    case "text":
      return {
        editor: "input",
        formatter: function (cell: any, formatterParams: any, onRendered: any) {
          cell.getElement().style.fontWeight = "bold";
          cell.getElement().style.whiteSpace = "pre-wrap";
          cell.getElement().style.overflowWrap = "break-word";
          cell.getElement().style.overflowY = "auto";
          return cell.getValue();
        }
      };
    case "progress":
      return {
        formatter: "progress",
        editor: "progress"
      };
    case "select":
      return {
        editor: "select",
        editorParams: {
          allowEmpty: true,
          showListOnEmpty: true,
          values: editorParams
        }
      };
    case "DateEditor":
      return {
        editor: DateEditor,
        editorParams: { format: "MM/DD/YYYY" }
      };
    case "number":
      return {
        editor: "input"
      };
  }
}

function getPlaceholderData(
  columnData: any,
  tableData: any,
  tableAnswer?: any
) {
  // console.log("Inside getPlaceholderData");
  let placeholderObj: any[] = [];
  let isStaticTable = !isTableEditable(columnData);
  if (tableAnswer) {
    let tableAnswersArray = tableAnswer;
    tableAnswersArray?.forEach((answer: any) => {
      for (let key in answer) {
        if (!answer[key]) {
          columnData?.forEach((col: any) => {
            if (col.field === key) {
              answer[key] = col.placeholder;
            }
          });
        }
      }
      placeholderObj.push(answer);
    });
  } else {
    if (isStaticTable) {
      tableData.rows?.forEach((row: any) => {
        let obj: any = {};
        columnData?.forEach((col: any) => {
          if (row[col.field]) {
            obj[col.field] = row[col.field];
          } else {
            obj[col.field] = col.placeholder;
          }
        });
        placeholderObj.push(obj);
      });
    } else {
      let obj: any = {};
      columnData?.forEach((col: any) => {
        obj[col.field] = col.placeholder;
      });
      placeholderObj.push(obj);
    }
  }
  return placeholderObj;
}

function findTableAnswerData(tableAnswer: any) {
  let answer = tableAnswer?.answer
    ? typeof tableAnswer.answer === "string"
      ? JSON.parse(tableAnswer.answer)
      : tableAnswer.answer
    : null;
  if (answer?.length) {
    return answer;
  }
  return;
}

function formatRow(
  tableData: any,
  tableStaticElements: TableStaticElements,
  counter: number,
  tableIndex: any,
  tableAnswer: any,
  onTableDataChange: any,
  editable?: boolean
) {
  // console.log("counter is >> ", counter);
  let nestedTable = tableData[`header${counter}`];
  //let deepNested = !!tableData[`header${counter + 1}`]?.length;
  //let childTableClassName = `tabulator-child-${tableIndex}`;

  if (!nestedTable) {
    return {};
  }

  return {
    rowFormatter: function (row: any) {
      //create and style holder elements
      const rowIndex = row.getTable().rowManager.getRowIndex(row);
      let rowData = row.getTable().getData()[rowIndex][`child${counter - 1}`];
      //let rowPlaceHolder = getRowPlaceHolder(nestedTable);
      var holderEl = document.createElement("div");
      holderEl.id = "nested-container-" + counter;
      var tableEl = document.createElement("div");
      holderEl.style.boxSizing = "border-box";
      holderEl.style.padding = "10px 30px 10px 10px";
      holderEl.style.borderTop = "1px solid #333";
      holderEl.style.borderBottom = "1px solid #333";
      holderEl.style.background = "#ddd";

      tableEl.style.border = "1px solid #333";
      const childClassName = `child-${counter - 1}-${rowIndex}`;
      tableEl.className = childClassName;

      /*
      if(editable){
        holderEl.appendChild(buttonEl);
      }
      */

      holderEl.appendChild(tableEl);
      row.getElement().appendChild(holderEl);

      let nestedTableConfig = {
        data: getPlaceholderData(nestedTable, tableData, rowData),
        columns: getColumnsDetails(
          nestedTable,
          tableStaticElements,
          editable,
          true
        ),
        ...getTableLayoutDetails(),
        dataChanged: function (newData: any) {
          const tableData = row.getTable().getData();
          tableData[rowIndex][`child${counter - 1}`] = newData;
          onTableDataChange(tableData);
        },
        // rowDeleted:onTableDataChange,
        reactiveData: true
      };

      new Tabulator(tableEl, nestedTableConfig);
    }
  };
}

export function getDefaultPlaceHolder(table: any) {
  let colDef = table.getColumnDefinitions();
  return getRowPlaceHolder(colDef);
}
