import { Section } from "./types";
import {
  Question,
  PublicPrivateCommentsType
} from "../QuestionComponent/types";
export function updateQuestionInSection({
  section,
  question
}: {
  section: Section;
  question: Question;
}) {
  let questionIndex = section.questions.findIndex(
    (val: Question) => val.id === question.id
  );

  let updatedQuestions = [...section.questions];
  updatedQuestions[questionIndex] = question;

  return { ...section, questions: [...updatedQuestions] };
}

export function updateSubSections({
  subSections,
  subSection,
  parentSection
}: {
  subSections: Section[];
  subSection: Section;
  parentSection: Section;
}) {
  let subSecIndex = subSections.findIndex(
    (val: Section) => val.id === subSection.id
  );

  let updatedSections = [...subSections];

  updatedSections[subSecIndex] = subSection;

  return { ...parentSection, subSections: updatedSections };
}

export function updateCommentInSection(
  section: Section,
  comments: PublicPrivateCommentsType[]
) {
  return {
    ...section,
    publicPrivateComments: comments.length ? comments : []
  };
}
