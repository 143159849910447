import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { formatGraphQLError } from "../../../../lib/utils";
import Icons from "../../../presentation/Icons";
import BarChart from "../../../presentation/BarChart";
import Select from "react-select";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { toast } from "react-toastify";
import {
  SelectedQuestionnaire,
  Params,
  ChartState,
  CompletedPublishedChartCountState
} from "../types";
import * as apis from "../../../../apis/Dashboard";
import { getChartDataset, getDatasetCount } from "./service";
import { useTranslation } from "react-i18next";

type Props = {
  questionnairesList: any;
};

const PublishedCompletedQuestionnaireGraph: React.FC<Props> = ({
  questionnairesList
}) => {
  const { t } = useTranslation();
  const { accountId }: Params = useParams<any>();
  const assetManagerId = accountId;
  const defaultSelectedValue = { id: 1, value: "All", label: "All" };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    height: 230,
    legend: false,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            fontColor: "#868e96"
          },
          gridLines: {
            display: false
          }
        }
      ],
      xAxes: [
        {
          ticks: {
            fontColor: "#868e96",
            fontSize: 16
          },
          gridLines: {
            color: "#f6f6f6"
          }
        }
      ]
    }
  };
  const [selectedQuestionnaire, setSelectedQuestionnaire] =
    useState<SelectedQuestionnaire>(defaultSelectedValue);
  const [chartData, setChartData] = useState<ChartState>();
  const [legendCount, setLegendCount] =
    useState<CompletedPublishedChartCountState>({
      completedCount: 0,
      publishedCount: 0
    });

  useEffect(() => {
    const { value } = selectedQuestionnaire;
    const reqObj =
      value !== "All"
        ? {
            assetManagerId: assetManagerId,
            questionnaireId: value
          }
        : { assetManagerId: assetManagerId };
    apis
      .getPublishedCompleted(reqObj)
      .then((res) => {
        setChartData(getChartDataset(res?.data?.getPublishedCompleted));
        setLegendCount(getDatasetCount(res?.data?.getPublishedCompleted));
      })
      .catch((error) => {
        toast.error(formatGraphQLError(error.message));
      });
  }, [selectedQuestionnaire]);

  return (
    <div className="row mb-4">
      <div className="col-sm-12">
        <div className="card card--chartjs">
          <div className="card__header">
            <div className="row row--minus-margin-fix align-items-start justify-content-between flex-wrap">
              <div className="card__title col-12 col-sm-8 col-lg-6">
                <h4 className="mb-lg-0">
                  {t("Questionnaires published and completed")}
                  <OverlayTrigger
                    placement="bottom"
                    rootClose
                    overlay={
                      <Tooltip id={`questionnaires-published-completed`}>
                        {t(
                          "Published and completed questionnaire breakdown by month"
                        )}
                      </Tooltip>
                    }
                  >
                    <span className="m-l-10">
                      <Icons className="icon icon--small" icon="info"></Icons>
                    </span>
                  </OverlayTrigger>
                </h4>
              </div>
              {/* <!-- <p className="chart-legend col-12 col-sm-6 mb-sm-0 col-lg-3 col-xl-4 align-self-center"><span className="chart-legend__square published"></span> Published <span className="chart-legend__square completed"></span> Completed</p> --> */}

              <div className="col-9 col-sm-4 col-lg-3 col-xl-2">
                <Select
                  options={questionnairesList}
                  name="select-single"
                  placeholder={`${t("Select")}...`}
                  onChange={(ele: any) => {
                    setSelectedQuestionnaire(ele);
                  }}
                  defaultValue={defaultSelectedValue}
                  isClearable={false}
                  isMulti={false}
                  noOptionsMessage={() => t("No options")}
                />
              </div>

              <div className="col-12 col-md-6 d-inline-flex align-items-end">
                <span className="chart-ytd text-muted">YTD</span>
              </div>
              <p className="chart-legend chart-legend--published col-12 col-md-6 align-self-end">
                <span className="chart-legend__square published"></span>{" "}
                <span className="js-chart-published">
                  {t("Published")} {`(${legendCount.publishedCount})`}
                </span>{" "}
                <span className="chart-legend__square completed"></span>{" "}
                <span className="js-chart-completed">
                  {t("Completed")} {`(${legendCount.completedCount})`}
                </span>
              </p>
            </div>
          </div>

          <div className="card__body pt-0">
            <div id="chartPublishedCompleted">
              {chartData ? (
                <BarChart data={chartData} options={options} />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PublishedCompletedQuestionnaireGraph;
