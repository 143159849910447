import React from "react";
import { useTranslation } from "react-i18next";

import { useHistory } from "react-router-dom";
interface Props {
  title: string;
  description?: string;
  link?: string;
  icons?: any;
  infoIcon?: any;
  subHeader?: any;
}

const Panel: React.FC<Props> = ({
  title,
  description,
  link,
  icons,
  infoIcon,
  subHeader
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const navigateToLink = (link: string) => {
    history.push(link);
  };
  return (
    <div
      className={`card ${link ? "card-onclick" : null} card--list`}
      onClick={(e) => {
        link ? navigateToLink(link) : e.stopPropagation();
      }}
    >
      <div className="card__body">
        <div className="flex-center">
          {infoIcon}
          <div>
            <h4 className="card__title">{t(title)}</h4>
            {description && (
              <div className="details__list">
                <span className="detail">{t(description)}</span>
              </div>
            )}
            {subHeader}
          </div>
        </div>
      </div>
      <div className="card__actions">{icons}</div>
    </div>
  );
};

export default Panel;
