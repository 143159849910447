import Keycloak from "keycloak-js";

const kc = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM!,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID!
});

const initKeyCloak = (onAuthenticatedCb: any) => {
  kc.init({
    onLoad: "check-sso",
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html'
  })
    .then((authenticated) => {
      console.log("authenticated", authenticated);
      if (!authenticated) {
        doLogin();
      } else {
        onAuthenticatedCb();
      }
    })
    .catch((err) => {
      console.error(err);
    });
};

const doLogin = kc.login;

const doLogout = kc.logout;

const getToken = () => kc.token;

const isLoggedIn = () => !!kc.token;

const updateToken = kc.updateToken;

const UserService = {
  initKeyCloak,
  doLogin,
  doLogout,
  getToken,
  isLoggedIn,
  updateToken
};

export default UserService;
