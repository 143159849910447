import React from "react";
import { ApprovalStatus } from "../../container/ReviewQuestionnaire/types";
import Icons from "../Icons";
import * as utils from "../../../lib/utils";
import { QuestionnaireStatus } from "../../../constants/app-constants";
import Modal from "react-bootstrap/esm/Modal";
import { useTranslation } from "react-i18next";

type Props = {
  modalTitle: string;
  userType: string;
  questionnaireStatus: string;
  questionnaireApprovalDetails: any;
  onConfirmApproval: (obj: any) => void;
  onModalClose: () => void;
  showApprovalModal: boolean;
};

const ApprovalModal: React.FC<Props> = ({
  modalTitle,
  userType,
  questionnaireStatus,
  questionnaireApprovalDetails,
  onConfirmApproval,
  onModalClose,
  showApprovalModal
}) => {
  const { t } = useTranslation();
  function getApproveButton(userType: string, value: ApprovalStatus) {
    if (value.approvedBy) {
      return (
        <span className="btn btn-success btn-icon">
          {t("Approved")}
          <Icons className="ml-2" icon="tick" />
        </span>
      );
    } else {
      if (utils.isAdminUser(userType)) {
        return "";
      } else {
        if (
          utils.isAssetManager(userType) &&
          questionnaireStatus !== QuestionnaireStatus.AwaitingZeidlerReview &&
          questionnaireStatus !== QuestionnaireStatus.Approved
        ) {
          return (
            <button
              className="btn btn-primary"
              data-dismiss="modal"
              data-toggle="modal"
              data-target="#cdd-questionnaire-approve-modal--confirm"
              type="button"
              onClick={onConfirmApproval}
              name={value.approvalStatus}
            >
              {t("Approve")}
            </button>
          );
        }
      }
    }
  }

  return (
    <Modal
      show={showApprovalModal}
      onHide={onModalClose}
      className="modal fade"
      id="cdd-questionnaire-approve-modal"
      tabIndex={-1}
    >
      <Modal.Header className="modal__header" closeButton>
        <Modal.Title className="modal__title">{t(modalTitle)}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal__body modal__body--comments p-0">
        <ul className="modal__list modal__list--flex-space-list">
          {questionnaireApprovalDetails &&
            questionnaireApprovalDetails.length > 0 &&
            questionnaireApprovalDetails.map(
              (value: ApprovalStatus, index: number) => {
                return (
                  <li className="modal__list-item" key={index}>
                    <span>
                      <h4 className="mb-0">{value.approvalStatus}</h4>
                      <div className="details__list">
                        <span className="detail">
                          {value.approvedBy
                            ? `Approved by: ${value.approvedBy}`
                            : "Waiting for approval"}
                        </span>
                      </div>
                    </span>
                    {getApproveButton(userType, value)}
                  </li>
                );
              }
            )}
        </ul>
      </Modal.Body>
    </Modal>
  );
};

export default ApprovalModal;
