import { ResponseByStatus } from "../types";

export function getChartDataset(responseByStatus: ResponseByStatus) {
  const { labels, data } = getMappedData(responseByStatus);
  return {
    labels: labels,
    datasets: [
      {
        label: "My First Dataset",
        data: data,
        backgroundColor: [
          "#0abaee",
          "#f5a623",
          "#f3c67b",
          "#c47df8",
          "#d5b2ef",
          "#35cb93",
          "#dee2e6"
        ],
        hoverOffset: 4
      }
    ]
  };
}
export const statusLabelMapping = [
  { label: "New", key: "new", value: 0, dotClassName: "new" },
  { label: "Draft", key: "draft", value: 0, dotClassName: "draft" },
  {
    label: "Missing Information",
    key: "missingInformation",
    value: 0,
    dotClassName: "missing"
  },
  {
    label: "Under review",
    key: "underReview",
    value: 0,
    dotClassName: "pending"
  },
  {
    label: "Ready for approval",
    key: "readyForClientApproval",
    value: 0,
    dotClassName: "approval"
  },
  { label: "Valid", key: "valid", value: 0, dotClassName: "valid" },
  { label: "Archived", key: "archived", value: 0, dotClassName: "archived" }
];
function getMappedData(responseByStatus: any) {
  let labels: string[] = [];
  let data: number[] = [];
  statusLabelMapping.forEach(({ label, key }) => {
    labels.push(label);
    data.push(responseByStatus[key]);
  });
  return { labels, data };
}

export function getResponseStatusCount(responseByStatus: any) {
  const statusCount = statusLabelMapping.map((obj) => {
    return { ...obj, value: responseByStatus[obj.key] };
  });
  return statusCount;
}
