import React from "react";
import { useTranslation } from "react-i18next"

const AppFooter = () => {
  const { t, i18n } = useTranslation()
  return (
    <footer className="site-footer">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <p>© BerlinHyp {new Date().getFullYear()}. {t("All Rights Reserved")}</p>
          </div>
          <div className="col-md-12">
            <div className="ft-polices-Link">
              <a
                href={`/locales/${i18n.language}/terms_and_conditions.html#legal-notice`}
                target="_blank"
                rel="noopener"
              >
                {t("Legal Notice")}
              </a>
              <a
                href={`/locales/${i18n.language}/terms_and_conditions.html#website-terms`}
                target="_blank"
                rel="noopener"
              >
                {t("Website terms of Use")}
              </a>
              <a
                href={`/locales/${i18n.language}/terms_and_conditions.html#privacy-notice`}
                target="_blank"
                rel="noopener"
              >
                {t("Data Protection Policy")}
              </a>
              <a
                href="mailto:kyc-support@berlinhyp.de"
                target="_blank"
                rel="noopener"
              >
                {t("Support")}
              </a>
              {/* <a
                href={`${process.env.REACT_APP_SSO_LOGIN_URL}/terms_and_conditions#cookie-policy`}
                target="_blank"
                rel="noopener"
              >
                Privacy and Cookies Notice
              </a> */}
              {/* <a
                href={`https://zeidler.group/insights/#insights_types-release-notes?utm_source=zeidler_swift&amp;utm_medium=link&amp;utm_campaign=swift_cross_ref`}
                target="_blank"
                rel="noopener"
              >
                Release Notes
              </a> */}
              {/* <a
                href={`https://zeidler.group/insights/?utm_source=zeidler_swift&amp;utm_medium=link&amp;utm_campaign=swift_cross_ref`}
                target="_blank"
                rel="noopener"
              >
                Insights
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default AppFooter;
