import React from "react";
import { addNodeDimension } from "../../service";

const translate = (addNodeDimension - 16) / 2;

const AddIcon = () => (
  <g transform={`translate(${translate}, ${translate})`}>
    <path
      d="M1 8L15 8M8 15L8 1L8 15Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </g>
);

export default AddIcon;
