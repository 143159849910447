import gql from "graphql-tag";

export const GET_DASHBOARD_QUESTIONNAIRES_LIST_QUERY = gql`
  query PublishedQuestionnaires($assetManagerId: ID!) {
    publishedQuestionnaires(assetManagerId: $assetManagerId) {
      id
      title
    }
  }
`;

export const GET_PUBLISHED_COMPLETED_QUERY = gql`
  query PublishedCompletedDashboard(
    $assetManagerId: ID!
    $questionnaireId: ID
  ) {
    getPublishedCompleted(
      assetManagerId: $assetManagerId
      questionnaireId: $questionnaireId
    ) {
      published
      completed
    }
  }
`;

export const GET_COUNTERPARTIES_ONBOARDED = gql`
  query CounterpartiesOnboarded($assetManagerId: ID!) {
    newCounterpartiesOnboarded(assetManagerId: $assetManagerId) {
      currentMonth
      lastMonth
      lastTwelveMonths
    }
  }
`;

export const GET_RESPONSE_BY_STATUS = gql`
  query ResponseByStatus($assetManagerId: ID!, $questionnaireId: ID) {
    responseByStatus(
      assetManagerId: $assetManagerId
      questionnaireId: $questionnaireId
    ) {
      archived
      draft
      missingInformation
      new
      readyForClientApproval
      totalResponse
      underReview
      valid
    }
  }
`;

export const GET_HIGH_RISK_COUNTERPARTIES = gql`
  query HighRiskCounterparties($assetManagerId: ID!, $questionnaireId: ID) {
    highRiskCounterparties(
      assetManagerId: $assetManagerId
      questionnaireId: $questionnaireId
    ) {
      submittedQuestionnaires {
        id
        questionnaireId
        score
        title
        sectionId
        counterpartyName
        accountQuestionnaireId
      }
    }
  }
`;

export const GET_RESPONSE_TIMELINE = gql`
  query ResponseTimeline($assetManagerId: ID!) {
    responseTimeline(assetManagerId: $assetManagerId) {
      oneMonthCount
      twoMonthCount
      threeMonthCount
      fourMonthCount
      fourPlusMonthCount
    }
  }
`;
