import React, { useState, useRef, useEffect, useCallback } from "react";
import NotificationDetails from "../NotificationDetails";
import { NotificationTypes } from "../../../../types/applicationData";
import { saveAMNotificationPreferences } from "../../../../apis/QuestionnairesList";
import { toast } from "react-toastify";
import { formatGraphQLError } from "../../../../lib/utils";
import BulkNotificationDetails from "../BulkNotificationDetails";
import { QuestionnairesList } from "./types";
import { getAllQuestionnairesListForAM } from "../../../../apis/QuestionnairesList";
import { NOTIFICATION_PREFERENCES_MAPPING } from "../../../../constants/app-constants";
interface Props {
  show: boolean;
  isBulkUpdate?: boolean;
  onClose: () => void;
  notificationDetails?: NotificationTypes;
  applyChanges?: (
    notifications: NotificationTypes,
    assetManagerQuestionnaireId: string | number
  ) => void;
  applyBulkChanges?: () => void;
  accountId: string | number;
  assetManagerQuestionnaireId?: string | number;
  userId: string | number;
}
const ClientQuestionnaireNotifications: React.FC<Props> = ({
  show,
  isBulkUpdate,
  onClose,
  notificationDetails,
  applyChanges,
  applyBulkChanges,
  accountId,
  assetManagerQuestionnaireId,
  userId
}) => {
  const node = useRef<HTMLDivElement>(null);
  const [allQuestionnaires, setAllQuestionnaires] =
    useState<QuestionnairesList[]>();
  const handleClick = useCallback(
    (e: any) => {
      if (node.current && node.current.contains(e.target)) {
        return;
      }
      // outside click
      // ... do whatever on click outside here ...
      onClose();
    },
    [onClose]
  );
  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [handleClick]);
  useEffect(() => {
    if (isBulkUpdate) {
      getAllQuestionnairesListForAM({ assetManagerId: accountId }).then(
        (res) => {
          setAllQuestionnaires(res?.data?.assetManagerQuestionnaires);
        }
      );
    }
  }, [isBulkUpdate, accountId]);
  const saveNotificationPreferences = useCallback(
    (
      notificationDetails: NotificationTypes,
      assetManagerQuestionnaireId: string[] | number[],
      isBulkUpdateOperation: boolean
    ) => {
      assetManagerQuestionnaireId?.length &&
        saveAMNotificationPreferences({
          userId,
          accountId,
          assetManagerQuestionnaireId: assetManagerQuestionnaireId,
          ...notificationDetails
        })
          .then((res) => {
            toast.success(res.data.saveAmNotificationPreferences.message);
            isBulkUpdateOperation
              ? applyBulkChanges && applyBulkChanges()
              : applyChanges &&
                applyChanges(
                  notificationDetails,
                  assetManagerQuestionnaireId[0]
                );
            onClose();
          })
          .catch((e: Error) => {
            console.error(e);
            toast.error(formatGraphQLError(e.message));
          });
    },
    [userId, accountId, applyChanges, onClose, applyBulkChanges]
  );
  return isBulkUpdate ? (
    <span ref={node}>
      {allQuestionnaires && (
        <BulkNotificationDetails
          onClose={onClose}
          show={show}
          bulkUpdateList={allQuestionnaires}
          applyBulkChanges={(
            notifications: NotificationTypes,
            isSelectAll: boolean,
            selectedQuestionnaires: string[] | number[]
          ) => {
            saveNotificationPreferences(
              notifications,
              isSelectAll ? ["all"] : selectedQuestionnaires,
              isBulkUpdate
            );
          }}
        />
      )}
    </span>
  ) : (
    <span ref={node}>
      {notificationDetails && (
        <NotificationDetails
          notificationPreferences={NOTIFICATION_PREFERENCES_MAPPING}
          show={show}
          notificationDetails={notificationDetails}
          applyChanges={(notifications: NotificationTypes) => {
            assetManagerQuestionnaireId &&
              saveNotificationPreferences(
                notifications,
                [String(assetManagerQuestionnaireId)],
                isBulkUpdate || false
              );
          }}
          onClose={onClose}
        />
      )}
    </span>
  );
};

export default ClientQuestionnaireNotifications;
