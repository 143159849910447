import React, { useState } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  defaultPageSize: number;
  options?: number[];
  handleChange: (selectValue: number) => void;
};

const DEFAULT_OPTIONS: number[] = [20, 40, 60, 80]; // Default options for page size component

const PageSize: React.FC<Props> = ({
  defaultPageSize,
  options = DEFAULT_OPTIONS,
  handleChange
}) => {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const handlePageSize = ({ target }: { target: any }) => {
    let val = parseInt(target.value);
    setPageSize(val);
    handleChange(val); // Update parent with selected page size, so that it can update the pagination component
  };

  return (
    <div className="pagination__limit d-flex align-items-center">
      <span>{t("Show")}</span>
      <div className="page-action dropdown mx-2 mb-0">
        <select
          onChange={handlePageSize}
          value={pageSize}
          className="btn btn-outline-secondary btn-sm dropdown-toggle mb-0"
        >
          {options?.map((size) => (
            <option key={size} value={size}>
              {size}
            </option>
          ))}
        </select>
      </div>
      <span>{t("rows per page")}</span>
    </div>
  );
};

export default PageSize;
