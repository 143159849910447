import client from "../apollo.config";
import * as queries from "./queries";
export const getRiskCountries = () => {
  return client.query({
    query: queries.GET_RISK_COUNTRIES_QUERY,
    fetchPolicy: "network-only"
  });
};
export const getRiskIndustries = () => {
  return client.query({
    query: queries.GET_RISK_INDUSTRIES_QUERY,
    fetchPolicy: "network-only"
  });
};
export const getRiskLegalForms = () => {
  return client.query({
    query: queries.GET_RISK_LEGAL_FORMS_QUERY,
    fetchPolicy: "network-only"
  });
};
export const getQuestionnaireRiskLevels = () => {
  return client.query({
    query: queries.GET_QUESTIONNAIRE_RISK_LEVELS,
    fetchPolicy: "network-only"
  });
};
