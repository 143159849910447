import gql from "graphql-tag";

export const GET_USERS_LIST_QUERY = gql`
  query GetUsersList(
    $pageNumber: Int
    $pageSize: Int
    $accountId: ID!
    $search: String
    $sort: [SortingAttributes!]
    $filters: [UserFilterAttributes!]
  ) {
    usersList(
      pageNumber: $pageNumber
      pageSize: $pageSize
      accountId: $accountId
      search: $search
      sort: $sort
      filters: $filters
    ) {
      name
      email
      roles {
        label
        id
      }
      accountGroups {
        id
        name
      }
      status
      userId
      validDomain
    }
    totalUsersCount(accountId: $accountId)
    userFilterList(accountId: $accountId, filters: $filters) {
      nameList
      emailList
      statusList
      rolesList
      accountGroupList
    }
    userListSearchCount(
      accountId: $accountId
      search: $search
      filters: $filters
    )
    getAccountGroups(accountId: $accountId) {
      id
      name
      usageType
      isDefaultGroup
    }
    accountTypes(accountId: $accountId)
  }
`;

export const CREATE_USER_QUERY = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      message
    }
  }
`;

export const GET_USER_ROLES_QUERY = gql`
  query GetUserRoles($roleType: [String!]!) {
    getUserRoles(roleType: $roleType) {
      label
      id
    }
  }
`;

export const INVITE_USER_QUERY = gql`
  mutation InviteUser($input: InviteUserInput!) {
    inviteUser(input: $input) {
      message
    }
  }
`;

export const DELETE_USER_QUERY = gql`
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      message
    }
  }
`;

export const EDIT_USER_QUERY = gql`
  mutation EditUser($input: EditUserInput!) {
    editUser(input: $input) {
      message
    }
  }
`;
