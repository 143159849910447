import styles from "../css/styles";
import React, { FunctionComponent } from "react";
import Icons from "../../../presentation/Icons";

interface Props {
  onZoomIn: () => void;
  onZoomOut: () => void;
}

const ZoomControls: FunctionComponent<Props> = ({ onZoomIn, onZoomOut }) => (
  <div className={styles.zoomControls}>
    <button className={styles.in} onClick={onZoomIn}>
      <Icons icon="plus" />
    </button>
    <button className={styles.out} onClick={onZoomOut}>
      <Icons icon="minus" />
    </button>
  </div>
);

export default ZoomControls;
