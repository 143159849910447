import { OrgVisualizationNode } from "../types";
import * as d3 from "d3";
import { CSSProperties } from "react";
import * as fallbackVars from "../css/fallback-vars";
import { supportsCssVariables } from "../service";

export const isAvatarUsed = (node: OrgVisualizationNode) =>
  ["company", "natural"].includes(node.type);

export const isDescendant = (
  element: Element | null,
  parent: Element | null
): boolean => {
  if (!element || !parent) {
    return false;
  }

  if (element === parent) {
    return true;
  }

  return isDescendant(element.parentElement, parent);
};

/**
 * Fallback for browsers not supporting CSS variables
 */
export const getFallbackFrameStyle = (
  node: d3.HierarchyPointNode<OrgVisualizationNode>,
  selected: boolean,
  hover: boolean
): CSSProperties | undefined => {
  if (supportsCssVariables()) {
    return;
  }

  const fallbackFrameStyle: CSSProperties = {};

  if (node.data.type === "add" && "fill-primary-fallback") {
    fallbackFrameStyle.fill = fallbackVars.primaryColor;
  }
  if (selected) {
    fallbackFrameStyle.stroke = fallbackVars.primaryColor;
  }
  if (node.data.type === "add" && hover) {
    fallbackFrameStyle.stroke = fallbackVars.buttonOutlineColor;
  }

  return fallbackFrameStyle;
};
