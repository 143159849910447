import {
  NOTIFICATION_PREFERENCES_MAPPING,
  SUBSCRIBE_ALL,
  UNSUBSCRIBE_ALL
} from "../../../../constants/app-constants";
export function parseNotificationPreferences(operationType: string | null) {
  let booleanValue = false;
  let parsedData: any = {};
  if (operationType && operationType === SUBSCRIBE_ALL) {
    booleanValue = true;
  }
  Object.keys(NOTIFICATION_PREFERENCES_MAPPING).forEach(
    (notification: string) => {
      parsedData = { ...parsedData, [notification]: booleanValue };
    }
  );
  return parsedData;
}
export function deriveOperationType(notifications: { [key: string]: boolean }) {
  let notificationKeys = Object.keys(notifications);
  let totalSubscribed = notificationKeys.filter(
    (val: string) => notifications[val]
  );
  let totalUnsubscribed = notificationKeys.filter((val) => !notifications[val]);
  if (totalSubscribed.length === notificationKeys.length) {
    return SUBSCRIBE_ALL;
  }
  if (totalUnsubscribed.length === notificationKeys.length) {
    return UNSUBSCRIBE_ALL;
  }
  return null;
}
