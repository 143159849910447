import {
  SECTION_PENDING,
  SECTION_COMPLETED,
  SUBMIT
} from "../../../constants/app-constants";
import {
  NEW,
  MISSING_INFORMATION,
  DRAFT,
  VALID
} from "../../../lib/questionnaireStatusAccess";
export function getSectionStatusIcon({
  sectionStatus,
  sidebarType
}: {
  sectionStatus: string;
  sidebarType: string;
}) {
  //This condition is for counterparty edit page where answers can be provided
  if (sidebarType === SUBMIT) {
    if ([NEW, MISSING_INFORMATION, DRAFT].includes(sectionStatus)) {
      return { icon: SECTION_PENDING, tooltip: "In progress" };
    } else {
      return { icon: SECTION_COMPLETED, tooltip: "Completed" };
    }
  } else {
    //This condition is for submitted questionnaire review page where answers can be reviewed
    //here answer submit will always be false
    if ([VALID].includes(sectionStatus)) {
      return { icon: SECTION_COMPLETED, tooltip: "Approved" };
    } else {
      return { icon: SECTION_PENDING, tooltip: "Pending Approval" };
    }
  }
}
