import client from "../apollo.config";
import * as queries from "./queries";
import { PageDetailsState } from "../../components/container/ClientQuestionnaireList/types";

export const getQuestionnairesList = ({
  pageNumber,
  pageSize,
  search,
  sort,
  assetManagerId
}: PageDetailsState) => {
  //console.log(pageNumber, pageSize, search, sort, assetManagerId);
  return client.query({
    query: queries.GET_QUESTIONNAIRES_LIST_QUERY,
    fetchPolicy: "network-only",
    variables: { pageNumber, pageSize, search, sort, assetManagerId }
  });
};
export const saveAMNotificationPreferences = (input: {
  userId: string | number;
  accountId: string | number;
  assetManagerQuestionnaireId: string[] | number[];
  publicPrivateComments: boolean;
  //deductionComments: boolean;
  submissionStatusChange: boolean;
}) => {
  return client.mutate({
    mutation: queries.SAVE_AM_NOTIFICATION_PREFERENCES,
    variables: { input }
  });
};
export const getAllQuestionnairesListForAM = ({
  assetManagerId
}: {
  assetManagerId: string | number;
}) => {
  return client.query({
    query: queries.GET_ALL_QUESTIONNAIRES_FOR_AM,
    fetchPolicy: "network-only",
    variables: { assetManagerId }
  });
};
