import { getPastMonths } from "../../../../lib/utils";
import { CompletedPublishedResponse } from "../types";

export function getChartDataset({
  published,
  completed
}: CompletedPublishedResponse) {
  return {
    labels: [...getPastMonths(11, 3), "MTD"],
    datasets: [
      {
        label: "Published",
        data: [...published],
        backgroundColor: "#b6b8c7",
        barThickness: 35,
        barPercentage: 0.5,
        hoverBackgroundColor: "#a3a4ab"
      },
      {
        label: "Completed",
        data: [...completed],
        backgroundColor: "#d9dae3",
        barThickness: 35,
        barPercentage: 0.5,
        hoverBackgroundColor: "#abaec5"
      }
    ]
  };
}

export function getDatasetCount({
  published,
  completed
}: CompletedPublishedResponse) {
  let publishedCount = published.reduce((a, b) => a + b, 0);
  let completedCount = completed.reduce((a, b) => a + b, 0);
  return { publishedCount, completedCount };
}
