import React from "react";
import { Column } from "react-table";
import { Columns, UsersFilterList } from "./types";
import { Filter } from "../../presentation/GridFilters/types";
import Icons from "../../presentation/Icons";
import CustomPopover from "../../presentation/Popovers";

export function getColumnConfig() {
  const columnConfig: Column<Columns>[] = [
    {
      Header: "Name",
      accessor: "name",
      Cell: (cellProps: any) => {
        return cellProps.value || "";
      }
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: (cellProps: any) => {
        return (
          <>
            <span className="user-email">{cellProps.value}</span>
            {cellProps.row.values.validDomain ? (
              ""
            ) : (
              <CustomPopover content="This email does not match with the account domain">
                <div>
                  <Icons className="icon" icon="exclamation"></Icons>
                </div>
              </CustomPopover>
            )}
          </>
        );
      }
    },
    {
      Header: "Status",
      accessor: "status"
    },
    {
      Header: "Roles",
      accessor: "roles",
      disableSortBy: true,
      Cell: (cellProps: any) => {
        return (
          <span className="user-roles">
            {cellProps.value &&
              cellProps.value.map((role: any) => role.label).join(", ")}
          </span>
        );
      }
    },
    {
      Header: "Account Groups",
      accessor: "accountGroups",
      disableSortBy: true,
      Cell: (cellProps: any) => {
        return (
          <span className="account-groups">
            {cellProps.value &&
              cellProps.value.map((group: any) => group.name).join(", ")}
          </span>
        );
      }
    },
    {
      Header: "Action",
      accessor: "actions",
      disableSortBy: true,
      Cell: (cellProps: any) => {
        return (
          <>
            <div className="action-group">
              <div
                className={`action action--view ${
                  cellProps.row.values.status === "Registered"
                    ? "disabled-icon"
                    : ""
                }`}
                onClick={() => {
                  if (cellProps.row.values.status === "Registered")
                    return false;
                  cellProps.action &&
                    cellProps.action.inviteUser(cellProps.row.values.userId);
                }}
              >
                <span>
                  {cellProps.row.values.status === "Invited" ? (
                    <Icons className="icon" icon="reinvite" />
                  ) : (
                    <Icons className="icon" icon="invite" />
                  )}
                </span>
              </div>
              <div
                className="action action--view"
                onClick={() =>
                  cellProps.action &&
                  cellProps.action.openEditDialog({
                    name: cellProps.row.values.name || "",
                    email: cellProps.row.values.email,
                    userId: cellProps.row.values.userId,
                    roles: cellProps.row.values.roles,
                    accountGroups: cellProps.row.values.accountGroups || []
                  })
                }
              >
                <Icons className="icon" icon="edit" />
              </div>
              <div
                className="action action--delete"
                onClick={() =>
                  cellProps.action &&
                  cellProps.action.deleteUser(cellProps.row.values.userId)
                }
              >
                <Icons className="icon" icon="delete" />
              </div>
            </div>
          </>
        );
      }
    },
    {
      accessor: "userId",
      Cell: () => null,
      Header: () => null
    },
    {
      accessor: "validDomain",
      Cell: () => null,
      Header: () => null
    }
  ];
  return columnConfig;
}

export function getGridFiltersConfig(filtersData: UsersFilterList) {
  const gridFilters: Filter[] = [
    {
      label: "Name",
      items: [
        ...new Set(
          filtersData["nameList"].map((x: any) => {
            return { label: x, value: x };
          })
        )
      ],
      key: "name",
      searchable: true,
      multiple: true
    },
    {
      label: "Email",
      items: [
        ...new Set(
          filtersData["emailList"].map((x: any) => {
            return { label: x, value: x };
          })
        )
      ],
      key: "email",
      searchable: true,
      multiple: true
    },
    {
      label: "Status",
      items: [
        ...new Set(
          filtersData["statusList"].map((x: any) => {
            return { label: x, value: x };
          })
        )
      ],
      key: "status"
    },
    {
      label: "Roles",
      items: [
        ...new Set(
          filtersData["rolesList"].map((x: any) => {
            return { label: x, value: x };
          })
        )
      ],
      key: "roles",
      searchable: true,
      multiple: true
    },
    {
      label: "Account Groups",
      items: [
        ...new Set(
          filtersData["accountGroupList"].map((x: any) => {
            return { label: x, value: x };
          })
        )
      ],
      key: "accountGroups",
      searchable: true,
      multiple: true
    }
  ];
  return gridFilters;
}
