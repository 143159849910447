import React, { useState, useCallback, useRef } from "react";
import { NotificationTypes } from "../../../../types/applicationData";
import {
  NOTIFICATION_PREFERENCES_MAPPING,
  SUBSCRIBE_ALL,
  UNSUBSCRIBE_ALL
} from "../../../../constants/app-constants";
import { QuestionnairesList } from "../ClientQuestionnaireNotifications/types";
import { parseNotificationPreferences, deriveOperationType } from "./services";
import Search from "../../../presentation/Search";
import Checkbox from "../../../presentation/Checkbox";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

interface Props {
  show: boolean;
  bulkUpdateList: QuestionnairesList[];
  onClose: () => void;
  applyBulkChanges: (
    notifications: NotificationTypes,
    isSelectAll: boolean,
    selectedQuestionnaires: string[] | number[]
  ) => void;
}
const BulkNotificationDetails: React.FC<Props> = ({
  show,
  bulkUpdateList,
  applyBulkChanges,
  onClose
}) => {
  const { t } = useTranslation();
  const [notificationInput, setNotificationInput] = useState<NotificationTypes>(
    parseNotificationPreferences(UNSUBSCRIBE_ALL)
  );
  const [operationType, setOperationType] = useState<string | null>(
    UNSUBSCRIBE_ALL
  );
  const [search, setSearch] = useState<string>("");

  const handleChange = useCallback(
    ({ target }: { target: any }) => {
      if (notificationInput) {
        let updatedData = {
          ...notificationInput,
          [target.name]: target.checked
        };
        setNotificationInput(updatedData);
        setOperationType(deriveOperationType(updatedData));
      }
    },
    [notificationInput]
  );
  const handleOperationChange = useCallback((operation: string) => {
    setOperationType(operation);
    setNotificationInput(parseNotificationPreferences(operation));
  }, []);
  const [isSelectAll, setSelectAll] = useState<boolean>(false);
  const selectedQuestionnaires = useRef<{ [key: string]: boolean }>({});
  return (
    <div className={`dropdown-menu dropdown-menu-right ${show ? "show" : ""}`}>
      <div className="p-3">
        <button
          onClick={() => {
            handleOperationChange(UNSUBSCRIBE_ALL);
          }}
          className={`btn-normal  ${
            operationType === UNSUBSCRIBE_ALL ? "active" : ""
          } `}
        >
          {t(UNSUBSCRIBE_ALL)}
        </button>
        /
        <button
          onClick={() => {
            handleOperationChange(SUBSCRIBE_ALL);
          }}
          className={`btn-normal ${
            operationType === SUBSCRIBE_ALL ? "active" : ""
          }`}
        >
          {t(SUBSCRIBE_ALL)}
        </button>
        {t("All Notification Types")}
      </div>

      <div className="p-4 border-bottom">
        <div className="pb-2 small-text">{t("Notification Types")}</div>
        <div>
          {notificationInput &&
            Object.entries(notificationInput).map(([key, value]) => {
              return (
                <div className="flex-left-right py-1" key={key}>
                  <div className="bold">
                    {t(NOTIFICATION_PREFERENCES_MAPPING[key])}
                  </div>
                  <div className="border-switch-toggle">
                    <div className="bt-switch-toggle ">
                      <div className="bt-switch-toggle-flex">
                        <div className="toggle-btn">
                          <label className="switch">
                            <input
                              name={key}
                              type="checkbox"
                              onChange={handleChange}
                              checked={value}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      <div className="p-4">
        <div className="pb-3 small-text">
          <Search
            placeholder={t("Search questionnaire")}
            onSearch={(searchtext: string) => {
              setSearch(searchtext);
            }}
          />
        </div>
        <div className="max-scroll-200">
          <div className="form-group mb-0">
            <div className="form-check-group mb-0">
              <Checkbox
                id="questionnaire-select-all"
                name="checkbox"
                label={t("Select all")}
                value="selectAll"
                checked={isSelectAll}
                onChange={() => {
                  setSelectAll(!isSelectAll);
                }}
              />

              {bulkUpdateList
                .filter((list) =>
                  list.title.toLowerCase().includes(search.toLowerCase())
                )
                .map((questionnaire) => {
                  return (
                    <div key={questionnaire.assetManagerQuestionnaireId}>
                      <Checkbox
                        id={`checkbox-list-${questionnaire.assetManagerQuestionnaireId}`}
                        name="checkbox"
                        label={questionnaire.title}
                        value={String(
                          questionnaire.assetManagerQuestionnaireId
                        )}
                        checked={
                          isSelectAll
                            ? true
                            : selectedQuestionnaires.current[
                                questionnaire.assetManagerQuestionnaireId
                              ] || false
                        }
                        onChange={(e: any) => {
                          selectedQuestionnaires.current[e.target.value] =
                            e.target.checked;
                        }}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>

      <div className="pl-4 pr-4 pb-4">
        <button
          className="btn btn-primary"
          onClick={() => {
            let selectedQuesIds = Object.keys(
              selectedQuestionnaires.current
            ).filter((val) => selectedQuestionnaires.current[val]);
            if (selectedQuesIds?.length || isSelectAll) {
              notificationInput &&
                applyBulkChanges(
                  notificationInput,
                  isSelectAll || false,
                  selectedQuesIds
                );
            } else {
              toast.error(
                "Please select questionnaires to apply notification changes"
              );
            }
          }}
        >
          {t("Apply")}
        </button>
        <button className="btn btn-outline-secondary ml-3" onClick={onClose}>
          {t("Cancel")}
        </button>
      </div>
    </div>
  );
};

export default BulkNotificationDetails;
