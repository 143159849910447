import { Questionnaire } from "../components/container/ReviewQuestionnaire/types";
import { Section } from "../components/container/SectionComponent/types";
import {
  Question,
  FieldValue,
  SubQuestion
} from "../components/container/QuestionComponent/types";
import Excel from "exceljs";

const rewritePattern = require("regexpu-core");
const {
  generateRegexpuOptions
} = require("@babel/helper-create-regexp-features-plugin/lib/util");
const { RegExp } = global;
try {
  new RegExp("a", "u");
} catch (err) {
  // @ts-ignore
  global.RegExp = function (pattern, flags) {
    if (flags && flags.includes("u")) {
      return new RegExp(
        rewritePattern(
          pattern,
          flags,
          generateRegexpuOptions({ flags, pattern })
        )
      );
    }
    return new RegExp(pattern, flags);
  };
  // @ts-ignore
  global.RegExp.prototype = RegExp;
}
// const Excel = require("exceljs/dist/es5/exceljs.browser");
const setColumnWidth = function (worksheet: any) {
  worksheet.getColumn("A").width = 120;
  worksheet.getColumn("B").width = 50;
  worksheet.getColumn("C").width = 50;
};
function createWorkbook() {
  const workbook = new Excel.Workbook();
  return workbook;
}
function createWorksheet({
  workbook,
  sheetName
}: {
  workbook: any;
  sheetName: string;
}) {
  const sheet = workbook.addWorksheet(sheetName);
  return sheet;
}
async function download({
  workbook,
  fileName,
  extension = "xlsx"
}: {
  workbook: any;
  fileName: string;
  extension?: string;
}) {
  const buf = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([buf]), `${fileName}.${extension}`);
}
function addHeader({
  worksheet,
  headerName
}: {
  worksheet: any;
  headerName: string;
}) {
  worksheet.columns = [
    {
      header: headerName,
      key: "title",
      width: 20
    }
  ];
  worksheet.mergeCells("A1", "C1");
  let cellA1 = worksheet.getCell("A1");
  cellA1.alignment = { vertical: "middle", horizontal: "center" };
  cellA1.fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "303246" }
  };
  cellA1.font = {
    size: 20,
    bold: true,
    color: { argb: "FFFFFF" }
  };
  cellA1.border = {
    top: { style: "thin" },
    left: { style: "thin" },
    bottom: { style: "thin" },
    right: { style: "thin" },
    color: { argb: "FFFFFF" }
  };
}
function getDatasetSections(allSections: Section[]) {
  return allSections.filter((section) => !section.visibility);
}
function addColumns({
  worksheet,
  columns
}: {
  worksheet: any;
  columns: string[];
}) {
  let headerRow = worksheet.addRow(columns);
  headerRow.eachCell(function (cell: any) {
    cell.style = {
      font: { size: 13, bold: true }
    };
  });
}
const getCombinedQuesAndSections = function (
  questions: Question[] = [],
  sections: Section[] = []
) {
  let combinedData: any[] = [];
  combinedData = combinedData.concat(questions).concat(sections);
  return combinedData.sort((a: any, b: any) => a.position - b.position);
};
function calculateIndentation(entityNumber: string) {
  let count = (entityNumber.match(/\./g) || []).length;
  return count ? count + (count + 1) : 0;
}
function createNestedTable({
  worksheet,
  tableData,
  tableLevel = 1
}: {
  worksheet: any;
  tableData: any;
  tableLevel?: number;
}) {
  let columns: any[] = [];
  let rows: any = [];
  tableData[`header${tableLevel}`].forEach((value: any) => {
    columns.push(value.title);
    rows.push(value.placeholder);
  });
  addColumns({ worksheet, columns });
  worksheet.addRow(rows);
}
function parseTableData({
  worksheet,
  tableData,
  tableLevel = 1
}: {
  worksheet: any;
  tableData: any;
  tableLevel?: number;
}) {
  let tableRow: any = {};
  worksheet.columns = tableData[`header${tableLevel}`].map((value: any) => {
    tableRow[value.field] = value.placeholder;
    return { header: value.title, key: value.field, width: 32 };
  });
  // worksheet.addRow(tableRow);
  if (tableData.rows?.length) {
    tableData.rows.forEach((item: any) => {
      worksheet.addRow(item);
      if (tableData[`header${tableLevel + 1}`]?.length) {
        createNestedTable({ worksheet, tableData, tableLevel: tableLevel + 1 });
      }
    });
  } else {
    if (tableData[`header${tableLevel + 1}`]?.length) {
      worksheet.addRow(tableRow);
      createNestedTable({ worksheet, tableData, tableLevel: tableLevel + 1 });
    }
  }
}
function printTableQuestion({
  workbook,
  questionNumber,
  fieldValues
}: {
  workbook: any;
  questionNumber: string;
  fieldValues: FieldValue[];
}) {
  const tableData = JSON.parse(fieldValues[0].value);
  const sheetName = `${questionNumber}-Table`;
  const tableWorksheet = createWorksheet({
    workbook,
    sheetName: sheetName
  });
  parseTableData({
    worksheet: tableWorksheet,
    tableData
  });
  return sheetName;
}
function printDataSetQuestion({
  worksheet,
  workbook,
  questionNumber,
  fieldValues,
  dataSetSections
}: {
  worksheet: any;
  workbook: any;
  questionNumber: string;
  fieldValues: FieldValue[];
  dataSetSections: Section[];
}) {
  let mappedDataSetId: string | number = fieldValues[0].sectionId;

  let mappedSection: Section | undefined = dataSetSections.find(
    (section) => String(section.id) === String(mappedDataSetId)
  );
  if (mappedSection) {
    printSections({
      worksheet,
      workbook,
      sectionNumber: `${questionNumber}.1`,
      section: mappedSection,
      dataSetSections
    });
  }
}
function printSelectQuestions({
  worksheet,
  questionNumber,
  fieldValues
}: {
  worksheet: any;
  questionNumber: string;
  fieldValues: FieldValue[];
}) {
  let sortedFieldValues: FieldValue[] = [];
  sortedFieldValues = sortedFieldValues.concat(fieldValues).sort((a, b) => {
    return a.position - b.position;
  });
  sortedFieldValues.forEach((fieldValue) => {
    let optionsRow = worksheet.addRow([`- ${fieldValue.label}`]);
    optionsRow.getCell(1).alignment = {
      indent: calculateIndentation(`${questionNumber}`)
    };
  });
}
function printRadioCheckboxQuestions({
  worksheet,
  workbook,
  questionNumber,
  fieldValues,
  dataSetSections
}: {
  worksheet: any;
  workbook: any;
  questionNumber: string;
  fieldValues: FieldValue[];
  dataSetSections: Section[];
}) {
  let sortedFieldValues: FieldValue[] = [];
  sortedFieldValues = sortedFieldValues.concat(fieldValues).sort((a, b) => {
    return a.position - b.position;
  });
  sortedFieldValues.forEach((fieldValue, optionIndex) => {
    let optionsRow = worksheet.addRow([
      `${questionNumber}.${optionIndex + 1}. ${fieldValue.label}`
    ]);
    optionsRow.getCell(1).alignment = {
      indent: calculateIndentation(`${questionNumber}.${optionIndex + 1}`)
    };
    if (fieldValue.subQuestions && fieldValue.subQuestions.length) {
      let sortedSubQuestions: SubQuestion[] = [];
      sortedSubQuestions = sortedSubQuestions
        .concat(fieldValue.subQuestions)
        .sort((a, b) => {
          return a.question.position - b.question.position;
        });
      sortedSubQuestions.forEach((subQ, subQIndex) => {
        printQuestion({
          worksheet,
          workbook,
          questionNumber: `${questionNumber}.${optionIndex + 1}.${
            subQIndex + 1
          }`,
          question: subQ.question,
          dataSetSections
        });
      });
    }
  });
}
function printQuestion({
  worksheet,
  workbook,
  questionNumber,
  question,
  dataSetSections
}: {
  worksheet: any;
  workbook: any;
  questionNumber: string;
  question: Question;
  dataSetSections: Section[];
}) {
  const questionType = question.fieldType.name;
  let row = worksheet.addRow([
    `${questionNumber}. ${question.label}`,
    questionType,
    question.internalUseOnly ? "Yes" : "No"
  ]);
  row.getCell(1).alignment = {
    indent: calculateIndentation(questionNumber)
  };
  if (question.fieldValues.length) {
    switch (questionType) {
      case "radio_button":
      case "checkbox":
        printRadioCheckboxQuestions({
          worksheet,
          workbook,
          questionNumber,
          fieldValues: question.fieldValues,
          dataSetSections
        });
        break;
      case "select_single":
      case "select_multiple":
        printSelectQuestions({
          worksheet,
          questionNumber,
          fieldValues: question.fieldValues
        });
        break;
      case "data_set":
        printDataSetQuestion({
          worksheet,
          workbook,
          questionNumber,
          fieldValues: question.fieldValues,
          dataSetSections
        });
        break;
      case "table": {
        const tableSheetName = printTableQuestion({
          workbook,
          questionNumber,
          fieldValues: question.fieldValues
        });
        let tableRow = worksheet.addRow([
          `Please refer sheet ${tableSheetName} to see table structure`
        ]);
        tableRow.getCell(1).alignment = {
          indent: calculateIndentation(questionNumber)
        };
        tableRow.getCell(1).font = {
          color: { argb: "728FCE" }
        };
      }
    }
  }
}
function printSections({
  worksheet,
  workbook,
  sectionNumber,
  section,
  dataSetSections
}: {
  worksheet: any;
  workbook: any;
  sectionNumber: string;
  section: Section;
  dataSetSections: Section[];
}) {
  let row = worksheet.addRow([
    `${sectionNumber}. ${section.title}`,
    "",
    section.internalUseOnly ? "Yes" : "No"
  ]);
  row.getCell(1).alignment = { indent: calculateIndentation(sectionNumber) };
  let combinedData = getCombinedQuesAndSections(
    section.questions,
    section.subSections
  );

  combinedData.length &&
    combinedData.forEach((item, index) => {
      //This means it is question
      if ("label" in item) {
        printQuestion({
          worksheet,
          workbook,
          questionNumber: `${sectionNumber}.${index + 1}`,
          question: item,
          dataSetSections
        });
      } else {
        printSections({
          worksheet,
          workbook,
          sectionNumber: `${sectionNumber}.${index + 1}`,
          section: item,
          dataSetSections
        });
      }
    });
}
export function downloadQuestionnaireInExcel(questionnaire: Questionnaire) {
  const workbook = createWorkbook();
  const worksheet = createWorksheet({ workbook, sheetName: "Questionnaire" });
  addHeader({ worksheet, headerName: questionnaire.title });
  addColumns({
    worksheet,
    columns: ["Questionnaire", "Question type", "Is internal use only ?"]
  });
  let sortedSection: Section[] = [];
  sortedSection = sortedSection
    .concat(questionnaire?.sections)
    .sort((a: Section, b: Section) => a.position - b.position);
  sortedSection.forEach((section, index) => {
    section.visibility &&
      printSections({
        worksheet,
        workbook,
        sectionNumber: String(index + 1),
        section,
        dataSetSections: getDatasetSections(questionnaire?.sections)
      });
  });
  setColumnWidth(worksheet);
  download({ workbook, fileName: questionnaire.title });
}
