import React, { useEffect, useState, useContext, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Params } from "./types";
import * as apis from "../../../apis/ReviewQuestionnaire";
import {
  Questionnaire,
  CommentableType,
  CommentDetails,
  ApprovalStatus
} from "./types";
import { Section } from "../SectionComponent/types";
import PageHeader from "../../presentation/PageHeader";
import SectionComponent from "../SectionComponent";
import { toast } from "react-toastify";
import Icons from "../../presentation/Icons";
import Accordion from "react-bootstrap/Accordion";
import { UserContext } from "../../../Contexts/UserContext";
import * as service from "./services";
import CommentsModal from "../../presentation/Modals/CommentModal";
import ApprovalModal from "../../presentation/Modals/ApprovalModal";
import ConfirmationModal from "../../presentation/Modals/ConfirmationModal";
import * as utils from "../../../lib/utils";
import "./styles.scss";
import NoDataFound from "../../presentation/NoDataFound";
import {
  getBuildApprovalDetails,
  approveBuild,
  createComment,
  updateComment,
  deleteComment
} from "../../../apis/BuildApprovals";
import {
  ApprovalDetails,
  ApproveSubmissionData,
  AddComments,
  UpdateComments,
  DeleteComments
} from "../SubmittedQuestionnaire/types";
import QuestionnaireLevelApprovals from "../Approvals/QuestionnaireLevelApprovals";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useTranslation } from "react-i18next";
import { ADMIN_USER } from "../../../lib/questionnairePermissionsAccess";
import { BHY } from "../../../constants/app-constants"; 

const ReviewQuestionnaire: React.FC = () => {
  const { t } = useTranslation();
  const { assetManagerId, questionnaireId }: Params = useParams<any>();
  const [questionnaire, setQuestionnaire] = useState<Questionnaire | null>(
    null
  );
  const [commentDetails, setCommentDetails] =
    React.useState<CommentDetails | null>(null);

  const [questionnaireApprovalDetails, setQuestionnaireApprovalDetails] =
    useState<ApprovalStatus[]>([]);
  const [questionnaireStatus, setQuestionnaireStatus] = useState<string>("");
  const [reloadData, setReloadData] = useState<number>(1);
  const { userDetails }: any = useContext(UserContext);
  const [showCommentsModal, setShowCommentsModal] = useState<boolean>(false);
  const [showApprovalModal, setShowApprovalModal] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [confirmationBoxData, setConfirmationBoxData] = useState({
    title: "",
    message: "",
    callBack: () => {}
  });
  const [expandAll, setExpandAll] = useState<boolean>(true);
  const [activeKeys, setActiveKeys] = useState<string[]>([]);
  const [approvalDetails, setApprovalDetails] = useState<ApprovalDetails[]>();
  const [refreshApprovals, setRefreshApprovals] = useState(0);
  const dataSetSectionList = useMemo(
    () => questionnaire && service.getDataSetSectionsList(questionnaire),
    [questionnaire]
  );
  useEffect(() => {
    apis
      .getReviewQuestionnaire(
        service.isAssetManagerView(assetManagerId)
          ? { assetManagerId, questionnaireId }
          : { questionnaireId }
      )
      .then((response) => {
        setQuestionnaire(
          Object.assign({}, response?.data?.reviewQuestionnaire)
        );
        setQuestionnaireStatus(response?.data.questionnaireStatus);
        setQuestionnaireApprovalDetails(
          response?.data.questionnaireApprovalDetails
        );
      })
      .catch((error) => {
        toast.error(utils.formatGraphQLError(error.message));
        setQuestionnaire(null);
        setQuestionnaireStatus("");
        setQuestionnaireApprovalDetails([]);
        //console.log("getReviewQuestionnaire error is - ", err.message);
      });
  }, [assetManagerId, questionnaireId, reloadData]);

  useEffect(() => {
    (async () => {
      if (service.isAssetManagerView(assetManagerId) && questionnaireId) {
        let approvalData: any = await getBuildApprovalDetails({
          assetManagerId,
          questionnaireId
        }).catch((e) => {
          toast.error(utils.formatGraphQLError(e.message));
        });
        approvalData?.data?.getBuildApprovalDetails?.approvalStepGroupRole &&
          setApprovalDetails([
            ...approvalData?.data?.getBuildApprovalDetails
              ?.approvalStepGroupRole
          ]);
      }
    })();
  }, [assetManagerId, questionnaireId, refreshApprovals]);

  function showComments(commentData: CommentDetails) {
    setCommentDetails(commentData);
    setShowCommentsModal(true);
  }

  function saveQuestionnaireComments(comment: string) {
    addComments({
      comment: comment,
      commentableId: commentDetails?.commentableId,
      commentableType: commentDetails?.commentableType,
      questionnaireId,
      assetManagerId
    });
  }

  function addComments(commentObj: any) {
    setShowCommentsModal(false);

    apis
      .addComments(commentObj)
      .then((response) => {
        questionnaire &&
          setQuestionnaire(
            Object.assign(
              {},
              service.populateAddedComments(
                questionnaire,
                response?.data?.saveQuestionnaireReviewComment?.commentsList,
                commentObj.commentableType,
                commentObj.commentableId
              )
            )
          );
        setCommentDetails(null);
      })
      .catch((error) => {
        toast.error(utils.formatGraphQLError(error.message));
      });
  }

  function confirmApproval({ target }: { target: any }) {
    let approval = { approvalStatus: target.name };
    setConfirmationBoxData(
      Object.assign(
        {},
        {
          title: "Confirm approval",
          message: "Are you sure want to approve this questionnaire?",
          callBack: approveQuestionnaire.bind(approval)
        }
      )
    );
    setShowConfirmationModal(true);
  }

  /*function confirmSendToReview(event: any) {
    let status = { questionnaireStatus: event.target.id };
    setConfirmationBoxData(
      Object.assign(
        {},
        {
          title: "Confirm Send To Review",
          message: "Are you sure want to send this questionnaire for Review ?",
          callBack: sendForReview.bind(status)
        }
      )
    );
  }*/

  function approveQuestionnaire() {
    setShowApprovalModal(false);
    setShowConfirmationModal(false);

    apis
      .addApprovalForQuestionnaire({
        questionnaireId: questionnaireId,
        assetManagerId: assetManagerId,
        approvalStatus: this.approvalStatus
      })
      .then((response) => {
        setReloadData(reloadData + 1);
        toast.success(response?.data?.approveQuestionnaire?.data);
      })
      .catch((error) => {
        toast.error(utils.formatGraphQLError(error.message));
      });
  }

  /*function sendForReview() {
    setShowConfirmationModal(false);

    apis
      .updateStatus({
        questionnaireId: questionnaireId,
        assetManagerId: assetManagerId,
        questionnaireStatus: this.questionnaireStatus
      })
      .then((response) => {
        setReloadData(reloadData + 1);
        toast.success(response?.data?.updateQuestionnaireReviewStatus?.data);
      })
      .catch((error) => {
        toast.error(utils.formatGraphQLError(error.message));
      });
  }*/

  function getActiveKey(sectionId: string) {
    let activeKeyVal;
    if (activeKeys.indexOf(sectionId) === -1) {
      activeKeyVal = "";
    } else {
      activeKeyVal = sectionId;
    }
    //console.log("fn(getActiveKey) >> activeKeyVal - ", activeKeyVal);
    return activeKeyVal;
  }

  function addRemoveSectionById(sectionId: string) {
    let tempActiveKeys = [...activeKeys];

    if (tempActiveKeys.indexOf(sectionId as string) === -1) {
      tempActiveKeys = [...tempActiveKeys, sectionId];
    } else {
      tempActiveKeys.splice(tempActiveKeys.indexOf(sectionId as string), 1);
    }
    if (tempActiveKeys.length === 0) {
      setExpandAll(false);
    }
    //console.log("fn(addRemoveSectionById) >> tempActiveKeys - ", tempActiveKeys);
    setActiveKeys(tempActiveKeys);
  }

  function getSectionAccordionState(sectionId: string) {
    let accordionState: string;
    accordionState =
      activeKeys.indexOf(sectionId) === -1 ? "collapsed" : "open";
    return accordionState;
  }

  useEffect(() => {
    //console.log("ExpandAll >> ", expandAll);
    let sections: string[] = [];
    if (questionnaire && questionnaire.sections.length) {
      sections = questionnaire.sections
        .filter((sec) => sec.visibility)
        .map((sec) => sec.id as string);
    }

    return expandAll ? setActiveKeys(sections) : setActiveKeys([]);
  }, [expandAll, questionnaire]);
  function approveBuildDetails(data: ApproveSubmissionData) {
    approveBuild({
      ...data,
      questionnaireId,
      assetManagerId
    })
      .then(() => {
        setRefreshApprovals(refreshApprovals + 1);
        toast.success(t("Approved successfully"));
      })
      .catch((e) => {
        toast.error(utils.formatGraphQLError(e.message));
      });
  }
  async function addApprovalComments(data: AddComments) {
    createComment({
      ...data,
      questionnaireId,
      assetManagerId
    })
      .then(() => {
        setRefreshApprovals(refreshApprovals + 1);
        toast.success(t("Comment added successfully"));
      })
      .catch((e) => {
        toast.error(utils.formatGraphQLError(e.message));
      });
  }
  async function updateApprovalComments(data: UpdateComments) {
    updateComment({
      ...data,
      questionnaireId,
      assetManagerId
    })
      .then(() => {
        setRefreshApprovals(refreshApprovals + 1);
        toast.success(t("Comment updated successfully"));
      })
      .catch((e) => {
        toast.error(utils.formatGraphQLError(e.message));
      });
  }
  async function deleteApprovalComments(data: DeleteComments) {
    deleteComment({
      ...data,
      questionnaireId,
      assetManagerId
    })
      .then(() => {
        setRefreshApprovals(refreshApprovals + 1);
        toast.success(t("Comment deleted successfully"));
      })
      .catch((e) => {
        toast.error(utils.formatGraphQLError(e.message));
      });
  }
  return questionnaire ? (
    <React.Fragment>
      <PageHeader
        title={`${questionnaire.title}`}
        backLink={
          assetManagerId !== ADMIN_USER
            ? `/accountManagerInfo/${assetManagerId}/questionnairesList`
            : ""
        }
        backLinkLabel={
          assetManagerId !== ADMIN_USER ? t("Questionnaires List") : ""
        }
      />
      <main className="distributor-due-diligence-main">
        {approvalDetails && (
          <QuestionnaireLevelApprovals
            allowApprovals={true}
            onAddComment={addApprovalComments}
            onUpdateComment={updateApprovalComments}
            onDeleteComment={deleteApprovalComments}
            onApprove={approveBuildDetails}
            approvalDetails={approvalDetails}
          />
        )}

        <div className="page-content review-questionnaire">
          <div className="container">
            <div className="row mt-4">
              <div className="col-12">
                <div id="questionnaire-container">
                  <div
                    className="card card--accordion-list"
                    style={{ cursor: "pointer" }}
                  >
                    <div
                      id="root-toggle"
                      className="card__accordion-header"
                      onClick={() => {
                        setExpandAll(!expandAll);
                      }}
                    >
                      <div
                        className="card__header flex-row justify-content-between"
                        role="tab"
                      >
                        <a
                          className="card-onclick__target"
                          data-accordionstate={expandAll ? "open" : "collapsed"}
                        >
                          <h4 className="card__title">{questionnaire.title}</h4>
                        </a>
                      </div>
                      <div className="card__actions card__actions--resource">
                        <div className="action-group">
                          {service.isAssetManagerView(assetManagerId) && (
                            <div
                              className="action"
                              data-toggle="modal"
                              data-target="#cdd-add-comment-modal"
                              onClick={(event: any) => {
                                event.stopPropagation();
                                showComments({
                                  commentableId: questionnaire.id,
                                  commentableType:
                                    CommentableType.Questionnaire,
                                  comments: questionnaire.comments
                                });
                              }}
                            >
                              <span
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title={
                                  questionnaire.comments &&
                                  questionnaire.comments.length > 0
                                    ? t("View comments")
                                    : t(
                                        "Add comments regarding questions or scoring."
                                      )
                                }
                              >
                                <Icons
                                  className={`icon ${
                                    questionnaire.comments &&
                                    questionnaire.comments.length > 0
                                      ? "text-primary"
                                      : ""
                                  }`}
                                  icon={
                                    questionnaire.comments &&
                                    questionnaire.comments.length > 0
                                      ? "comment"
                                      : "add-comment"
                                  }
                                />
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div id="root-collapse">
                      <React.Fragment>
                        {questionnaire &&
                        questionnaire.sections &&
                        questionnaire.sections.length > 0
                          ? []
                              .concat(questionnaire.sections as any)
                              .sort((a: Section, b: Section) => {
                                return a.position - b.position;
                              })
                              .map((section: Section, index: number) => {
                                return (
                                  section.visibility && (
                                    <Accordion
                                      defaultActiveKey={section.id as string}
                                      activeKey={getActiveKey(
                                        section.id as string
                                      )}
                                      key={index}
                                    >
                                      <div className="card card--accordion-list card--inner">
                                        <Accordion.Toggle
                                          as="div"
                                          eventKey={section.id as string}
                                          className="card__accordion-header card-onclick"
                                          onClick={(event: any) => {
                                            event.stopPropagation();
                                            addRemoveSectionById(
                                              section.id as string
                                            );
                                          }}
                                        >
                                          <div
                                            className="card__header flex-row justify-content-between"
                                            role="tab"
                                          >
                                            <a
                                              className="card-onclick__target"
                                              data-accordionstate={getSectionAccordionState(
                                                section.id as string
                                              )}
                                            >
                                              <h5 className="card__title h5">
                                                {index + 1}. {section.title}
                                                {section.internalUseOnly && (
                                                  // <span className="badge que-badge">
                                                  //   For internal use
                                                  // </span>
                                                  <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                      <Tooltip
                                                        id={`internal-use-${
                                                          index + 1
                                                        }
                                                        `}
                                                      >
                                                        {t("For internal use")}
                                                      </Tooltip>
                                                    }
                                                  >
                                                    <span
                                                      style={{
                                                        marginLeft: "10px"
                                                      }}
                                                    >
                                                      <Icons
                                                        className="text-primary"
                                                        icon="privateInternalShield"
                                                      />
                                                    </span>
                                                  </OverlayTrigger>
                                                )}
                                              </h5>
                                            </a>
                                          </div>
                                          <div className="card__actions card__actions--resource">
                                            <div className="action-group">
                                              {service.isAssetManagerView(
                                                assetManagerId
                                              ) && (
                                                <div
                                                  className="action"
                                                  data-toggle="modal"
                                                  data-target="#cdd-add-comment-modal"
                                                  onClick={(event: any) => {
                                                    event.stopPropagation();
                                                    showComments({
                                                      commentableId: section.id,
                                                      commentableType:
                                                        CommentableType.Section,
                                                      comments: section.comments
                                                    });
                                                  }}
                                                >
                                                  <span
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    title={
                                                      section.comments &&
                                                      section.comments.length >
                                                        0
                                                        ? t("View comments")
                                                        : t(
                                                            "Add comments regarding questions or scoring."
                                                          )
                                                    }
                                                  >
                                                    <Icons
                                                      className={`icon ${
                                                        section.comments &&
                                                        section.comments
                                                          .length > 0
                                                          ? "text-primary"
                                                          : ""
                                                      }`}
                                                      icon={
                                                        section.comments &&
                                                        section.comments
                                                          .length > 0
                                                          ? "comment"
                                                          : "add-comment"
                                                      }
                                                    />
                                                  </span>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse
                                          eventKey={section.id as string}
                                        >
                                          <form
                                            className="form response response--commentable"
                                            onSubmit={(e) => e.preventDefault()}
                                          >
                                            {section.description && (
                                              <div className="form-group">
                                                <div className="response-row response-row--title">
                                                  <div className="response__cell response__cell--content ">
                                                    <div
                                                      className="response__description-text custom_edit_text"
                                                      dangerouslySetInnerHTML={{
                                                        __html: section.description
                                                      }}
                                                    ></div>
                                                  </div>

                                                  <div className="response__cell response__cell--deductions"></div>
                                                  <div className="response__cell response__cell--actions">
                                                    <div className="action-group">
                                                      <div
                                                        className="action"
                                                        data-toggle="modal"
                                                        data-target="#cdd-add-comment-modal"
                                                      ></div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )}

                                            <SectionComponent
                                              itemId={index + 1}
                                              section={section}
                                              isParent={true}
                                              showComments={
                                                service.isAssetManagerView(
                                                  assetManagerId
                                                )
                                                  ? showComments
                                                  : undefined
                                              }
                                              sectionViewType={
                                                utils.BUILD_REVIEW
                                              }
                                              currentUserId={
                                                userDetails.user_id
                                              }
                                              hiddenSections={questionnaire.sections.filter(
                                                (section: Section) =>
                                                  !section.visibility
                                              )}
                                              submittedQuestionnaireId=""
                                              deductionDetails={{
                                                showCurrentScore: false,
                                                showDefaultScore: process.env.REACT_APP_ORGANIZATION === BHY ? false : true,
                                                editScoring: false
                                              }}
                                              dataSetSectionList={
                                                dataSetSectionList || []
                                              }
                                            />
                                          </form>
                                        </Accordion.Collapse>
                                      </div>
                                    </Accordion>
                                  )
                                );
                              })
                          : questionnaire && <NoDataFound />}
                      </React.Fragment>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* Modal for comments start */}
      {commentDetails && (
        <CommentsModal
          modalTitle="Add Comments"
          comments={commentDetails?.comments}
          onSaveComment={saveQuestionnaireComments}
          onModalClose={() => {
            setShowCommentsModal(false);
            setCommentDetails(null);
          }}
          allowAddComment={true}
          showCommentsModal={showCommentsModal}
        />
      )}
      {/* Modal for approval start */}
      {questionnaireStatus && (
        <React.Fragment>
          <ApprovalModal
            modalTitle="Questionnaire Approval"
            userType={userDetails?.user_type}
            questionnaireStatus={questionnaireStatus}
            questionnaireApprovalDetails={questionnaireApprovalDetails}
            showApprovalModal={showApprovalModal}
            onModalClose={() => {
              setShowApprovalModal(false);
            }}
            onConfirmApproval={confirmApproval}
          />
          {/* Modal for confirmation box start */}
          <ConfirmationModal
            modalTitle={confirmationBoxData.title}
            message={confirmationBoxData.message}
            callBack={confirmationBoxData.callBack}
            showConfirmationModal={showConfirmationModal}
            onModalClose={() => {
              setShowConfirmationModal(false);
            }}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  ) : (
    <></>
  );
};

export default ReviewQuestionnaire;
