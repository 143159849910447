import arrowIcon from "../../../assets/images/icons/arrow-right-32.svg";
import arrowIcon16 from "../../../assets/images/icons/arrow-right-16.svg";
import arrowIcon12 from "../../../assets/images/icons/arrow-right-12.svg";
import viewIcon from "../../../assets/images/icons/circle-eye-32.svg";
import commentIcon from "../../../assets/images/icons/circle-message-32.svg";
import uploadIcon from "../../../assets/images/icons/upload-12.svg";
import addCommentIcon from "../../../assets/images/icons/circle-message-plus-32.svg";
import adminIcon from "../../../assets/images/icons/admin-32.svg";
import userIcon from "../../../assets/images/icons/user-32.svg";
import searchIcon from "../../../assets/images/icons/search-16.svg";
import penIcon from "../../../assets/images/icons/pen-12.svg";
import tickIcon from "../../../assets/images/icons/tick-12.svg";
import crossIcon from "../../../assets/images/icons/cross-32.svg";
import downloadIcon from "../../../assets/images/icons/circle-cloud-download-32.svg";
import inviteIcon from "../../../assets/images/icons/circle-invite-32.svg";
import reinviteIcon from "../../../assets/images/icons/circle-reinvite-32.svg";
import editIcon from "../../../assets/images/icons/circle-pen-32.svg";
import deleteIcon from "../../../assets/images/icons/circle-bin-32.svg";
import exclamationIcon from "../../../assets/images/icons/circle-exclamation-28.svg";
import completedIcon from "../../../assets/images/icons/complete.svg";
import pendingIcon from "../../../assets/images/icons/pending.svg";
import lockedIcon from "../../../assets/images/icons/locked.svg";
import errorIcon from "../../../assets/images/icons/error.svg";
import collapseSideBarIcon from "../../../assets/images/icons/collapse-sidebar.svg";
import expandSideBarIcon from "../../../assets/images/icons/expand-sidebar.svg";
import infoIcon from "../../../assets/images/icons/info.svg";
import savingIcon from "../../../assets/images/icons/saving.svg";
import helpVideosIcon from "../../../assets/images/icons/help-videos.svg";
import addSectionIcon from "../../../assets/images/icons/add-section-32.svg";
import deleteSectionIcon from "../../../assets/images/icons/delete-section-32.svg";
import circleArrowRight from "../../../assets/images/icons/circle-arrow-right-32.svg";
import sectionAssignmentIcon from "../../../assets/images/icons/section-assignment-32.svg";
import uboUser16 from "../../../assets/images/icons/ubo-user-16.svg";
import homeEntity16 from "../../../assets/images/icons/home-intity-16.svg";
import addEntity from "../../../assets/images/icons/addEntity.svg";
import navArrowRight16 from "../../../assets/images/icons/nav-arrow-right-16.svg";
import warningTriangle from "../../../assets/images/icons/warningTriangle.svg";
import chevronLeft12 from "../../../assets/images/icons/chevron-left-12.svg";
import plus16 from "../../../assets/images/icons/plus-16.svg";
import minus16 from "../../../assets/images/icons/minus-16.svg";
import circleApprovedView from "../../../assets/images/icons/circle-approve-view-32.svg";
import circleUnapprovedView from "../../../assets/images/icons/circle-unapproved-view-32.svg";
import smallCommentMessage from "../../../assets/images/icons/small-comment-message.svg";
import circleBigTimeConfirm from "../../../assets/images/icons/circle-big-time-confirm.svg";
import privateInternalShield from "../../../assets/images/icons/private-Internal-circle-32.svg";
import activeDocument from "../../../assets/images/icons/active-document.svg";
import inactiveDocument from "../../../assets/images/icons/inactive-document.svg";
import infoIcon32 from "../../../assets/images/icons/info-32.svg";
import circleStatusChange from "../../../assets/images/icons/circle-status-change-32.svg";
import regeneratePDFIcon from "../../../assets/images/icons/circle-repeat-32.svg";
import circleMenuIcon from "../../../assets/images/icons/circle-dot-18.svg";
import changeStatusIcon from "../../../assets/images/icons/change-status-16.svg";
import darkDownloadIcon from "../../../assets/images/icons/dark-download-16.svg";
import lineArrowRight from "../../../assets/images/icons/line-arrowr-right-16.svg";
import regeneratePdf from "../../../assets/images/icons/regenerate-pdf.svg";
import notifications from "../../../assets/images/icons/notifications.svg";
import unlockedIcon from "../../../assets/images/icons/unlock-20.svg";
import greenDot from "../../../assets/images/icons/green-dot.svg";
import orangeDot from "../../../assets/images/icons/orange-dot.svg";
export function getIcon(icon: string) {
  switch (icon) {
    case "comment":
      return commentIcon;
    case "add-comment":
      return addCommentIcon;
    case "view":
      return viewIcon;
    case "upload":
      return uploadIcon;
    case "admin":
      return adminIcon;
    case "user":
      return userIcon;
    case "search":
      return searchIcon;
    case "pen":
      return penIcon;
    case "tick":
      return tickIcon;
    case "cross":
      return crossIcon;
    case "close":
      return crossIcon;
    case "download":
      return downloadIcon;
    case "invite":
      return inviteIcon;
    case "reinvite":
      return reinviteIcon;
    case "edit":
      return editIcon;
    case "delete":
      return deleteIcon;
    case "exclamation":
      return exclamationIcon;
    case "complete":
      return completedIcon;
    case "pending":
      return pendingIcon;
    case "locked":
      return lockedIcon;
    case "error":
      return errorIcon;
    case "collapse-sidebar":
      return collapseSideBarIcon;
    case "expand-sidebar":
      return expandSideBarIcon;
    case "info":
      return infoIcon;
    case "saving":
      return savingIcon;
    case "add-section":
      return addSectionIcon;
    case "delete-section":
      return deleteSectionIcon;
    case "circleArrowRight":
      return circleArrowRight;
    case "section-assignment":
      return sectionAssignmentIcon;
    case "uboUser-16":
      return uboUser16;
    case "homeEntity-16":
      return homeEntity16;
    case "arrow-right-16":
      return arrowIcon16;
    case "arrow-right-12":
      return arrowIcon12;
    case "addEntity":
      return addEntity;
    case "navArrowRight16":
      return navArrowRight16;
    case "warningTriangle":
      return warningTriangle;
    case "chevronLeft12":
      return chevronLeft12;
    case "plus":
      return plus16;
    case "minus":
      return minus16;
    case "arrow-right":
      return arrowIcon;
    case "circleApprovedView":
      return circleApprovedView;
    case "circleUnapprovedView":
      return circleUnapprovedView;
    case "smallCommentMessage":
      return smallCommentMessage;
    case "circleBigTimeConfirm":
      return circleBigTimeConfirm;
    case "privateInternalShield":
      return privateInternalShield;
    case "activeDocument":
      return activeDocument;
    case "inactiveDocument":
      return inactiveDocument;
    case "infoIcon32":
      return infoIcon32;
    case "circleStatusChange":
      return circleStatusChange;
    case "regeneratePDF":
      return regeneratePDFIcon;
    case "help-videos":
      return helpVideosIcon;
    case "circleMenuIcon":
      return circleMenuIcon;
    case "changeStatusIcon":
      return changeStatusIcon;
    case "darkDownloadIcon":
      return darkDownloadIcon;
    case "lineArrowRight":
      return lineArrowRight;
    case "regeneratePdf":
      return regeneratePdf;
    case "notifications":
      return notifications;
    case "unlocked":
      return unlockedIcon;
    case "greenDot":
      return greenDot;
    case "orangeDot":
      return orangeDot;
    default:
      return "";
  }
}
