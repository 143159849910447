import React, { Fragment, FunctionComponent } from "react";
import { OrgVisualizationNode } from "../types";
import RoundedRect from "../RoundedRect";
import { formatPercentage } from "../service";
import { InfoRow } from "./types";
import styles from "../css/styles";
import { getFallbackStyle } from "./service";
import { useTranslation } from "react-i18next"

const DropdownMenu: FunctionComponent<{
  node: OrgVisualizationNode;
  width: number;
  filterId: string;
  nodeSelected: boolean;
}> = ({ node, width, filterId, nodeSelected }) => {
  const rowSpace = 28;
  const px = 20;
  const py = 30;
  const { t } = useTranslation();
  const rows: InfoRow[] = [
    {
      label: "Voting rights:",
      value: formatPercentage(node.auxInformation.voting),
      className: styles.relative
    },
    {
      label: "Shares held:",
      value: formatPercentage(node.auxInformation.holdings),
      className: styles.relative
    },
    {
      label: "Overall voting rights:",
      value: formatPercentage(node.auxInformation.votingAbsolute),
      className: styles.overall
    },
    {
      label: "Overall shares held:",
      value: formatPercentage(node.auxInformation.holdingsAbsolute),
      className: styles.overall
    }
  ];

  const colOffset = width - px * 2;
  const height = rowSpace * rows.length + py;
  const r = 5;

  return (
    <g className={styles.dropdown}>
      <RoundedRect
        corners={[0, 0, r, r]}
        width={width}
        height={height}
        filter={`url(#${filterId})`}
        style={getFallbackStyle(nodeSelected)}
      />
      <g transform={`translate(${px},${py})`}>
        {rows.map((row, index) => (
          <Fragment key={row.label}>
            <text y={index * rowSpace} className={row.className}>
              {t(row.label)}
            </text>
            <text
              x={colOffset}
              y={index * rowSpace}
              textAnchor="end"
              className={row.className}
            >
              {row.value}
            </text>
          </Fragment>
        ))}
      </g>
    </g>
  );
};

export default DropdownMenu;
