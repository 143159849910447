import gql from "graphql-tag";

export const ADD_COMMENT_QUERY = gql`
  mutation CreateApprovalComments($input: CreateApprovalCommentInput!) {
    createApprovalComments(input: $input) {
      message
    }
  }
`;
export const UPDATE_COMMENT_QUERY = gql`
  mutation UpdateApprovalComment($input: UpdateApprovalCommentInput!) {
    updateApprovalComment(input: $input) {
      message
    }
  }
`;
export const DELETE_COMMENT_QUERY = gql`
  mutation DeleteApprovalComment($input: DeleteApprovalCommentInput!) {
    deleteApprovalComment(input: $input) {
      message
    }
  }
`;
export const APPROVE_SUBMISSION = gql`
  mutation ApproveSubmission($input: ApproveSubmissionInput!) {
    approveSubmission(input: $input) {
      message
    }
  }
`;

export const GET_SUBMISSION_APPROVAL_DETAILS_QUERY = gql`
  query GetSubmissionApprovalDetails(
    $sectionId: ID
    $submittedQuestionnaireId: ID!
  ) {
    getSubmissionApprovalDetails(
      sectionId: $sectionId
      submittedQuestionnaireId: $submittedQuestionnaireId
    ) {
      approvalStepGroupRole {
        accountGroup {
          id
          name
        }
        approvalComments {
          comment
          createdAt
          id
          updatedAt
          user {
            email
            name
            userId
          }
        }
        approvalStep {
          approvalName
          approvalType {
            id
            name
          }
          id
          sequence
        }
        approval {
          approvalStatus
          approvedBy
          approvedById
          approvedAt
          id
        }
        id
      }
    }
  }
`;
