import React, { useState, useEffect } from "react";

interface Props {
  id: number | string;
  name: string;
  label?: string;
  value: string;
  isDisabled?: boolean;
  selection: string;
  allowDeSelect?: boolean;
  onChange?: ({ target }: { target: any }) => void;
}
const RadioButton: React.FC<Props> = ({
  id,
  label,
  value,
  isDisabled = false,
  name,
  selection,
  onChange,
  allowDeSelect = false
}) => {
  const [radioSelection, setRadioSelection] = useState<string>();
  useEffect(() => {
    setRadioSelection(selection);
  }, [selection]);
  return (
    <div className="custom-control custom-radio">
      <input
        type="radio"
        name={name}
        id={`radio-${id}`}
        className="custom-control-input"
        value={value ? value.toString() : ""}
        disabled={isDisabled}
        checked={radioSelection === value}
        // onChange={(e) => {
        //   onChange && onChange(e);
        // }}
        onClick={(e: any) => {
          if (allowDeSelect && selection && radioSelection === e.target.value) {
            setRadioSelection("");
            e.target.value = "";
            e.target.checked = false;
          } else {
            setRadioSelection(e.target.value);
          }
          onChange && onChange(e);
        }}
      />
      {label && (
        <label className="custom-control-label" htmlFor={`radio-${id}`}>
          {label}
        </label>
      )}
    </div>
  );
};
export default RadioButton;
