/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from "react";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import "./styles.scss";
import { useQueryParams } from "../../../Hooks/useQueryParams";

type tabDetails = {
  className: string;
  role: string;
  ariaControls: string;
  ariaSelected: boolean;
  tabName: string;
  tabLink: string;
  visible: boolean;
};

interface Props {
  title?: string;
  details?: string[];
  tabs?: tabDetails[];
  backLink?: string;
  backLinkLabel?: string;
  handleTabChange?: (tabName: string, tabLink: string) => void;
  headerButtons?: any;
  accountLogo?: string;
  headerLeftMenu?: any;
  headerLeftMenuTriggerButton?: any;
}

const PageHeader: React.FC<Props> = ({
  title,
  details,
  tabs,
  backLink,
  backLinkLabel,
  handleTabChange,
  children,
  headerButtons,
  accountLogo,
  headerLeftMenu,
  headerLeftMenuTriggerButton
}) => {
  const { t } = useTranslation();
  const [subNavClassName, setSubNavClassName] = useState<string>("");
  const [showMenu, setShowMenu] = useState(false);
  function toggle() {
    setShowMenu(!showMenu);
  }
  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);
  const node = useRef<HTMLDivElement>(null);
  // Remove jwt_token if present in url
  useQueryParams({
    paramNames: ["jwt_token"]
  });

  function toggleSubNavClass() {
    subNavClassName
      ? setSubNavClassName("")
      : setSubNavClassName("sub-nav--open");
  }
  const handleClick = (e: any) => {
    if (node.current && node.current.contains(e.target)) {
      return;
    }
    // outside click
    // ... do whatever on click outside here ...
    setShowMenu(false);
  };
  return (
    <div className="page-header">
      <div className="container">
        <div className="row">
          <Col className="col-12 col-md-12 page-header__details">
            <div className="heading-leftRight-colum">
              <div className="heading-left-box">
                {backLink && backLinkLabel && (
                  <div className="page-header__backlink backlink--page">
                    <RouterLink to={backLink}>{backLinkLabel}</RouterLink>
                  </div>
                )}
                <div className="page-header__content">
                  {title && (
                    <h1
                      className={`h2 page-header__title mt-3 ${
                        showMenu ? "show" : ""
                      } inline-block`}
                      ref={node}
                    >
                      {t(title)}
                      <div className="relative inline-block">
                        <span
                          className="menu ml-3"
                          onClick={(e) => {
                            e.stopPropagation();
                            toggle();
                          }}
                        >
                          {headerLeftMenuTriggerButton}
                        </span>
                        <div
                          className={`dropdown-menu mt-0 icon-dropdown ${
                            showMenu ? "show" : ""
                          } menu-options`}
                        >
                          {headerLeftMenu}
                        </div>
                      </div>
                    </h1>
                  )}
                  <div className="details__list">
                    {details?.map((detail, i) => (
                      <span key={i} className="detail">
                        {detail}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
              {headerButtons && (
                <div className="heading-right-box">
                  <div className="action-group flex-end">{headerButtons}</div>
                </div>
              )}
            </div>
          </Col>
          {tabs && (
            <Col
              sm={12}
              lg="auto"
              className="ml-lg-auto align-self-end page-header__nav"
            >
              {accountLogo && (
                <div className="page-header__logo header-logo-width">
                  <img src={accountLogo} alt="" />
                </div>
              )}
              <ul className={`nav sub-nav flex-end ${subNavClassName}`}>
                {tabs?.map(
                  (item, i) =>
                    item.visible && (
                      <li className="nav-item" key={i}>
                        <a
                          className={item.className}
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleTabChange &&
                              handleTabChange(item.tabName, item.tabLink);
                            toggleSubNavClass();
                          }}
                          tabIndex={0}
                          role={item.role}
                          aria-controls={item.ariaControls}
                          aria-selected={item.ariaSelected}
                        >
                          {t(item.tabName)}
                        </a>
                      </li>
                    )
                )}
              </ul>
            </Col>
          )}
        </div>
        {children}
      </div>
    </div>
  );
};

export default PageHeader;
