import React, { useState, useEffect, useRef } from "react";
import CustomModal from "../../presentation/Modals/CustomModal";
import { PublicPrivateCommentsType } from "../QuestionComponent/types";
import * as apis from "../../../apis/PublicPrivateComments";
import { toast } from "react-toastify";
import { formatGraphQLError, getDate } from "../../../lib/utils";
import Icons from "../../presentation/Icons";
import { useTranslation } from "react-i18next";
import { useQueryParams } from "../../../Hooks/useQueryParams";
import { QueryPrameters } from "../../../types/applicationData";
type Props = {
  //getPageHeaderDetails: (pageDetails: any) => void;
  // comments

  submittedQuestionnaireId?: string | number;
  accountQuestionnaireId?: string | number;
  commentDetails?: PublicPrivateCommentsType[];
  commentableId: string | number;
  commentableType: string;
  onClose: (commentsList: any) => void;
  currentUserId: string | number;
  allowComments?: {
    showPublicComment?: boolean;
    showPrivateComment?: boolean;
    editPublicComment?: boolean;
    editPrivateComment?: boolean;
  };
};
const PublicPrivateComments: React.FC<Props> = ({
  commentDetails,
  submittedQuestionnaireId,
  accountQuestionnaireId,
  commentableId,
  commentableType,
  currentUserId,
  allowComments,
  onClose
}) => {
  const queryParams: QueryPrameters | undefined = useQueryParams({
    paramNames: ["commentId"]
  });
  const commentRef = useRef<any>();
  const isScrollingDone = useRef<boolean>(false);
  const { t } = useTranslation();
  const [commentList, setCommentList] = useState<PublicPrivateCommentsType[]>(
    commentDetails ? commentDetails : []
  );
  const [commentType, setCommentType] = useState<string>("Public");
  const [comments, setComments] = useState<string>();
  const [editedComments, setEditedComments] = useState<string>();
  useEffect(() => {
    if (queryParams?.commentId && !isScrollingDone.current) {
      commentRef?.current?.scrollIntoView(true);
      isScrollingDone.current = true;
    }
  });
  function handleCommentTypeChange(commentType: string) {
    setCommentType(commentType);
  }
  async function deleteComment(id: number | string, userId: number | string) {
    if (id && userId.toString() === currentUserId.toString()) {
      let response: any = await apis
        .deleteComment({
          id
        })
        .catch((e) => {
          toast.error(formatGraphQLError(e.message));
        });
      if (response?.data?.deletePublicPrivateComment?.commentsList) {
        setCommentList([
          ...response?.data?.deletePublicPrivateComment?.commentsList
        ]);
      }
    }
  }
  function enableDisableEdit(
    comments: PublicPrivateCommentsType,
    type: string
  ) {
    let commentIndex = commentList.findIndex((val) => val.id === comments.id);
    let updatedCommentList = [...commentList];
    updatedCommentList[commentIndex] = {
      ...comments,
      enableEdit: type === "enable" ? true : false
    };
    setCommentList([...updatedCommentList]);
    if (type === "enable") {
      setEditedComments(comments.comment);
    }
  }

  async function updatedComment(
    comment: PublicPrivateCommentsType,
    type: string
  ) {
    if (type === "save") {
      if (editedComments) {
        if (
          comment.id &&
          comment.user.userId.toString() === currentUserId.toString()
        ) {
          let response: any = await apis
            .updateComment({
              id: comment.id,
              comment: editedComments
            })
            .catch((e) => {
              toast.error(formatGraphQLError(e.message));
            });
          if (response?.data?.updatePublicPrivateComment?.commentsList) {
            setEditedComments("");
            setCommentList([
              ...response?.data?.updatePublicPrivateComment?.commentsList
            ]);
          }
        }
      } else {
        toast.error(t("Please enter comments"));
      }
    } else {
      enableDisableEdit(comment, "disable");
      setEditedComments("");
    }
  }
  async function addComments() {
    if (comments?.trim()) {
      let response: any = await apis
        .createComment({
          submittedQuestionnaireId,
          accountQuestionnaireId,
          commentType: commentType,
          commentableId,
          commentableType,
          comment: comments.trim()
        })
        .catch((e) => {
          toast.error(formatGraphQLError(e.message));
        });
      if (response?.data?.createPublicPrivateComments?.commentsList) {
        setComments("");
        setCommentList([
          ...response?.data?.createPublicPrivateComments?.commentsList
        ]);
      }
    } else {
      toast.error(t("Please enter comments"));
    }
  }
  return (
    <>
      <CustomModal
        modalTitle={t("Comments")}
        classNames="cdd-groups-management cdd-auto-feature editable"
        showModal={true}
        onClose={() => {
          onClose(commentList);
        }}
        showFooter={true}
        footer={
          <div className="comments">
            {commentList && commentList.length > 0
              ? []
                  .concat(commentList as any)
                  .sort(
                    (
                      a: PublicPrivateCommentsType,
                      b: PublicPrivateCommentsType
                    ) => {
                      return (
                        new Date(a.createdAt).valueOf() -
                        new Date(b.createdAt).valueOf()
                      );
                    }
                  )
                  .map((comment: PublicPrivateCommentsType, index: number) => {
                    return (
                      <div
                        key={index}
                        id={`ppc-individual-comment-${comment.id}`}
                        className={`comment ${
                          comment.commentType.name === "Private"
                            ? "comment--private"
                            : ""
                        }`}
                        ref={commentRef}
                      >
                        <div className="comment__meta">
                          <span className="mr-1">{comment.user.name}</span>
                          <span className="text-muted">
                            {getDate(
                              comment.createdAt,
                              "MMMM Do YYYY, h:mm:ss a"
                            )}{" "}
                            {/* {getTime(comment.createdAt)} */}
                          </span>
                          <span className="comment__private-note">
                            <span className="text-primary">
                              {comment.commentType.name === "Private"
                                ? comment.commentType.name + " note"
                                : ""}
                            </span>
                            {(allowComments?.editPrivateComment ||
                              allowComments?.editPublicComment) &&
                            !comment.enableEdit &&
                            comment.user.userId.toString() ===
                              currentUserId.toString() ? (
                              <>
                                <span
                                  className="text-primary"
                                  onClick={() =>
                                    enableDisableEdit(comment, "enable")
                                  }
                                >
                                  <Icons className="icon" icon={"edit"} />
                                </span>
                                <span
                                  className="text-primary"
                                  onClick={() =>
                                    deleteComment(
                                      comment.id,
                                      comment.user.userId
                                    )
                                  }
                                >
                                  <Icons className="icon" icon={"delete"} />
                                </span>
                              </>
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                        {comment.enableEdit ? (
                          <div className="comment">
                            <div className="comment__content">
                              <div className="form-group">
                                <textarea
                                  //type="text"
                                  className="form-control form-control--bordered"
                                  rows={6}
                                  placeholder={t("Enter your comment here")}
                                  onChange={(data) =>
                                    setEditedComments(data.target.value)
                                  }
                                  value={editedComments}
                                ></textarea>
                              </div>
                              <button
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={() => updatedComment(comment, "save")}
                              >
                                {t("Save")}
                              </button>
                              <button
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={() =>
                                  updatedComment(comment, "cancel")
                                }
                              >
                                {t("Cancel")}
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="comment__content">
                            {comment.comment}
                          </div>
                        )}
                      </div>
                    );
                  })
              : t("No comments available")}
          </div>
        }
      >
        {(allowComments?.editPrivateComment ||
          allowComments?.editPublicComment) && (
          <div className="modal__body">
            <form>
              <div className="comment-tabs">
                {allowComments?.editPublicComment && (
                  <>
                    <input
                      type="radio"
                      id="public"
                      name="commentType"
                      value="public"
                      // onChange={handleCommentTypeChange}
                      defaultChecked={commentType === "Public"}
                    />
                    {allowComments?.editPrivateComment &&
                      allowComments?.editPublicComment && (
                        <label
                          htmlFor="public"
                          className="comment-tabs__label mr-3"
                          onClick={() => handleCommentTypeChange("Public")}
                        >
                          {t("Public reply")}
                        </label>
                      )}
                  </>
                )}
                {allowComments?.editPrivateComment && (
                  <>
                    <input
                      type="radio"
                      id="private"
                      name="commentType"
                      value="private"
                      defaultChecked={commentType === "Private"}
                    />
                    <label
                      htmlFor="private"
                      className="comment-tabs__label mr-3"
                      onClick={() => handleCommentTypeChange("Private")}
                    >
                      {t("Private note")}
                    </label>
                  </>
                )}
              </div>

              <div className="form-group">
                <textarea
                  //type="text"
                  className="form-control form-control--bordered"
                  rows={6}
                  placeholder={t("Enter your comment here")}
                  value={comments || ""}
                  onChange={(data) => setComments(data.target.value)}
                ></textarea>
              </div>
            </form>
            <button
              className="btn btn-primary"
              onClick={addComments}
              disabled={comments ? false : true}
            >
              {t("Add comment")}
            </button>
          </div>
        )}
      </CustomModal>
    </>
  );
};
export default PublicPrivateComments;
