import client from "../apollo.config";
import * as queries from "./queries";

export const createComment = ({
  submittedQuestionnaireId,
  accountQuestionnaireId,
  commentType,
  commentableId,
  commentableType,
  comment
}: {
  submittedQuestionnaireId?: string | number;
  accountQuestionnaireId?: string | number;
  commentType: string | number;
  commentableId: string | number;
  commentableType: string | number;
  comment: string;
}) => {
  return client.mutate({
    mutation: queries.CREATE_COMMENTS_QUERY,
    variables: {
      input: {
        submittedQuestionnaireId,
        accountQuestionnaireId,
        commentType,
        commentableId,
        commentableType,
        comment
      }
    }
  });
};

export const updateComment = ({
  id,
  comment
}: {
  id: number | string;
  comment: string;
}) => {
  return client.mutate({
    mutation: queries.UPDATES_COMMENTS_QUERY,
    variables: { input: { id, comment } }
  });
};
export const deleteComment = ({ id }: { id: number | string }) => {
  return client.mutate({
    mutation: queries.DELETE_COMMENTS_QUERY,
    variables: { input: { id } }
  });
};
