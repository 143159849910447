import React, { useRef, useState, useEffect, useContext } from "react";
import {
  ApprovalDetails,
  ApproveSubmissionData
} from "../../SubmittedQuestionnaire/types";
import { UserContext } from "../../../../Contexts/UserContext";
import { identifyApprovalSteps } from "./service";
import { ApproveButtonData } from "./types";
import CustomModal from "../../../presentation/Modals/CustomModal";
import { parseApproveDetails } from "./service";
import Icons from "../../../presentation/Icons";
import { FOUR_EYE_CHECK } from "../../../../constants/app-constants";
import { useTranslation } from "react-i18next";
type Props = {
  approvalDetails: ApprovalDetails[];
  buttonName: string;
  onApprove: (approveData: ApproveSubmissionData) => void;
};

const ApprovalButton: React.FC<Props> = ({
  approvalDetails,
  buttonName,
  onApprove
}) => {
  const { t } = useTranslation();
  const { userDetails }: any = useContext(UserContext);
  const [toggle, setToggle] = useState<boolean>(false);
  const [approveData, setApproveData] = useState<ApproveButtonData[]>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedStepToApprove, setSelectedStepToApprove] =
    useState<ApproveButtonData | null>(null);
  const node = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);
  const handleClick = (e: any) => {
    if (node.current && node.current.contains(e.target)) {
      return;
    }
    // outside click
    // ... do whatever on click outside here ...
    setToggle(false);
  };
  useEffect(() => {
    let identifiedData: any = identifyApprovalSteps({
      approvalDetails,
      loggedInUser: userDetails
    });
    identifiedData && setApproveData([...identifiedData]);
  }, [approvalDetails]);

  function approveSubmissionData() {
    selectedStepToApprove &&
      onApprove(parseApproveDetails(selectedStepToApprove));
    setShowModal(false);
  }
  return approveData ? (
    <>
      {approveData.length > 1 ? (
        <div className="approve-heading-btn-wrp">
          <div className="approve-heading-btn">
            <div className={`dropdown ${toggle ? "show" : ""}`} ref={node}>
              <button
                className="btn btn-primary dropdown-toggle"
                type="button"
                id="dropdownMenu2"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={(e) => {
                  setToggle(!toggle);
                  e.stopPropagation();
                }}
              >
                {t(buttonName)}
              </button>
              <div
                className={`dropdown-menu ${toggle ? "show" : ""}`}
                aria-labelledby="dropdownMenu2"
              >
                {approveData.map((step, index) => {
                  return (
                    <button
                      onClick={(e) => {
                        setShowModal(true);
                        setSelectedStepToApprove(step);
                        setToggle(false);
                        e.stopPropagation();
                      }}
                      className="dropdown-item"
                      type="button"
                      key={index}
                    >
                      <div className="step-approval-text-heading">
                        {step.title}
                      </div>
                      <div className="step-approval-sub-heading">
                        {step.group}{" "}
                        {step.approvalType === FOUR_EYE_CHECK
                          ? `(${step.approvalType})`
                          : ""}
                      </div>
                    </button>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <button
          className="btn btn-primary"
          onClick={(e) => {
            setShowModal(true);
            approveData[0] && setSelectedStepToApprove(approveData[0]);
            e.stopPropagation();
          }}
          disabled={!approveData?.length}
        >
          {t(buttonName)}
        </button>
      )}
      {selectedStepToApprove && (
        <CustomModal
          // modalTitle={datasetSection.title}
          classNames="modal modal--large"
          showModal={showModal}
          onClose={() => {
            setShowModal(false);
            setSelectedStepToApprove(null);
          }}
          showFooter={true}
          footer={
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <button
                className="btn btn-outline-primary"
                onClick={(e) => {
                  setShowModal(false);
                  setSelectedStepToApprove(null);
                }}
              >
                {t("Cancel")}
              </button>
              <button
                className="btn btn-primary"
                onClick={(e) => {
                  approveSubmissionData();
                }}
              >
                {t("Confirm")}
              </button>
            </div>
          }
        >
          <form
            className="form"
            data-form-sections-id="create-rules"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <div className="approval-section-modal-in">
              <div className="approval-section-modal-in-icon">
                <Icons icon={"circleBigTimeConfirm"} />
              </div>
              <div className="approval-section-modal-in-heading">
                {t("Confirm Approval")}
              </div>
              <div className="approval-section-modal-in-text">
                {t(`You are performing {{title}} approval`, {
                  title: selectedStepToApprove.title
                })}
              </div>
            </div>
            <div className="form-section mb-0" data-form-section-id="rule">
              <div className="form-row">
                <div className="col-12 mt-2">
                  <textarea
                    onChange={(e) => {
                      selectedStepToApprove &&
                        setSelectedStepToApprove({
                          ...selectedStepToApprove,
                          comment: e.target.value
                        });
                      e.stopPropagation();
                    }}
                    className="form-control form-control--bordered mb-0"
                    rows={4}
                    placeholder={t("Enter your comment")}
                  ></textarea>
                </div>
              </div>
            </div>
          </form>
        </CustomModal>
      )}
    </>
  ) : (
    <></>
  );
};
export default ApprovalButton;
